import React, { useRef } from "react";

import Slider from "react-slick";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";

import ReviewsBreakDown from "./ReviewsBreakDown";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: "#ffcd3c",
  boxShadow: "none",
}));

const Testimonials = () => {
  let sliderRef = useRef(null);

  const playSlider = () => {
    sliderRef.slickPlay();
  };
  const pauseSlider = () => {
    sliderRef.slickPause();
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2500,
    // pauseOnHover: playSlider,
    cssEase: "linear",
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log("playSlider==>", playSlider);
  return (
    <>
      {/* <Row>
      <Col>
        <div className=" text-center">
          <div className="mb-5">
            <p className="notify_header">Educational</p>
            <h3 className="sub_header">Real stories about title fraud</h3>
          </div>
        </div>
      </Col>
    </Row> */}
      <div className="testimonialWrapper">
        <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`This service gives me great comfort!  I'm glad I heard Mike speak about your offerings in his program after two scammers attempted to establish residency at my new home, which I paid in full after selling my ranch.  There's always that latent anxiety of the next scammer... now that gone with Equity Protect!  it's a sage investment.`}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Adam M. Cox</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`My goal in August of 2023 was to find a company that would actually prevent deed fraud rather than monitoring only.
                              As an older person, I try to stay on top of my security. I had tried a well known company that monitors and will notify you 
                              of any change in title. However, if something does occur, it is after the fact.<br/><br/>
                              When I found EquityProtect, I was finally able to sign up with a company that would not only prevent deed fraud, but 
                              would keep track of anything going on with my title. This will be helpful if I decide to sell my home at a later date. 
                              EquityProtect recorded a document with my county recorder. That remains there until I want it removed.<br/><br/>
                              EquityProtect answered all my questions. I have a token in place to verify my identity if its needed. I feel like they have
                              covered all the bases. I would highly recommend this company and I am definitely a satisfied customer.`}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Bonnie W</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/testimonials/Christine.WebP")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}

                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`Being the owner of a title company, I hear a lot about
                    attempts at owner/title fraud and – in some cases successful
                    – stealing of homes almost daily now. Recently, I was made
                    aware of a story where an investment property was sold out
                    from under a property owner. The owners were unaware of the
                    fraud until they were sent the final sale documents at their
                    mailing address! I can only imagine what gut-twisting
                    feeling of fear and terror this letter must have given these
                    poor people. EquityProtect would have blocked this
                    transaction from occurring and saved these owners from what
                    would have turned into many hours of litigating and endless
                    worry.`}
                  />

                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Christine A</p>
                <p className="companyName">Company Owner - NV</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/testimonials/Christine.WebP")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`
                    As a Broker, we are always looking for ways to protect our
                    agents and customers, and with the market slowing, we’ve
                    seen a serious increase in property fraud attempts – like
                    fraudsters trying to list and sell vacant land to second
                    homes. All these property owners would have been protected
                    from even the mere attempt by EquityProtect. So I
                    wholeheartedly recommend every property owner protect their
                    asset with EquityProtect.
                  `}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Sue Lowe</p>
                <p className="companyName">Company Owner</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/testimonials/Sue_Lowe.WebP")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>

          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}

                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`EquityProtect has been an invaluable partner in
                    safeguarding my home ownership and peace of mind. As a
                    74-year-old homeowner, protecting my property is paramount,
                    and EquityProtect goes above and beyond in this regard.
                    Their innovative solution actively prevents deed and home
                    title theft, offering a level of security unmatched by other
                    companies we have used. With EquityProtect, I feel confident
                    that my home and equity are protected from scammers,
                    allowing me to enjoy my retirement without worrying about
                    the safety of my property. I highly recommend EquityProtect
                    to anyone looking for comprehensive protection and peace of
                    mind for their home ownership.`}
                  />

                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Dennis Roccaforte</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}

                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`As a homeowner in my golden years, safeguarding my property
                    and financial investments is paramount. EquityProtect has
                    been a revelation for me. Their pioneering technology goes
                    beyond mere monitoring; it actively shields my home title
                    and equity from the ever-looming threats of cybercriminals
                    and fraudsters. With EquityProtect, I feel confident knowing
                    that my property ownership is fortified against deed and
                    home title theft, thanks to their innovative approach to
                    prevention. I wholeheartedly endorse EquityProtect as the
                    premier solution for anyone seeking to secure their home and
                    assets effectively.`}
                  />

                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Dennis Lynn</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}

                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`EquityProtect has been a game-changer for me as a homeowner
                    in Florida. At 65, I've seen my fair share of security
                    threats, but the peace of mind this company provides is
                    unparalleled. Knowing that my home title and equity are
                    safeguarded against cybercriminals and fraud gives me
                    immense relief. EquityProtect doesn't just monitor, they
                    take proactive steps to prevent deed and home title theft,
                    and that's something I value greatly. I highly recommend
                    EquityProtect to anyone looking to protect their most
                    valuable asset.`}
                  />

                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Gary Yeoman</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>

          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`
                    When your identity is stolen its very hard to find help with
                    all the things you have to do cause nobody wants to help.
                    **** with ************** was amazing. The criminals have
                    stolen over $160k in cc and bank money from us and opened
                    card and were moving on to securing home equity lines of
                    credit. This was the easiest company to work for in regards
                    to caring how fast we needed to move cause the criminals
                    move very fast. Thank you **** for being such a rockstar and
                    a great human being. The world needs more people that can
                    make decisions fast and actually show you they give a s***
                    about their customers instead of just creative writing on
                    their website that says one thing to sell you but then do
                    another when you do business. Thank you **** and
                    **************.
                  `}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Rory A.</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`
                    So happy we have ************** on our Home! They were so
                    helpful and easy to work with!! No brainer, were getting
                    ready to renew another year with **************. I highly
                    recommended this company.
                  `}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Carol P.</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`
                    The experience with ************** was wonderful. I would
                    highly recommend it.
                `}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Heather</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`
                    EquityProtect is a great service, providing peace of mind
                    that no other service can match.
                  `}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- Dennis R.</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
          <div className="testimonialCard h-100">
            <div className="testimonials h-100 d-flex flex-column">
              <div className="contentWrapper d-flex flex-column">
                <div className="content position-relative">
                  {/* <LazyLoadImage
                    src={QuoteStart}
                    className="QuoteStart"
                    alt="Quotation Start"
                  /> */}
                  <ReviewsBreakDown
                    pauseSlider={pauseSlider}
                    playSlider={playSlider}
                    content={`
                    Once I realized how common and easy real estate theft can
                    happen, I knew I needed to find someone to help me protect
                    my investment. EquityProtect was a no ******** for me. Easy
                    to sign up, navigate the website, and awesome customer
                    service. Everybody should safe guard with EquityProtect!
                  `}
                  />
                  {/* <LazyLoadImage
                    src={QuoteEnd}
                    className="QuoteEnd"
                    alt="Quotation End"
                  /> */}
                </div>
                <p className="authorName mb-1 mt-auto">- David W.</p>
                <p className="companyName">Customer</p>
              </div>
              <div className="author d-flex flex-column align-items-center justify-content-center">
                <div
                  className="authorImg mb-1"
                  style={{
                    backgroundImage: `url(${require("../../assets/dashboard/Dummy_Profile_photo.png")})`,
                  }}
                ></div>
                <Stack direction="row" spacing={0}>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                  <Item>
                    <StarIcon />
                  </Item>
                </Stack>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};
export default React.memo(Testimonials);
