import { put, takeLatest, call } from "redux-saga/effects";
import { postAPIPayload } from "../../../apis/postApi";
import { getAPIPayload } from "../../../apis/getApi";
import { exportCSVDetialsAction } from "../../slices/export-csv/exportcsvSlice";

export function* getExportCSVDetailsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(exportCSVDetialsAction.fetchSetExportData(response));
}

export function* fetchPaymentDetailsListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(exportCSVDetialsAction.fetchPaymentDetailsListData(response));
}

export function* watchExportCSVDetailsAsync() {
  yield takeLatest(
    exportCSVDetialsAction.setExportCSVData,
    getExportCSVDetailsSaga
  );
  yield takeLatest(exportCSVDetialsAction.getPaymentDetailsListData, fetchPaymentDetailsListDataSaga);
}
