import React from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Box, Button, Stack, Typography } from "@mui/material";

import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import Testimonials from "../common/Testimonials";
import pana from "../../assets/properties/pana.png";
import pana2 from "../../assets/properties/pana2.png";
import house_icon from "../../assets/icons/house.svg";
import lable_icon from "../../assets/icons/label.svg";
import moniter from "../../assets/icons/moniter.webp";
import house from "../../assets/properties/house.webp";
import forbes from "../../assets/leadpage/forbes.WebP";
import vector from "../../assets/properties/Vector.png";
import tv_icon from "../../assets/leadpage/tv-icon.svg";
import check_img from "../../assets/icons/checkmark.png";
import article_icon from "../../assets/icons/article.svg";
import vector1 from "../../assets/properties/Vector1.png";
import vector2 from "../../assets/properties/Vector2.png";
import vector3 from "../../assets/properties/Vector3.png";
import vector4 from "../../assets/properties/Vector4.png";
import address_icon from "../../assets/icons/pin_drop.svg";
import phishing from "../../assets/properties/phishing.webp";
import usa_today from "../../assets/leadpage/usa_today.WebP";
import msnbc_logo from "../../assets/leadpage/msnbc-logo.png";
import support from "../../assets/properties/24_7_support.webp";
import maskgroup from "../../assets/properties/mask-group.webp";
import money_back from "../../assets/properties/money_back.webp";
import headphones_icon from "../../assets/icons/headset_mic.svg";
import certificate from "../../assets/properties/certificate.webp";
import PropertyRiskManagementForm from "./PropertyRiskManagementForm";
import isolation_mode from "../../assets/properties/isolation_mode.webp";
import home_protection from "../../assets/properties/home_protection.webp";

import "./PropertyRiskManagement.scss";
import "../../styles/landingpage.scss";
import "../../styles/howItWorksSection.scss";
import PrivateGuardSection from "../common/PrivateGuardSection";

const PropertyRiskManagement = () => {
  return (
    <>
      <div className="property-risk-page-content bg-white pb-150 main_class">
        {/* <Box className="property_form">
          <PropertyRiskManagementForm />
        </Box> */}
        <div className="first_section">
          <section
            className="d-flex align-items-center prm_bg_img  first-section"
            // style={{
            //   minHeight: "80vh",
            //   backgroundImage: `url('${propertyRisk}')`,
            //   position: "relative",
            // }}
          >
            <Container fluid className="text-white main_container">
              <Row className="">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={5}
                  className=" header_texts"
                >
                  <Stack
                    direction={"column"}
                    // justifyContent={{ sm: "center", md: "start" }}
                    // alignItems={{ sm: "center", xs: "center" }}
                    spacing={2}
                  >
                    <>
                      <p
                        className="notify_header mb-2"
                        style={{ color: "#0395FF" }}
                      >
                        Check your property
                      </p>
                      <h1 className="fw-700 mb-3 main-text">
                        Has your property been targeted by scammers?
                      </h1>
                      <p className="paragraph_text fw-400 mb-3 mt-2">
                        Check the addresses for free to ensure your properties
                        are still being held with your correct vesting.
                      </p>
                    </>
                  </Stack>
                </Col>
              </Row>
              <div className="px-4 my-3 py-1 d-flex align-items-center as_seen_on ">
                <span className="px-2 pt-1">As Seen on</span>
                <img src={tv_icon} alt="Tv_icon" />
              </div>
              <div className="pb-3 logos-width">
                <Stack
                  className="homepage_logos d-flex  logo_columned"
                  spacing={2}
                >
                  <Stack
                    spacing={{ xs: 2, sm: 3 }}
                    direction="row"
                    className="alignments"
                  >
                    <LazyLoadImage src={nbc} alt="nbc" />
                    <LazyLoadImage src={forbes} alt="forbes" />
                    <LazyLoadImage src={fox} alt="fox" />
                  </Stack>
                  <Stack
                    spacing={{ xs: 1, sm: 3 }}
                    direction="row"
                    className="alignments"
                  >
                    <LazyLoadImage src={cbs} alt="cbs" />
                    <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" />
                    <LazyLoadImage src={usa_today} alt="usa_today" />
                    <LazyLoadImage src={cnn} alt="cnn" />
                  </Stack>
                </Stack>
                <Box className="homepage_logos d-flex logo_row">
                  <LazyLoadImage src={nbc} alt="nbc" className="px-3" />
                  <LazyLoadImage src={forbes} alt="forbes" className="px-2" />
                  <LazyLoadImage src={fox} alt="fox" className="px-3" />
                  <LazyLoadImage src={cbs} alt="cbs" className="px-3" />
                  <LazyLoadImage
                    src={msnbc_logo}
                    alt="msnbc_logo"
                    className="px-3"
                  />
                  <LazyLoadImage
                    src={usa_today}
                    alt="usa_today"
                    className="px-2"
                  />
                  <LazyLoadImage src={cnn} alt="cnn" className="px-3" />
                </Box>
              </div>
            </Container>
          </section>
          <Row className="d-flex justify-content-between align-items-center text-left bg-white w-100 d-sm-pt-0 pt-2 m-0">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ order: 2 }}>
              <Stack
                direction={{ sm: "column", md: "row" }}
                justifyContent={{ sm: "center", md: "space-around" }}
                alignItems={{ sm: "center", xs: "center" }}
                spacing={2}
                className="sec1"
              >
                <>
                  <Stack direction={"row"}>
                    <img
                      src={check_img}
                      className="check_img ml-0"
                      alt="check"
                    />
                    <span className="sec1_text">
                      Your data is completely secure
                    </span>
                  </Stack>
                </>
                <>
                  <Stack direction={"row"}>
                    <img src={check_img} className="check_img" alt="check" />
                    <span className="sec1_text">
                      We won’t save anything locally
                    </span>
                  </Stack>
                </>
              </Stack>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className=" d-flex justify-content-center align-items-center second-column p-0 property_form"
              id="lead-page-form"
            >
              <>
                <PropertyRiskManagementForm
                  getFreeReport={true}
                  widthClass={"prm-form"}
                  source={"EquityProtect"}
                />
              </>
            </Col>
          </Row>
        </div>

        <section className="pt-5 alert_section  text-center mobile-secions-pt-0 bg-white">
          <Container fluid className="mx-150 it_works">
            <h3>How this works</h3>
            <Typography className="my-4">
              Let's make sure everything on your property is how it should be
            </Typography>
            <Row className="steps_wrapper text-start mt-2">
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <span className="steps mb-4">1</span>
                  <h5>Fill in the form above</h5>
                  <Typography className="mt-4 block1">
                    This tells us where to look.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <span className="steps mb-4">2</span>
                  <h5>Property Search</h5>
                  <Typography className="mt-4 block2">
                    Our system is going to locate your property and pull the
                    most recent title data.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <span className="steps mb-4">3</span>
                  <h5>We'll send you your free report.</h5>
                  <Typography className="mt-4 block3">
                    This report will display the current vesting information on
                    our title report.
                  </Typography>
                </Box>
              </Col>
            </Row>
            {/* <Stack
              className="steps_wrapper"
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              mt={4}
              textAlign={"start"}
            >
              <Box className="info_blocks">
                <span className="steps mb-4">1</span>
                <h5>Fill in the form above</h5>
                <Typography className="mt-4">
                  Placerat nunc sit libero feugiat elit amet sed sed. Vitae
                  morbi neque amet faucibus cursus viverra tempor.
                </Typography>
              </Box>
              <Box className="info_blocks">
                <span className="steps mb-4">2</span>
                <h5>Property Search</h5>
                <Typography className="mt-4">
                  Placerat nunc sit libero feugiat elit amet sed sed. Vitae
                  morbi neque amet faucibus cursus viverra tempor.
                </Typography>
              </Box>
              <Box className="info_blocks">
                <span className="steps mb-4">3</span>
                <h5>We'll send you your free report.</h5>
                <Typography className="mt-4">
                  Placerat nunc sit libero feugiat elit amet sed sed. Vitae
                  morbi neque amet faucibus cursus viverra tempor.
                </Typography>
              </Box>
            </Stack> */}
          </Container>
        </section>

        <section
          className="pt-150 "
          style={{
            position: "relative",
            background: "#F6F9FC",
            // marginBottom: "150px",
          }}
        >
          <Container fluid className="p-0 card_top box_shadow">
            <>
              <Stack
                className="bg-white m-0"
                direction={{ sm: "column", md: "row" }}
                justifyContent={"space-around"}
                mt={4}
                textAlign={"center"}
              >
                <Box className="card_content">
                  <>
                    <Typography
                      className="fw-600 text-black mb-4 d-flex text-start"
                      variant="h6"
                    >
                      Learn How EquityProtect™ Can Help Protect You
                    </Typography>
                    <ul
                      className="features_list mb-4"
                      style={{ listStyleType: "none", textAlign: "left" }}
                    >
                      <li className="position-relative mb-3">
                        Physical Protection prevents the crime from occurring
                      </li>
                      <li className="position-relative mb-3">
                        24/7 title monitoring
                      </li>
                      <li className="position-relative mb-3">
                        Utilization of Multifactor authentication before
                        allowing permission to move forward with a new refinance
                        or sale
                      </li>
                    </ul>
                    <Link
                      className=" text-white text-decoration-none"
                      to="/create-account"
                    >
                      <Button className="button1">
                        Prevention is a click away
                      </Button>
                    </Link>
                  </>
                </Box>
                <Box className="m-0 markgroup_img">
                  <img src={maskgroup} className="h-100 w-100" alt="home_Img" />
                </Box>
              </Stack>

              {/* <Row className="card_section">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box className="card_content ">
                    <Typography
                      className="fw-600 text-black mb-4 d-flex text-start"
                      variant="h6"
                    >
                      Learn How EquityProtect™ Can Help Protect You
                    </Typography>
                    <ul
                      className="features_list mb-4"
                      style={{ listStyleType: "none", textAlign: "left" }}
                    >
                      <li className="position-relative mb-3">
                        Justo, viverra diam vitae odio.
                      </li>
                      <li className="position-relative mb-3">
                        Ultricies sem sed dignissim erat dui tempus
                      </li>
                      <li className="position-relative mb-3">
                        Unlimited re-engagements* Only pay for additional
                        recording fee's if applicable.{" "}
                      </li>
                    </ul>
                    <Button className="button1">Find Out More</Button>
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <img
                    src={maskgroup}
                    className="markgroup_img"
                    alt="home_Img"
                  />
                </Col>
              </Row> */}
            </>
          </Container>

          <Container
            fluid
            className="mx-150 pt-150 pb-150 middle-container testimonials"
          >
            <Testimonials />
          </Container>

          <Container fluid className="property-protected-container ">
            <div>
              <h3 className="pp_title ">
                Why it’s important to have your property protected
              </h3>
              <Typography className="pp_caption pt-2">
                Your Smart Policy includes more protection for your peace of
                mind.
              </Typography>
            </div>

            <Row className="mt-4 mb-5 text-start pp_points">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing "
              >
                <img
                  className="mb-3"
                  src={house}
                  alt="Title Fraud and Property Theft"
                />
                <p className="protection_title">
                  Title Fraud and Property Theft
                </p>
                <p className="protection_desc pe-0 pe-lg-5">
                  Criminals forge documents to steal property titles, selling
                  homes or taking loans, leaving owners with legal and financial
                  battles.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing"
              >
                <img className="mb-3" src={phishing} alt="Data Breaches" />
                <p className="protection_title" style={{ paddingTop: "5px" }}>
                  Data Breaches
                </p>
                <p className="protection_desc pe-0 pe-lg-5">
                  Cybercriminals target lenders, stealing homeowners' sensitive
                  data for identity theft, leading to unauthorized transactions
                  and equity loss.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing"
              >
                <img
                  className="mb-3"
                  src={isolation_mode}
                  alt="Home Equity Scams"
                />
                <p className="protection_title">Home Equity Scams</p>
                <p className="protection_desc pe-0 pe-lg-5">
                  Deceptive practices strip homeowners of equity through
                  inflated fees and unfair terms, exploiting vulnerabilities for
                  financial gain.
                </p>
              </Col>
            </Row>
          </Container>

          {/* <Container fluid className="mx-150 pt-150 pb-150 middle-container">
            <Row className="pb-150">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ padding: "45px" }}
                className="content-spacing"
              >
                <div className="alwaysAlert_wrapper">
                  <p className="notify_header mb-4">Always in alert</p>
                  <h3 className="bold sub_header mb-4">
                    We’re Your Private Guard.
                  </h3>
                  <p className="description_text fw-300 mt-2 mb-3 mb-lg-5">
                    The FBI reported that in 2017 there were 9,654 cases of real
                    estate fraud, resulting in more than $56 million in losses.
                    Home title fraud has grown considerably since then, with
                    11,578 cases of real estate fraud reported in 2021, with
                    losses totaling more than $350 million.
                  </p>
                  <p className="description_text fw-500 mt-2 mb-3 mb-lg-5">
                    We’re here for one thing only, ensuring this kind of equity
                    ownership fraud will never happen to you. We guard you
                    against:
                  </p>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={{ span: 7, offset: 1 }}
                xl={{ span: 7, offset: 1 }}
                className="content-spacing"
              >
                <Row style={{ marginTop: "20px" }}>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={moniter}
                        className="p-1 pt-3"
                        width="78px"
                        height="78px"
                        alt="article"
                      />

                      <p className="privateGuardList">Multiple data points</p>
                      <p className="p-2">
                        Criminals can literally remove you from your home's
                        title. Then they take out loans against your home and
                        leave you in debt.
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={house_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="house"
                      />

                      <p className="privateGuardList">Sale listings</p>
                      <p className="p-2">
                        By constantly monitoring the Real Estate Listing
                        Services and the web, we can protect your investment
                        property (or second home) from being sold without your
                        knowledge.
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={article_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="article"
                      />

                      <p className="privateGuardList">Title changes</p>
                      <p className="p-2">
                        Criminals can literally remove you from your home's
                        title. Then they take out loans against your home and
                        leave you in debt.
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={lable_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="lable"
                      />

                      <p className="privateGuardList">Rent listings</p>
                      <p className="p-2">
                        EquityProtect will continuously monitor for your homes’
                        addresses to be listed on popular vacation rental and
                        classified ad sites.
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={address_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="address"
                      />

                      <p className="privateGuardList">Property address</p>
                      <p className="p-2">
                        Instant alerts if we detect tampering with your title or
                        mortgage - we’re the hawk keeping a close watch at all
                        times.
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={headphones_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="headphones"
                      />

                      <p className="privateGuardList">Live Support</p>
                      <p className="p-2">
                        Instant alerts 24/7 if we detect any form of tampering
                        with your title or mortgage.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container> */}

          {/* <Container fluid className="px-3 px-md-5 card_bottom">
            <Row>
              <Col>
                <div className="card text-center linear_bg">
                  <div className="card-body p-0">
                    <img src={vector4} className="vector4" alt="Vector" />
                    <img src={vector3} className="vector3" alt="Vector" />
                    <img src={vector2} className="vector2" alt="Vector" />
                    <img src={vector1} className="vector1" alt="Vector" />
                    <img src={vector} className="vector" alt="Vector" />
                    <img src={pana} className="pana" alt="pana" />
                    <img src={pana2} className="pana2" alt="pana" />
                    <p className="privateGuardList card-title text-white text-center">
                      Learn How EquityProtect™ Can Help Protect You
                    </p>
                  </div>
                  <div className="mt-2">
                    <Link
                      className=" text-white text-decoration-none"
                      to="/create-account"
                    >
                      <Button className="button2">
                        Prevention is a click away
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container> */}
        </section>

        <section className="bg-white">
          <Container fluid className="ep_section">
            <Row className="">
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className="">
                <div className="">
                  <p className="notify_header mb-4">Always in alert</p>
                  <h3 className="bold ep_header mb-4">Who is EquityProtect?</h3>
                  <h3 className="bold ep_sub_header mb-4">
                    The ONLY Company That PREVENTS Deed And Home Title Theft
                  </h3>

                  <p className="ep_text mt-2 mb-3 mb-lg-5">
                    Most “home title lock” companies today will monitor your
                    property for fraud and then notify you after the crime
                    occurs – some will even help you fix it once it has
                    happened. What they don’t tell you is that this type of
                    monitoring is already available to you absolutely free from
                    many county recorders.
                  </p>
                  <p className="ep_text  mt-2 mb-3 mb-lg-5">
                    Additionally, once the fraud happens, it can cost you up to
                    thousands of dollars to reverse and months of stress and
                    worry that could have been easily prevented.
                  </p>
                  <p className="ep_text  mt-2 mb-3 mb-lg-5">
                    Using a new Patented process, EquityProtect will scan,
                    monitor, alert, and most importantly, protect you from any
                    attempts to tamper with or steal your home equity and
                    ownership – 365 days a year, 24/7.
                  </p>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className="hp_img_section"
              >
                <img
                  src={home_protection}
                  alt="home_protection"
                  className="hp_img"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <PrivateGuardSection />
      </div>
    </>
  );
};

export default PropertyRiskManagement;
