import { useRef } from "react";
import { useCallback } from "react";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Zoom from "@mui/material/Zoom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";

import { Loader } from "../common/loader";
import AlertDialog from "../common/AlertDialog";
import DeleteModal from "../common/DeleteModal";
import VisaCard from "../../assets/dashboard/visa.svg";
import StannupInfoModal from "../common/StannupInfoModal";
import SupportEmailLink from "../common/SupportEmailLink";
import DialogBox from "../userProfile/Dashboard/cardPopup";
import AddCardForm from "../userProfile/Dashboard/addCardForm";
import MasterCard from "../../assets/dashboard/mastercard.svg";
import DiscoverCard from "../../assets/dashboard/discover.svg";
import PaymentFailed from "../userProfile/Dashboard/PaymentFailed";
import ProfileDP from "../../assets/dashboard/Dummy_Profile_photo.png";
import AmericanCard from "../../assets/dashboard/american-express.svg";
import { ProfessionalDashboadrForm } from "./ProfessionalDashboardForm";
import BiometricAndSign from "../userProfile/Dashboard/BiometricAndSign";
import { userSigInAction } from "../../redux/slices/user/userSignInSlice";
import UploadProfilePictureModal from "../common/UploadProfilePictureModal";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import {
  clearLocalStoarge,
  getMonthFromString,
  getObjLength,
} from "../helpers/utils";
import PropertiesListing from "../userProfile/Dashboard/StannupVerification/PropertiesListing";
import {
  AddressVerificationColumns,
  DEFAULT_URL,
  ROLES,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";

import "../../styles/dashboard.scss";

const styles = {
  "&.MuiButton-outlined": {
    background: "#FFFFFF",
    border: "1px solid #15BE53",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#15BE53",
    padding: "11px",
    minWidth: "auto",
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const PROFESSIONAL_REFERRAL_LINK =
  process.env.REACT_APP_PROFESSIONAL_REFERRAL_LINK;

export default function ProfessionalsProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const formRef = useRef();

  console.log("location.pathname", window.location.pathname, location);

  const [open, setOpen] = useState(false);
  const [defaultCardmsg, setDefaultCardmsg] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isEditProfilePictureModalOpen, setIsEditProfilePictureModalOpen] =
    useState(false);
  const [isAddNewCardModalOpen, setIsAddNewCardModalOpen] = useState(false);
  const [isRemoveCardModalOpen, setIsRemoveCardModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [openStanupPopup, setOpenStanupPopup] = useState(false);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);

  const {
    cardDetails,
    userProfileDetails,
    StannupEmailDetails,
    addressLoader,
    setDefaultCard: setDefaultData,
    editedUserProfileDetails: editedData,
    editProfileLoader,
    profileLoader,
    removedCardLoader,
    removeCardDetails,
    cardLoader,
    defaultCardLoader,
    paymnetDoneDetails,
    isPaymentPending,
    biometric_signLoader,
    isResetPasswordRequired,
  } = useSelector((state) => state.userProfile);
  const { stateList: stateData } = useSelector((state) => state?.signUpUser);

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };

  const handleClose = () => {
    if (cardDetails?.success) {
      setIsAddNewCardModalOpen(false);
    }
    if (Object.keys(setDefaultData).length > 0) {
      setDefaultCardmsg(true);
    }

    if (userProfileDetails?.success) {
      setEdit(false);
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const clearUserData = () => {
    dispatch(userProfileAction.clearUserProfileData());
  };

  // handle edit profile picture button
  const handleEditProfilePicBtn = () => {
    if (formRef?.current) {
      formRef?.current?.resetForm();
    }
    setEdit(false);
    setIsEditProfilePictureModalOpen(true);
  };

  // handle edit profile picture button
  const handleEditProfilePicModalClose = () => {
    setIsEditProfilePictureModalOpen(false);
  };
  const handleSignOut = () => {
    dispatch(
      userProfileAction.logoutUser({ url: "logout", data: {}, token: true })
    );
    dispatch(userSigInAction.successLogout());
    // localStorage.removeItem("authToken");
    // localStorage.removeItem("authorised");
    clearLocalStoarge();

    let isIos = false;
    if (JSON.parse(localStorage.getItem("isIos"))) {
      isIos = JSON.parse(localStorage.getItem("isIos"));
    }
    clearLocalStoarge();
    localStorage.setItem("ivokeNativeMethod", isIos);

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }

    navigate("/login");
  };

  // handle upload profile picture
  const handleUploadProfilePicture = (jpegFile) => {
    const formData = new FormData();

    formData.append("company_name", userProfileDetails?.data?.company_name);
    formData.append("first_name", userProfileDetails?.data?.first_name);
    formData.append("last_name", userProfileDetails?.data?.last_name);
    formData.append("email", userProfileDetails?.data?.email);
    formData.append("phone_number", userProfileDetails?.data?.phone_number);
    formData.append("zip_code", userProfileDetails?.data?.zip_code);
    formData.append("zip_code2", userProfileDetails?.data?.zip_code2);
    formData.append("profile_img", jpegFile);

    dispatch(
      userProfileAction.editUserProfileData({
        url: "professional/update-profile",
        data: formData,
        token: true,
      })
    );
  };

  const getCardListing = useCallback(() => {
    dispatch(
      userProfileAction.userCardDetails({
        url: "professional/card/list",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  const closeAddCardPopup = () => {
    setIsAddNewCardModalOpen(false);
    if (location?.state?.isAddPaymentModal) {
      navigate(location.pathname, { replace: true });
    }
  };

  const handleAddNewCard = () => {
    setIsAddNewCardModalOpen(true);
  };

  const handleRemoveCard = (data) => {
    setSelectedCard(data);
    setIsRemoveCardModalOpen(true);
  };

  const setDefaultCard = (data) => {
    dispatch(
      userProfileAction.setDefaultCard({
        url: "professional/card/" + data?.id + "/set-default",
        data: {},
        token: true,
      })
    );
  };

  const handleDeleteCard = () => {
    if (selectedCard?.id) {
      dispatch(
        userProfileAction.removeNewCard({
          url: "professional/card/" + selectedCard?.id + "/delete",
          data: {},
          token: true,
        })
      );
    }
  };
  const handleCloseDeleteCardModal = () => {
    setSelectedCard(null);
    setIsRemoveCardModalOpen(false);
    dispatch(userProfileAction.getRemovedCardDetails({}));
  };

  useEffect(() => {
    console.log("data editted called==>", editedData);
    if (editedData?.success) {
      handleToggle();
      dispatch(
        userProfileAction.userProfileData({
          url: "profile",
          data: {},
          token: true,
        })
      );
    }
  }, [editedData]);

  useEffect(() => {
    if (userProfileDetails?.success || cardDetails?.success) {
      handleClose();
    }

    if (getObjLength(userProfileDetails) && userProfileDetails?.success) {
      if (userProfileDetails?.data?.status === "inactive") {
        localStorage.clear();
        localStorage.setItem("preference", true);
        window.location.href = `${DEFAULT_URL}login`;
      }
    }
  }, [userProfileDetails, cardDetails]);

  useEffect(() => {
    getCardListing();
    if (
      parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
      isResetPasswordRequired !== 1
    ) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, getCardListing]);

  // open stanup modal of any property remaining veifying
  useEffect(() => {
    if (StannupEmailDetails?.success && StannupEmailDetails?.data?.length > 0) {
      dispatch(userProfileAction.paymnetDoneDetails({}));
      if (localStorage.getItem("stanupPopupCount") === "1") {
        setOpenStanupPopup(true);
      }
    }
  }, [StannupEmailDetails, dispatch]);

  useEffect(() => {
    if (getObjLength(paymnetDoneDetails)) {
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
        dispatch(
          userProfileAction.getStannupEmailData({
            url: "customer/address",
            data: {},
            token: true,
          })
        );
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  useEffect(() => {
    dispatch(
      userProfileAction.getBiometricData({
        url: "customer/get-biometric/face",
        data: {},
        token: true,
      })
    );
    dispatch(
      userProfileAction.getSignatureData({
        url: "customer/get-biometric/signature",
        data: {},
        token: true,
      })
    );
  }, []);
  console.log("isResetPasswordRequired==>", isResetPasswordRequired);

  useEffect(() => {
    if (cardDetails?.data?.length > 0 && location?.state?.isAddPaymentModal) {
      navigate(location.pathname, { replace: true });
    }
  }, [
    cardDetails?.data?.length,
    location.pathname,
    location?.state?.isAddPaymentModal,
    navigate,
  ]);

  // handle password Reset Required
  const passwordResetRequired = () => {
    localStorage.setItem(
      "isResetPasswordRequired",
      parseInt(isResetPasswordRequired) + 1
    );
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  };
  return (
    <>
      <Box className="profile-wrapper">
        {Object.keys(userProfileDetails).length > 0 ? (
          userProfileDetails?.success ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Typography variant="h6" className="title">
                  My Profile
                </Typography>
                <Button
                  variant="contained"
                  className="signoutBtn d-none d-md-block"
                  onClick={handleSignOut}
                >
                  Sign Out
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Paper
                    elevation={0}
                    className="personal-info"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title" my={1.6}>
                        Personal information
                      </Typography>
                      {!edit && (
                        <>
                          <Button
                            variant="outlined"
                            className="d-none d-md-block EditBtn"
                            sx={styles}
                            onClick={() => setEdit(!edit)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            className="d-block d-md-none EditBtn p-2"
                            sx={styles}
                            onClick={() => setEdit(!edit)}
                          >
                            <ModeEditOutlineOutlinedIcon fontSize="small" />
                          </Button>
                        </>
                      )}
                    </Box>
                    <Box className="d-flex align-items-center">
                      <Box className="profile-photo-box">
                        <img
                          src={
                            userProfileDetails?.data?.profile_img
                              ? userProfileDetails?.data?.profile_img
                              : ProfileDP
                          }
                          alt="Profile"
                          className="profilePic"
                        />
                        <IconButton
                          className="profile-photo-edit-btn"
                          onClick={handleEditProfilePicBtn}
                        >
                          <ModeEditOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box className="form" sx={{ mt: 5 }}>
                      {userProfileDetails?.success && (
                        <ProfessionalDashboadrForm
                          userProfileDetails={userProfileDetails}
                          stateData={stateData}
                          edit={edit}
                          password={""}
                          setEdit={setEdit}
                          formRef={formRef}
                        />
                      )}
                    </Box>
                  </Paper>

                  {/* payment information */}
                  <Paper
                    elevation={0}
                    className="payment-info"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title">
                        Payment information
                      </Typography>
                      <Button
                        className="d-none d-md-block"
                        onClick={handleAddNewCard}
                        variant="outlined"
                      >
                        Add new card
                      </Button>
                      <Button
                        variant="outlined"
                        className="d-block d-md-none EditBtn p-2"
                        sx={styles}
                        onClick={handleAddNewCard}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </Box>
                    {Object.keys(cardDetails)?.length > 0 &&
                      cardDetails?.success &&
                      cardDetails?.data?.map((data, key) => (
                        <>
                          {/* desktop view */}
                          <Box className="pt-2 d-none d-sm-block" key={key}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                mb={1}
                                className="d-flex align-items-center"
                              >
                                <Box className="px-3 w-100">
                                  {data.brand === "MasterCard" ? (
                                    <img
                                      width={"10%"}
                                      src={MasterCard}
                                      alt="Master Card"
                                    />
                                  ) : data.brand === "Visa" ? (
                                    <img
                                      width={"10% "}
                                      src={VisaCard}
                                      alt="Visa Card"
                                    />
                                  ) : data.brand === "American Express" ? (
                                    <img
                                      width={"10%"}
                                      src={AmericanCard}
                                      alt="American Card"
                                    />
                                  ) : data.brand === "Discover" ? (
                                    <img
                                      width={"10%"}
                                      src={DiscoverCard}
                                      alt="Discover Card"
                                    />
                                  ) : (
                                    <CreditCardIcon fontSize="large" />
                                  )}

                                  <Typography
                                    variant="caption"
                                    className="visa-num ms-3 text-uppercase "
                                  >
                                    {data.brand} - **** {data.last4}
                                    &nbsp;&nbsp;
                                    {data.default === 1 && (
                                      <Chip
                                        sx={{ backgroundColor: "#46b14c33" }}
                                        className="fw-600 text-success"
                                        label="DEFAULT"
                                      />
                                    )}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={8}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    className="d-flex justify-content-center align-items-center"
                                  >
                                    Expiry Date, {data.exp_month} {data.exp_year}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    className="text-end"
                                  >
                                    {data.default === 0 && (
                                      <>
                                        <Tooltip
                                          title="Set as Default"
                                          TransitionComponent={Zoom}
                                          arrow
                                        >
                                          <IconButton
                                            sx={{
                                              color: "#43D551",
                                            }}
                                            disabled={
                                              data?.id
                                                ? data?.exp_year <=
                                                  new Date().getFullYear()
                                                  ? getMonthFromString(
                                                      data?.exp_month,
                                                      data?.exp_year
                                                    ) <
                                                    new Date().getMonth() + 1
                                                    ? true
                                                    : false
                                                  : false
                                                : true
                                            }
                                            onClick={() => setDefaultCard(data)}
                                          >
                                            <CheckCircleIcon />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip
                                          title="Remove card"
                                          TransitionComponent={Zoom}
                                          arrow
                                        >
                                          <IconButton
                                            sx={{ color: "#FC2125" }}
                                            onClick={() =>
                                              handleRemoveCard(data)
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>

                          {/* mobile view */}
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            className="d-flex d-sm-none"
                          >
                            <Stack direction="column">
                              <Box
                                sx={{
                                  minWidth: data.default === 1 ? "200px" : "",
                                }}
                              >
                                {data.brand === "MasterCard" ? (
                                  <img
                                    width={"15%"}
                                    src={MasterCard}
                                    alt="Master Card"
                                  />
                                ) : data.brand === "Visa" ? (
                                  <img
                                    width={"15% "}
                                    src={VisaCard}
                                    alt="Visa Card"
                                  />
                                ) : data.brand === "American Express" ? (
                                  <img
                                    width={"15%"}
                                    src={AmericanCard}
                                    alt="American Card"
                                  />
                                ) : data.brand === "Discover" ? (
                                  <img
                                    width={"15%"}
                                    src={DiscoverCard}
                                    alt="Discover Card"
                                  />
                                ) : (
                                  <CreditCardIcon fontSize="large" />
                                )}
                                <Typography
                                  variant="caption"
                                  className="visa-num ms-2 text-uppercase"
                                  sx={{ mr: 1 }}
                                >
                                  **** {data.last4}
                                </Typography>
                                {data.default === 1 && (
                                  <Chip
                                    sx={{
                                      backgroundColor: "#46b14c33",
                                      p: 0,
                                    }}
                                    className="fw-600 text-success"
                                    label="DEFAULT"
                                  />
                                )}
                              </Box>
                              <Typography>
                                Expiry Date, {data.exp_month} {data.exp_year}
                              </Typography>
                            </Stack>
                            <Stack direction="row">
                              {data.default === 0 && (
                                <>
                                  <Tooltip
                                    title="Set as Default"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <IconButton
                                      sx={{ color: "#43D551" }}
                                      onClick={() => setDefaultCard(data)}
                                      disabled={
                                        data?.id
                                          ? data?.exp_year <=
                                            new Date().getFullYear()
                                            ? getMonthFromString(
                                                data?.exp_month,
                                                data?.exp_year
                                              ) <
                                              new Date().getMonth() + 1
                                              ? true
                                              : false
                                            : false
                                          : true
                                      }
                                    >
                                      <CheckCircleIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Remove card"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <IconButton
                                      sx={{ color: "#FC2125" }}
                                      onClick={() => handleRemoveCard(data)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Stack>
                          </Stack>
                          {key < cardDetails?.data?.length - 1 && <hr />}
                        </>
                      ))}
                  </Paper>

                  {/* Address Verification module */}
                  {Object.keys(StannupEmailDetails).length > 0 &&
                    (StannupEmailDetails?.data).length > 0 && (
                      <Paper
                        elevation={0}
                        className="payment-info"
                        sx={Paperstyles}
                      >
                        <Box
                          className="d-flex align-items-center justify-content-between"
                          sx={{ mb: 3 }}
                        >
                          <Typography variant="h6" className="card-title">
                            Address Verification
                          </Typography>
                        </Box>
                        <Box>
                          <PropertiesListing
                            columns={AddressVerificationColumns}
                            rowsData={StannupEmailDetails?.data}
                          />
                        </Box>
                      </Paper>
                    )}
                </Grid>
                <Grid
                  className="pt-1 pt-lg-3"
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Paper
                    elevation={0}
                    className="trusted-contacts"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 1 }}
                    >
                      <Typography variant="h6" className="card-title">
                        Referral Link
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      className="w-75 caption mb-3 mb-lg-4"
                    >
                      You can share this link with others and they will
                      associate with you by using your Referral code attached to
                      this link.
                    </Typography>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      position="relative"
                      spacing={3}
                      // margin={2}
                    >
                      <Box className="contacts d-flex flex-column w-75">
                        <Typography variant="body1" className="px-2">
                          {userProfileDetails?.success &&
                          userProfileDetails?.data?.professional_promocode
                            ? PROFESSIONAL_REFERRAL_LINK +
                              userProfileDetails?.data?.professional_promocode
                            : "No referral link found for now."}
                        </Typography>
                      </Box>
                      {userProfileDetails?.success &&
                      userProfileDetails?.data?.professional_promocode ? (
                        <Stack
                          direction="row"
                          style={{ position: "absolute", top: 0, right: 0 }}
                        >
                          <Tooltip
                            title="Copy Referral Link"
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "#0395FF",
                                fontSize: "1.4rem",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  PROFESSIONAL_REFERRAL_LINK +
                                    userProfileDetails?.data
                                      ?.professional_promocode
                                )
                              }
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Paper>

                  <Paper
                    elevation={0}
                    className="help-contact"
                    sx={Paperstyles}
                  >
                    <Box
                      className="d-flex align-items-center justify-content-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6" className="card-title">
                        Help and contact
                      </Typography>
                    </Box>
                    <List
                      className="supportContact"
                      sx={{ width: "100%", bgcolor: "background.paper" }}
                    >
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          Support:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <SupportEmailLink
                            email={SUPPORT_EMAIL}
                            text={"Click here to contact us"}
                          />
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          Email:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <SupportEmailLink
                            email={SUPPORT_EMAIL}
                            text={SUPPORT_EMAIL}
                          />
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding className="align-items-start">
                        <ListItemText className="mb-0 label">
                          By phone:
                        </ListItemText>
                        <ListItemText className="mb-0 content">
                          <div className="d-flex flex-column">
                            <Callto phone={`${SUPPORT_PHONE}`}>
                              {SUPPORT_PHONE_FORMAT}
                            </Callto>
                            <p className="mb-0">
                              MON-FRI: <span>8:00AM - 6:00PM</span>
                            </p>
                            <p className="mb-0">
                              SAT-SUN: <span>9:00AM - 5:00PM</span>
                            </p>
                          </div>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Paper>

                  {!userProfileDetails?.data?.role?.includes(
                    ROLES.secondaryUser
                  ) && (
                    <>
                      {/* signature and biomatric */}
                      <BiometricAndSign Paperstyles={Paperstyles} />
                    </>
                  )}
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  className="signoutBtn d-block d-md-none w-100"
                  onClick={handleSignOut}
                >
                  Sign Out
                </Button>
              </Box>
            </>
          ) : (
            <Box className="text-center">
              <Button
                className="text-danger text-capitalize"
                onClick={clearUserData}
              >
                {userProfileDetails?.data?.message}
                <Link to={"/login"} className="bold text-decoration-none">
                  &nbsp;Click here to Login again.
                </Link>
              </Button>
            </Box>
          )
        ) : (
          <Loader open={true} />
        )}
      </Box>

      {/* upload profile picture modal */}
      {isEditProfilePictureModalOpen ? (
        <UploadProfilePictureModal
          open={isEditProfilePictureModalOpen}
          handleClose={handleEditProfilePicModalClose}
          handleUploadProfilePicture={handleUploadProfilePicture}
          loader={editProfileLoader}
        />
      ) : null}

      {getObjLength(editedData) ? (
        editedData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userProfileAction.fetcheditedUserData({}));
              handleEditProfilePicModalClose();
            }}
            mesage={editedData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() =>
              dispatch(userProfileAction.fetcheditedUserData({}))
            }
            mesage={editedData?.data?.message}
          />
        )
      ) : null}

      {isAddNewCardModalOpen || location?.state?.isAddPaymentModal ? (
        <DialogBox
          width="sm"
          openPopup={
            isAddNewCardModalOpen || location?.state?.isAddPaymentModal
          }
          setOpenPopup={setIsAddNewCardModalOpen}
          onClose={closeAddCardPopup}
          mesage={
            // <Elements stripe={stripePromise}>
            <AddCardForm
              openPopup={
                isAddNewCardModalOpen || location?.state?.isAddPaymentModal
              }
              loader={
                open || addressLoader || profileLoader || editProfileLoader
              }
            />
            // </Elements>
          }
        />
      ) : null}

      {/* Delete Card modal */}
      {isRemoveCardModalOpen ? (
        <DeleteModal
          open={isRemoveCardModalOpen}
          title="Delete Card"
          item="Card"
          deleteLoader={removedCardLoader}
          handleClose={handleCloseDeleteCardModal}
          handleDeleteBtn={handleDeleteCard}
          fetchedData={removeCardDetails}
          isDeleteSuccess={removeCardDetails?.success}
          successMessage={removeCardDetails?.message}
          errorMessage={removeCardDetails?.data?.message}
          callListingAPI={() => {
            getCardListing();
            dispatch(userProfileAction.getRemovedCardDetails({}));
          }}
        />
      ) : null}

      {/* success and error modal for default card */}
      {getObjLength(setDefaultData) ? (
        setDefaultData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userProfileAction.emptyDefaultCardDetails({}));
              getCardListing();
            }}
            mesage={setDefaultData?.message}
            buttons={
              <Button
                className="popup_Btn success_popup_btn"
                onClick={() => {
                  dispatch(userProfileAction.emptyDefaultCardDetails({}));
                  getCardListing();
                }}
              >
                Ok, All Good
              </Button>
            }
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userProfileAction.emptyDefaultCardDetails({}));
            }}
            mesage={setDefaultData?.data?.message}
            buttons={
              <Button
                className="popup_Btn error_popup_btn "
                onClick={() => {
                  dispatch(userProfileAction.emptyDefaultCardDetails({}));
                }}
              >
                Ok, Close
              </Button>
            }
          />
        )
      ) : null}

      {/* stannup info modal */}
      {openStanupPopup ? (
        <StannupInfoModal
          openStanupPopup={openStanupPopup}
          setOpenStanupPopup={setOpenStanupPopup}
        />
      ) : null}

      {/* Paymnet failed Popup */}
      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
      isPaymentDonePopup ? (
        <PaymentFailed isPaymentDonePopup={isPaymentDonePopup} />
      ) : null}

      {/* Reset password popup  */}
      {/* {isResetPasswordRequired ? (
        <AlertDialog
          type="Attention"
          openPopup={isResetPasswordRequired}
          onClose={true}
          onCloseFun={passwordResetRequired}
          mesage={
            <p className="fw-400 mt-3">
              Please change auto generated password for the better security of
              your account.
            </p>
          }
          buttons={
            <Button
              className="popup_Btn success_popup_btn"
              onClick={passwordResetRequired}
            >
              Ok, All Good
            </Button>
          }
        />
      ) : null} */}

      <Loader
        open={
          addressLoader ||
          editProfileLoader ||
          cardLoader ||
          defaultCardLoader ||
          biometric_signLoader
        }
      />
    </>
  );
}
