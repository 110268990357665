import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../common/loader";
import { decryptPayload, getObjLength } from "../helpers/utils";
import AlertDialog from "../common/AlertDialog";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import { useParams } from "react-router-dom";

const OwnerVerified = ({
  propertiesList,
  addNewProperty,
  onBtnClick,
  setOwnerScreen,
  backtoAddProperty,
  setPropertyListScreen,
  setViewPropertyListScreen,
  viewPropertyListScreen,
}) => {
  const [open, setOpen] = useState(false);
  const [apiErr, setApiErr] = useState(false);
  const [params, setParams] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [propertyVerifiedPopup, setPropertyVerifiedPopup] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [propertyQuestionPopup, setPropertyQuestionPopup] = useState(false);

  const dispatch = useDispatch();
  let county_name = decryptPayload(localStorage.getItem("county-code"));

  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const mailingAddress = useSelector(
    (state) => state.signUpUser.mailingAddress
  );
  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );
  const {
    propertyQuestion,
    propertyListLoader,
    verifiedPropertiesList,
    mailingAddressLoader,
  } = useSelector((state) => state.signUpUser);

  const { countyData } = useSelector((state) => state?.propertyRiskManagement);

  const addedNewProperty = () => {
    localStorage.removeItem("searched_Add_popup");
    addNewProperty();
    localStorage.setItem("button", "skip");
    // for not caling property list api on default action
    dispatch(userSigupAction.showSecurityInfoScreen(true));
  };

  const { county_name: county } = useParams();

  const getMailingAddress = () => {
    let data = {
      user_id: getObjLength(crerateAccData)
        ? crerateAccData.data?.user_id
        : userIdFromSession,
      // crerateAccData?.data?.user_id
      step: 2,
      step_key: "GET_MAILING_ADDRESS",
    };
    dispatch(
      userSigupAction.getMailingAddress({
        url: "get-mailing-address",
        data: data,
      })
    );
  };

  const setPropertiesData = () => {
    let propertyIds = verifiedPropertiesList?.data?.map((i) => i?.property_id);
    let data = {};
    let properties = verifiedPropertiesList?.data?.map((i) => {
      return (data = {
        address: i?.address,
        address2: i?.address2,
        city: i.city,
        country: i.country,
        latitude: i.latitude,
        longitude: i.longitude,
        property_id: i.property_id,
        site_route: i?.site_route,
        state: i.state,
        street_number: i?.street_number,
        zip: i.zip,
      });
    });
    console.log(verifiedPropertiesList, "propertyIds==>", properties);
    dispatch(userSigupAction.setPropertiesId({ propertyIds, properties }));
  };

  const finishBtn = () => {
    let promoCode = localStorage.getItem("promoCode");
    let data = {};
    setPropertiesData();
    handleToggle();
    getMailingAddress();
    if (promoCode) {
      data = { promo_code: promoCode };
    }
    if (county) {
      dispatch(
        userSigupAction.getSubscriptionPlan({
          url: `county/${countyData?.data?.id}/get-plans`,
          data: {},
          type: "post",
        })
      );
    } else {
      dispatch(
        userSigupAction.getSubscriptionPlan({
          url: "get-plans",
          data: data,
          type: "post",
        })
      );
    }

    // dispatch(userSigupAction.clearData());
    // onBtnClick();
  };

  const handleClose = () => {
    if (mailingAddress?.success) {
      console.log(propertiesList?.data, "mailingAddress==>>", mailingAddress);
      if (propertiesList?.data?.length >= 1) {
        onBtnClick();
      }
    }
    if (!mailingAddress?.success) {
      setApiErr(mailingAddress?.data?.message);
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailingAddress]);

  const propertyAction = (event, type, data) => {
    console.log("Selected row type==>", type, "==Data==>", data);

    if (type === "verify") {
      if (Object.keys(data)?.length > 0) {
        // onBtnClick();
        // handleToggle();
        let propertyData = data?.json_res;

        let inputParams = {
          state_code: propertyData.mm_fips_state_code,
          property_id: data?.property_id,
          muni_code: propertyData.mm_fips_muni_code,
          sa_y_coord: propertyData.sa_y_coord,
          sa_x_coord: propertyData.sa_x_coord,
          user_id: getObjLength(crerateAccData)
            ? crerateAccData.data?.user_id
            : userIdFromSession,
          address: data.address,
          country: data.country || "USA",
          city: data.city,
          state: data.state,
          zip: data.zip,
          latitude: data.latitude,
          longitude: data.longitude,
          bed_rooms: data.bed_rooms,
          baths: data.baths,
          sqft: data.sqft,
          year_built: data.year_built,
          json_response: propertyData,
          price: data.price,
          county: data.county,
          check_county: county_name ? true : false,
          step: 2,
          step_key: "GENERATE_QUESTIONS",
        };
        console.log("security questions==>", inputParams);
        dispatch(userSigupAction.selectedUserPropertyData(data));

        setTimeout(() => {
          delete inputParams.step;
          delete inputParams.step_key;
          dispatch(propertiesAction.generateQuestionsInputParams(data));
        }, 3000);

        dispatch(
          userSigupAction.generateQuestions({
            url: "properties/generate-questions",
            data: inputParams,
            toekn: true,
          })
        );
      }
      setViewPropertyListScreen(true);
      setPropertyListScreen(false);
      localStorage.setItem("button", "propertyList");
      // backtoAddProperty(2, "propertyList");
      // setOwnerScreen(false);
      // localStorage.setItem("isOwnerVerified", 0);
    }

    if (type === "delete") {
      let ipParams = {
        // user_id: data?.owner_id,
        user_id: data?.owner_id || data?.user_id,
        property_id: data?.property_id,
      };
      setParams(ipParams);
      setPropertyName(data?.address);
      setOpenDeletePopup(true);
      console.log("user_id==>", params);
    }
  };

  const OwnerCardStyle = {
    background: "rgb(21 190 83 / 5%)",
    boxShadow: "0px 17.0825px 39.8592px -5.69417px rgba(0, 0, 0, 0.15);",
    borderRadius: "3px",
    py: 2,
    px: 4,
    // border: '1px solid #15be53',
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "55px",
    color: "#0D120B",
  };

  const skipVerification = () => {
    // let data = {};
    // let properties = verifiedPropertiesList?.data?.map((i) => {
    //   return (data = {
    //     address: i?.address,
    //     address2: i?.address2,
    //     city: i.city,
    //     country: i.country,
    //     latitude: i.latitude,
    //     longitude: i.longitude,
    //     property_id: i.property_id,
    //     site_route: i?.site_route,
    //     state: i.state,
    //     street_number: i?.street_number,
    //     zip: i.zip,
    //   });
    // });

    // let propertyIds = verifiedPropertiesList?.data?.map((i) => i?.id);
    // console.log("propertyIds==>", propertyIds);
    // dispatch(userSigupAction.setPropertiesId({ propertyIds, properties }));
    setPropertiesData();
    getMailingAddress();
    setPropertyVerifiedPopup(false);
  };

  let verified = propertiesList?.data?.find((i, j) => i?.is_verified === 0);
  console.log("data list ==>", verified);
  useEffect(() => {
    console.log(
      propertiesList?.data?.length,
      "setPropertyListScreen=>",
      localStorage.getItem("button")
    );
    localStorage.setItem("button", "noButton");
    if (propertiesList?.data?.length === 0) {
      addNewProperty();
    }
  }, []);
  useEffect(() => {
    console.log("setPropertyListScreen=>", propertyQuestion);
    if (getObjLength(propertyQuestion)) {
      if (!propertyQuestion?.success) {
        setPropertyQuestionPopup(true);
      }
      if (propertyQuestion?.success) {
        setPropertyQuestionPopup(false);
      }
    }
  }, [propertyQuestion]);

  return (
    <>
      <Box className="accountBoxWrapper">
        <Typography align="center" variant="h3">
          Owner Verified
        </Typography>
        <Typography align="center" variant="body1">
          Your Ownership of the below property has been verified by
          EquityProtect.
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent={`${propertiesList?.data?.length > 1 ? "" : "center"}`}
        >
          {propertiesList?.success ? (
            propertiesList?.data?.map((data, key) => (
              <>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box sx={{ position: "relative", mb: 3 }}>
                    <Paper className="verifiedOwnerCard" sx={OwnerCardStyle}>
                      <Box>
                        <Typography variant="h3" sx={{ minHeight: "52px" }}>
                          Property: {data?.address}
                        </Typography>
                        <Typography variant="h3">City: {data?.city}</Typography>
                        <Typography variant="h3">
                          State: {data?.state}
                        </Typography>
                        <Typography variant="h3">
                          Country: {data?.country}
                        </Typography>
                        <Typography variant="h3">Zip: {data?.zip}</Typography>
                      </Box>
                      <Box
                        className={`d-flex ${
                          data?.is_verified === 0
                            ? `justify-content-between`
                            : `justify-content-end`
                        } pt-4`}
                      >
                        {data?.is_verified === 0 ? (
                          <Tooltip
                            title={
                              "Property verification will be completed by admin"
                            }
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                            disableFocusListener
                          >
                            <Button
                              className="px-4"
                              variant="disableElevation"
                              sx={{
                                backgroundColor: "#d5ddd8",
                                ":hover": { backgroundColor: "#d5ddd8" },
                                color: "#9ea4a0",
                              }}
                            >
                              QUESTIONS SKIPPED
                            </Button>
                          </Tooltip>
                        ) : null}
                        {propertiesList?.data?.length > 1 && (
                          <Tooltip
                            title={"Delete this property"}
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                            disableFocusListener
                          >
                            <IconButton
                              variant="contained"
                              onClick={(e) => propertyAction(e, "delete", data)}
                              color="error"
                            >
                              <DeleteIcon sx={{ fontSize: "30px" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Paper>
                    {data?.is_verified === 1 ? (
                      <>
                        <div className="verifiedOwnerbadge">
                          <VerifiedUserIcon
                            color="success"
                            sx={{ textAlign: "center" }}
                          />
                        </div>
                      </>
                    ) : null}
                  </Box>
                </Grid>
              </>
            ))
          ) : propertiesList?.data?.message?.length > 0 ? (
            <p className="text-danger">{propertiesList?.data?.message}</p>
          ) : (
            <Loader open={propertyListLoader} />
          )}
        </Grid>
        {apiErr && <p className="text-danger text-center mt-3">{apiErr}</p>}
        <Box align="center" mt={3}>
          <Button
            className="popup_Btn other_popup_btn"
            variant="contained"
            onClick={addedNewProperty}
          >
            Add Another Property
          </Button>
          {!verified ? (
            propertiesList?.data?.length === 0 ? (
              <Button
                className={`popup_Btn success_popup_btn m-2 cursor-disable`}
              >
                Next
              </Button>
            ) : (
              <Button
                onClick={finishBtn}
                disabled={propertiesList?.data?.length === 0}
                type="submit"
                className={`popup_Btn success_popup_btn m-2`}
                variant="contained"
              >
                Next
              </Button>
            )
          ) : (
            <Button
              // onClick={() => setPropertyVerifiedPopup(true)}
              onClick={finishBtn}
              type="submit"
              className="popup_Btn success_popup_btn m-2"
              variant="contained"
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
      <Loader open={open || propertyListLoader || mailingAddressLoader} />
      {openDeletePopup && (
        <AlertDialog
          type="Attention"
          openPopup={openDeletePopup}
          onClose={true}
          onCloseFun={() => setOpenDeletePopup(false)}
          mesage={
            <>
              Are you sure you want to delete <b>{propertyName}</b> address?
            </>
          }
          buttons={
            <div className="text-center stepBtn mt-4 ">
              <Button
                onClick={() => {
                  setOpenDeletePopup(false);
                }}
                className="popup_Btn other_popup_btn"
                variant="contained"
              >
                No,Close
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    userSigupAction.deleteProperties({
                      url: "property-delete",
                      data: params,
                    })
                  );
                  setOpenDeletePopup(false);
                }}
                className="popup_Btn success_popup_btn mx-3"
                variant="contained"
              >
                Yes,Delete
              </Button>
            </div>
          }
        />
      )}
      {propertyVerifiedPopup && (
        <AlertDialog
          type="Attention"
          openPopup={propertyVerifiedPopup}
          onClose={true}
          onCloseFun={() => setPropertyVerifiedPopup(false)}
          mesage={
            <p className="fw-500">
              There are some properties that have not been verified yet. Please
              verify or delete those properties, or skip their verification for
              now. <br />
              <br />
              Later you can contact the admin for respective properties
              verification.
              {/* Please Verifiy or Delete the <b>{verified?.address}</b> address to
              proceed further. */}
            </p>
          }
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                sx={{ marginBottom: "13px" }}
                onClick={skipVerification}
              >
                Skip Verification
              </Button>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={() => setPropertyVerifiedPopup(false)}
                sx={{ marginBottom: "13px" }}
              >
                Verify Properties
              </Button>
            </>
          }
        />
      )}
      {propertyQuestionPopup && (
        <AlertDialog
          type="Error"
          openPopup={propertyQuestionPopup}
          onClose={true}
          onCloseFun={() => {
            setPropertyQuestionPopup(false);
            localStorage.setItem("button", "noButton");
            dispatch(userSigupAction.showSecurityInfoScreen(true));
          }}
          mesage={propertyQuestion?.data?.message}
        />
      )}
    </>
  );
};

export default OwnerVerified;
