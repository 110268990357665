import * as React from "react";
import { useEffect } from "react";

import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { Backdrop } from "@mui/material";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import PaidIcon from "@mui/icons-material/Paid";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessIcon from "@mui/icons-material/Business";
import ListItemButton from "@mui/material/ListItemButton";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CabinOutlinedIcon from "@mui/icons-material/CabinOutlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import IosShareIcon from "@mui/icons-material/IosShare";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";

import { Loader } from "../common/loader";
import CallUsNow from "../common/CallUsNow";
import AlertDialog from "../common/AlertDialog";
import LogoIcon from "../../assets/dashboard/icon.svg";
import NotificationMenu from "../layout/notifications";
import AdminAccountMenu from "../layout/adminAccountMenu";
import SellIconSVG from "../common/customSVGs/SellIconSVG";
import SendIconSVG from "../common/customSVGs/SendIconSVG";
import MenuIconImg from "../../assets/dashboard/menu_open.svg";
import SidebarLogo from "../../assets/footer/equity black.png";
import Facebook_Icon from "../../assets/articles/facebook.svg";
import { decryptPayload, getObjLength } from "../helpers/utils";
import Instagram_Icon from "../../assets/articles/instagram.svg";
import PropertiesIconSVG from "../common/customSVGs/PropertiesIconSVG";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import {
  danEmail,
  DEFAULT_URL,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  ROLES,
} from "../helpers/constants";
import { propertyUsersAction } from "../../redux/slices/admin/propertyUsers/propertyUsersSlice";

import "../../styles/appbar.scss";
import "../../styles/sidebar.scss";

console.log("location name", window.location.pathname);

const drawerWidth = 203;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  border: "none",
  zIndex: "999",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  border: "none",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("md")]: {
    width: "0",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 3),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginLeft: theme.spacing(7),
  width: `calc(100% - ${theme.spacing(8)} - 1.5px)`,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: "0",
  },
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  // zIndex: theme.zIndex.drawer + 1,
  zIndex: "9",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AdminSideBar({ comp }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [loader, setLoader] = React.useState(true);

  const userProfileDetails = useSelector(
    (state) => state.userProfile.userProfileDetails
  );
  const { notificationLoader } = useSelector((state) => state?.notifications);

  const clearUserData = () => {
    dispatch(userProfileAction.clearUserProfileData());
  };

  // console.log("userProfileDetails", userProfileDetails?.success);

  const handleModalClosing = () => {
    if (window.innerWidth < 575) {
      setOpen((prevState) => !prevState);
    }
    const pathname = window.location.pathname;
    if (pathname?.length > 0 && !pathname?.includes("admin-dashboard")) {
      localStorage.removeItem("offset");
    }
  };

  useEffect(() => {
    setOpen(true);
    setLoader(true);
    dispatch(
      userProfileAction.userProfileData({
        url: "profile",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (window.innerWidth < 575) {
      setOpen(false);
    }
    setLoader(false);
  }, []);

  useEffect(() => {
    if (getObjLength(userProfileDetails) && userProfileDetails?.success) {
      if (userProfileDetails?.data?.status === "inactive") {
        localStorage.clear();
        localStorage.setItem("preference", true);
        window.location.href = `${DEFAULT_URL}login`;
      }
    }
  }, [userProfileDetails]);

  const userPreference = (access) => {
    localStorage.setItem("preference", access);
    setOpenAlert(false);
  };
  let superAdmin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.admin) || (danEmail === userProfileDetails?.data?.email);
  let access =
    decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.admin) ||
    decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.subAdmin);
  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          className={open ? "open" : "close"}
        >
          <Toolbar className="toolbar">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
            >
              <img
                src={MenuIconImg}
                alt="Menu Icon"
                className="d-none d-md-block"
              />
              <MenuIcon color="action" className="d-block d-md-none" />
            </IconButton>
            <Link
              className="text-white text-center text-decoration-none"
              to="/"
            >
              <img
                src={SidebarLogo}
                alt="Logo"
                className="d-block d-md-none"
                style={{ height: "30px", width: "84px" }}
              />
            </Link>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                ml: "auto",
              }}
            >
              {/* <NotificationMenu /> */}
              <AdminAccountMenu />
              <div className="px-1 px-md-3">
                <CallUsNow
                  type="help"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                />
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          className={
            open
              ? window.innerWidth < 575
                ? "open position-absolute"
                : "open"
              : window.innerWidth < 575
              ? "close position-absolute"
              : "close"
          }
        >
          <DrawerHeader
            sx={{ minHeight: "24px" }}
            className={`${!open ? "header_height" : ""} d-none d-md-flex`}
          >
            <div
              className="sidebarLogoWrapper d-none d-md-block"
              style={{ width: open ? "80%" : "100%", cursor: "pointer" }}
            >
              <Link
                className="text-white text-center text-decoration-none"
                to="/"
              >
                <Box sx={{ display: open ? "visible" : "none" }}>
                  <img src={SidebarLogo} alt="Sidebar Logo" />
                </Box>
                <Box sx={{ display: open ? "none" : "visible" }}>
                  <img
                    src={LogoIcon}
                    alt="Logo Icon"
                    style={{ maxHeight: "24px" }}
                  />
                </Box>
              </Link>
            </div>
          </DrawerHeader>
          <List
            className="menuItemsList d-flex flex-column"
            sx={{ flex: "1 0 auto" }}
          >
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("admin-dashboard")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/admin-dashboard"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PropertiesIconSVG />
                  </ListItemIcon>
                  <ListItemText
                    className={`menu-item`}
                    primary={"Dashboard"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("admin-properties")
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  handleModalClosing();
                  dispatch(propertyUsersAction.setSelectedProperty({}));
                }}
                to="/admin-properties"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CabinOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={`menu-item`}
                    primary={"Properties"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            {(access || county_admin) && (
              <ListItem disablePadding>
                <Link
                  className={`text-white text-center text-decoration-none ${
                    window.location.pathname
                      .split("/")
                      .includes("admin-property-monitoring")
                      ? "active"
                      : ""
                  }`}
                  onClick={handleModalClosing}
                  to="/admin-property-monitoring"
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      width: 250,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ScreenSearchDesktopIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="menu-item"
                      primary={"Property Monitoring"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            )}
            {access && (
              <>
                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("admin-professionals")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/admin-professionals"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <GroupOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Professionals"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname.split("/").includes("add-leads")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/add-leads"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <LockIconSVG /> */}
                        {/* <PersonAddAltIcon /> */}
                        <SendIconSVG />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Send Invite"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname.split("/").includes("promocodes")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/promocodes"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <CodeOffOutlinedIcon /> */}
                        {/* <SubtitlesOutlinedIcon /> */}
                        <SellIconSVG />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Promo Codes"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("add-new-users")
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      handleModalClosing();
                      dispatch(userSigupAction.clearData());
                      dispatch(userSigupAction.userAddedByAdmin(true));
                      dispatch(
                        propertiesAction.setIsBypassOptionAvailable(true)
                      );
                    }}
                    to="/add-new-users"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <PersonAddAltIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Add new user"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("service-providers")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/service-providers"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SupervisorAccountIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Service Providers"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname.split("/").includes("admins")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/admins"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AdminPanelSettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Admins"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("admin-providers")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/admin-providers"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SupervisedUserCircleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Providers"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("admin-county")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/admin-county"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"County"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("request-document-list")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/request-document-list"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SummarizeOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Request Documents"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname
                        .split("/")
                        .includes("admin-recording-fees")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/admin-recording-fees"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <PaidIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Recording Fees"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                {superAdmin && (
                  <ListItem disablePadding>
                    <Link
                      className={`text-white text-center text-decoration-none ${
                        window.location.pathname
                          .split("/")
                          .includes("admin-export-csv")
                          ? "active"
                          : ""
                      }`}
                      onClick={handleModalClosing}
                      to="/admin-export-csv"
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          width: 250,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <IosShareIcon />
                        </ListItemIcon>
                        <ListItemText
                          className="menu-item"
                          primary={"Export Payments"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                )}

                <ListItem disablePadding>
                  <Link
                    className={`text-white text-center text-decoration-none ${
                      window.location.pathname.split("/").includes("admin-logs")
                        ? "active"
                        : ""
                    }`}
                    onClick={handleModalClosing}
                    to="/admin-logs"
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        width: 250,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="menu-item"
                        primary={"Logs"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <p>
                    <br />
                  </p>
                </ListItem>
              </>
            )}
          </List>
          <Stack
            direction={open ? "row" : "column"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            className="text-center border-top pt-2"
            sx={{}}
          >
            <a
              href={INSTAGRAM_LINK}
              target="_blank"
              rel="noreferrer"
              className="px-2 pt-2"
            >
              <img
                src={Instagram_Icon}
                alt="Facebook"
                style={{ height: "25px" }}
              />
              {/* <InstagramIcon
                style={{ color: "#E4405F" }}
                fontSize={!open ? "large" : "medium"}
              /> */}
            </a>

            <a
              href={FACEBOOK_LINK}
              target="_blank"
              rel="noreferrer"
              className="px-2 pt-2"
            >
              <img
                src={Facebook_Icon}
                alt="Facebook"
                style={{ height: "25px" }}
              />
              {/* <FacebookIcon fontSize={!open ? "large" : "medium"} /> */}
            </a>
          </Stack>
          <Box
            className="text-center p-0 pb-3"
            p={3}
            sx={{ display: open ? "visible" : "none" }}
          >
            <CallUsNow
              type="text"
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            />
          </Box>
          <Box
            className="text-center p-0 py-2"
            sx={{ display: open ? "none" : "block", py: 3 }}
          >
            <div className="call cursor-pointer">
              <CallUsNow
                type="icon"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              />
            </div>
          </Box>
        </Drawer>
        <Box
          component="main"
          className="main p-4 p-md-5 mt-5 mt-md-0 pb-0 pb-md-0"
          sx={{ flexGrow: 1, overflowX: "hidden" }}
        >
          <DrawerHeader sx={{ minHeight: "40px !important" }} />
          {userProfileDetails?.success ? (
            comp
          ) : (
            <Box className="text-center">
              <span
                className="text-danger text-capitalize"
                // onClick={clearUserData}
              >
                {userProfileDetails?.data?.message ? (
                  <>
                    {`${userProfileDetails?.data?.message}`}
                    <Link
                      to={"/login"}
                      className="bold text-decoration-none"
                      onClick={clearUserData}
                    >
                      &nbsp;Click here to Login again.
                    </Link>
                  </>
                ) : null}
              </span>
            </Box>
          )}
          {/* {comp} */}
        </Box>
        <Loader open={loader || notificationLoader} />
        {(localStorage.getItem("preference") === null ||
          localStorage.getItem("preference").length === 0) && (
          <AlertDialog
            type="Warning"
            // width="md"
            openPopup={openAlert}
            onClose={false}
            mesage={
              <Box className="text-start">
                <Typography className=" fw-600 text-center m-3" variant="h6">
                  This website uses cookies
                </Typography>
                <p className="m-4 fw-500">
                  We use essential cookies to make our site work. With your
                  consent, we may also use non-essential cookies to improve user
                  experience, share information about your use of our site with
                  our social media partners, advertising, analytics partners and
                  analyze website traffic. By clicking “ACCEPT” you agree to our
                  website's cookie use. You can change your cookie settings at
                  any time by clicking “Preferences.”
                </p>
              </Box>
            }
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn m-2"
                  color="error"
                  onClick={() => userPreference(false)}
                >
                  Deny
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => userPreference(true)}
                >
                  Accept
                </Button>
              </>
            }
          />
        )}
      </Box>

      {open && window.innerWidth < 575
        ? ReactDOM.createPortal(
            <>
              <Backdrop
                sx={{ zIndex: 995 }}
                open={open}
                onClick={() => {
                  setOpen((prevState) => !prevState);
                }}
              />
            </>,
            document.body
          )
        : null}
    </>
  );
}
