import React from "react";
import YouTube from "react-youtube";

const LazyIframe = ({ src, id }) => {
  const opts = {
    height: "300px",
    width: "100%",
    playerVars: {
      // autoplay: 1,
    },
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <div>
      {/* <iframe
        // src={`${src}?autoplay=1&mute=1`}
        src={`${src}`}
        width="100%"
        height="227px"
        frameborder="0"
        allowfullscreen
        title="youtube video"
      ></iframe> */}
      <YouTube videoId={id} opts={opts} onReady={_onReady} />
    </div>
  );
};

export default LazyIframe;
