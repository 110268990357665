import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Success from "../../assets/icons/success.png";
import Error from "../../assets/icons/error.png";
import Info from "../../assets/icons/info.png";
import Warning from "../../assets/icons/warning.png";
import help_center from "../../assets/icons/help_center.svg";
import CallUsNow from "./CallUsNow";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "./loader";
import "./common.scss";

export default function AlertDialog({
  openPopup,
  setOpenPopup,
  mesage,
  onClose,
  width,
  type,
  buttons,
  loader,
  onCloseFun,
  exclamationMark = true,
  footer = true,
  title,
  isPadding= true,
}) {
  const [open, setOpen] = React.useState(openPopup);

  const handleClose = () => {
    setOpen(false);
    // setOpenPopup(false);
    onCloseFun();
  };

  console.log(open, "AlertDialog", openPopup);

  return (
    <Dialog
      maxWidth={width ? width : "sm"}
      sx={{zIndex:"13000001", textAlign: "center"}}
      fullWidth={true}
      open={open}
      onClose={onClose ? handleClose : null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {onClose && (
        <Button
          onClick={handleClose}
          className="modal-close-btn"
          style={{
            position: "absolute",
            right: 10,
            top: 15,
            background: "transparent",
          }}
        >
          <CloseIcon sx={{ color: "#9CB8A6" }} />
        </Button>
      )}
      <DialogTitle id="alert-dialog-title" className="my-2">
        {type === "Success" && (
          <img className="popup_icon" src={Success} alt={type} />
        )}
        {type === "Error" && (
          <img className="popup_icon" src={Error} alt={type} />
        )}
        {type === "Info" && (
          <img className="popup_icon" src={Info} alt={type} />
        )}
        {type === "Help" && (
          <img className="popup_icon" src={Info} alt={type} />
        )}
        {type === "Attention" && (
          <img className="popup_icon" src={Info} alt={type} />
        )}
        {type === "Warning" && (
          <img className="popup_icon" src={Warning} alt={type} />
        )}

        <p className="text-center fw-600 m-0 my-2">
          {title ? title : type} {/*{exclamationMark && "!!"} */}
        </p>
        <Typography className="text-center fw-400 m-0 ">{mesage}</Typography>
      </DialogTitle>
      <Box className="mb-4 alert-modal-btn">
        {onClose &&
          !buttons &&
          (type === "Error" ? (
            <Button
              className="popup_Btn error_popup_btn "
              onClick={handleClose}
            >
              ok, Close
            </Button>
          ) : (
            <Button
              className="popup_Btn success_popup_btn"
              onClick={handleClose}
            >
              ok, All Good
            </Button>
          ))}
        {buttons && buttons}
      </Box>

      {footer && (
        <>
          <hr />
          <Stack direction={"row"} justifyContent="center" padding={2}>
            <img src={help_center} alt="help_center" className="me-1" />
            <span className="me-1">Having trouble?</span>
            <CallUsNow
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              renderText={
                <Link className="text-blue text-decoration-none" to="">
                  Contact support
                </Link>
              }
            />{" "}
          </Stack>
        </>
      )}
      {loader && <Loader open={loader} />}
    </Dialog>
  );
}
