import React, { useRef } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Box, Button, Stack, Typography } from "@mui/material";

import CallUsNow from "../common/CallUsNow";
import AsSeenOnSection from "./AsSeenOnSection";
import logo from "../../assets/logo/equity.WebP";
import Testimonials from "../common/Testimonials";
import { decryptPayload } from "../helpers/utils";
import phoneTalk from "../../assets/phone_in_talk.svg";
import { Callto } from "../common/ContcatAdminSupport";
import EP_bg_logo from "../../assets/icons/EP_bg_logo.webp";
import analysis from "../../assets/properties/analysis.webp";
import dennisPrager from "../../assets/properties/prager.png";
import risk_score from "../../assets/properties/risk_score.webp";
import sebastianGorka from "../../assets/properties/gorka_2022.png";
import PropertyRiskManagementForm from "./PropertyRiskManagementForm";
import gallagher_2022 from "../../assets/properties/gallagher_2022.png";
import risk_indicator from "../../assets/properties/risk_indicator.webp";
import verified_monitoring from "../../assets/properties/verified_monitoring.webp";
import {
  GORKA_MARKETING_AUTOMATION_ID,
  Gorka30,
  MIKE_MARKETING_AUTOMATION_ID,
  Mike30,
  PRAGER_MARKETING_AUTOMATION_ID,
  Prager30,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";

import "../../styles/landingpage.scss";
import "./NationalHostsLandingPage.scss";
import "../../styles/howItWorksSection.scss";

const NationalHostsLandingPage = ({ radio }) => {
  const navigate = useNavigate();
  const riskFormRef = useRef(null);
  const path = window?.location?.pathname;
  console.log("params-->", path);

  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));

  let promocode =
    radio === "Mike Gallagher"
      ? Mike30
      : radio === "Dr. Sebastian Gorka"
      ? Gorka30
      : Prager30;

  let name = radio?.split(" ");
  let host_name = name?.length === 3 ? name?.[1] : name?.[0];

  const goToPricing = () => {
    navigate(`/pricing?${promocode}`);
  };

  // Function to handle scrolling to the form
  const scrollToForm = () => {
    riskFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="national_host_content bg-white">
      <section className="natioal_host_bg_img ">
        <Stack
          direction={"row"}
          spacing={2}
          className="header_content"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Link
            to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
            className="EP_logo"
          >
            <LazyLoadImage src={logo} width="250px" height="100%" alt="logo" />
          </Link>
          <Box className="contact_section">
            <div className="d-none d-md-block">
              <Typography className="help_texts">Need help?</Typography>
              <Typography className="contact_texts">
                Call us at:{" "}
                <Callto phone={`${SUPPORT_PHONE}`}>
                  <span className="text-white">{SUPPORT_PHONE_FORMAT}</span>
                </Callto>
              </Typography>
            </div>
            <div className="MobAction_btn ms-auto d-flex flex-row d-md-none">
              <CallUsNow
                hideEmail={true}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                renderText={
                  <Button className="mx-1 call_btn" variant="link">
                    <LazyLoadImage
                      src={phoneTalk}
                      width="24px"
                      height="24px"
                      alt="Phone Talk"
                    />
                  </Button>
                }
              />
            </div>
          </Box>
        </Stack>
        <div className="d-flex align-items-center first-section">
          <Container fluid className="text-white mx-150">
            <Row className="align-items-center" spacing={3}>
              <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                <Stack direction={"column"} spacing={2}>
                  <Box className="top_section_spacing">
                    <p
                      className="notify_header mb-2"
                      style={{ color: "#0395FF" }}
                    >
                      Check your property
                    </p>
                    <h1 className="fw-700 mb-3 main-text">
                      My name is {radio} and I recommend Equity Protect. 
                    </h1>
                    <p className="paragraph_text fw-400 mb-1 mt-2">
                      Check multiple addresses for free!
                    </p>
                    <p className="paragraph_text fw-400 mb-3 mt-1">
                      If you're at medium or higher levels of risk, be sure to
                      use the{" "}
                      <b className="fw-700">
                        <u>
                          {radio === "Mike Gallagher"
                            ? Mike30
                            : radio === "Dr. Sebastian Gorka"
                            ? Gorka30
                            : Prager30}
                        </u>
                      </b>
                       discount code to get 30% off your first year.
                    </p>
                    <Stack
                      className="buttons_section"
                      direction={{ xs: "column", sm: "row" }}
                    >
                      <Button
                        className="risk_button success_popup_btn"
                        onClick={scrollToForm}
                      >
                        Free Risk Assessment
                      </Button>
                      <Button className="discount_button" onClick={goToPricing}>
                        Get 30% off the first year
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={5}
                xl={5}
                className="text-center"
              >
                <div className="ep__img-block">
                  <img
                    className="radio_img"
                    src={
                      radio === "Mike Gallagher"
                        ? gallagher_2022
                        : radio === "Dr. Sebastian Gorka"
                        ? sebastianGorka
                        : dennisPrager
                    }
                    alt={""}
                  />
                </div>
              <div className="ep__bg-logo">
                <img className="" src={EP_bg_logo} alt={""} />
              </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="as_seen_on_section mb-5">
        <AsSeenOnSection />
      </section>

      <section className="pt-5 risk_eveluate_section">
        <Container fluid className="property-protected-container ">
          <h3 className="pp_title mb-5">How We Evaluate Risk:</h3>

          <Row className="mt-5 mb-2 risk_points">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={6}
              className="content-spacing "
            >
              <Stack direction={{ sm: "column", md: "row" }} className="pb-3">
                <div>
                  <img className="mb-3" src={analysis} alt="Data Analysis" />
                  <p className="protection_title">Data Analysis</p>
                  <p className="protection_desc pe-0 pe-lg-5">
                    We cross-reference numerous data points, including ownership
                    patterns, property values, equity percentages, and property
                    data changes to identify discrepancies or suspicious
                    activity that could signal potential fraud.
                  </p>
                </div>
                <div>
                  <img
                    className="mb-3"
                    src={risk_indicator}
                    alt="Risk Indicators"
                  />
                  <p className="protection_title">Risk Indicators</p>
                  <p className="protection_desc pe-0 pe-lg-5">
                    Changes in mailing addresses, unexpected vacancies, or
                    rental listings can be red flags for fraudulent activity.
                    Our report pinpoints these indicators for proactive
                    intervention.
                  </p>
                </div>
              </Stack>
              <Stack direction={{ sm: "column", md: "row" }} className="pt-3 ">
                <div className="pe-3">
                  <img
                    className="mb-3"
                    src={verified_monitoring}
                    alt="Verification and Monitoring"
                  />
                  <p className="protection_title">
                    Verification and Monitoring
                  </p>
                  <p className="protection_desc pe-0 pe-lg-5">
                    We rigorously validate contact information and use advanced
                    dark web monitoring to detect compromised personal details,
                    fraudulent schemes, cyber threats, and any activity
                    targeting your property.
                  </p>
                </div>
                <div className="ml-3">
                  <img className="mb-3" src={risk_score} alt="Risk Scoring" />
                  <p className="protection_title">Risk Scoring</p>
                  <p className="protection_desc pe-0 pe-lg-5">
                    Our sophisticated algorithms combine these data points to
                    generate a comprehensive risk score, highlighting the
                    likelihood of equity theft or fraud. This allows for
                    targeted protection measures.
                  </p>
                </div>
              </Stack>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={5} className="">
              <Box
                className=" pb-2 property_form property-risk-page-content"
                ref={riskFormRef}
              >
                <PropertyRiskManagementForm
                  getFreeReport={true}
                  leadGen={true}
                  widthClass={"lead-prm-form"}
                  source={radio}
                  host_promocode={promocode}
                  host_path={path}
                  AUTOMATION_ID={
                    radio === "Mike Gallagher"
                      ? MIKE_MARKETING_AUTOMATION_ID
                      : radio === "Dr. Sebastian Gorka"
                      ? GORKA_MARKETING_AUTOMATION_ID
                      : PRAGER_MARKETING_AUTOMATION_ID
                  }
                />
              </Box>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="testimonials_section light_bg">
        <Container fluid className="mx-150 pt-150 pb-5">
          <h3 className="pp_title mb-4">Our clients trust us</h3>
          <Testimonials />
        </Container>
      </section>

      <section className="light_bg ">
        <Container fluid className="last_section py-5">
          <Row className="ep__promocode-sec">
            <div className="ep__promocode-wrap">
              <Box className="caption_sections ep__promocode-desc">
                <p className="captions fw-400 mb-3 mt-2">
                  If you're at medium or higher levels of risk, be sure to use
                  the{" "}
                  <b className="fw-700">
                    <u>
                      {radio === "Mike Gallagher"
                        ? Mike30
                        : radio === "Dr. Sebastian Gorka"
                        ? Gorka30
                        : Prager30}
                    </u>
                  </b>
                   discount code to get 30% off your first year.
                </p>
                <p className="captions fw-400 mb-3 mt-2">
                  Or if you're low risk, you can activate free title
                  monitoring and be alerted if there's an issue. All from your
                  friend, {host_name === "Sebastian" ? "Dr. Gorka" : host_name}{" "}
                  and the folks at Equity Protect. 
                </p>
                <Stack
                  className="buttons_section"
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Button
                    className="risk_button success_popup_btn"
                    onClick={scrollToForm}
                  >
                    Free Risk Assessment
                  </Button>
                  <Button className="discount_button" onClick={goToPricing}>
                    Get 30% off the first year
                  </Button>
                </Stack>
              </Box>
            </div>

            <div className="img_section">
              <img
                className="radio_img"
                src={
                  radio === "Mike Gallagher"
                    ? gallagher_2022
                    : radio === "Dr. Sebastian Gorka"
                    ? sebastianGorka
                    : dennisPrager
                }
                alt={""}
              />
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default NationalHostsLandingPage;
