/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Typography } from "@mui/material";
// import { Elements } from "@stripe/react-stripe-js";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { ROLES } from "../../helpers/constants";
import SecurePaymentPanel from "../../common/SecurePaymentPanel";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import {
  decryptPayload,
  getObjLength,
  reactPixelEventOnlyForCustomer,
} from "../../helpers/utils";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

const CardPayment = ({
  onChangeModalScreen,
  answers,
  planId,
  onCloseModalHandler,
  isByPassOptionAvailable,
  selectedData,
  addPromoCode = false,
  from = "",
  callListingAPI = () => {},
  propertiesIds = [],
  ids = [],
  uid = null,
  tabComponent = false,
  skipVerification = false,
  isUserCreateProperty = false,
  handleCloseAddPropertyModal,
}) => {
  const dispatch = useDispatch();

  // states
  const [cardData, setCardData] = useState({});
  const [alertMsg, setAlertMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [valdiateModal, setValdiateModal] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState();
  const [isPaymentDone, setIsPaymentDone] = useState(false);

  // store data
  const { cardDetails, cardLoader, userProfileDetails, loggedInEmail } =
    useSelector((state) => state.userProfile);
  const {
    paymentLoader,
    propertyPayment,
    propertyQuestion,
    selectedProperty,
    genearteQuesPayload,
  } = useSelector((state) => state?.properties);
  const { mailingAddress } = useSelector((state) => state.signUpUser);

  // useEffect(() => {
  //   if (Object.keys(propertyPayment).length > 0) {
  //     paymentResponse();
  //   }
  // }, [propertyPayment]);

  const metaData = () => {
    const plan_details = localStorage.getItem("planDetails");
    let metadata = {};
    if (plan_details) {
      metadata = {
        property_id: selectedProperty?.property_id,
        property_address: selectedProperty?.address,
        email: loggedInEmail,
        ...JSON.parse(plan_details),
      };
    } else {
      metadata = {
        property_id: selectedProperty?.id,
        property_address: selectedProperty?.id,
        email: loggedInEmail,
      };
    }
    console.log("plan_details==>", metadata);
    return metadata;
  };

  const paymentResponse = () => {
    setAlertMsg(true);
    // fb metadata

    let metadata = metaData();
    if (propertyPayment?.success) {
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.customer
        ) &&
        !propertyPayment?.data?.actionRequired
      ) {
        reactPixelEventOnlyForCustomer("Property Added by User", metadata);
      }
      setSuccessMsg(propertyPayment?.message);
      setErrMsg("");
    } else {
      setSuccessMsg("");
      setErrMsg(propertyPayment?.data?.message);
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.customer
        ) &&
        !propertyPayment?.data?.actionRequired
      ) {
        reactPixelEventOnlyForCustomer("Add Property failed", metadata);
      }
    }
  };

  const paySecure = () => {
    let activeCreditId = cardDetails?.data?.filter((i) => i.default === 1);
    let selectedCardData = (cardDetails?.data).filter(
      (i) => i.id === parseInt(activeCreditId[0]?.id)
    );
    console.log(activeCreditId, "selcted card data==>", selectedCardData);

    let cardId;
    if (cardData.length > 0) {
      cardId = cardData?.[0]?.id;
    } else {
      cardId = selectedCardData?.[0]?.id;
    }

    let inputParams = {
      ...answers,
      card_id: cardId,
      plan_id: planId,
      verified: propertyQuestion?.data?.is_verified,
      skip_verification: skipVerification,
    };
    if (getObjLength(selectedData)) {
      inputParams = { ...selectedData, ...inputParams };
    }
    if (promoCodeValue) {
      inputParams.promo_code = promoCodeValue;
    }
    console.log(propertiesIds, "input params==>", inputParams);
    if (from === "add-subscriptions" || from === "add-property") {
      let data = {
        plan: planId,
        user_id: uid,
        first_name: mailingAddress?.data?.first_name,
        last_name: mailingAddress?.data?.last_name,
        address: mailingAddress?.data?.address?.address,
        city: mailingAddress?.data?.address?.city,
        state: mailingAddress?.data?.address?.state,
        zip: mailingAddress?.data?.address?.zip,
        step: 4,
        step_key: "PAYMENT",
        card_id: cardId,
        property_ids: tabComponent
          ? propertiesIds
          : [selectedData?.property_id],
      };
      if (promoCodeValue) {
        data.promo_code = promoCodeValue;
      }
      console.log("== Paymnet done...", data);
      dispatch(
        propertiesAction.paymentForProperty({
          url: `admin/users/${uid}/post-payment`,
          data: data,
          token: true,
        })
      );
    } else {
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
      ) {
        dispatch(
          propertiesAction.paymentForProperty({
            url: "service-provider/customer-verify-answers",
            data: { ...inputParams, ...genearteQuesPayload },
            token: true,
          })
        );
      } else if (
        userProfileDetails?.data?.user_type?.includes(ROLES?.professional) &&
        !userProfileDetails?.data?.role?.includes(ROLES?.customer)
      ) {
        dispatch(
          propertiesAction.paymentForProperty({
            url: "professional/professional-verify-answers",
            data: { ...inputParams, ...genearteQuesPayload },
            token: true,
          })
        );
      } else {
        dispatch(
          propertiesAction.paymentForProperty({
            url: "customer/customer-verify-answers",
            data: { ...inputParams, ...genearteQuesPayload },
            token: true,
          })
        );
      }
    }
  };

  const goback = () => {
    onChangeModalScreen("subscriptions");
  };

  const onSuccessFun = () => {
    localStorage.removeItem("promoCode");
    localStorage.removeItem("referralCode");
    if (
      decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.customer)
    ) {
      reactPixelEventOnlyForCustomer(
        "Property Added by User with 3d secure card",
        metaData()
      );
    }
    onCloseModalHandler("success");
    dispatch(propertiesAction.setAddPropertySubscription({}));
  };
  const onFailedFun = () => {
    if (
      decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.customer)
    ) {
      reactPixelEventOnlyForCustomer("Add Property failed", metaData());
    }
    dispatch(propertiesAction.setAddPropertySubscription({}));
    onCloseModalHandler("error");
  };

  useEffect(() => {
    if (getObjLength(propertyPayment)) {
      paymentResponse();
      if (getObjLength(propertyPayment?.data)) {
        setValdiateModal(true);
        setIsPaymentDone(propertyPayment?.data?.payment_done);
      }
      if (propertyPayment?.success) {
        if (propertyPayment?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !propertyPayment?.data?.actionRequired &&
          propertyPayment?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [propertyPayment]);

  return (
    <Box>
      <Typography
        align="center"
        variant="subtitle1"
        className="py-1 formHeader zoom-container"
        sx={{ position: "relative" }}
      >
        <LockIcon sx={{ fontSize: 16 }} className="me-1" />
        Secure connection
        <Button
          onClick={handleCloseAddPropertyModal}
          className="modal-close-btn close-text hide-inner-close"
          style={{
            position: "absolute",
            top: 0,
            right: -16,
            background: "transparent",
            textTransform: "none",
          }}
        >
          <CloseIcon
            className="hide-inner-close"
            sx={{ fontSize: 20, color: "white" }}
          />
        </Button>
      </Typography>
      {/* <Elements stripe={stripePromise}> */}
      {from === "add-subscriptions" || from === "add-property" ? (
        <SecurePaymentPanel
          displayLabel={false}
          cardData={setCardData}
          paymentData={propertyPayment}
          onSuccessFun={onSuccessFun}
          onFailedFun={onFailedFun}
          userId={uid}
          from={from}
          callListingAPI={callListingAPI}
          addPromoCode={addPromoCode}
          promoCodeValue={setPromoCodeValue}
          paySecure={paySecure}
          // for add properties
          uid={uid}
          isUserCreateProperty={isUserCreateProperty}
        />
      ) : (
        <SecurePaymentPanel
          displayLabel={false}
          cardData={setCardData}
          paymentData={propertyPayment}
          onSuccessFun={onSuccessFun}
          onFailedFun={onFailedFun}
          userId={answers?.user_id}
          // for promocode
          addPromoCode={addPromoCode}
          promoCodeValue={setPromoCodeValue}
          paySecure={paySecure}
          isUserCreateProperty={isUserCreateProperty}
        />
      )}
      {/* </Elements> */}
      {/* <CardList cardData={setCardData} /> */}

      {/* desktop buttons */}
      <div className="d-flex align-items-center justify-content-between mt-3 ">
        <Button
          onClick={goback}
          disabled={paymentLoader}
          className="popup_Btn other_popup_btn"
        >
          Back
        </Button>

        <Button
          type="submit"
          onClick={paySecure}
          className="popup_Btn success_popup_btn"
          variant="contained"
          disabled={!cardData?.[0]?.id || paymentLoader}
        >
          Pay Securly
        </Button>
      </div>
      {alertMsg && valdiateModal && (
        <>
          {Object.keys(propertyPayment).length > 0 &&
          propertyPayment?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                dispatch(propertiesAction.addNewProperty({}));
                localStorage.removeItem("promoCode");
                localStorage.removeItem("referralCode");
                onCloseModalHandler("success");
              }}
              mesage={successMsg}
            />
          ) : (
            // <p className="text-success">{successMsg}</p>
            <AlertDialog
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                dispatch(propertiesAction.addNewProperty({}));
                if (propertyPayment?.data?.message?.includes("Card expired")) {
                  setAlertMsg(false);
                  dispatch(propertiesAction.fethPayment({}));
                } else {
                  setAlertMsg(false);
                  dispatch(propertiesAction.clearPropertiesList("error"));
                  dispatch(userSigupAction.fetchTotalPaymentDetails({}));
                  if (!from && !isPaymentDone) {
                    onChangeModalScreen("security");
                  }
                }
                onCloseModalHandler("error");
              }}
              mesage={errMsg}
            />
            // <p className="text-danger">{errMsg}</p>
          )}
        </>
      )}

      <Loader open={paymentLoader || cardLoader} />
    </Box>
  );
};

export default CardPayment;
