import { createSlice } from "@reduxjs/toolkit";

const intialAdminsState = {
  adminListData: {},
  adminListDataCount: 0,
  addAdminDetails: {},
  deleteAdminDetails: {},
  addCountyAdminDetails: {},
  adminListLoader: false,
};

const admins = createSlice({
  name: "adminDetails",
  initialState: intialAdminsState,
  reducers: {
    clearAddAdminDetailsData(state, action) {
      state.addAdminDetails = {};
      state.addCountyAdminDetails = {};
      state.adminListLoader = false;
    },
    addAdminDetails(state, action) {
      state.adminListLoader = true;
    },
    deleteAdminDetails(state, action) {
      state.adminListLoader = true;
    },
    setAdminDetails(state, action) {
      state.addAdminDetails = action.payload;
      state.adminListLoader = false;
    },
    setDeletedAdminDetails(state, action) {
      state.deleteAdminDetails = action.payload;
      state.adminListLoader = false;
    },
    fetchAdminListData(state, action) {
      state.adminListLoader = true;
    },
    setAdminListData(state, action) {
      state.adminListData = action.payload;
      state.adminListDataCount = action.payload?.count;
      state.adminListLoader = false;
    },
    addCountyAdminDetails(state, action) {
      state.adminListLoader = true;
    },
    fetchCountyAdminDetails(state, action) {
      state.addCountyAdminDetails = action.payload;
      state.adminListLoader = false;
    },
  },
});
export const adminAction = admins.actions;
export default admins.reducer;
