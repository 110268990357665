import React from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import CustomTextField from "../../common/CustomTextField";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { getFormatedPhoneNumber, getObjLength } from "../../helpers/utils";
import {
  UCC_FILLER_EMAIL,
  UCC_FILLER_NAME,
  UCC_FILLER_PHONE,
  UCC_SECURED_PARTY_ADDRESS,
  UCC_SECURED_PARTY_CITY,
  UCC_SECURED_PARTY_ORGANIZATION_NAME,
  UCC_SECURED_PARTY_POSTAL_CODE,
  UCC_SECURED_PARTY_STATE,
} from "../../helpers/constants";

const UccForm = ({ selectedProperty, handleRequestDocBtn }) => {
  // handle submitting form
  const handleSubmit = (values, validation) => {
    handleRequestDocBtn(values, validation);
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          filerName: UCC_FILLER_NAME,
          filerPhone: UCC_FILLER_PHONE,
          filerEmail: UCC_FILLER_EMAIL,
          ownerFullName:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.["Owner Name"]
              : "",
          ownerAddress:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Mailing Address"
                ]
              : "",
          ownerCity:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Mailing City & State"
                ]?.split(",")[0]
              : "",
          ownerState:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Mailing City & State"
                ]?.split(",")[1]
              : "",
          ownerZipcode:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.["Mailing Zip"]
              : "",

          // 1st debtor details
          organization1Name: "",
          owner1FirstName:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Owner Name"
                ]?.split(" ")?.[0]
              : "",
          owner1LastName:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Owner Name"
                ]?.split(" ")?.length > 2
                ? selectedProperty?.property_details?.OwnerInfo?.[
                    "Owner Name"
                  ]?.split(" ")?.[2]
                : selectedProperty?.property_details?.OwnerInfo?.[
                    "Owner Name"
                  ]?.split(" ")?.[1]
              : "",
          owner1MiddleInitial:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Owner Name"
                ]?.split(" ")?.length > 2
                ? selectedProperty?.property_details?.OwnerInfo?.[
                    "Owner Name"
                  ]?.split(" ")?.[1]
                : ""
              : "",
          owner1Suffix: "",
          owner1MailAddress:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Mailing Address"
                ]
              : "",
          owner1MailCity:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Mailing City & State"
                ]?.split(",")[0]
              : "",
          owner1MailState:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.[
                  "Mailing City & State"
                ]?.split(",")[1]
              : "",
          owner1MailZipcode:
            selectedProperty &&
            selectedProperty?.property_details &&
            selectedProperty?.property_details?.OwnerInfo
              ? selectedProperty?.property_details?.OwnerInfo?.["Mailing Zip"]
              : "",
          owner1MailCountry: "USA",
          // 2nd debtor details
          organizations2Name: "",
          owner2FirstName: "",
          owner2LastName: "",
          owner2MiddleInitial: "",
          owner2Suffix: "",
          owner2MailAddress: "",
          owner2MailCity: "",
          Owner2MailState: "",
          owner2MailZipcode: "",
          owner2MailCountry: "",
          // security details
          securedPartyOrganizationsName: UCC_SECURED_PARTY_ORGANIZATION_NAME,
          securedPartyFirstName: "",
          securedPartyMiddleInitial: "",
          securedPartyLastName: "",
          securedPartySuffix: "",
          securedPartyMailAddress: UCC_SECURED_PARTY_ADDRESS,
          securedPartyMailCity: UCC_SECURED_PARTY_CITY,
          securedPartyMailState: UCC_SECURED_PARTY_STATE,
          securedPartyMailZipCode: UCC_SECURED_PARTY_POSTAL_CODE,
          securedPartyMailCountry: "USA",
          // other info
          collateral: `Debtor hereby declares that any Purported Transfer (defined below) involving all or any portion of, or any interest in, their title to the Property shall be invalid and of no force and effect unless there has been recorded in the Public Records a UCC-3 Amendment signed by Secured Party either terminating this UCC-1 filing or specifically authorizing the Purported Transfer. The term "Purported Transfer" shall mean the recording of any document in the Public Records purporting to (1) sell, convey, transfer, or assign all or any portion of the Debtor's right, title, or interest in or to the Property or any portion of the Property; and (b) mortgage, lien, or otherwise voluntarily encumber all or any portion of Debtor's right, title or interest in or to the Property or any portion of the Property.\nThe term Purported Transfer" shall not apply to transfers by operation of law or to judgment liens, federal or state tax liens, real property tax liens, mechanics or material men's liens, Medicare reimbursement liens, or other involuntary liens against real property.`,

          // 3rd debtor's initial value
          organization3Name: "",
          owner3FirstName: "",
          owner3LastName: "",
          owner3MiddleInitial: "",
          owner3Suffix: "",

          // 3rd debtor's mailing address
          owner3MailingAddress: "",
          owner3MailCity: "",
          owner3MailState: "",
          owner3MailZipcode: "",
          owner3MailCountry: "",

          additionalCollateral: `The Restriction shall be effective as of the date and time it is recorded in the public records of the county (or equivalent recording jurisdiction) in which the Property is located (Public Records"). The Restriction shall expire at 11:59 p.m. on the fifth (5th) anniversary date of the recording of the Notice in the Public Records. To secure a termination of this filing, to secure a waiver of this filing to allow a particular conveyance or encumbrance, or to confirm that a previously recorded waiver or termination of his filing was valid, contact Secured Party at the above address or at [https://equityprotect.com/]`,
          legalDescription: selectedProperty?.deed_info?.realPropertyDescription
            ? selectedProperty?.deed_info?.realPropertyDescription
            : "",
          legalVesting: "",
          serviceAgreement: selectedProperty?.transaction_id || "",
          IACA: "",

          checkBox1: false,
          checkBox2: false,
          checkBox3: false,
        }}
        validationSchema={Yup.object().shape(
          {
            // filler validation
            filerName: Yup.string("")
              .trim()
              .required("Please enter filler name"),
            filerPhone: Yup.string("")
              .trim()
              .required("Please enter filler phone"),
            filerEmail: Yup.string("")
              .email("Please enter valid email")
              .trim()
              .required("Please enter filler email"),

            // recipient validation
            ownerFullName: Yup.string("")
              .trim()
              .required("Please enter owner name"),
            ownerAddress: Yup.string("")
              .trim()
              .required("Please enter owner address"),
            ownerCity: Yup.string("").trim().required("Please Enter City"),
            ownerState: Yup.string("").trim().required("Please Enter State"),
            ownerZipcode: Yup.string("")
              .trim()
              .required("Please Enter Postal Code"),

            checkBox1: Yup.bool(),
            checkBox2: Yup.bool(),
            checkBox3: Yup.bool(),

            // 1st debtor's validation
            organization1Name: Yup.string()
              .trim()
              .when(["owner1LastName", "owner1FirstName"], {
                is: (a, b) => !a || a.length === 0 || !b || b.length === 0,
                then: Yup.string().required(
                  "Please Enter Organization Name OR Fill individual details"
                ),
                otherwise: Yup.string().optional().nullable(),
              }),
            owner1FirstName: Yup.string("")
              .trim()
              .when("organization1Name", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().required(
                  "Please Enter Organization Name OR Personal Name"
                ),
                otherwise: Yup.string().optional().nullable(),
              }),
            owner1LastName: Yup.string("")
              .trim()
              .when("organization1Name", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().required(
                  "Please Enter Organization Name OR Surname"
                ),
                otherwise: Yup.string().optional().nullable(),
              }),
            owner1MiddleInitial: Yup.string("").trim(),
            owner1Suffix: Yup.string("").trim(),
            owner1MailAddress: Yup.string("")
              .trim()
              .required("Please Enter Mailing Address"),
            owner1MailCity: Yup.string("").trim().required("Please Enter City"),
            owner1MailState: Yup.string("")
              .trim()
              .required("Please Enter State"),
            owner1MailZipcode: Yup.string("")
              .trim()
              .required("Please Enter Postal Code"),
            owner1MailCountry: Yup.string("")
              .trim()
              .required("Please Enter Country"),

            // 2nd debtor's validation
            organizations2Name: Yup.string().trim().optional().nullable(),
            owner2FirstName: Yup.string("")
              .trim()
              .when("owner2LastName", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter Personal Name"),
              }),
            owner2LastName: Yup.string("")
              .trim()
              .when("owner2FirstName", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter Surname"),
              }),
            owner2MiddleInitial: Yup.string("").trim(),
            owner2Suffix: Yup.string("").trim(),
            owner2MailAddress: Yup.string("").trim(),
            owner2MailCity: Yup.string("").trim(),
            Owner2MailState: Yup.string("").trim(),
            owner2MailZipcode: Yup.string("").trim(),
            owner2MailCountry: Yup.string("").trim(),

            // security validation
            securedPartyOrganizationsName: Yup.string()
              .trim()
              .when(["securedPartyLastName", "securedPartyFirstName"], {
                is: (a, b) => !a || a.length === 0 || !b || b.length === 0,
                then: Yup.string().required(
                  "Please Enter Organization Name OR Fill individual details"
                ),
                otherwise: Yup.string().optional().nullable(),
              }),
            securedPartyFirstName: Yup.string("")
              .trim()
              .when("securedPartyOrganizationsName", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().required(
                  "Please Enter Organization Name OR Personal Name"
                ),
                otherwise: Yup.string().optional().nullable(),
              }),
            securedPartyLastName: Yup.string("")
              .trim()
              .when("securedPartyOrganizationsName", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().required(
                  "Please Enter Organization Name OR Surname"
                ),
                otherwise: Yup.string().optional().nullable(),
              }),
            securedPartyMiddleInitial: Yup.string("").trim(),
            securedPartySuffix: Yup.string("").trim(),
            securedPartyMailAddress: Yup.string("")
              .trim()
              .required("Please Enter Mailing Address"),
            securedPartyMailCity: Yup.string("")
              .trim()
              .required("Please Enter City"),
            securedPartyMailState: Yup.string("")
              .trim()
              .required("Please Enter State"),
            securedPartyMailZipCode: Yup.string("")
              .trim()
              .required("Please Enter Postal Code"),
            securedPartyMailCountry: Yup.string("")
              .trim()
              .required("Please Enter Country"),

            // other information
            collateral: Yup.string().trim(),

            // 2nd addendum debtor's validation
            organization3Name: Yup.string().trim().optional().nullable(),
            owner3FirstName: Yup.string("")
              .trim()
              .when("owner3LastName", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter Personal Name"),
              }),
            owner3LastName: Yup.string("")
              .trim()
              .when("owner3FirstName", {
                is: (a) => !a || a.length === 0,
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter Surname"),
              }),
            owner3MiddleInitial: Yup.string("").trim(),
            owner3Suffix: Yup.string("").trim(),

            // mailing address
            owner3MailingAddress: Yup.string("")
              .trim()
              .when(["owner3FirstName", "owner3LastName"], {
                is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required(
                  "Please Enter Mailing Address"
                ),
              }),
            owner3MailCity: Yup.string("")
              .trim()
              .when(["owner3FirstName", "owner3LastName"], {
                is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter City"),
              }),
            owner3MailState: Yup.string("")
              .trim()
              .when(["owner3FirstName", "owner3LastName"], {
                is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter State"),
              }),
            owner3MailZipcode: Yup.string("")
              .trim()
              .when(["owner3FirstName", "owner3LastName"], {
                is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter Postal Code"),
              }),
            owner3MailCountry: Yup.string("")
              .trim()
              .when(["owner3FirstName", "owner3LastName"], {
                is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().required("Please Enter Country"),
              }),

            additionalCollateral: Yup.string().trim(),
            legalDescription: Yup.string().trim(),
            legalVesting: Yup.string().trim(),
            serviceAgreement: Yup.string().trim(),
            IACA: Yup.string().trim(),
          },
          [
            // 1st debtor's dependancies
            [
              "organization1Name",
              "owner1FirstName",
              "owner1LastName",
              "owner1MiddleInitial",
              "owner1Suffix",
            ],
            ["organization1Name", "owner1FirstName"],
            ["organization1Name", "owner1LastName"],
            ["organization1Name", "owner1MiddleInitial"],
            ["organization1Name", "owner1Suffix"],

            // 2nd debtor's dependancies
            ["owner2LastName", "owner2FirstName"],

            // security party's dependancies
            [
              "securedPartyOrganizationsName",
              "securedPartyFirstName",
              "securedPartyLastName",
              "securedPartyMiddleInitial",
              "securedPartySuffix",
            ],
            ["securedPartyOrganizationsName", "securedPartyFirstName"],
            ["securedPartyOrganizationsName", "securedPartyLastName"],
            ["securedPartyOrganizationsName", "securedPartyMiddleInitial"],
            ["securedPartyOrganizationsName", "securedPartySuffix"],

            // addendum 2nd debtor's dependancies
            ["owner3LastName", "owner3FirstName"],

            // addendum 2nd debtor's mailing address dependancies
            [
              "owner3FirstName",
              "owner3LastName",
              "owner3MailingAddress",
              "owner3MailCity",
              "owner3MailState",
              "owner3MailZipcode",
              "owner3MailCountry",
            ],
          ]
        )}
        onSubmit={(values) => {
          if (!isValidPhoneNumber(values?.filerPhone)) {
            return;
          }
          let payload = JSON.parse(JSON.stringify(values));
          const phoneNumber = values?.filerPhone;
          payload.filerPhone = getFormatedPhoneNumber(phoneNumber);

          console.log("final values", values, "payload", payload);

          handleSubmit(payload);
        }}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          resetForm,
          isValidating,
        }) => {
          const scrollIfError = () => {
            console.log("isValidating", isValidating);
            if (getObjLength(errors)) {
              const idsArray = Object.keys(errors);
              console.log("idsArray", idsArray);
              if (idsArray?.length > 0) {
                const element = document.getElementById(idsArray?.[0]);
                console.log("element", element);
                element.scrollIntoView({ behavior: "smooth" });
              }
            }
          };

          return (
            <Form className="ucc-form w-100" id="ucc-form">
              <Box mb={2}>
                <Divider textAlign="center" sx={{ fontWeight: 600 }}>
                  UCC FINANCING STATEMENT
                </Divider>
              </Box>
              {/* filler details */}
              <Box mb={2}>
                <Divider textAlign="left">FILLER DETAILS :-</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* filler name */}
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="Filler Name"
                    name="filerName"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* filler phone */}
                <Grid item xs={12} md={6} mt={1} px={1} pl={3} pb={1}>
                  <CustomPhoneNumberInput
                    name="filerPhone"
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                </Grid>
                {/* filler email */}
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="Filler Email"
                    name="filerEmail"
                    type="email"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}></Grid>
              </Grid>

              {/* Ackknowledge details */}
              <Box mb={2} mt={2}>
                <Divider textAlign="left">ACKNOWLEDGMENT TO :-</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Owner name */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="Owner Name"
                    name="ownerFullName"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Owner address */}
                <Grid item xs={12} md={5} px={1} pb={1}>
                  <CustomTextField
                    label="Owner Address"
                    name="ownerAddress"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Owner city */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="Owner City"
                    name="ownerCity"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Owner state */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Owner State"
                    name="ownerState"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Owner postal code */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Owner Postal Code"
                    name="ownerZipcode"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
              </Grid>

              {/* 1st debtor's details */}
              <Box mb={2} mt={2}>
                <Divider textAlign="left">1st DEBTOR'S DETAILS :-</Divider>
              </Box>
              <Typography variant="subtitle2" className="break-word" mb={2}>
                - Provide only one Debtor name (use exact, full name; do not
                omit, modify, or abbreviate any part of the Debtor’s name); if
                any part of the Individual Debtor’s name will not fit in below
                fields, leave all of fields blank, check here
                <Checkbox
                  name="checkBox1"
                  checked={values?.checkBox1}
                  onChange={(event) => {
                    setFieldValue("checkBox1", event.target.checked);
                    setFieldValue("checkBox3", event.target.checked);
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 24 },
                    padding: "0 5px",
                  }}
                />
                and provide the Individual Debtor information in 3rd DEBTOR'S
                details of the Financing Statement Form.
              </Typography>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* organization name */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="Organization Name"
                    name="organization1Name"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (
                          values?.owner1FirstName ||
                          values?.owner1LastName ||
                          values?.owner1MiddleInitial ||
                          values?.owner1Suffix
                        ) {
                          setFieldValue("owner1FirstName", "");
                          setFieldValue("owner1LastName", "");
                          setFieldValue("owner1MiddleInitial", "");
                          setFieldValue("owner1Suffix", "");
                        }
                        setFieldValue("organization1Name", event.target.value);
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box mb={2}>
                <Divider textAlign="center">OR</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* personal name */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="First Personal Name"
                    name="owner1FirstName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization1Name)
                          setFieldValue("organization1Name", "");
                        setFieldValue("owner1FirstName", event.target.value);
                      },
                    }}
                  />
                </Grid>
                {/* initials */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="Additional Name(s)/Initial(s)"
                    name="owner1MiddleInitial"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization1Name)
                          setFieldValue("organization1Name", "");
                        setFieldValue(
                          "owner1MiddleInitial",
                          event.target.value
                        );
                      },
                    }}
                  />
                </Grid>
                {/* individual surname */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="Individual Surname"
                    name="owner1LastName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization1Name)
                          setFieldValue("organization1Name", "");
                        setFieldValue("owner1LastName", event.target.value);
                      },
                    }}
                  />
                </Grid>
                {/* suffix */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Suffix"
                    name="owner1Suffix"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization1Name)
                          setFieldValue("organization1Name", "");
                        setFieldValue("owner1Suffix", event.target.value);
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Box mb={2} mt={2}>
                <Divider textAlign="left">
                  1st DEBTOR'S MAILING ADDRESS :-
                </Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Mailing Address */}
                <Grid item xs={12} md={4} px={1} pb={1}>
                  <CustomTextField
                    label="Mailing Address"
                    name="owner1MailAddress"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Mailing city */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="City"
                    name="owner1MailCity"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* state */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="State"
                    name="owner1MailState"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* postal code */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="Postal Code"
                    name="owner1MailZipcode"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Country */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Country"
                    name="owner1MailCountry"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
              </Grid>

              {/* 2nd debtor's details */}
              <Box mb={2} mt={2}>
                <Divider textAlign="left">2nd DEBTOR'S DETAILS :-</Divider>
              </Box>
              <Typography variant="subtitle2" className="break-word" mb={2}>
                - Provide only one Debtor name (use exact, full name; do not
                omit, modify, or abbreviate any part of the Debtor’s name); if
                any part of the Individual Debtor’s name will not fit in below
                fields, leave all of fields blank, check here
                <Checkbox
                  name="checkBox2"
                  checked={values?.checkBox2}
                  onChange={(event) => {
                    setFieldValue("checkBox2", event.target.checked);
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 24 },
                    padding: "0 5px",
                  }}
                />
                and provide the Individual Debtor information in 3rd DEBTOR'S
                details of the Financing Statement Form.
              </Typography>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* organization name */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="Organization Name"
                    name="organizations2Name"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (
                          values?.owner2FirstName ||
                          values?.owner2LastName ||
                          values?.owner2MiddleInitial ||
                          values?.owner2Suffix
                        ) {
                          setFieldValue("owner2FirstName", "");
                          setFieldValue("owner2LastName", "");
                          setFieldValue("owner2MiddleInitial", "");
                          setFieldValue("owner2Suffix", "");
                        }
                        setFieldValue("organizations2Name", event.target.value);
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box mb={2}>
                <Divider textAlign="center">OR</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* personal name */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="First Personal Name"
                    name="owner2FirstName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organizations2Name)
                          setFieldValue("organizations2Name", "");
                        setFieldValue("owner2FirstName", event.target.value);
                      },
                    }}
                  />
                </Grid>
                {/* initials */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="Additional Name(s)/Initial(s)"
                    name="owner2MiddleInitial"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organizations2Name)
                          setFieldValue("organizations2Name", "");
                        setFieldValue(
                          "owner2MiddleInitial",
                          event.target.value
                        );
                      },
                    }}
                  />
                </Grid>
                {/* individual surname */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="Individual Surname"
                    name="owner2LastName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organizations2Name)
                          setFieldValue("organizations2Name", "");
                        setFieldValue("owner2LastName", event.target.value);
                      },
                    }}
                  />
                </Grid>
                {/* suffix */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Suffix"
                    name="owner2Suffix"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organizations2Name)
                          setFieldValue("organizations2Name", "");
                        setFieldValue("owner2Suffix", event.target.value);
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Box mb={2} mt={2}>
                <Divider textAlign="left">
                  2nd DEBTOR'S MAILING ADDRESS :-
                </Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Mailing Address */}
                <Grid item xs={12} md={4} px={1} pb={1}>
                  <CustomTextField
                    label="Mailing Address"
                    name="owner2MailAddress"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Mailing city */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="City"
                    name="owner2MailCity"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* state */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="State"
                    name="Owner2MailState"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* postal code */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="Postal Code"
                    name="owner2MailZipcode"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Country */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Country"
                    name="owner2MailCountry"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
              </Grid>

              {/* security details */}
              <Box mb={2} mt={2}>
                <Divider textAlign="left">SECURED PARTY'S DETAILS :-</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* organization name */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="Organization Name"
                    name="securedPartyOrganizationsName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (
                          values?.securedPartyFirstName ||
                          values?.securedPartyLastName ||
                          values?.securedPartyMiddleInitial ||
                          values?.securedPartySuffix
                        ) {
                          setFieldValue("securedPartyFirstName", "");
                          setFieldValue("securedPartyLastName", "");
                          setFieldValue("securedPartyMiddleInitial", "");
                          setFieldValue("securedPartySuffix", "");
                        }
                        setFieldValue(
                          "securedPartyOrganizationsName",
                          event.target.value
                        );
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box mb={2}>
                <Divider textAlign="center">OR</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* personal name */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="First Personal Name"
                    name="securedPartyFirstName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.securedPartyOrganizationsName)
                          setFieldValue("securedPartyOrganizationsName", "");
                        setFieldValue(
                          "securedPartyFirstName",
                          event.target.value
                        );
                      },
                    }}
                  />
                </Grid>
                {/* initials */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="Additional Name(s)/Initial(s)"
                    name="securedPartyMiddleInitial"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.securedPartyOrganizationsName)
                          setFieldValue("securedPartyOrganizationsName", "");
                        setFieldValue(
                          "securedPartyMiddleInitial",
                          event.target.value
                        );
                      },
                    }}
                  />
                </Grid>
                {/* individual surname */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="Individual Surname"
                    name="securedPartyLastName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.securedPartyOrganizationsName)
                          setFieldValue("securedPartyOrganizationsName", "");
                        setFieldValue(
                          "securedPartyLastName",
                          event.target.value
                        );
                      },
                    }}
                  />
                </Grid>
                {/* suffix */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Suffix"
                    name="securedPartySuffix"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.securedPartyOrganizationsName)
                          setFieldValue("securedPartyOrganizationsName", "");
                        setFieldValue("securedPartySuffix", event.target.value);
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box mb={2} mt={2}>
                <Divider textAlign="left">
                  SECURED PARTY'S MAILING ADDRESS :-
                </Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Mailing Address */}
                <Grid item xs={12} md={4} px={1} pb={1}>
                  <CustomTextField
                    label="Mailing Address"
                    name="securedPartyMailAddress"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Mailing city */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="City"
                    name="securedPartyMailCity"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* state */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="State"
                    name="securedPartyMailState"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* postal code */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="Postal Code"
                    name="securedPartyMailZipCode"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Country */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Country"
                    name="securedPartyMailCountry"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
              </Grid>
              <Box mb={2} mt={2}>
                <Divider textAlign="left">COLLATERAL :-</Divider>
                <Typography variant="subtitle2" className="break-word">
                  - This financing statement covers the following collateral:
                </Typography>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Collateral */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="collateral"
                    name="collateral"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      multiline: true,
                      rows: 4,
                    }}
                  />
                </Grid>
              </Grid>

              {/* 3rd DEBTOR'S DETAILS */}
              <Box mb={2} mt={2}>
                <Divider textAlign="left">3rd DEBTOR'S DETAILS :-</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* organization name */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="Organization Name"
                    name="organization3Name"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (
                          values?.owner3FirstName ||
                          values?.owner3LastName ||
                          values?.owner3MiddleInitial ||
                          values?.owner3Suffix
                        ) {
                          setFieldValue("owner3FirstName", "");
                          setFieldValue("owner3LastName", "");
                          setFieldValue("owner3MiddleInitial", "");
                          setFieldValue("owner3Suffix", "");
                        }
                        setFieldValue("organization3Name", event.target.value);
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box mb={2}>
                <Divider textAlign="center">OR</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* personal name */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="First Personal Name"
                    name="owner3FirstName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization3Name)
                          setFieldValue("organization3Name", "");
                        setFieldValue("owner3FirstName", event.target.value);
                      },
                    }}
                  />
                </Grid>
                {/* initials */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="Additional Name(s)/Initial(s)"
                    name="owner3MiddleInitial"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization3Name)
                          setFieldValue("organization3Name", "");
                        setFieldValue(
                          "owner3MiddleInitial",
                          event.target.value
                        );
                      },
                    }}
                  />
                </Grid>
                {/* individual surname */}
                <Grid item xs={12} md={3.5} px={1} pb={1}>
                  <CustomTextField
                    label="Individual Surname"
                    name="owner3LastName"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization3Name)
                          setFieldValue("organization3Name", "");
                        setFieldValue("owner3LastName", event.target.value);
                      },
                    }}
                  />
                </Grid>
                {/* suffix */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Suffix"
                    name="owner3Suffix"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      onChange: (event) => {
                        if (values?.organization3Name)
                          setFieldValue("organization3Name", "");
                        setFieldValue("owner3Suffix", event.target.value);
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box mb={2} mt={2}>
                <Divider textAlign="left">
                  3rd DEBTOR'S MAILING ADDRESS :-
                </Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Mailing Address */}
                <Grid item xs={12} md={4} px={1} pb={1}>
                  <CustomTextField
                    label="Mailing Address"
                    name="owner3MailingAddress"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Mailing city */}
                <Grid item xs={12} md={3} px={1} pb={1}>
                  <CustomTextField
                    label="City"
                    name="owner3MailCity"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* state */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="State"
                    name="owner3MailState"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* postal code */}
                <Grid item xs={12} md={1.5} px={1} pb={1}>
                  <CustomTextField
                    label="Postal Code"
                    name="owner3MailZipcode"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* Country */}
                <Grid item xs={12} md={2} px={1} pb={1}>
                  <CustomTextField
                    label="Country"
                    name="owner3MailCountry"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
              </Grid>

              <Box mb={2} mt={2}>
                <Divider textAlign="left">ADDITIONAL COLLATERAL :-</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Collateral */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="ADDITIONAL SPACE FOR ITEM 4 (Collateral)"
                    name="additionalCollateral"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      multiline: true,
                      rows: 4,
                    }}
                  />
                </Grid>
              </Grid>

              <Box mb={2} mt={2}>
                <Divider textAlign="left">DESCRIPTION OF REAL ESTATE :-</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Collateral */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="Description of Real Estate"
                    name="legalDescription"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      multiline: true,
                      rows: 4,
                    }}
                  />
                </Grid>
              </Grid>

              <Box mb={2} mt={2}>
                <Divider textAlign="left">LEGAL VESTING :-</Divider>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              >
                {/* Legal Vesting */}
                <Grid item xs={12} md={12} px={1} pb={1}>
                  <CustomTextField
                    label="Legal Vesting"
                    name="legalVesting"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      multiline: true,
                      rows: 3,
                    }}
                  />
                </Grid>
              </Grid>

              {/* <Box mb={2} mt={2}>
                <Divider textAlign="left">SERVICE AGREEMENT :-</Divider>
              </Box> */}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
                mt={2}
              >
                {/* Service Agreement */}
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="Service Agreement"
                    name="serviceAgreement"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
                {/* </Grid> */}
                {/* 
              <Box mb={2} mt={2}>
                <Divider textAlign="left">IACA :-</Divider>
              </Box> */}
                {/* <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="bg-white"
              > */}
                {/* Service Agreement */}
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="IACA"
                    name="IACA"
                    validation={{ values, setFieldValue, touched, errors }}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Stack direction="row" spacing={2} pb={2}>
                  <>
                    <Button
                      variant="link"
                      onClick={() => {
                        resetForm();
                        // handleClose();
                      }}
                      className="text-danger"
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      // disabled={
                      //   JSON.stringify(initialValues) ===
                      //   JSON.stringify(values)
                      // }
                      onClick={() => {
                        scrollIfError();
                      }}
                      variant="link"
                      className="text-success"
                    >
                      Request
                    </Button>
                  </>
                </Stack>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UccForm;
