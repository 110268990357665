import React, { useEffect, useRef, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import { Loader } from "../common/loader";
import pana from "../../assets/properties/pana.png";
import DonutChart from "../common/charts/DonutChart";
import pana2 from "../../assets/properties/pana2.png";
import { Callto } from "../common/ContcatAdminSupport";
import vector from "../../assets/properties/Vector.png";
import vector1 from "../../assets/properties/Vector1.png";
import vector2 from "../../assets/properties/Vector2.png";
import vector3 from "../../assets/properties/Vector3.png";
import vector4 from "../../assets/properties/Vector4.png";
import equity_theft from "../../assets/properties/equity_theft.webp";
import PropertyRiskManagementForm from "../PropertyRiskManagement/PropertyRiskManagementForm";
import {
  decryptPayload,
  encryptPayload,
  getFormatedPhoneNumber,
  getObjLength,
} from "../helpers/utils";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

import "./CountyRecorder.scss";

const CountyRecorder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { county_name } = useParams();

  const riskFormRef = useRef(null);
  if (county_name) {
    localStorage.setItem("county-code", encryptPayload(county_name));
    console.log("code==>", county_name);
  }

  const [getFreeReport, setGetFreeReport] = useState(false);

  // store data
  const { countyData, countyLoader } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  const getCountyFreeReport = () => {
    setGetFreeReport(true);
  };

  //Remove the query string from the URL
  const removeQueryString = () => {
    // Get the current URL
    const currentUrl = window.location.href;
    // Check if the URL contains the query parameter 'abcd'
    if (currentUrl.includes(county_name)) {
      // Remove the query parameter
      const newUrl = currentUrl.replace(`?${county_name}`, "");
      localStorage.setItem("county-code", county_name);
      // Use react-router-dom to navigate to the new URL
      navigate(newUrl.replace(window.location.origin, ""));
    }
  };

  // Function to handle scrolling to the form
  const scrollToForm = () => {
    getCountyFreeReport();
    // Scroll to the top of the form element
    riskFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    let countyCode = decryptPayload(localStorage.getItem("county-code"));
    dispatch(
      propertyRiskManagementAction.countyData({
        url: `county/details`,
        data: { county: county_name || countyCode },
      })
    );
    //Set County-Recorder Page to HomePage(scrp_hp)
    localStorage.setItem("scrp_hp", 1);
  }, []);

  useEffect(() => {
    if (getObjLength(countyData)) {
      if (!countyData?.success) {
        dispatch(propertyRiskManagementAction.fetchCountyData({}));
        navigate("/404");
      }
    }
  }, [countyData]);

  const profile_image = countyData?.data?.profile_image;

  return (
    <>
      <div className="county-recorder-content bg-white">
        <div className="first_section_div">
          <section
            className="d-flex align-items-center cr_bg_img  first-section"
            // style={{
            //   minHeight: "80vh",
            //   backgroundImage: `url('${propertyRisk}')`,
            //   position: "relative",
            // }}
          >
            <Container fluid className="text-white main_container">
              <Row className=" justify-content-center text-center">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={10}
                  xl={8}
                  className=" header_texts"
                >
                  <>
                    {/* <p
                      className="notify_header mb-2"
                      style={{ color: "#0395FF" }}
                    >
                      Recorder's Office
                    </p> */}
                    <h1 className="fw-700 mb-3 main-text">
                      Welcome to the {countyData?.data?.county_name} County
                      Recorder’s Property Fraud Prevention Page Brought to you
                      by CSI and Equity Protect
                    </h1>
                    {/* <p className="paragraph_text fw-300 mb-3 mt-2 text-white">
                      Lorem ipsum dolor sit amet consectetur. Ornare egestas
                      auctor porta vestibulum tortor eu enim. Tortor rhoncus est
                      mattis aliquam sed vulputate feugiat in.
                    </p> */}
                  </>
                </Col>
              </Row>
            </Container>
          </section>
          <Row className="d-flex justify-content-between align-items-start text-left d-sm-pt-0 pt-2 county_block1">
            {!countyLoader ? (
              getObjLength(countyData) && countyData?.success ? (
                <>
                  {profile_image && (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      className=" d-flex justify-content-center  second-column p-0 "
                      id="lead-page-form"
                    >
                      <Box className="county_person_img">
                        <img
                          // src={county_person}
                          src={profile_image}
                          // className="h-100 w-100 "
                          alt="home_Img"
                        />
                      </Box>
                    </Col>
                  )}
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={profile_image ? 8 : 12}
                    xl={profile_image ? 8 : 12}
                    className={`d-flex justify-content-center second-column p-0 ${
                      !profile_image && "text-center"
                    }`}
                    id="lead-page-form"
                  >
                    <Box
                      className={`county_content ${
                        !profile_image && "content_padding"
                      }`}
                    >
                      {countyLoader ? (
                        <Loader open={countyLoader} />
                      ) : (
                        <>
                          {countyData?.data?.logo_url && (
                            <img
                              src={countyData?.data?.logo_url}
                              className="m-2 county_logo"
                              alt="county_logo"
                            />
                          )}
                          <Typography className="county_captions">
                            {countyData?.data?.county_name} County Recorder
                          </Typography>
                          <Typography className="county_title">
                            {countyData?.data?.first_name}{" "}
                            {countyData?.data?.last_name}
                          </Typography>
                          <Typography className="county_captions">
                            {countyData?.data?.address} <br />
                            {countyData?.data?.city}, {countyData?.data?.state}{" "}
                            {countyData?.data?.zip} <br />
                            <Callto
                              phone={`${countyData?.data?.phone}`}
                              className="text-black"
                            >
                              {getFormatedPhoneNumber(countyData?.data?.phone)}
                            </Callto>
                            <br />
                            <br />
                            Email: 
                            <a
                              href={`mailto:${countyData?.data?.email}`}
                              className="county_email"
                            >
                              {countyData?.data?.email}
                            </a>
                          </Typography>
                          <br />
                          <Typography
                            className={`county_captions ${
                              !profile_image ? "text-center" : "text-start"
                            }`}
                            sx={{ whiteSpace: "pre-wrap" }}
                            // dangerouslySetInnerHTML={{
                            //   __html: countyData?.data?.description,
                            // }}
                          >
                            {countyData?.data?.description}
                          </Typography>
                          <Typography className="county_captions ">
                            <br />
                            <br />
                            {countyData?.data?.first_name}{" "}
                            {countyData?.data?.last_name} <br />
                            {countyData?.data?.county_name} County Recorder
                          </Typography>
                          {countyData?.data?.stamp_url && (
                            <img
                              src={countyData?.data?.stamp_url}
                              className="py-4 county_seal"
                              alt="county_seal"
                            />
                          )}
                        </>
                      )}
                    </Box>
                  </Col>
                </>
              ) : (
                <p className="text-center text-danger">
                  County details not found. Please try again later.
                </p>
              )
            ) : null}
          </Row>
        </div>
        {getObjLength(countyData) &&
        countyData?.success &&
        countyData?.data?.is_statistics ? (
          <section className="pb-5 alert_section d-flex justify-content-between align-items-center  text-center bg-white">
            <Container fluid className="equity_theft1 ">
              <div>
                <h3 className="equity_theft_title">
                  {countyData?.data?.county_name} County Risk Numbers
                </h3>
                <Typography className="my-4 equity_theft_caption">
                  Here are the stats for your county.
                </Typography>
              </div>
              <Box className="d-flex justify-content-center align-items-center px-3">
                {countyLoader ? (
                  <Loader open={countyLoader} />
                ) : (
                  <DonutChart chart_data={countyData?.data} />
                )}
              </Box>
              {/* <Row className="text-start mt-2 ">
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <LazyLoadImage
                    src={moniter}
                    className="p-1 pt-3"
                    width="78px"
                    height="78px"
                    alt="article"
                  />
                  <h5 className="info_blocks_title">Multiple data points</h5>
                  <Typography className="mt-4 info_blocks_subtitle">
                    Et non eget cursus pharetra eget viverra a. At magna massa
                    imperdiet nunc libero. Dictumst aliquam nisl odio cras
                    scelerisque. Rhoncus felis varius euismod egestas viverra
                    amet integer.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <LazyLoadImage
                    src={house_icon}
                    className="p-1"
                    width="78px"
                    height="78px"
                    alt="article"
                  />
                  <h5 className="info_blocks_title">Sale listings</h5>
                  <Typography className="mt-4 info_blocks_subtitle">
                    Et non eget cursus pharetra eget viverra a. At magna massa
                    imperdiet nunc libero. Dictumst aliquam nisl odio cras
                    scelerisque. Rhoncus felis varius euismod egestas viverra
                    amet integer.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <LazyLoadImage
                    src={article_icon}
                    className="p-1"
                    width="78px"
                    height="78px"
                    alt="article"
                  />
                  <h5 className="info_blocks_title">Title changes</h5>
                  <Typography className="mt-4 info_blocks_subtitle">
                    Et non eget cursus pharetra eget viverra a. At magna massa
                    imperdiet nunc libero. Dictumst aliquam nisl odio cras
                    scelerisque. Rhoncus felis varius euismod egestas viverra
                    amet integer.
                  </Typography>
                </Box>
              </Col>
            </Row> */}
            </Container>
          </section>
        ) : null}

        <section
          className=""
          style={{
            position: "relative",
            background: "#F6F9FC",
            paddingTop: "85px",
            boxShadow: "0px 1px 0px 0px #DCE4EC,0px -1px 0px 0px #DCE4EC",
          }}
        >
          <Container fluid className="container_padding how_its_works pb-5">
            <div ref={riskFormRef}>
              <h3 className="how_its_works_title">
                Are you at risk? Find out now!
              </h3>
              <Typography className="my-4 how_its_works_caption">
                It’s easy, quick, and free to get your risk assessment report!
                Click the button below, fill in some basic information, and find
                out if you’re at risk.
              </Typography>
            </div>
            <>
              {getFreeReport ? (
                <Box className=" property_form">
                  <Box className="d-flex justify-content-center align-items-center second-column p-0 property-risk-page-content">
                    <PropertyRiskManagementForm
                      is_PRM={false}
                      setGetFreeReport={setGetFreeReport}
                      getFreeReport={getFreeReport}
                      county_id={countyData?.data?.id}
                      widthClass="cnt-prm-form"
                    />
                  </Box>
                  <Divider
                    className="mb-4"
                    style={{ border: " 1px solid #a3a5a6" }}
                  />
                </Box>
              ) : (
                <Button
                  onClick={getCountyFreeReport}
                  className={`button1 success_popup_btn my-4 
                          w-50
                          `}
                  variant="contained"
                >
                  Get Your Free Report
                </Button>
              )}
            </>
            <Row className="steps_wrapper text-start">
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <span className="steps mb-4">1</span>
                  <h5 className="county_captions fw-600">Fill in the form</h5>
                  <Typography className="mt-4 block1 county_captions">
                    This tells us where to look.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <span className="steps mb-4">2</span>
                  <h5 className="county_captions fw-600">Property Search</h5>
                  <Typography className="mt-4 block2 county_captions">
                    Our system is going to locate your property and pull the
                    most recent title data.
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
                <Box className="info_blocks">
                  <span className="steps mb-4">3</span>
                  <h5 className="county_captions fw-600">
                    We'll send you your free report.
                  </h5>
                  <Typography className="mt-4 block3 county_captions">
                    This report will display the current vesting information on
                    our title report.
                  </Typography>
                </Box>
              </Col>
            </Row>

            <Row className="theft_section d-flex justify-content-center align-items-start d-sm-pt-0 pt-2">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center second-column p-0 "
                id="lead-page-form"
              >
                <Box className="county_person_img">
                  <img
                    src={equity_theft}
                    className="h-100 w-100"
                    alt="home_Img"
                  />
                </Box>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center  second-column p-0 "
                id="lead-page-form"
              >
                <Box className="theft_content">
                  <>
                    <Typography className="county_title">
                      Free Monitoring and Copies of Recorded Documents
                    </Typography>
                    <Typography className="theft_captions">
                      {countyData?.data?.county_name} County property owners may
                      access a free service offered by{" "}
                      <a
                        href={`https://watch.doxpop.com/property/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Doxpop
                      </a>{" "}
                      to be notified when a document has been recorded in the{" "}
                      {countyData?.data?.county_name} County Recorder’s Office
                      with specific criteria such as legal description and/or
                      name. Property owners may also sign up for free monitoring
                      offered by Equity Protect.
                    </Typography>
                    <Typography className="theft_captions">
                      Please contact the Recorder’s Office at{" "}
                      <Callto
                        phone={`${countyData?.data?.phone}`}
                        className="text-black"
                      >
                        {getFormatedPhoneNumber(countyData?.data?.phone)}
                      </Callto>{" "}
                      should you receive mail stating “Deed Processing Notice”.{" "}
                      {countyData?.data?.county_name} County is NOT sending out
                      this processing notice. A copy of your deed and any other
                      recorded documents are available in our office for $1 per
                      page. Deeds are usually one or two pages, so it is
                      significantly less to get copies directly from our office.
                    </Typography>
                    <a
                      href={`https://watch.doxpop.com/property/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="submit"
                        className={`button1 success_popup_btn my-4`}
                        variant="contained"
                        // onClick={scrollToForm}
                      >
                        <Stack
                          direction={"column"}
                          display={"flex"}
                          alignContent={"center"}
                          justifyContent={"center"}
                        >
                          <span>Doxpop Property Watch</span>

                          <span className="text-capitalize mt-1 d-flex align-items-center">
                            Protect your property online
                          </span>
                        </Stack>
                      </Button>
                    </a>
                  </>
                </Box>
              </Col>
            </Row>
          </Container>
          <Container fluid className="px-3 px-md-5 card_bottom">
            <Row>
              <Col>
                <div className="card text-center linear_bg">
                  <div className="card-body p-0">
                    <img src={vector4} className="vector4" alt="Vector" />
                    <img src={vector3} className="vector3" alt="Vector" />
                    <img src={vector2} className="vector2" alt="Vector" />
                    <img src={vector1} className="vector1" alt="Vector" />
                    <img src={vector} className="vector" alt="Vector" />
                    <img src={pana} className="pana" alt="pana" />
                    <img src={pana2} className="pana2" alt="pana" />
                    <p className="privateGuardList card-title text-white text-center">
                      Learn How EquityProtect™ Can Help Protect You
                    </p>
                  </div>
                  <div className="mt-2">
                    <Link
                      className=" text-white text-decoration-none"
                      to={`/${county_name}/create-account`}
                    >
                      <Button className="button2">
                        Prevention is a click away
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section
          className="pb-5"
          style={{ boxShadow: "0px px 0px 0px #DCE4EC" }}
        >
          <Container fluid className="container_padding stay_loop_section pb-4">
            <Row className="d-flex justify-content-center align-items-center bg-white d-sm-pt-0 pt-2 m-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center  second-column p-0 "
                id="lead-page-form"
              >
                <Box className="stay_loop_img">
                  <img
                    src={county_pana}
                    className="h-100 w-100"
                    alt="home_Img"
                  />
                </Box>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center  second-column p-0 "
                id="lead-page-form"
              >
                <Box className="stay_loop_content">
                  <>
                    <Typography className="county_title py-2">
                      Stay in the loop
                    </Typography>
                    <Typography className="county_captions pt-2 pb-3">
                      Get the latest news and updates, straight to your inbox.
                      We’ll never spam you, you’ll only hear from us once a
                      month with the most important updates.
                    </Typography>
                    <Box className="pt-4 stay_in_form">
                      <StayInForm />
                    </Box>
                  </>
                </Box>
              </Col>
            </Row>
          </Container>
        </section> */}

        {/* <section
          style={{
            position: "relative",
            background: "#F6F9FC",
            boxShadow: "0px -1px 0px 0px #DCE4EC",
          }}
          className="county_search_section"
        >
          <Container fluid className="container_padding county_search">
            <Row className="d-flex justify-content-center align-items-center d-sm-pt-0 pt-2 m-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center  second-column p-0 "
                id="lead-page-form"
              >
                <Box className="county_search_content">
                  <>
                    <Typography className="county_title py-2">
                      Lorem ipsum dolor sit amet consectetur. Tellus eget nam.
                    </Typography>
                    <Typography className="county_search_captions pt-2 pb-3">
                      Lorem ipsum dolor sit amet consectetur. Adipiscing urna
                      euismod porttitor mattis. Mauris adipiscing sociis sapien
                      dolor lacus nunc mi metus. Eros tempus amet etiam
                      tincidunt. Neque adipiscing feugiat elementum.
                    </Typography>
                  </>
                </Box>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center  second-column p-0 "
                id="lead-page-form"
              >
                <Box className="county_search_img">
                  <img
                    src={county_search}
                    className="h-100 w-100"
                    alt="home_Img"
                  />
                </Box>
              </Col>
            </Row>
          </Container>

          <Container fluid className="px-3 px-md-5 card_bottom">
            <Row>
              <Col>
                <div className="card text-center linear_bg">
                  <div className="card-body p-0">
                    <img src={vector4} className="vector4" alt="Vector" />
                    <img src={vector3} className="vector3" alt="Vector" />
                    <img src={vector2} className="vector2" alt="Vector" />
                    <img src={vector1} className="vector1" alt="Vector" />
                    <img src={vector} className="vector" alt="Vector" />
                    <img src={pana} className="pana" alt="pana" />
                    <img src={pana2} className="pana2" alt="pana" />
                    <p className="privateGuardList card-title text-white text-center">
                      Learn How EquityProtect™ Can Help Protect You
                    </p>
                  </div>
                  <div className="mt-2">
                    <Link
                      className=" text-white text-decoration-none"
                      to="/create-account"
                    >
                      <Button className="button2">
                        Prevention is a click away
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
      </div>
      <Loader open={countyLoader} />
    </>
  );
};

export default CountyRecorder;
