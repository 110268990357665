import { createSlice } from "@reduxjs/toolkit";
import {
  decryptPayload,
  getDatafromArray,
  reactPixelEventOnlyForCustomer,
} from "../../../components/helpers/utils";

const intialSubscriptionsState = {
  subscriptionData: [],
  subscriptionLoader: false,
  subscriptionRenewed: {},
  totalSubscriptionData: 0,
  threedSecurePayment: {},
  bulkPlanRenewalData: {},
  retryPaymentData: {},
  threedSecurePaymentLoader: false,
  totalAmountData: {},
  totalAmountLoader: false,
  failedPaymentData: {},
  failedPaymentLoader: false,
  metaPixelPropertyData: null,
};

const subscription = createSlice({
  name: "subscriptionDetails",
  initialState: intialSubscriptionsState,
  reducers: {
    getSubscriptionData(state, action) {
      state.subscriptionLoader = true;
      console.log("get subscription slice==>", action.payload);
    },
    fethSubscriptionDataDoc(state, action) {
      console.log("get subscription slice==>", action.payload);
      state.subscriptionData = action.payload;
      state.totalSubscriptionData = action.payload?.count;
      state.subscriptionLoader = false;
    },
    getSubscriptionRenewed(state, action) {
      state.subscriptionLoader = true;
      console.log("get subscription slice==>", action.payload);
    },
    fethSubscriptionRenewedData(state, action) {
      let subscriptionData = state?.metaPixelPropertyData;
      state.subscriptionRenewed = action.payload;
      state.subscriptionLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        properties: subscriptionData,
      };
      if (action.payload?.success && !action.payload?.data?.actionRequired) {
        if (action.payload?.success) {
          reactPixelEventOnlyForCustomer("Renew Subscription successful", data);
        } else {
          reactPixelEventOnlyForCustomer("Renew Subscription failed", data);
        }
      }
    },
    clearData(state, action) {
      state.subscriptionRenewed = {};
      state.bulkPlanRenewalData = {};
      state.retryPaymentData = {};
      // state.subscriptionLoader = false;
    },
    getTotalAmountData(state, action) {
      state.totalAmountData = {};
      state.totalAmountLoader = true;
    },
    fetchTotalAmountData(state, action) {
      console.log("totalAmountData slice==>", action.payload);
      state.totalAmountData = action.payload;
      state.totalAmountLoader = false;
    },
    clearTotalAmountData(state, action) {
      state.totalAmountData = {};
      state.totalAmountLoader = false;
    },
    get3dSecurePaymentData(state, action) {
      console.log("get subscription slice==>", action.payload);
      state.threedSecurePayment = {};
      state.threedSecurePaymentLoader = true;
    },
    fetch3dSecurePaymentData(state, action) {
      state.threedSecurePayment = action.payload;
      state.threedSecurePaymentLoader = false;
    },
    clear3dSecurePaymentData(state, action) {
      state.threedSecurePayment = {};
      state.threedSecurePaymentLoader = false;
    },
    getBulkPlanRenewalData(state, action) {
      state.bulkPlanRenewalData = {};
      state.threedSecurePaymentLoader = true;
    },
    fetchBulkPlanRenewalData(state, action) {
      state.bulkPlanRenewalData = action.payload;
      let subscriptionData = state?.metaPixelPropertyData;
      console.log("bulkPlanRenewalData==>", action.payload);
      state.threedSecurePaymentLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        properties: subscriptionData,
      };
      if (action.payload?.success && !action.payload?.data?.actionRequired) {
        reactPixelEventOnlyForCustomer("Bulk Subscription Payment", data);
      }
    },
    getRetryPaymentData(state, action) {
      state.retryPaymentData = {};
      state.threedSecurePaymentLoader = true;
    },
    fetchRetryPaymentData(state, action) {
      let subscriptionData = state?.metaPixelPropertyData;
      state.retryPaymentData = action.payload;
      console.log("retryPaymentData==>", action.payload);
      state.threedSecurePaymentLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        properties: subscriptionData,
      };
      if (!action.payload?.data?.actionRequired) {
        if (action.payload?.success) {
          reactPixelEventOnlyForCustomer("Retry Subscription successful", data);
        } else {
          reactPixelEventOnlyForCustomer("Retry Subscription failed", data);
        }
      }
    },
    getFailedPaymentData(state, action) {
      console.log("Failed paymnet payload", action.payload);
      state.failedPaymentData = {};
      state.failedPaymentLoader = true;
    },
    fetchFailedPaymentData(state, action) {
      state.failedPaymentData = action.payload;
      state.failedPaymentLoader = false;
    },
    clearFailedPaymentData(state, action) {
      state.failedPaymentData = action.payload;
      state.failedPaymentLoader = false;
    },
    getPropertiesDataForMetaPixel(state, action) {
      let subscriptionData = state?.subscriptionData?.data;
      console.log(action?.payload, "Proeprties data ==>", subscriptionData);
      let fetchPropertiesData = getDatafromArray(
        action?.payload,
        subscriptionData
      );
      console.log("Fetching properties data==>", fetchPropertiesData);
      state.metaPixelPropertyData = fetchPropertiesData;
    },
    // from property module for meta pixel
    getPropertiesData(state, action) {
      let subscriptionData = action.payload?.propertiesData;
      console.log(action?.payload, "Proeprties data ==>");
      let fetchPropertiesData = getDatafromArray(
        action?.payload?.subId,
        subscriptionData
      );
      console.log("Fetching properties data==>", fetchPropertiesData);
      state.metaPixelPropertyData = fetchPropertiesData;
    },
  },
});
export const subscriptionAction = subscription.actions;
export default subscription.reducer;
