import { useEffect } from "react";
import React, { useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactGoogleAutocomplete from "react-google-autocomplete";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { GOOGLE_API_KEY } from "../../helpers/constants";
import PersonalTokenScreen from "../../user-signup/personalTokenScreen";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import PropertySearchType from "../../userProfile/add-property/PropertySearchType";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import {
  getAddressFromGoogleAutoComplete,
  getObjLength,
} from "../../helpers/utils";
import SecurityInfoAndCardPayment from "../../userProfile/add-property/SecurityInfoAndCardPayment";

const FieldStyle = {
  borderRadius: "3px",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const queryString = window.location.search;
const paramString = queryString.split("?")[1];
const referral_code = paramString?.split("=")[1];
const valuOf = paramString?.split("=")[0];

const validationSchema = Yup.object().shape({
  first_name: Yup.string("Please enter User's First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string("Please enter User's Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
  address: Yup.string("Please enter User's Address.")
    .trim("The Address cannot include leading and trailing spaces")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),

  city: Yup.string("Please enter User's City.")
    .trim("The city cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),

  email: Yup.string("Please enter User's Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  zip: Yup.string("Please enter User's Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // .min(6, "Zip needs 6 digits")
  // .max(6, "Zip needs 6 digits"),
  phone1: Yup.string("Please enter User's Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Phone number is required"),
  referral_code: Yup.string(""),
  promo_code: Yup.string(""),
});

const AddNewUserPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const [formData, setFormData] = useState(null);
  const [isOtpConfirmationModalOpen, setIsOtpConfirmationModalOpen] =
    useState(false);
  const [searchedAddress, setSearchedAddress] = useState("");
  const [isOTPByPass, setIsOTPByPass] = useState(false);
  const [screen, setScreen] = useState(null);
  const [isSubscriptionScreenRequired, setIsSubscriptionScreenRequired] =
    useState(false);

  // store data
  const {
    createAccResponse,
    signupLoader,
    stateList: stateData,
  } = useSelector((state) => state?.signUpUser);

  const addresss = (place) => {
    var address = place.formatted_address;
    console.log("Place values==?>", place);
    console.log(
      "Place values filterd==>",
      place.address_components.filter((i, k) =>
        i.types.includes("street_number")
      )
    );
    var value = address?.split(",");
    const count = value.length;
    const country = value[count - 1];
    const stateVal = value[count - 2];
    const city = value[count - 3];
    const streetAdd = value[count - 4] !== undefined ? value[count - 4] : "";
    var stateSplit = stateVal?.split(" ");
    var i = stateSplit?.length;
    var pincode = "";
    const state = stateSplit[1];
    if (i > 2) {
      pincode = stateSplit[2];
    }
    var ss = streetAdd?.split(" ");
    console.log(ss.length, "ss==>.", ss);
    const sn = place.address_components.filter((i, k) =>
      i.types.includes("street_number")
    );
    var streetNum = sn.length > 0 ? sn[0]?.long_name : "";
    const sr = place.address_components.filter((i, k) =>
      i.types.includes("route")
    );
    var site_route = sr.length > 0 ? sr[0]?.long_name : "";
    const condos = place.address_components.filter((i, k) =>
      i.types.includes("subpremise")
    );
    var units = condos.length > 0 ? condos[0]?.long_name : "";

    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();
    let param = {
      street_number: streetNum,
      site_route: site_route,
      city: city,
      state: state,
      zip: pincode,
      unit_no: units,
    };
    let fixParams = {
      country: country,
      latitude: latitude,
      longitude: longitude,
      address: streetAdd,
    };
    setSearchedAddress({
      param: param,
      fixed_params: fixParams,
      full_address: address,
    });
    console.log(fixParams, "map lag==>>", param);
    localStorage.setItem("param", JSON.stringify(param));
    localStorage.setItem("fixParams", JSON.stringify(fixParams));
    localStorage.setItem("searched_Add", address);
    localStorage.setItem("searched_Add_popup", true);
  };

  const clearLocalStorageData = () => {
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
  };

  const goBack = () => {
    clearLocalStorageData();
    // getPropertyUserListingData();
    setScreen(null);
    dispatch(userSigupAction.userAddedByAdmin(false));
    dispatch(userSigupAction.clearData());
    dispatch(propertiesAction.resetState());
    navigate("/admin-dashboard");
  };

  // submit button handler
  const handleSubmit = (values, validation) => {
    if (!isValidPhoneNumber(values?.phone1)) {
      return;
    }
    if (!stateData?.find((val) => val.name === values?.state)) {
      // values.state = "";
      return;
    }
    if (values?.promo_code) {
      localStorage.setItem("promoCode", values.promo_code);
    }

    if (values?.referral_code) {
      localStorage.setItem("referralCode", values.referral_code);
    }

    let data = values;
    if (values.referral_code?.length === 0) {
      // data = { ...values, referral_code };
      delete values.referral_code;
    }
    if (values.promo_code?.length === 0) {
      localStorage.removeItem("promoCode");
      delete values.promo_code;
    }
    console.log("final data==>>", data);
    data = {
      ...values,
      step: 0,
      step_key: "SEND_OTP",
      searched_address: searchedAddress,
    };
    setFormData(data);
    // open confirmation modal for asking whether user bypass otp or not
    setIsOtpConfirmationModalOpen(true);
    // dispatch(
    //   propertyUsersAction.createUser({
    //     url: "send-otp",
    //     data: data,
    //   })
    // );
    // dispatch(userSigupAction.userSignupData({ url: "send-otp", data: data }));
  };

  // handle otp confirmation modal
  const handleCreateUser = (isOTPByPass) => {
    let payload = {};
    setIsOTPByPass(isOTPByPass);
    setIsOtpConfirmationModalOpen(false);
    // dispatch(
    //   propertyUsersAction.createUser({
    //     url: "send-otp",
    //     data: { ...formData, otp_bypass: isOTPByPass },
    //   })
    // );
    // if (isBypassOptionAvailable) {
    payload = {
      url: "admin/send-otp",
      data: { ...formData, skip_verification: isOTPByPass },
      token: true,
    };
    // } else {
    //   payload = {
    //     url: "send-otp",
    //     data: { ...formData },
    //   };
    // }
    console.log("send OTP::");
    dispatch(userSigupAction.userSignupData(payload));
  };

  // handle close property modal
  const handleCloseAddPropertyModal = () => {
    setScreen(null);
    goBack();
  };

  // useEffect to open find property screen after otp bypass
  useEffect(() => {
    if (
      getObjLength(createAccResponse) &&
      createAccResponse?.success &&
      isOTPByPass
    ) {
      dispatch(propertiesAction.clearPropertiesList());
      setScreen("security");
    }
  }, [createAccResponse, dispatch, isOTPByPass]);

  // useEffect to get state list
  useEffect(() => {
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
    localStorage.removeItem("referralCode");
    localStorage.removeItem("promoCode");
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
  }, [dispatch]);

  // // useEffect to go back after otp bypass
  // useEffect(() => {
  //   if (
  //     isOTPByPass &&
  //     getObjLength(createAccResponse) &&
  //     createAccResponse?.success
  //   ) {
  //     goBack();
  //     dispatch(
  //       propertyUsersAction.getPropertyUsersData({
  //         url: "admin/users-v1",
  //         token: true,
  //       })
  //     );
  //   }
  // }, [createAccResponse, dispatch, goBack, isOTPByPass]);
  return (
    <>
      {!screen && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mb={3}
          >
            <Typography variant="h6" className="card-title fw-600">
              Add a new user
            </Typography>
            <Tooltip
              className="d-none d-sm-block"
              title="Go to Property User list"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Button
                variant="contained"
                className="success-btn d-none d-sm-block"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to go back to Property User list?"
                    )
                  ) {
                    goBack();
                  }
                }}
              >
                Go Back
              </Button>
            </Tooltip>
            <Tooltip
              className="d-flex d-sm-none"
              title="Go to Property User list"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <IconButton
                className="success-btn d-flex d-sm-none"
                variant="outlined"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to go back to Property User list?"
                    )
                  ) {
                    goBack();
                  }
                }}
                sx={{ color: "#FFF" }}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {(!getObjLength(createAccResponse) ||
            (getObjLength(createAccResponse) &&
              !createAccResponse?.success)) && (
            <>
              {/* signup box */}
              <Box>
                <CssBaseline />
                <Box
                  sx={{
                    bgcolor: "white",
                    paddingTop: "14px",
                    width: window.innerWidth < 768 ? "100%" : "80%",
                    boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
                  }}
                >
                  <Box className="accountBoxWrapper">
                    {/* signup form */}
                    <Box>
                      <Formik
                        enableReinitialize
                        validateOnChange
                        initialValues={{
                          first_name: "",
                          last_name: "",
                          address: "",
                          city: "",
                          state: "",
                          zip: "",
                          email: "",
                          phone: "",
                          phone1: "",
                          // password: "",
                          // confirm_password: "",
                          referral_code: valuOf === "ref" ? referral_code : "",
                          promo_code: valuOf === "code" ? referral_code : "",
                        }}
                        validationSchema={validationSchema}
                        // onSubmit={onSubmit}
                        onSubmit={(values, validation) => {
                          if (
                            localStorage.getItem("searched_Add_popup") !== true
                          ) {
                            localStorage.setItem(
                              "param",
                              JSON.stringify({
                                street_number: values?.address
                                  ? values?.address?.split(" ")?.[0]
                                  : "",
                                site_route: values?.address
                                  ? values?.address
                                      ?.split(" ")
                                      ?.slice(1)
                                      ?.join(" ")
                                  : "",
                                city: values?.city || "",
                                state: values?.state || "",
                                unit_no: values?.unit_no || "",
                                zip: values?.zip || "",
                              })
                            );
                            if (!localStorage.getItem("fixParams")) {
                              localStorage.setItem(
                                "fixParams",
                                JSON.stringify({
                                  country: "USA",
                                  address: values?.address,
                                })
                              );
                            }
                            localStorage.setItem(
                              "searched_Add",
                              [
                                values?.address,
                                values?.city,
                                values?.state,
                                values?.zip,
                                "USA",
                              ]?.join(", ")
                            );
                            localStorage.setItem("searched_Add_popup", true);
                          }
                          handleSubmit(values, validation);
                        }}
                      >
                        {({ values, setFieldValue, touched, errors }) => {
                          // for the manage state validation
                          if (
                            !stateData?.find(
                              (val) => val.name === values?.state
                            )
                          ) {
                            values.state = "";
                          } else {
                            delete errors.state;
                          }

                          // handle selected address for sign up form
                          const handleChange = (address) => {
                            console.log("address changed==>", address);
                            setFieldValue(
                              "address",
                              getAddressFromGoogleAutoComplete(
                                address,
                                "street_number"
                              ) +
                                (getAddressFromGoogleAutoComplete(
                                  address,
                                  "street_number"
                                )
                                  ? " "
                                  : "") +
                                getAddressFromGoogleAutoComplete(
                                  address,
                                  "route"
                                )
                            );
                            setFieldValue(
                              "city",
                              getAddressFromGoogleAutoComplete(
                                address,
                                "locality"
                              )
                            );
                            setFieldValue(
                              "state",
                              getAddressFromGoogleAutoComplete(
                                address,
                                "administrative_area_level_1"
                              )
                            );
                            setFieldValue(
                              "zip",
                              getAddressFromGoogleAutoComplete(
                                address,
                                "postal_code"
                              )
                            );
                            setFieldValue(
                              "unit_no",
                              getAddressFromGoogleAutoComplete(
                                address,
                                "subpremise"
                              )
                            );
                          };
                          return (
                            <Form>
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              >
                                {/* <Grid xs={12} md={6} px={1} pb={4}>
                                  <FormControl
                                      fullWidth
                                      className="sign-textField"
                                      // error={
                                      //   touched.state && Boolean(errors.state)
                                      // }
                                    >
                                      <Select
                                        labelId="demo-simple-select-placeholder"
                                        id="demo-simple-select"
                                        value='Homeowner'
                                        label="Homeowner"
                                        name="homeowner"
                                        variant="outlined"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "homeowner",
                                            event.target.value
                                          );
                                        }}
                                        MenuProps={MenuProps}
                                        displayEmpty
                                      >
                                        <MenuItem value="Homeowner"> Homeowner </MenuItem>
                                      </Select>
                                      {touched.state && Boolean(errors.state) && (
                                        <FormHelperText sx={{ color: "#d32f2f" }}>
                                          {touched.state && errors.state}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                  </Grid>
                                  <Grid xs={12} md={6} px={1} pb={4}>
                                  <FormControl
                                      fullWidth
                                      className="sign-textField"
                                      // error={
                                      //   touched.state && Boolean(errors.state)
                                      // }
                                    >
                                      <Select
                                        labelId="demo-simple-select-placeholder"
                                        id="demo-simple-select"
                                        value='full access'
                                        label="full access"
                                        name="full access"
                                        variant="outlined"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "full access",
                                            event.target.value
                                          );
                                        }}
                                        MenuProps={MenuProps}
                                        displayEmpty
                                      >
                                        <MenuItem value="full access"> full access </MenuItem>
                                      </Select>
                                      {touched.state && Boolean(errors.state) && (
                                        <FormHelperText sx={{ color: "#d32f2f" }}>
                                          {touched.state && errors.state}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                  </Grid> */}
                                {/* <Grid xs={12} md={12} px={1} pb={4}>
                                  <Divider />
                                  </Grid> */}
                                <Grid xs={12} md={12} px={1} pb={4}>
                                  <ReactGoogleAutocomplete
                                    className="googleAutoComplete mw-100"
                                    apiKey={GOOGLE_API_KEY}
                                    onPlaceSelected={(address) => {
                                      handleChange(address);
                                      addresss(address);
                                    }}
                                    onKeyDown={(event) =>
                                      event.key === "Enter"
                                        ? event.preventDefault()
                                        : null
                                    }
                                    placeholder="Enter User's property address"
                                    options={{
                                      types: ["address"],
                                      componentRestrictions: { country: "us" },
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  xs={12}
                                  md={12}
                                  px={1}
                                  pb={4}
                                >
                                  <span className="text-label">
                                    or Enter it manually
                                  </span>
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <TextField
                                    className="sign-textField "
                                    // id="outlined-basic"
                                    label="Address"
                                    name="address"
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder={"Address"}
                                    value={values?.address}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "address",
                                        event.target.value
                                      );
                                    }}
                                    helperText={
                                      touched.address && errors.address
                                    }
                                    error={
                                      touched.address && Boolean(errors.address)
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <TextField
                                    className="sign-textField "
                                    // id="outlined-basic"
                                    label="City"
                                    name="city"
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder={"City"}
                                    value={values?.city}
                                    onChange={(event) => {
                                      setFieldValue("city", event.target.value);
                                    }}
                                    helperText={touched.city && errors.city}
                                    error={touched.city && Boolean(errors.city)}
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={4}>
                                  <FormControl
                                    fullWidth
                                    className="sign-textField"
                                    error={
                                      touched.state && Boolean(errors.state)
                                    }
                                  >
                                    <InputLabel
                                      shrink
                                      // id="demo-simple-select-placeholder"
                                      style={{ color: "#00000075" }}
                                      label="State"
                                    >
                                      State
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-placeholder"
                                      id="demo-simple-select"
                                      value={values?.state}
                                      label="State"
                                      name="state"
                                      variant="outlined"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "state",
                                          event.target.value
                                        );
                                      }}
                                      MenuProps={MenuProps}
                                      displayEmpty
                                    >
                                      <MenuItem disabled value="">
                                        <span style={{ color: "#00000075" }}>
                                          State
                                        </span>
                                      </MenuItem>
                                      {stateData?.map((state) => (
                                        <MenuItem value={state.name}>
                                          {state.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {touched.state && Boolean(errors.state) && (
                                      <FormHelperText sx={{ color: "#d32f2f" }}>
                                        {touched.state && errors.state}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={4}>
                                  <TextField
                                    className="sign-textField "
                                    // length={6}
                                    // id="outlined-basic"
                                    label="Zip"
                                    name="zip"
                                    type="number"
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder={"Zip"}
                                    value={values?.zip}
                                    onChange={(event) => {
                                      setFieldValue("zip", event.target.value);
                                    }}
                                    helperText={touched.zip && errors.zip}
                                    error={touched.zip && Boolean(errors.zip)}
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={4}>
                                  <TextField
                                    className="sign-textField "
                                    label="Unit Number"
                                    name="unit_no"
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder={"Unit Number"}
                                    value={values?.unit_no}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "unit_no",
                                        event.target.value
                                      );
                                    }}
                                    helperText={
                                      touched.unit_no && errors.unit_no
                                    }
                                    error={
                                      touched.unit_no && Boolean(errors.unit_no)
                                    }
                                  />
                                </Grid>

                                <Grid xs={12} md={12} px={1} pb={4}>
                                  <Divider />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <TextField
                                    className="sign-textField "
                                    label="First Name"
                                    name="first_name"
                                    value={values?.first_name}
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder={"First Name"}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "first_name",
                                        event.target.value
                                      );
                                    }}
                                    helperText={
                                      touched.first_name && errors.first_name
                                    }
                                    error={
                                      touched.first_name &&
                                      Boolean(errors.first_name)
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <TextField
                                    className="sign-textField "
                                    label="Last Name"
                                    name="last_name"
                                    value={values?.last_name}
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder={"Last Name"}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "last_name",
                                        event.target.value
                                      );
                                    }}
                                    helperText={
                                      touched.last_name && errors.last_name
                                    }
                                    error={
                                      touched.last_name &&
                                      Boolean(errors.last_name)
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <TextField
                                    className="sign-textField "
                                    // id="outlined-basic"
                                    label="Email Address"
                                    name="email"
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder={"Email Address"}
                                    value={values?.email}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "email",
                                        event.target.value
                                      );
                                    }}
                                    onBlur={(event) =>
                                      setFieldValue("email", event.target.value)
                                    }
                                    helperText={touched.email && errors.email}
                                    error={
                                      touched.email && Boolean(errors.email)
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <CustomPhoneNumberInput
                                    name="phone1"
                                    values={values}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    otherProps={{
                                      onChange: (event) => {
                                        setFieldValue("phone1", event);
                                        setFieldValue("phone", event);
                                        if (!event) {
                                          setFieldValue("phone1", "");
                                          setFieldValue("phone", "");
                                        }
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <TextField
                                    className="sign-textField "
                                    label="Referral Code"
                                    name="referral_code"
                                    value={values?.referral_code}
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder="Optional"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "referral_code",
                                        event.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid xs={12} md={6} px={1} pb={3}>
                                  <TextField
                                    className="sign-textField "
                                    label="Promo Code"
                                    name="promo_code"
                                    value={values?.promo_code}
                                    variant="outlined"
                                    sx={FieldStyle}
                                    InputLabelProps={{
                                      style: {
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#00000075",
                                      },
                                      shrink: true,
                                    }}
                                    placeholder="Optional"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "promo_code",
                                        event.target.value
                                      );
                                    }}
                                  />
                                </Grid>

                                <Grid xs={12} md={12} px={1} pb={1}>
                                  <div className="text-center createAcBtn mt-5">
                                    <Button
                                      onClick={() => {
                                        console.log("errors", errors);
                                        // onClickSubmit(errors);
                                      }}
                                      type="submit"
                                      className="next_button"
                                      variant="contained"
                                    >
                                      Next step
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                      {/* {storeData ? (
            <p className="text-sucess">{accCreated.message}</p>
          ) : (
            <p className="text-danger">{accCreated && accCreated}</p>
          )} */}
                    </Box>
                    {getObjLength(createAccResponse) &&
                      !createAccResponse?.success && (
                        <Typography className="text-danger text-center mt-2">
                          {createAccResponse?.data?.message ||
                            "Something went wrong"}
                        </Typography>
                      )}
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {/* otp confirmation modal */}
          {isOtpConfirmationModalOpen && (
            <>
              <AlertDialog
                type="Info"
                openPopup={isOtpConfirmationModalOpen}
                onClose={true}
                onCloseFun={() => {
                  setIsOtpConfirmationModalOpen(false);
                }}
                mesage={
                  "Do you want this user to bypass the Token confirmation process?"
                }
                footer={false}
                buttons={
                  <>
                    <Button
                      className="popup_Btn other_popup_btn mx-2"
                      onClick={() => handleCreateUser(false)}
                    >
                      No
                    </Button>
                    <Button
                      className="popup_Btn success_popup_btn mx-2"
                      onClick={() => handleCreateUser(true)}
                    >
                      Yes
                    </Button>
                  </>
                }
              />
            </>
          )}

          {getObjLength(createAccResponse) &&
            createAccResponse?.success &&
            !isOTPByPass && (
              <PersonalTokenScreen
                onBtnClick={() => {
                  dispatch(propertiesAction.clearPropertiesList());
                  setScreen("security");
                }}
              />
            )}
        </>
      )}

      {screen === "security" ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // backgroundColor: 'white'
            }}
            mb={3}
          >
            <Typography variant="h6" className="card-title fw-600">
              Find Property
            </Typography>
            <Tooltip
              className="d-none d-sm-block"
              title="Go to Property User list"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Button
                variant="contained"
                className="success-btn d-none d-sm-block"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to go back to Property User list?"
                    )
                  ) {
                    goBack();
                  }
                }}
              >
                Go Back
              </Button>
            </Tooltip>
            <Tooltip
              className="d-flex d-sm-none"
              title="Go to Property User list"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <IconButton
                className="success-btn d-flex d-sm-none"
                variant="outlined"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to go back to Property User list?"
                    )
                  ) {
                    goBack();
                  }
                }}
                sx={{ color: "#FFF" }}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <PropertySearchType
            isShow={false}
            onBtnClick={(e) => {
              // e.preventDefault();
              console.log("submitted");
            }}
            isByPassOptionAvailable={true}
            onChangeModalScreen={(scr = "questions") => setScreen(scr)}
            // setIsSubscriptionScreenRequired={setIsSubscriptionScreenRequired}
            onCloseModalHandler={() => {
              setScreen("propertyList");
              // handleCloseAddPropertyModal();
            }}
            isAdminCreateUser={true}
          />
        </>
      ) : (
        <>
          {screen && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: 'white'
              }}
              mb={3}
            >
              <Typography variant="h6" className="card-title fw-600">
                {screen === "questions"
                  ? "Security Info"
                  : screen === "propertyList"
                  ? "Properties"
                  : screen === "subscriptions"
                  ? "Subscriptions"
                  : screen === "cardsInfo"
                  ? "Card Payment"
                  : null}
              </Typography>
              <Tooltip
                className="d-none d-sm-block"
                title="Go to Property User list"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <Button
                  variant="contained"
                  className="success-btn d-none d-sm-block"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to go back to Property User list?"
                      )
                    ) {
                      goBack();
                    }
                  }}
                >
                  Go Back
                </Button>
              </Tooltip>
              <Tooltip
                className="d-flex d-sm-none"
                title="Go to Property User list"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  className="success-btn d-flex d-sm-none"
                  variant="outlined"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to go back to Property User list?"
                      )
                    ) {
                      goBack();
                    }
                  }}
                  sx={{ color: "#FFF" }}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <SecurityInfoAndCardPayment
            isShow={false}
            screen={screen}
            onChangeModalScreen={setScreen}
            onCloseModalHandler={() => {
              handleCloseAddPropertyModal();
            }}
            isByPassOptionAvailable={true}
            isSubscriptionScreenRequired={isSubscriptionScreenRequired}
            isAdminCreateUser={true}
            backToSearchProperty={setScreen}
          />
        </>
      )}

      {/* loader */}
      <Loader open={signupLoader} />
    </>
  );
};

export default AddNewUserPage;
