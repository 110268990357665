import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FieldStyle, MenuProps } from "../../helpers/constants";
import { useSelector } from "react-redux";

import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";

const CountyAdminForm = ({ selectedItem, onSubmit, handleClose }) => {
  const { addCountyAdminDetails } = useSelector((state) => state?.admins);
  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );

  return (
    <>
      {" "}
      <div className="d-flex justify-content-center bg-white">
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            first_name: selectedItem?.first_name || "",
            last_name: selectedItem?.last_name || "",
            email: selectedItem?.email || "",
            county: selectedItem?.county_admin?.county_id || "",
            // is_active: 1,
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string("Please enter your First Name.")
              .trim("The First name cannot include leading and trailing spaces")
              .strict(true)
              .required("First Name is required"),
            last_name: Yup.string("Please enter your Last name.")
              .trim("The Last name cannot include leading and trailing spaces")
              .strict(true)
              .required("Last Name is required"),
            county: Yup.string("Please Select County Name.").required(
              "County name is required"
            ),
            email: Yup.string("Please enter your Email.")
              .trim("The Email cannot include leading and trailing spaces")
              .strict(true)
              .email("Please enter a valid email address")
              .required("Email is required"),
          })}
          onSubmit={(values) => onSubmit(values, "county_admin")}
        >
          {({ values, setFieldValue, touched, errors, initialValues }) => {
            return (
              <Form className="beneficiary-form w-100 bg-white">
                <div className="">
                  {/* {!addAdminDetails?.success && ( */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="bg-white"
                  >
                    <>
                      {/* first name */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <TextField
                          className="sign-textField "
                          label="First name"
                          name="first_name"
                          placeholder="First Name"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.first_name}
                          onChange={(event) => {
                            setFieldValue("first_name", event.target.value);
                          }}
                          helperText={touched.first_name && errors.first_name}
                          error={
                            touched.first_name && Boolean(errors.first_name)
                          }
                        />
                      </Grid>
                      {/* last name */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <TextField
                          className="sign-textField "
                          label="Last name"
                          name="last_name"
                          placeholder="Last Name"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.last_name}
                          onChange={(event) => {
                            setFieldValue("last_name", event.target.value);
                          }}
                          helperText={touched.last_name && errors.last_name}
                          error={touched.last_name && Boolean(errors.last_name)}
                        />
                      </Grid>
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <FormControl
                          disabled={selectedItem?.county_admin?.county_id}
                          fullWidth
                          className="sign-textField "
                          error={touched.county && Boolean(errors.county)}
                        >
                          <InputLabel
                            id="demo-simple-select-placeholder"
                            label="County"
                          >
                            County
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-placeholder"
                            id="demo-simple-select"
                            value={values?.county}
                            label="County"
                            name="county"
                            variant="outlined"
                            onChange={(event) => {
                              setFieldValue("county", event.target.value);
                            }}
                            MenuProps={MenuProps}
                          >
                            {registeredCountyListData?.data?.map(
                              (county, key) => (
                                <MenuItem value={county?.id}>
                                  {county?.county_name}
                                </MenuItem>
                              )
                            )}
                          </Select>

                          {touched.county && Boolean(errors.county) && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              {touched.county && errors.county}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {/* email */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <TextField
                          className="sign-textField "
                          label="Email"
                          name="email"
                          disabled={selectedItem?.id ? true : false}
                          placeholder="Email"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.email}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                          helperText={touched.email && errors.email}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </Grid>

                      {/* phone number */}
                      {/* <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomPhoneNumberInput
                          name="phone"
                          values={values}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          // disabled={!plansDetails}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue("phone", event);
                              setFieldValue("phone_number", event);
                              if (!event) {
                                setFieldValue("phone", "");
                                setFieldValue("phone_number", "");
                              }
                            },
                          }}
                        />
                      </Grid> */}
                    </>
                  </Grid>
                </div>

                {addCountyAdminDetails?.success ? (
                  <Button
                    className="popup_Btn other_popup_btn"
                    // variant="contained"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                ) : (
                  <>
                    <Stack
                      direction="row"
                      pb={2}
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Button
                        className="popup_Btn other_popup_btn"
                        // variant="contained"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="popup_Btn success_popup_btn"
                        type="submit"
                        disabled={
                          JSON.stringify(initialValues) ===
                          JSON.stringify(values)
                        }
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Stack>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CountyAdminForm;
