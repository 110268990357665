import { useEffect } from "react";
import { useCallback } from "react";
import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CropOutlinedIcon from '@mui/icons-material/CropOutlined';

import {
  Box,
  Button,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import FindProperty from "./FindProprty";
import { Loader } from "../../common/loader";
import PropertyListing from "./PropertyListing";
import { ROLES } from "../../helpers/constants";
import AlertDialog from "../../common/AlertDialog";
import FindPropertyByParcel from "./FindPropertyByParcel";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography sx={{ p: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PropertySearchType = ({
  onBtnClick,
  onChangeModalScreen,
  onCloseModalHandler,
  isByPassOptionAvailable = false,
  isAdminCreateUser = false,
  editProperty = false,
  selectedData = {},
  prop_id = null,
  isShow = true,
  handleCloseAddPropertyModal,
}) => {
  const [value, setValue] = useState(1);
  const [propertyValues, setPropertyValues] = useState({});
  const [showPropertyListing, setShowPropertyListing] = useState(false);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [questionsErrMsg, setQuestionsErrMsg] = useState("");
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    getPropertyData,
    selectedProperty: selectedPropertyData,
    propertyQuestion,
    propertyLoader,
    selectedUserID,
    isQuestionByPass,
    isSubscriptionByPass,
    editPropertiesDetails,
  } = useSelector((state) => state.properties);

  const accCreated = useSelector((state) => state.signUpUser.createAccResponse);
  const { data: userData } = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );
  const { servicerProfessionalDetails } = useSelector(
    (state) => state?.servicerProfile
  );

  const handleChange = (event, newValue) => {
    dispatch(propertiesAction.addNewProperty({}));
    setQuestionsErrMsg("");
    setPropertyValues("");
    setErrMsg("");
    setValue(newValue);
  };

  const onBackClick = () => {
    // setPropertyForm(true);
    setShowPropertyListing(false);
    setQuestionsErrMsg("");
    setErrMsg("");
    dispatch(propertiesAction.addNewProperty({}));
    setPropertyValues({});
    setQuestionsErrMsg("");
    setErrMsg("");
  };

  const handleClose = useCallback(() => {
    if (getPropertyData?.data?.length > 1) {
      setShowPropertyListing(true);
      // setPropertyForm(false);
    }

    if (!propertyQuestion?.success) {
      setQuestionsErrMsg(propertyQuestion?.data?.message);
      setErrMsg("");
    }
    console.log("falssee=>>");
    setOpen(false);
  }, [
    getPropertyData?.data?.length,
    propertyQuestion?.data?.message,
    propertyQuestion?.success,
  ]);

  const handleToggle = () => {
    setQuestionsErrMsg("");
    setOpen(!open);
  };

  const validate = () => {
    handleClose();
    setErrMsg(getPropertyData?.data?.message);
    setQuestionsErrMsg("");
  };

  // handle modal open
  const handleResponseModalOpen = () => {
    setIsResponseModalOpen(true);
  };

  // handle modal close
  const handleResponseModalClose = () => {
    setIsResponseModalOpen(false);
  };

  const selectProperty = (data) => {
    setOpen(true);
    dispatch(propertiesAction.selectedUserPropertyData(data));
    // handleToggle();
  };

  const tabContent = [
    {
      label: "Address",
      value: 1,
      component: (
        <>
          <FindProperty
            onBtnClick={onBtnClick}
            isAdminCreateUser={isAdminCreateUser}
            isByPassOptionAvailable={isByPassOptionAvailable}
            onChangeModalScreen={onChangeModalScreen}
            onCloseModalHandler={onCloseModalHandler}
            setShowPropertyListing={setShowPropertyListing}
            propertyValues={propertyValues}
            selectedData={editPropertiesDetails}
            address={selectedData?.address}
            editProperty={editProperty}
            setPropertyValues={setPropertyValues}
            validate={validate}
            handleToggle={handleToggle}
            handleClose={handleClose}
            questionsErrMsg={questionsErrMsg}
            open={open}
            errMsg={errMsg}
            setErrMsg={setErrMsg}
          />
        </>
      ),
      icon: <FmdGoodOutlinedIcon />,
    },
    {
      label: "Parcel/Land",
      value: 2,
      icon: <CropOutlinedIcon />,
      component: (
        <>
          <FindPropertyByParcel
            onBtnClick={onBtnClick}
            isAdminCreateUser={isAdminCreateUser}
            isByPassOptionAvailable={isByPassOptionAvailable}
            onChangeModalScreen={onChangeModalScreen}
            onCloseModalHandler={onCloseModalHandler}
            setShowPropertyListing={setShowPropertyListing}
            propertyValues={propertyValues}
            setPropertyValues={setPropertyValues}
            validate={validate}
            handleToggle={handleToggle}
            handleClose={handleClose}
            questionsErrMsg={questionsErrMsg}
            open={open}
            errMsg={errMsg}
            setErrMsg={setErrMsg}
            editProperty={editProperty}
          />
        </>
      ),
    },
  ];

  //============== for call security question api ==============
  useEffect(() => {
    if (Object.keys(selectedPropertyData).length > 0) {
      // onBtnClick();
      // handleToggle();
      let propertyData = selectedPropertyData?.json_response;
      console.log(selectedPropertyData, "security questions==>", propertyData);
      let inputParams = {
        state_code: propertyData.mm_fips_state_code,
        property_id: selectedPropertyData?.property_id,
        muni_code: propertyData.mm_fips_muni_code,
        sa_y_coord: propertyData.sa_y_coord,
        sa_x_coord: propertyData.sa_x_coord,
        user_id: decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? servicerProfessionalDetails?.data?.user_id
          : isAdminCreateUser && getObjLength(accCreated)
          ? accCreated?.data?.user_id
          : isByPassOptionAvailable
          ? selectedUserID
          : userData?.user_id,
        address: selectedPropertyData.address,
        country: selectedPropertyData.country || "USA",
        city: selectedPropertyData.city,
        state: selectedPropertyData.state,
        zip: selectedPropertyData.zip,
        latitude: selectedPropertyData.latitude,
        longitude: selectedPropertyData.longitude,
        bed_rooms: selectedPropertyData.bed_rooms,
        baths: selectedPropertyData.baths,
        sqft: selectedPropertyData.sqft,
        year_built: selectedPropertyData.year_built,
        json_response: propertyData,
        price: selectedPropertyData.price,
        county: selectedPropertyData.county,
        step: 2,
        step_key: "GENERATE_QUESTIONS",
      };

      if (isByPassOptionAvailable) {
        if (isQuestionByPass) {
          inputParams.is_questions_verified_by_admin = isQuestionByPass;
          inputParams.subscribed_by_admin = isSubscriptionByPass;
          if (editProperty) {
            inputParams.prop_id = prop_id;
            dispatch(
              propertiesAction.generateQuestions({
                url: "admin/edit/property",
                data: inputParams,
                token: true,
              })
            );
          } else {
            dispatch(
              propertiesAction.generateQuestions({
                url: "admin/properties/create",
                data: inputParams,
                token: true,
              })
            );
          }
        } else {
          inputParams.subscribed_by_admin = isSubscriptionByPass;
          if (editProperty) {
            inputParams.prop_id = prop_id;
            console.log("Edit property==>", editProperty);
            dispatch(
              propertiesAction.generateQuestions({
                url: "admin/edit/property/generate-question",
                data: inputParams,
                token: true,
              })
            );
          } else {
            dispatch(
              propertiesAction.generateQuestions({
                url: "admin/properties/generate-questions",
                data: inputParams,
                token: true,
              })
            );
          }
        }
      } else {
        // if (localStorage.getItem("roles")?.includes(ROLES.serviceProvider)) {
        //   dispatch(
        //     propertiesAction.generateQuestions({
        //       url: "servicer/generate-question",
        //       data: inputParams,
        //       token: true,
        //     })
        //   );
        // } else
        if (
          userData?.user_type?.includes(ROLES?.professional) &&
          !userData?.role?.includes(ROLES?.customer)
        ) {
          dispatch(
            propertiesAction.generateQuestions({
              url: "professional/generate-question",
              data: inputParams,
              token: true,
            })
          );
        } else {
          dispatch(
            propertiesAction.generateQuestions({
              url: "properties/generate-questions",
              data: inputParams,
            })
          );
        }
      }
      setTimeout(() => {
        delete inputParams.step;
        delete inputParams.step_key;
        dispatch(propertiesAction.generateQuestionsInputParams(inputParams));
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accCreated,
    dispatch,
    editProperty,
    isAdminCreateUser,
    isByPassOptionAvailable,
    isQuestionByPass,
    isSubscriptionByPass,
    prop_id,
    selectedPropertyData,
    selectedUserID,
    servicerProfessionalDetails?.data?.user_id,
    userData?.role,
    userData?.user_id,
    userData?.user_type,
  ]);

  useEffect(() => {
    console.log("propertyQuestion  ==>", propertyQuestion);
    if (Object.keys(propertyQuestion).length > 0) {
      handleClose();
      if (propertyQuestion?.success) {
        onBtnClick();
        if (isQuestionByPass) {
          handleResponseModalOpen();
          // onCloseModalHandler();
        } else {
          onChangeModalScreen();
        }
      }
    }
    if (Object.keys(propertyQuestion).length === 0) {
      setQuestionsErrMsg("");
    }
  }, [
    handleClose,
    isQuestionByPass,
    onBtnClick,
    onChangeModalScreen,
    propertyQuestion,
  ]);
  //==========================================

  return (
    <>
    {isShow &&
    <Typography
         align="center"
         variant="subtitle1"
         className="py-1 formHeader zoom-container"
         sx={{maxWidth:"900px",position: "relative"}}
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
            <Button
            onClick={handleCloseAddPropertyModal}
            className="modal-close-btn close-text hide-inner-close"
            style={{
              position: "absolute",
              top: 0,
              right: -16,
              background: "transparent",
              textTransform:"none",
            
            }}
          >
            <CloseIcon className="hide-inner-close" sx={{fontSize:20 , color: "white" }} />
          </Button>
          </Typography>
          }
      {!showPropertyListing ? (<>
        <Box
          className="accountBoxWrapper findPropertyBoxWrapper bg-white"
          // className="d-flex justify-content-center align-items-center"
        >
          <Box className="icon-container">
            <div className="icon-outer-div">
              <div className="icon-inner-div"> 
                <AddHomeOutlinedIcon sx={{ fontSize: 24,color:"rgba(3, 149, 255, 1)" }} />
              </div>
            </div>
          </Box>
          <Typography align="center" variant="h3">
            Property Search
          </Typography>
          <Typography align="center" variant="body1">
            Please provide us with the address of the property you would like to
            have monitored and protected.
          </Typography>
          <Box className="d-block d-md-none" margin="0 10px">
            <Select
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              displayEmpty
              className="mobile-select-tabs w-100"
              inputProps={{ "aria-label": "Without label" }}
            >
              {tabContent?.map((tabs, index) => (
                <MenuItem
                  sx={{
                    color: value === index + 1 ? "#0D120B" : "#0395FF",
                    paddingLeft: value === index + 1 ? "30px" : "",
                  }}
                  value={tabs?.value}
                >
                  {tabs?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* <StyledSubTabs
      value={value}
      // scrollButtons
      onChange={handleChange}
      aria-label="basic tabs example"
      className="bg-white d-none d-md-block"
      sx={{ pl: 3, m: 0 }}
    > */}
          <Box className="d-flex justify-content-center align-items-center ">
            <Tabs
              value={value}
              className="bg-white d-none d-md-block"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              {tabContent?.map((tabs, index) => (
                <Tab
                  icon={tabs?.icon}
                  iconPosition="start"
                  value={tabs?.value}
                  label={tabs?.label}
                  {...a11yProps(index + 1)}
                />
              ))}
            </Tabs>
          </Box>
          <Box>
            {tabContent?.map((tabs, index) => (
              <TabPanel value={value} index={index + 1}>
                {tabs.component}
              </TabPanel>
            ))}
          </Box>
        </Box>
        </>
      ) : (
        // {/* )} */}
        // {/* {showPropertyListing &&  */}
        <Box className="accountBoxWrapper findPropertyBoxWrapper bg-white">
          <Typography align="center" variant="h3">
            Property List
          </Typography>
          <Typography align="center" variant="body1">
            Please select your relevant property from the property list.
          </Typography>
          {getPropertyData?.data?.map((data, key) => (
            <PropertyListing
              data={data}
              selectProperty={selectProperty}
              handleToggle={handleToggle}
            />
          ))}
          <div className="text-center stepBtn">
            <Button
              onClick={onBackClick}
              className="backButton"
              color="primary"
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          </div>
          {questionsErrMsg?.length > 0 && (
            <p className="text-danger text-center">{questionsErrMsg}</p>
          )}
        </Box>
      )}

      {isResponseModalOpen && getObjLength(propertyQuestion) && (
        <>
          {getObjLength(propertyQuestion) && propertyQuestion?.success ? (
            <AlertDialog
              type="Success"
              openPopup={isResponseModalOpen}
              onClose={true}
              onCloseFun={() => {
                handleResponseModalClose();
                onCloseModalHandler("success");
              }}
              mesage={propertyQuestion?.message}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={isResponseModalOpen}
              onClose={true}
              onCloseFun={() => {
                handleResponseModalClose();
              }}
              mesage={propertyQuestion?.data?.message}
              footer={false}
            />
          )}
        </>
      )}

      <Loader open={open || propertyLoader} />
    </>
  );
};

export default PropertySearchType;
