import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import CallUsNow from "../common/CallUsNow";
import { ROLES } from "../helpers/constants";
import logo from "../../assets/logo/equity.WebP";
import phoneTalk from "../../assets/phone_in_talk.svg";
import userAccount from "../../assets/account_circle.svg";
import {
  decryptPayload,
  encryptPayload,
  getObjLength,
  navigateToCustomerDomain,
} from "../helpers/utils";

const NavBar = ({ roles = [] }) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const pathname = window.location.pathname;

  const queryString = window.location.search;
  let paramString = queryString.split("?")[1];
  let valueOf = paramString?.split("=")[0];

  let is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));

  // store data
  const { riskManagementData } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  const maangeCountyData = (county_details) => {
    if (getObjLength(county_details)) {
      is_scrp_hp = 1;
      countyCode = encryptPayload(county_details?.county_domain_name);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.getElementById("HeaderNavbar")?.classList) {
        if (
          (window.location.href.includes("privacy") ||
            window.location.href.includes("terms")) > -1
        ) {
          document.getElementById("HeaderNavbar")?.classList.add("sticky");
        } else {
          if (window.scrollY > 10) {
            document.getElementById("HeaderNavbar")?.classList.add("sticky");
          } else {
            document.getElementById("HeaderNavbar")?.classList.remove("sticky");
          }
        }
      }
    });
    const handleOutsideClick = (event) => {
      // Close the Navbar if a click occurs outside of it
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    // Attach click event listener to the document body
    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      // Remove the event listener on component unmount
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (expanded) {
      handleNavItemClick();
    }
    if (pathname?.includes("property-risk-report")) {
      document.getElementById("HeaderNavbar")?.classList.add("sticky");
      if (
        valueOf === "report_token" &&
        getObjLength(riskManagementData) &&
        riskManagementData?.success
      ) {
        let county_details = riskManagementData?.data?.county;
        maangeCountyData(county_details);
        // set county if it's already available
        if (getObjLength(county_details)) {
          localStorage.setItem("scrp_hp", 1);
          localStorage.setItem(
            "county-code",
            encryptPayload(county_details?.county_domain_name)
          );
        } else {
          localStorage.removeItem("scrp_hp");
          localStorage.removeItem("county-code");
        }
      }
    }
  }, [pathname]);

  // handle My Account Button
  const handleMyAccountBtn = () => {
    console.log("My Account Button clicked");
    let user = localStorage.getItem("authorised");
    let userRole = decryptPayload(localStorage.getItem("roles"));
    if (
      user === "true" &&
      (userRole?.includes(ROLES.admin) ||
        userRole?.includes(ROLES.subAdmin) ||
        (!userRole?.includes(ROLES.serviceProvider) &&
          userRole?.includes(ROLES.provider)) ||
        userRole?.includes(ROLES.countyRecorder))
    ) {
      navigate("/admin-profile");
    } else if (
      user === "true" &&
      (userRole?.includes(ROLES.customer) ||
        userRole?.includes(ROLES.secondaryUser))
    ) {
      navigate("/user-profile");
    } else if (user === "true" && userRole?.includes(ROLES.serviceProvider)) {
      navigate("/servicer-profile");
    } else if (user === "true" && userRole?.includes(ROLES.professional)) {
      navigate("/professional-profile");
    } else {
      navigate("/login");
    }
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
  };
  // handle login Button
  const handleLoginBtn = () => {
    console.log("login Button clicked");
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
    let user = localStorage.getItem("authorised");
    let userRole = decryptPayload(localStorage.getItem("roles"));
    if (
      user === "true" &&
      (userRole?.includes(ROLES.admin) ||
        userRole?.includes(ROLES.subAdmin) ||
        userRole?.includes(ROLES.provider) ||
        userRole?.includes(ROLES.countyRecorder))
    ) {
      return "/admin-profile";
    } else if (
      user === "true" &&
      (userRole?.includes(ROLES.customer) ||
        userRole?.includes(ROLES.secondaryUser))
    ) {
      return "/user-profile";
    } else if (user === "true" && userRole?.includes(ROLES.serviceProvider)) {
      return "/servicer-profile";
    } else {
      return "/login";
    }
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        fixed="top"
        expand="lg"
        bg="transparent"
        variant="dark"
        className="py-4 py-md-4"
        id="HeaderNavbar"
        expanded={expanded}
        ref={navbarRef}
      >
        <Container fluid className="mx-150">
          <Navbar.Toggle
            className="mobile-menu"
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          ></Navbar.Toggle>
          <Link
            to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
            className="publicNav_logo"
          >
            <Navbar.Brand className="p-0 ">
              <LazyLoadImage
                src={logo}
                width="250px"
                height="100%"
                alt="logo"
              />
              {/* <img
                src={logo}
                alt="logo"
                style={{ width: "250px", height: "100%" }}
              /> */}
            </Navbar.Brand>
          </Link>
          <Nav
            className="MobAction_btn ms-auto d-flex flex-row d-lg-none"
            onSelect={handleNavItemClick}
          >
            <CallUsNow
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              renderText={
                <Button
                  className="footer_btn footer_blue mx-1 phone_btn"
                  variant="link"
                >
                  <LazyLoadImage
                    src={phoneTalk}
                    width="24px"
                    height="24px"
                    alt="Phone Talk"
                  />
                  {/* <img
                    src={phoneTalk}
                    alt="Phone Talk"
                    width="24px"
                    height="24px"
                  /> */}
                </Button>
              }
            />

            <Button
              className="footer_btn footer_green mx-1 my_account_btn"
              onClick={handleMyAccountBtn}
              variant="link"
            >
              <LazyLoadImage
                src={userAccount}
                width="24px"
                height="24px"
                alt="User Account"
              />
              {/* <img
                src={userAccount}
                alt="User Account"
                width="24px"
                height="24px"
              /> */}
            </Button>
          </Nav>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="navitems navbar-center justify-content-center"
              onSelect={handleNavItemClick}
            >
              <NavLink
                className={({ isActive }) =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 ${
                    isActive ? "link-active" : ""
                  }`
                }
                to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
              >
                Home
              </NavLink>
              {!roles.includes(ROLES.serviceProvider) && (
                <NavLink
                  className={({ isActive }) =>
                    `text-white text-uppercase mx-0 mx-md-3 mx-6 ${
                      isActive ? "link-active" : ""
                    }`
                  }
                  to="/how-it-works"
                >
                  How it Works
                </NavLink>
              )}
              {!roles.includes(ROLES.serviceProvider) && (
                <NavLink
                  className={({ isActive }) =>
                    `text-white text-uppercase mx-0 mx-md-3 mx-6 ${
                      isActive ? "link-active" : ""
                    }`
                  }
                  to={`${is_scrp_hp ? "/" + countyCode : ""}/pricing`}
                >
                  Pricing
                </NavLink>
              )}
              {roles.includes(ROLES.serviceProvider) && (
                <NavLink
                  className={() =>
                    `text-white text-uppercase mx-0 mx-md-3 mx-6 
                    }`
                  }
                  onClick={() => navigateToCustomerDomain("")}
                >
                  For Customer
                </NavLink>
              )}
              {!is_scrp_hp && (
                <NavLink
                  className={({ isActive }) =>
                    `text-white text-uppercase mx-0 mx-md-3 mx-6 ${
                      isActive ? "link-active" : ""
                    }`
                  }
                  to="/for-service-provider"
                >
                  For Service Providers
                </NavLink>
              )}
              <span onClick={handleMyAccountBtn}>
                <NavLink
                  className={() =>
                    `text-white text-uppercase mx-0 mx-md-3 mx-6 active-on-hover d-block d-lg-none`
                  }
                  to="#"
                >
                  Login
                </NavLink>
              </span>
              {/* <NavLink
                className={({ isActive }) =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 ${
                    isActive ? "link-active" : ""
                  }`
                }
                to="/lead"
              >
                Lead
              </NavLink> */}
              {/* <Link
                className="text-white text-uppercase mx-0 px-md-4 px-6"
                to="/faq-articles"
              >
                FAQ / Articles
              </Link> */}
            </Nav>
            <Nav className="action_btn mt-3 mt-md-0 d-none d-lg-block">
              <CallUsNow
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
              <Button
                className="footer_btn footer_green my-2 my-lg-0 mx-0 mx-lg-1"
                variant="link"
                onClick={handleMyAccountBtn}
              >
                My Account
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
