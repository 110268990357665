import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomLinkWrapper from "./CustomLinkWrapper";
import TextTruncate from "./TextTruncate";

const RequestedDocumentListModal = ({
  open,
  handleClose,
  requestedDocument,
  uploadedDocument,
}) => {
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-between align-items-center"
        >
          {/* Document List */}
          <Typography variant="h6">Document List</Typography>
          <Button
            onClick={handleClose}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers mb={2}>
          <Stack
            direction="column"
            spacing={2.5}
            className="m-1 p-3 fw-500 bg-white"
          >
            <Box>
              <Typography className="key">
                Requested Document (By Service Provider):
              </Typography>
              <Typography className="value">
                {requestedDocument ? (
                  <CustomLinkWrapper
                    link={requestedDocument}
                    title={
                      <TextTruncate
                        text={
                          requestedDocument?.split("/")?.[
                            requestedDocument?.split("/")?.length - 1
                          ] || "-"
                        }
                        maxLength={30}
                      />
                    }
                    classes="text-primary value"
                  />
                ) : (
                  "-"
                )}
              </Typography>
            </Box>

            <Box>
              <Typography className="key">Uploaded Document:</Typography>
              <Typography className="value">
                {uploadedDocument ? (
                  <CustomLinkWrapper
                    link={uploadedDocument}
                    title={
                      <TextTruncate
                        text={
                          uploadedDocument?.split("/")?.[
                            uploadedDocument?.split("/")?.length - 1
                          ] || "-"
                        }
                        maxLength={30}
                      />
                    }
                    classes="text-primary value"
                  />
                ) : (
                  "-"
                )}
              </Typography>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequestedDocumentListModal;
