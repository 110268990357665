import { useRef } from "react";
import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import AlertDialog from "../../common/AlertDialog";
import CustomTextField from "../../common/CustomTextField";
import UploadAndCropImage from "../../common/UploadAndCropImage";
import { getObjLength, roundOfNumIntrFloat } from "../../helpers/utils";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { MenuProps, SERVER_IP, USA_STATES } from "../../helpers/constants";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import { Loader } from "../../common/loader";

const fieldsValidations = (data) => {
  return Yup.object().shape({
    first_name: data?.personalDetails
      ? Yup.string("Please enter your First Name.")
          .trim("The First name cannot include leading and trailing spaces")
          .strict(true)
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("First Name is required")
      : Yup.string(),
    last_name: data?.personalDetails
      ? Yup.string("Please enter your Last name.")
          .trim("The Last name cannot include leading and trailing spaces")
          .strict(true)
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Last Name is required")
      : Yup.string(),
    email: data?.personalDetails
      ? Yup.string("Please enter your Email Id.")
          .email("Invalid email format")
          .max(255)
          .required("Email id is required")
      : Yup.string(),
    phone: data?.personalDetails
      ? Yup.string("Please enter your Phone number.")
          .typeError("That doesn't look like a phone number")
          // .positive()
          .required("Mobile number is required")
      : Yup.string(),
    address: data?.personalDetails
      ? Yup.string("Please enter your Address.")
          .trim("The Address cannot include leading and trailing spaces")
          .strict(true)
          .min(5, "Too Short!")
          .max(100, "Too Long!")
          .required("Address is required")
      : Yup.string(),
    county_name: data?.personalDetails
      ? Yup.string("Please enter your County.")
          .trim("The county cannot include leading and trailing spaces")
          .strict(true)
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("County is required")
      : Yup.string(),
    county_domain_name: data?.personalDetails
      ? Yup.string("Please enter your County Domain Name.")
          .trim("The domain name cannot include leading and trailing spaces")
          .strict(true)
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("County Domain Name is required")
      : Yup.string(),
    country: data?.personalDetails
      ? Yup.string("Please enter your Country.")
          .trim("The country cannot include leading and trailing spaces")
          .strict(true)
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Country is required")
      : Yup.string(),
    city: data?.personalDetails
      ? Yup.string("Please enter your City.")
          .trim("The city cannot include leading and trailing spaces")
          .strict(true)
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("City is required")
      : Yup.string(),
    state: data?.personalDetails
      ? Yup.string("Please select the state").required("State is required")
      : Yup.string(),
    zip: data?.personalDetails
      ? Yup.string("Please enter your Zip code.")
          .required("Zip is required")
          .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits")
      : Yup.string(),
    county_description: data?.personalDetails
      ? Yup.string("Please enter your county description.")
          .trim()
          .required("Please enter your county description.")
      : Yup.string(),

    // chart data
    low_risk_number:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your Low Risk Numbers.")
            .required("Low Risk Numbers is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.string(),
    low_risk_percent:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your Low Risk Percentage.")
            .max(100, "Percentage can't be more then 100%")
            .required("Low Risk Percentage is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),
    medium_risk_number:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your Medium Risk Numbers.")
            .required("Medium Risk Numbers is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),
    medium_risk_percent:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your Medium Risk Percentage.")
            .max(100, "Percentage can't be more then 100%")
            .required("Medium Risk Percentage is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),
    medium_high_risk_number:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your Medium High Risk Numbers.")
            .required("Medium High Risk Numbers is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),
    medium_high_risk_percent:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your Medium High Risk Percentage.")
            .max(100, "Percentage can't be more then 100%")
            .required("Medium High Risk Percentage is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),
    high_risk_number:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your High Risk Numbers.")
            .required("High Risk Numbers is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),
    high_risk_percent:
      data?.chartDetails && data?.isStatistics
        ? Yup.number("Please enter your  High Risk Percentage.")
            .max(100, "Percentage can't be more then 100%")
            .required(" High Risk Percentage is required")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),

    // Monthly plan fields validations
    monthly_plan_price:
      data?.plansDetails && data?.monthlyPlan
        ? Yup.number("Please enter your Monthly Setup amount.")
            .max(1000, "Too Long!")
            .required("Monthly Setup amount is required")
            .test(
              "not-allowed-chars",
              "Please enter postive amount for Monthly Subscription",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),

    monthly_renewal_price:
      data?.plansDetails && data?.monthlyPlan
        ? Yup.number("Please enter your Monthly Plan Renewal amount.")
            .max(1000, "Too Long!")
            .required("Monthly Plan Renewal amount is required")
            .test(
              "not-allowed-chars",
              "Please enter postive Monthly Plan Renewal amount",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),

    monthly_free_trial:
      data?.plansDetails && data?.monthlyPlan
        ? Yup.number()
            .required("Free trial period is required")
            .integer(
              "Please note that the Free Trial period cannot be combined with decimal numbers."
            )
            .max(365, "Free trial period cannot exceed 365 days")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers for Free trial period",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1 &&
                    value === Math.floor(value))
                  // Math.round(value) === value)
                );
              }
            )
        : Yup.number(),

    setup_fees:
      data?.plansDetails && data?.monthlyFees
        ? Yup.number("Please enter your Setup fees.")
            .max(1000, "Too Long!")
            .required("Setup fees is required")
            .test(
              "not-allowed-chars",
              "Please enter postive amount for Setup fees",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),

    // Yearly plan fields validations
    yearly_plan_price:
      data?.plansDetails && data?.yearlyPlan
        ? Yup.number("Please enter your Yearly Setup amount.")
            .max(1000, "Too Long!")
            .required("Yearly Setup amount is required")
            .test(
              "not-allowed-chars",
              "Please enter postive amount for Yearly Subscription",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),

    yearly_renewal_price:
      data?.plansDetails && data?.yearlyPlan
        ? Yup.number("Please enter your Yearly Plan Renewal amount.")
            .max(1000, "Too Long!")
            .required("Yearly Plan Renewal amount is required")
            .test(
              "not-allowed-chars",
              "Please enter postive Yearly Plan Renewal amount",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
        : Yup.number(),

    yearly_free_trial:
      data?.plansDetails && data?.yearlyPlan
        ? Yup.number()
            .required("Free trial period is required")
            .integer(
              "Please note that the Free Trial period cannot be combined with decimal numbers."
            )
            .max(365, "Free trial period cannot exceed 365 days")
            .test(
              "not-allowed-chars",
              "Please enter postive numbers for Free trial period",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1 &&
                    value === Math.floor(value))
                );
              }
            )
        : Yup.number(),

    recording_fees:
      data?.plansDetails && data?.recordingFees
        ? Yup.number("Please enter your Recording fees.")
            .max(1000, "Too Long!")
            .test(
              "not-allowed-chars",
              "Please enter postive amount for Recording fees",
              (value) => {
                // Custom validation to check for the presence of '-' and 'e'
                return (
                  value === undefined ||
                  (typeof value === "number" &&
                    `${value}`.indexOf("-") === -1 &&
                    `${value}`.indexOf("e") === -1)
                );
              }
            )
            .required("Recording  fees is required")
        : Yup.number(),
  });
  //     .test("at-least-one", null, function (value) {
  //       const { webhook_url, redirect_url } = value;

  //       if (!webhook_url && !redirect_url) {
  //         return this.createError({
  //           path: "redirect_url", // You can choose any field to attach the error
  //           message:
  //             "At least one of Webhook URL or Redirection URL is required.",
  //         });
  //       }

  //       return true;
  //     });
};

const CreateCountyRecorderForm = ({
  goBack = () => {},
  listingAPI,
  personalDetails = true,
  plansDetails = true,
  chartDetails = true,
  selectedData = {},
  plansData = {},
  closeDialogBox = () => {},
  isEdit = false,
  validateEditChart = true,
}) => {
  const dispatch = useDispatch();
  const signUpFormRef = useRef();
  const cropperRef = useRef();

  const [croppedProfileImage, setCroppedProfileImage] = useState(null);
  const [selectedProfileFile, setSelectedProfileFile] = useState(null);
  const [croppedLogoImage, setCroppedLogoImage] = useState(null);
  const [selectedLogoFile, setSelectedLogoFile] = useState(null);
  const [croppedStampImage, setCroppedStampImage] = useState(null);
  const [selectedStampFile, setSelectedStampFile] = useState(null);
  const [monthlyPlan, setMonthlyPlan] = useState(true);
  const [monthlyFees, setMonthlyFees] = useState(true);
  const [yearlyPlan, setYearlyPlan] = useState(true);
  const [yearlyFees, setYearlyFees] = useState(true);
  const [recordingFees, setRecordingFees] = useState(true);
  const [chartPercent, setChartPercent] = useState(true);
  const [isStatistics, setIsStatistics] = useState(true);

  const { editedPersonalDetailsData, countyRecordersLoader } = useSelector(
    (state) => state.countyRecorders
  );

  const handleSubmit = async (e, validateForm, setFieldTouched, values) => {
    e.preventDefault();
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      const errorField = Object.keys(errors)[0];
      Object.keys(errors).forEach((field) => setFieldTouched(field, true));
      document.getElementsByName(errorField)[0].focus();
    } else {
      if (!isValidPhoneNumber(values?.phone)) {
        document.getElementsByName("phone")[0].focus();
        return;
      }
      if (!chartPercent || (getObjLength(selectedData) && validateEditChart)) {
        onSubmit(values);
      }
    }
  };

  const onMonthlyPlanChecked = (e, checked, setFieldValue) => {
    setFieldValue("monthly_plan_price", "");
    setFieldValue("monthly_renewal_price", "");
    setFieldValue("monthly_free_trial", "");
    setFieldValue("setup_fees", "");
    setMonthlyPlan((prevState) => !prevState);
    if (!checked) {
      setMonthlyFees(false);
    } else {
      setMonthlyFees(true);
    }
  };

  const onMonthlyFessChecked = (e, checked, setFieldValue) => {
    setMonthlyFees((prevState) => !prevState);
    setFieldValue("setup_fees", "");
  };

  const onYearlyPlanChecked = (e, checked, setFieldValue) => {
    setFieldValue("yearly_plan_price", "");
    setFieldValue("yearly_free_trial", "");
    setFieldValue("yearly_renewal_price", "");
    setYearlyPlan((prevState) => !prevState);
    // if (!checked) {
    //   setYearlyFees(false);
    // } else {
    //   setYearlyFees(true);
    // }
  };

  const onRecordingFeesChecked = (e, checked, setFieldValue) => {
    setFieldValue("recording_fees", "");
    if (!checked) {
      setRecordingFees(false);
    } else {
      setRecordingFees(true);
    }
  };

  const onStatisticsChecked = (e, checked, setFieldValue) => {
    // setIsStatistics((prevState) => !prevState);
    if (!checked) {
      setIsStatistics(false);
    } else {
      setIsStatistics(true);
    }
    setFieldValue("low_risk_number", "");
    setFieldValue("low_risk_percent", "");
    setFieldValue("medium_risk_number", "");
    setFieldValue("medium_risk_percent", "");
    setFieldValue("medium_high_risk_number", "");
    setFieldValue("medium_high_risk_percent", "");
    setFieldValue("high_risk_number", "");
    setFieldValue("high_risk_percent", "");
  };

  const onSubmit = (values, errors) => {
    console.log("values=>", values);
    const formData = new FormData();
    let monthly_plans = {};
    let monthly_renewal_plans = {};
    let yearly_plans = {};
    let yearly_renewal_plans = {};

    if (monthlyPlan) {
      monthly_plans = {
        name: "monthly",
        amount: values?.monthly_plan_price,
        additional_charges: {
          "setup fees": values?.setup_fees,
          recording_fees: values.recording_fees,
        },
        is_default: 1,
        trial_period: {
          day: parseInt(values?.monthly_free_trial),
        },
      };
      monthly_renewal_plans = {
        name: "monthly",
        amount: values?.monthly_renewal_price,
        is_default: 0,
        additional_charges: {
          // "setup fees": values?.setup_fees,
          // recording_fees: values.recording_fees,
        },
        trial_period: {},
      };
    } else {
      monthly_plans = null;
      monthly_renewal_plans = null;
    }

    if (yearlyPlan) {
      yearly_plans = {
        name: "yearly",
        amount: values?.yearly_plan_price,
        additional_charges: {
          "setup fees": values?.setup_fees,
          recording_fees: values.recording_fees,
        },
        is_default: 1,
        trial_period: {
          day: parseInt(values?.yearly_free_trial),
        },
      };
      yearly_renewal_plans = {
        name: "yearly",
        amount: values?.yearly_renewal_price,
        is_default: 0,
        additional_charges: {
          // "setup fees": values?.setup_fees,
          // recording_fees: values.recording_fees,
        },
        trial_period: {},
      };
    } else {
      yearly_plans = null;
      yearly_renewal_plans = null;
    }

    let subscription_plans = [
      { ...monthly_plans },
      { ...monthly_renewal_plans },
      { ...yearly_plans },
      { ...yearly_renewal_plans },
    ];

    // for remove the empty objects
    const finalPlansList = subscription_plans.filter(
      (obj) => Object.keys(obj).length > 0
    );

    // for Chart data
    let chartData = {};

    if (isStatistics) {
      chartData = {
        low_risk_number: values?.low_risk_number,
        low_risk_percent: values?.low_risk_percent,
        medium_risk_number: values?.medium_risk_number,
        medium_risk_percent: values?.medium_risk_percent,
        medium_high_risk_number: values?.medium_high_risk_number,
        medium_high_risk_percent: values?.medium_high_risk_percent,
        high_risk_number: values?.high_risk_number,
        high_risk_percent: values?.high_risk_percent,
        is_statistics: 1,
      };
    } else {
      chartData = { is_statistics: 0 };
    }

    // Convert Description input to HTML format
    const formattedDescription = values.county_description.replace(
      /\n/g,
      "<br/>"
    );

    let data = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      phone: values?.phone,
      county_name: values?.county_name,
      county_domain_name: values?.county_domain_name,
      address: values?.address,
      address_2: values?.address_2,
      city: values?.city,
      state: values?.state,
      zip: values?.zip,
      country: values?.country,
      profile_image: values?.profile_image || "",
      logo_url: values?.logo_url || "",
      stamp_url: values?.stamp_url || "",
      description: values?.county_description || "",
      // description: formattedDescription || "",
    };
    console.log("Data==>>", data);

    // for personal details
    if (personalDetails) {
      if (values?.logo_url === selectedData?.logo_url) {
        delete data?.logo_url;
      }
      if (values?.stamp_url === selectedData?.stamp_url) {
        delete data?.stamp_url;
      }
      if (values?.profile_image === selectedData?.profile_image) {
        delete data?.profile_image;
      }
      if (values?.county_description === selectedData?.description) {
        delete data?.description;
      }
      // Append basic user data in form data
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
    }

    // for personal details
    if (chartDetails) {
      // Append chart data to formData
      for (const key in chartData) {
        if (chartData.hasOwnProperty(key)) {
          formData.append(key, chartData[key]);
        }
      }
    }

    if (plansDetails) {
      // Append plans array to FormData
      finalPlansList.forEach((plan, index) => {
        Object.keys(plan).forEach((key) => {
          // If it's an object, stringify it
          const value =
            typeof plan[key] === "object"
              ? JSON.stringify(plan[key])
              : plan[key];
          formData.append(`plans[${index}][${key}]`, value);
        });
      });
    }

    if (personalDetails && plansDetails && chartDetails) {
      dispatch(
        countyRecordersAction.createCountyRecordersData({
          url: `admin/county/create`,
          data: formData,
          isFile: true,
          token: true,
        })
      );
    } else {
      dispatch(
        countyRecordersAction.editedPersonalDetailsData({
          url: `admin/county/${selectedData?.id}/update`,
          data: formData,
          isFile: true,
          token: true,
        })
      );
    }
  };

  const closeAlertPopup = () => {
    if (editedPersonalDetailsData?.success) {
      closeDialogBox();
    }
    listingAPI();

    dispatch(countyRecordersAction.fetchCountyRecordersData({}));
    dispatch(countyRecordersAction.fetchEditedPersonalDetailsData({}));
  };

  // for enable/disable the checkboxws for data update
  useEffect(() => {
    if (getObjLength(plansData)) {
      if (!plansData?.monthly_plans?.is_active) {
        setMonthlyFees(false);
        setMonthlyPlan(false);
      }
      if (!plansData?.monthly_plans?.setup_fees) {
        setMonthlyFees(false);
      }
      if (!plansData?.yearly_plans?.is_active) {
        setYearlyFees(false);
        setYearlyPlan(false);
      }

      if (!plansData?.recording_fees) {
        setRecordingFees(false);
      }
    }

    if (getObjLength(selectedData) && personalDetails) {
      if (selectedData?.logo_url) {
        setCroppedLogoImage(selectedData?.logo_url);
        signUpFormRef?.current?.setFieldValue(
          "logo_url",
          selectedData?.logo_url
        );
      }
      if (selectedData?.stamp_url) {
        setCroppedStampImage(selectedData?.stamp_url);
        signUpFormRef?.current?.setFieldValue(
          "stamp_url",
          selectedData?.stamp_url
        );
      }
      if (selectedData?.profile_image) {
        setCroppedProfileImage(selectedData?.profile_image);
        signUpFormRef?.current?.setFieldValue(
          "profile_image",
          selectedData?.profile_image
        );
      }
    }
    if (getObjLength(selectedData) && chartDetails) {
      setIsStatistics(selectedData?.is_statistics);
    }
  }, [chartDetails, personalDetails, plansData, selectedData]);

  const validationSchema = fieldsValidations({
    monthlyPlan,
    monthlyFees,
    yearlyPlan,
    yearlyFees,
    recordingFees,
    personalDetails,
    plansDetails,
    chartDetails,
    isStatistics,
  });

  return (
    <Box>
      <Formik
        enableReinitialize
        validateOnChange
        innerRef={signUpFormRef}
        initialValues={{
          first_name: selectedData?.first_name || "",
          last_name: selectedData?.last_name || "",
          email: selectedData?.email || "",
          phone_number: selectedData?.phone || "",
          phone: selectedData?.phone || "",
          county_name: selectedData?.county_name || "",
          county_domain_name: selectedData?.county_domain_name || "",
          address: selectedData?.address || "",
          address_2: selectedData?.address_2 || "",
          city: selectedData?.city || "",
          state: selectedData?.state || "",
          zip: selectedData?.zip || "",
          country: selectedData?.country || "USA",
          county_description: selectedData?.description || "",
          profile_image: selectedData?.profile_image || "",
          logo_url: selectedData?.logo_url || "",
          stamp_url: selectedData?.stamp_url || "",
          low_risk_number: selectedData?.low_risk_number || "",
          lrn: "",

          low_risk_percent: selectedData?.low_risk_percent || "",
          lrp: "",
          medium_risk_number: selectedData?.medium_risk_number || "",
          mrn: "",
          medium_risk_percent: selectedData?.medium_risk_percent || "",
          mrp: "",
          medium_high_risk_number: selectedData?.medium_high_risk_number || "",
          mhrn: "",
          medium_high_risk_percent:
            selectedData?.medium_high_risk_percent || "",
          mhrp: "",
          high_risk_number: selectedData?.high_risk_number || "",
          hrn: "",
          high_risk_percent: selectedData?.high_risk_percent || "",
          hrp: "",
          monthly_plan_price:
            plansDetails && personalDetails
              ? ""
              : roundOfNumIntrFloat(
                  parseFloat(plansData?.monthly_plans?.amount || 0)
                  // +  parseFloat(plansData?.monthly_plans?.setup_fees || 0) // hide this as setup fees field is open
                ) || "",
          monthly_renewal_price:
            plansData?.monthly_plans?.monthly_renewal_plans || "",
          mpp: "",
          monthly_free_trial:
            plansData?.monthly_plans?.trial_period?.toString() || "",
          mft: "",
          setup_fees: plansData?.monthly_plans?.setup_fees || "",
          sf: "",
          yearly_renewal_price:
            plansData?.yearly_plans?.yearly_renewal_plans || "",
          yearly_plan_price: plansData?.yearly_plans?.amount || "",
          ypp: "",
          yearly_free_trial:
            plansData?.yearly_plans?.trial_period?.toString() || "",
          yft: "",
          recording_fees: plansData?.recording_fees || "",
          rf: "",
        }}
        validationSchema={validationSchema}
        // onSubmit={(values, validation) => {
        //   console.log("entered");
        //   if (!isValidPhoneNumber(values?.phone)) {
        //     return;
        //   }
        //   if (!chartPercent) {
        //     onSubmit(values);
        //   }
        // }}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          validateForm,
          setFieldTouched,
        }) => {
          let chart_percentages =
            parseFloat(values?.low_risk_percent || 0) +
            parseFloat(values?.medium_risk_percent || 0) +
            parseFloat(values?.medium_high_risk_percent || 0) +
            parseFloat(values?.high_risk_percent || 0);
          if (isStatistics && chart_percentages !== 100) {
            setChartPercent(true);
          } else {
            setChartPercent(false);
          }
          return (
            <Form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {/*  Personal Detalis */}
                {personalDetails && (
                  <>
                    {/* First name */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="First Name"
                        placeholder="First Name"
                        name="first_name"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("first_name", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    {/* Last Name */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Last Name"
                        placeholder="Last Name"
                        name="last_name"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("last_name", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    {/* email */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Email"
                        placeholder="Email"
                        name="email"
                        // disabled={!plansDetails}
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("email", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    {/* Phone Number */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomPhoneNumberInput
                        name="phone"
                        values={values}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        // disabled={!plansDetails}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("phone", event);
                            setFieldValue("phone_number", event);
                            if (!event) {
                              setFieldValue("phone", "");
                              setFieldValue("phone_number", "");
                            }
                          },
                        }}
                      />
                    </Grid>
                    {/* County Name */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="County Name"
                        placeholder="County Name"
                        name="county_name"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("county_name", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    {/* County Domain Name */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="County Domain Name"
                        placeholder="County Domain Name"
                        name="county_domain_name"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "county_domain_name",
                              event.target.value
                            );
                          },
                        }}
                      />
                    </Grid>
                    {/* Address Line 1 */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Address Line 1"
                        placeholder="Address Line 1"
                        name="address"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("address", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    {/* Address Line 2 */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Address Line 2"
                        placeholder="Address Line 2"
                        name="address_2"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("address_2", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    {/* City */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="City"
                        placeholder="City"
                        name="city"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("city", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    {/* State */}
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <FormControl
                        fullWidth
                        className="sign-textField text-start"
                        error={touched.state && Boolean(errors.state)}
                      >
                        <InputLabel
                          // id="demo-simple-select-placeholder"
                          label="State"
                        >
                          State
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          value={values?.state}
                          label="State"
                          name="state"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("state", event.target.value);
                          }}
                          MenuProps={MenuProps}
                        >
                          {USA_STATES?.map((state, i) => (
                            <MenuItem value={state?.code} key={i}>
                              {state?.code}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.state && Boolean(errors.state) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.state && errors.state}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} pb={1}>
                      <Stack
                        direction="row"
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        // alignItems="center"
                      >
                        <Grid xs={12} md={6}>
                          <Stack spacing={1} direction="column">
                            <>
                              {/* Zip */}
                              <Grid item xs={12} pb={1}>
                                <CustomTextField
                                  label="Zip"
                                  placeholder="Zip"
                                  name="zip"
                                  validation={{
                                    values,
                                    setFieldValue,
                                    touched,
                                    errors,
                                  }}
                                  otherProps={{
                                    onChange: (event) => {
                                      setFieldValue("zip", event.target.value);
                                    },
                                  }}
                                />
                              </Grid>
                            </>
                            <>
                              {/* County */}
                              <Grid item xs={12} pb={1}>
                                <CustomTextField
                                  label="Country"
                                  placeholder="Country"
                                  disabled
                                  name="country"
                                  validation={{
                                    values,
                                    setFieldValue,
                                    touched,
                                    errors,
                                  }}
                                  otherProps={{
                                    onChange: (event) => {
                                      setFieldValue(
                                        "country",
                                        event.target.value
                                      );
                                    },
                                  }}
                                />
                              </Grid>
                            </>
                            <>
                              {/*County Description */}
                              <Grid item xs={12}>
                                <CustomTextField
                                  label="County Description"
                                  name="county_description"
                                  multiline={true}
                                  validation={{
                                    values,
                                    setFieldValue,
                                    touched,
                                    errors,
                                  }}
                                  otherProps={{
                                    multiline: true,
                                    rows: 3,
                                  }}
                                />
                              </Grid>
                            </>
                          </Stack>
                        </Grid>
                        {/* Profile Picture */}
                        <Grid item xs={12} md={6} pt={3}>
                          <UploadAndCropImage
                            croppedImage={croppedProfileImage}
                            setCroppedImage={setCroppedProfileImage}
                            selectedFile={selectedProfileFile}
                            setSelectedFile={setSelectedProfileFile}
                            cropperRef={cropperRef}
                            signUpFormRef={signUpFormRef}
                            name={"Profile Picture"}
                            fieldName={"profile_image"}
                          />
                          {touched?.profile_image &&
                            Boolean(errors?.profile_image) && (
                              <FormHelperText
                                sx={{
                                  color: "#d32f2f !important",
                                  m: "0 !important",
                                  fontSize: "12px !important",
                                }}
                              >
                                Please upload Profile Picture
                              </FormHelperText>
                            )}
                        </Grid>
                      </Stack>
                    </Grid>

                    {/* County Logo */}
                    <Grid item xs={12} md={6}>
                      <UploadAndCropImage
                        croppedImage={croppedLogoImage}
                        setCroppedImage={setCroppedLogoImage}
                        selectedFile={selectedLogoFile}
                        setSelectedFile={setSelectedLogoFile}
                        cropperRef={cropperRef}
                        signUpFormRef={signUpFormRef}
                        name={"Logo"}
                        fieldName={"logo_url"}
                      />
                      {touched?.logo_url && Boolean(errors?.logo_url) && (
                        <FormHelperText
                          sx={{
                            color: "#d32f2f !important",
                            m: "0 !important",
                            fontSize: "12px !important",
                          }}
                        >
                          Please upload County logo
                        </FormHelperText>
                      )}
                    </Grid>
                    {/* Stamp Logo */}
                    <Grid item xs={12} md={6}>
                      <UploadAndCropImage
                        croppedImage={croppedStampImage}
                        setCroppedImage={setCroppedStampImage}
                        selectedFile={selectedStampFile}
                        setSelectedFile={setSelectedStampFile}
                        cropperRef={cropperRef}
                        signUpFormRef={signUpFormRef}
                        name={"Stamp Logo"}
                        fieldName={"stamp_url"}
                      />
                      {touched?.stamp_url && Boolean(errors?.stamp_url) && (
                        <FormHelperText
                          sx={{
                            color: "#d32f2f !important",
                            m: "0 !important",
                            fontSize: "12px !important",
                          }}
                        >
                          Please upload Stamp logo
                        </FormHelperText>
                      )}
                    </Grid>
                  </>
                )}

                {/* Chart Data */}
                {chartDetails && (
                  <>
                    {plansDetails && (
                      <Grid xs={12} md={12} px={1} py={3}>
                        <Divider>
                          <span className="fw-600">Chart Data</span>
                        </Divider>
                      </Grid>
                    )}

                    <Grid xs={12} md={12} px={1} pb={2}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                        pt={2}
                      >
                        <b className="px-3">Is Statistics available?</b>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 2 }}
                          alignItems="center"
                        >
                          <div
                            className={`${isStatistics && "cursor-disable"}`}
                          >
                            <CustomizedCheckbox
                              disabled={isStatistics}
                              checked={isStatistics}
                              onChecked={(e) =>
                                onStatisticsChecked(e, 1, setFieldValue)
                              }
                            />
                            <span>Yes</span>
                          </div>
                          <div
                            className={`${!isStatistics && "cursor-disable"}`}
                          >
                            <CustomizedCheckbox
                              disabled={!isStatistics}
                              checked={!isStatistics}
                              onChecked={(e) => {
                                onStatisticsChecked(e, 0, setFieldValue);
                              }}
                            />
                            <span>No</span>
                          </div>
                        </Stack>
                      </Stack>
                    </Grid>

                    {/* Low Risk */}
                    <Grid item xs={4} sm={2} md={2} mt={2}>
                      <b className="mt-3">Low Risk</b>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label="Risk Number"
                        placeholder="Risk Number"
                        name={isStatistics ? "low_risk_number" : "lrn"}
                        type={"number"}
                        disabled={!isStatistics}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "low_risk_number",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label=" Risk Percentage"
                        placeholder=" Risk Percentage"
                        name={isStatistics ? "low_risk_percent" : "lrp"}
                        disabled={!isStatistics}
                        type={"number"}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="start">
                        //       <span>%</span>
                        //     </InputAdornment>
                        //   ),
                        // }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "low_risk_percent",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    {/* Medium Risk */}
                    <Grid item xs={4} sm={2} md={2} mt={2}>
                      <b className="mt-3">Medium Risk</b>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label=" Risk Number"
                        placeholder=" Risk Number"
                        name={isStatistics ? "medium_risk_number" : "mrn"}
                        type={"number"}
                        disabled={!isStatistics}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "medium_risk_number",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label=" Risk Percentage"
                        placeholder=" Risk Percentage"
                        name={isStatistics ? "medium_risk_percent" : "mrp"}
                        type={"number"}
                        disabled={!isStatistics}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "medium_risk_percent",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    {/* Medium High Risk */}
                    <Grid item xs={4} sm={2} md={2} mt={2}>
                      <b className="mt-3">Medium High Risk</b>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label=" Risk Number"
                        placeholder=" Risk Number"
                        name={isStatistics ? "medium_high_risk_number" : "mhrn"}
                        type={"number"}
                        disabled={!isStatistics}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "medium_high_risk_number",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label=" Risk Percentage"
                        placeholder=" Risk Percentage"
                        name={
                          isStatistics ? "medium_high_risk_percent" : "mhrp"
                        }
                        type={"number"}
                        disabled={!isStatistics}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "medium_high_risk_percent",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    {/* High Risk */}
                    <Grid item xs={4} sm={2} md={2} mt={2}>
                      <b className="mt-3">High Risk</b>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label=" Risk Number"
                        placeholder=" Risk Number"
                        name={isStatistics ? "high_risk_number" : "hrn"}
                        type={"number"}
                        disabled={!isStatistics}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "high_risk_number",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} pb={1}>
                      <CustomTextField
                        label=" Risk Percentage"
                        placeholder=" Risk Percentage"
                        name={isStatistics ? "high_risk_percent" : "hrp"}
                        type={"number"}
                        disabled={!isStatistics}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue(
                              "high_risk_percent",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    {chart_percentages !== 100 && isStatistics ? (
                      <p className="text-danger para mb-0 py-2 fw-500">
                        The sum of all risk percentages must be exactly 100%
                      </p>
                    ) : null}
                  </>
                )}

                {/* Plan details */}
                <>
                  {plansDetails && (
                    <>
                      {personalDetails && (
                        <Grid xs={12} md={12} px={1} py={3}>
                          <Divider>
                            <span className="fw-600">Subscription Plans</span>{" "}
                          </Divider>
                        </Grid>
                      )}

                      {/* Monthly Plan details */}
                      <>
                        <Grid xs={12} md={12} px={1} pb={2}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 10 }}
                            pt={2}
                          >
                            <b className="">Monthly Plan</b>
                            {/* <Stack
                              direction="row"
                              spacing={{ xs: 1, sm: 1, md: 2 }}
                              alignItems="center"
                            >
                              <div>
                                <CustomizedCheckbox
                                  checked={monthlyPlan}
                                  disabled={monthlyPlan}
                                  onChecked={(e) =>
                                    onMonthlyPlanChecked(e, 1, setFieldValue)
                                  }
                                />
                                <span>Enable</span>
                              </div>
                              <div>
                                <CustomizedCheckbox
                                  checked={!monthlyPlan}
                                  disabled={!monthlyPlan}
                                  onChecked={(e) => {
                                    onMonthlyPlanChecked(e, 0, setFieldValue);
                                  }}
                                />
                                <span>Disable</span>
                              </div>
                            </Stack> */}
                          </Stack>
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={1}>
                          <CustomTextField
                            label="Setup amount"
                            disabled={!monthlyPlan}
                            name={monthlyPlan ? "monthly_plan_price" : "mpp"}
                            type={"number"}
                            validation={
                              { values, setFieldValue, touched, errors }
                              // : {}
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span>$</span>
                                </InputAdornment>
                              ),
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue(
                                  "monthly_plan_price",
                                  event.target.value
                                );
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={1}>
                          <CustomTextField
                            label="Monthly Renewal amount"
                            disabled={!monthlyPlan}
                            name={monthlyPlan ? "monthly_renewal_price" : "mpp"}
                            type={"number"}
                            validation={
                              { values, setFieldValue, touched, errors }
                              // : {}
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span>$</span>
                                </InputAdornment>
                              ),
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue(
                                  "monthly_renewal_price",
                                  event.target.value
                                );
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={1}>
                          <CustomTextField
                            disabled={!monthlyPlan}
                            label="Free Trial"
                            name={monthlyPlan ? "monthly_free_trial" : "mft"}
                            type={"number"}
                            max={3}
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue(
                                  "monthly_free_trial",
                                  event.target.value
                                );
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                          {!(
                            touched?.monthly_free_trial &&
                            Boolean(errors?.monthly_free_trial)
                          ) && (
                            <FormHelperText
                              sx={{
                                color: "#00000075 !important",
                                m: "0 !important",
                                maxWidth: "100% !important",
                                textAlign: "end",
                              }}
                            >
                              (In days)
                            </FormHelperText>
                          )}
                        </Grid>

                        <Grid xs={12} md={12} px={1} pb={2}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 2 }}
                            pt={2}
                          >
                            <b className="">Need to take setup fees?</b>
                            <Stack
                              direction="row"
                              spacing={{ xs: 1, sm: 1, md: 2 }}
                              alignItems="center"
                            >
                              <div
                                className={`${
                                  !monthlyPlan && "cursor-disable"
                                }`}
                              >
                                <CustomizedCheckbox
                                  disabled={!monthlyPlan || monthlyFees}
                                  checked={monthlyFees}
                                  onChecked={(e) =>
                                    onMonthlyFessChecked(e, 1, setFieldValue)
                                  }
                                />
                                <span>Enable</span>
                              </div>
                              <div
                                className={`${
                                  !monthlyPlan && "cursor-disable"
                                }`}
                              >
                                <CustomizedCheckbox
                                  disabled={!monthlyPlan || !monthlyFees}
                                  checked={!monthlyFees}
                                  onChecked={(e) => {
                                    onMonthlyFessChecked(e, 0, setFieldValue);
                                  }}
                                />
                                <span>Disable</span>
                              </div>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={1}>
                          <CustomTextField
                            disabled={!monthlyFees || !monthlyPlan}
                            label="Setup Fees"
                            name={monthlyFees ? "setup_fees" : "sf"}
                            type={"number"}
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span>$</span>
                                </InputAdornment>
                              ),
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("setup_fees", event.target.value);
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>
                      </>
                      <Grid xs={12} md={12} px={1} py={3}>
                        <Divider />
                      </Grid>
                      {/*  Yearly plan details */}
                      <>
                        <Grid xs={12} md={12} px={1} pb={2}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 10 }}
                            pt={2}
                          >
                            <b className="">Yearly Plan</b>
                            {/* <Stack
                              direction="row"
                              spacing={{ xs: 1, sm: 1, md: 2 }}
                              alignItems="center"
                            >
                              <div>
                                <CustomizedCheckbox
                                  checked={yearlyPlan}
                                  disabled={yearlyPlan}
                                  onChecked={(e) =>
                                    onYearlyPlanChecked(e, 1, setFieldValue)
                                  }
                                />
                                <span>Enable</span>
                              </div>
                              <div>
                                <CustomizedCheckbox
                                  checked={!yearlyPlan}
                                  disabled={!yearlyPlan}
                                  onChecked={(e) =>
                                    onYearlyPlanChecked(e, 0, setFieldValue)
                                  }
                                />
                                <span>Disable</span>
                              </div>
                            </Stack> */}
                          </Stack>
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={1}>
                          <CustomTextField
                            label="Setup amount"
                            disabled={!yearlyPlan}
                            name={yearlyPlan ? "yearly_plan_price" : "ypp"}
                            type={"number"}
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span>$</span>
                                </InputAdornment>
                              ),
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue(
                                  "yearly_plan_price",
                                  event.target.value
                                );
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={1}>
                          <CustomTextField
                            label="Yearly Renewal amount"
                            disabled={!yearlyPlan}
                            name={yearlyPlan ? "yearly_renewal_price" : "ypp"}
                            type={"number"}
                            max={3}
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span>$</span>
                                </InputAdornment>
                              ),
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue(
                                  "yearly_renewal_price",
                                  event.target.value
                                );
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={1}>
                          <CustomTextField
                            disabled={!yearlyPlan}
                            label="Free Trial"
                            name={yearlyPlan ? "yearly_free_trial" : "yft"}
                            type={"number"}
                            max={3}
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue(
                                  "yearly_free_trial",
                                  event.target.value
                                );
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                          {!(
                            touched?.yearly_free_trial &&
                            Boolean(errors?.yearly_free_trial)
                          ) && (
                            <FormHelperText
                              sx={{
                                color: "#00000075 !important",
                                m: "0 !important",
                                maxWidth: "100% !important",
                                textAlign: "end",
                              }}
                            >
                              (In days)
                            </FormHelperText>
                          )}
                        </Grid>
                      </>
                      <Grid xs={12} md={12} px={1} py={3}>
                        <Divider />
                      </Grid>

                      <Grid xs={12} md={12} px={1} pb={2}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={{ xs: 1, sm: 2, md: 2 }}
                          pt={2}
                        >
                          <b className="">Need to take recording fees?</b>
                          <Stack
                            direction="row"
                            spacing={{ xs: 1, sm: 1, md: 2 }}
                            alignItems="center"
                          >
                            <div>
                              <CustomizedCheckbox
                                // disabled={!yearlyPlan || yearlyFees}
                                checked={recordingFees}
                                onChecked={(e) =>
                                  onRecordingFeesChecked(e, 1, setFieldValue)
                                }
                              />
                              <span>Enable</span>
                            </div>
                            <div>
                              <CustomizedCheckbox
                                // disabled={!yearlyPlan || !yearlyFees}
                                checked={!recordingFees}
                                onChecked={(e) => {
                                  onRecordingFeesChecked(e, 0, setFieldValue);
                                }}
                              />
                              <span>Disable</span>
                            </div>
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          disabled={!recordingFees}
                          label="Recording Fees"
                          name={recordingFees ? "recording_fees" : "rf"}
                          type={"number"}
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span>$</span>
                              </InputAdornment>
                            ),
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "recording_fees",
                                event.target.value
                              );
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </>

                <Grid xs={12} md={12} px={1} pb={1}>
                  <div className="text-center createAcBtn mt-5">
                    <Button
                      type="submit"
                      className="next_button"
                      variant="contained"
                      onClick={(e) =>
                        handleSubmit(e, validateForm, setFieldTouched, values)
                      }
                    >
                      {isEdit ? `Update` : ` Submit`}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {/* edit provider alert */}
      {getObjLength(editedPersonalDetailsData) ? (
        <AlertDialog
          type={editedPersonalDetailsData?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          mesage={
            editedPersonalDetailsData?.message ||
            editedPersonalDetailsData?.data?.message
          }
          onCloseFun={closeAlertPopup}
        />
      ) : null}
      <Loader open={countyRecordersLoader} />
    </Box>
  );
};

export default CreateCountyRecorderForm;
