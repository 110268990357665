import React from "react";
import { useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { ROLES } from "../../helpers/constants";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";

const validationSchema = Yup.object().shape({
  first_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string("Please enter your Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
  company_name: Yup.string("Please enter your Company name.")
    .trim("The company name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Company name is required"),
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  old_password: Yup.string("Please enter Password.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
    )
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .required("Old Password is required")
        .test(
          "no-spaces",
          "Password cannot contain any spaces",
          (value) => !/\s/.test(value)
        ),
    }),
  password: Yup.string("Please enter Password.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
    )
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    ),
  password_confirmation: Yup.string("Please enter Confirm Password.").when(
    "password",
    {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf([Yup.ref("password")], "Both password need to be the same")
        .required("Confirm Password is required")
        .test(
          "no-spaces",
          "Password cannot contain any spaces",
          (value) => !/\s/.test(value)
        ),
    }
  ),
});

export const AdminProfileForm = ({
  userProfileDetails,
  edit,
  setEdit,
  formRef,
}) => {
  const dispatch = useDispatch();

  // states
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // store data
  const { profileLoader } = useSelector((state) => state?.userProfile);

  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (values, validation) => {
    dispatch(
      userProfileAction.editUserProfileData({
        url: "admin/update-profile",
        data: values,
        token: true,
      })
    );
    validation.resetForm();
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          first_name: userProfileDetails?.data?.first_name || "",
          last_name: userProfileDetails?.data?.last_name || "",
          email: userProfileDetails?.data?.email || "",
          company_name: userProfileDetails?.data?.company_name || "",
          password: "",
          password_confirmation: "",
          old_password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        {({ values, setFieldValue, touched, errors, resetForm }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield text-dark"
                    placeholder="First Name"
                    label="First Name"
                    name="first_name"
                    value={values?.first_name}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("first_name", event.target.value);
                    }}
                    helperText={touched.first_name && errors.first_name}
                    error={touched.first_name && Boolean(errors.first_name)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield"
                    placeholder="Last Name"
                    label="Last Name"
                    variant="outlined"
                    name="last_name"
                    value={values?.last_name}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("last_name", event.target.value);
                    }}
                    helperText={touched.last_name && errors.last_name}
                    error={touched.last_name && Boolean(errors.last_name)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    name="company_name"
                    label="Company Name"
                    placeholder="Company Name"
                    value={values?.company_name}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("company_name", event.target.value);
                    }}
                    helperText={touched.company_name && errors.company_name}
                    error={touched.company_name && Boolean(errors.company_name)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={true}
                    className="textfield"
                    variant="outlined"
                    name="email"
                    label="Email"
                    placeholder="Email Address"
                    value={values?.email}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("email", event.target.value);
                    }}
                    helperText={touched.email && errors.email}
                    error={touched.email && Boolean(errors.email)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OutlinedInput
                    disabled={!edit}
                    className="textfield"
                    label="Old Password"
                    type={showOldPassword ? "text" : "password"}
                    placeholder="Old Password"
                    name="old_password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!edit}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword(setShowOldPassword)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={edit ? values?.old_password : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("old_password", event.target.value?.trim());
                    }}
                    helperText={touched.old_password && errors.old_password}
                    error={touched.old_password && Boolean(errors.old_password)}
                  />
                  {touched.old_password && Boolean(errors.old_password) && (
                    <FormHelperText sx={{ color: "rgb(211, 47, 47)" }}>
                      {touched.old_password && errors.old_password}
                    </FormHelperText>
                  )}
                  {/* <TextField
                        disabled={!edit}
                        className="textfield"
                        variant="outlined"
                        placeholder="Old Password"
                        name="old_password"
                        inputProps={{
                          autoComplete: "new-password",
                        }}
                        value={edit ? values?.old_password : null}
                        fullWidth
                        onChange={(event) => {
                          setFieldValue("old_password", event.target.value);
                        }}
                        helperText={touched.old_password && errors.old_password}
                        error={
                          touched.old_password && Boolean(errors.old_password)
                        }
                      /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OutlinedInput
                    disabled={!edit}
                    className="textfield"
                    label="Password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!edit}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword(setShowNewPassword)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={edit ? values?.password : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("password", event.target.value?.trim());
                    }}
                    helperText={touched.password && errors.password}
                    error={touched.password && Boolean(errors.password)}
                  />
                  {touched.password && Boolean(errors.password) && (
                    <FormHelperText sx={{ color: "rgb(211, 47, 47)" }}>
                      {touched.password && errors.password}
                    </FormHelperText>
                  )}
                  {/* <TextField
                        disabled={!edit}
                        className="textfield"
                        variant="outlined"
                        placeholder="Password"
                        type="password"
                        name="password"
                        // autoComplete="off"
                        inputProps={{
                          autoComplete: "new-password",
                        }}
                        value={edit ? values?.password : null}
                        fullWidth
                        onChange={(event) => {
                          setFieldValue("password", event.target.value);
                        }}
                        helperText={touched.password && errors.password}
                        error={touched.password && Boolean(errors.password)}
                      /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OutlinedInput
                    disabled={!edit}
                    className="textfield"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!edit}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword(setShowConfirmPassword)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={edit ? values?.password_confirmation : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue(
                        "password_confirmation",
                        event.target.value?.trim()
                      );
                    }}
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    error={
                      touched.password_confirmation &&
                      Boolean(errors.password_confirmation)
                    }
                  />
                  {touched.password_confirmation &&
                    Boolean(errors.password_confirmation) && (
                      <FormHelperText sx={{ color: "rgb(211, 47, 47)" }}>
                        {touched.password_confirmation &&
                          errors.password_confirmation}
                      </FormHelperText>
                    )}
                  {/* <TextField
                        disabled={!edit}
                        className="textfield"
                        variant="outlined"
                        placeholder="Confirm Password"
                        name="password_confirmation"
                        type="password"
                        inputProps={{
                          autoComplete: "new-password",
                        }}
                        value={edit ? values?.password_confirmation : null}
                        fullWidth
                        onChange={(event) => {
                          setFieldValue(
                            "password_confirmation",
                            event.target.value
                          );
                        }}
                        helperText={
                          touched.password_confirmation &&
                          errors.password_confirmation
                        }
                        error={
                          touched.password_confirmation &&
                          Boolean(errors.password_confirmation)
                        }
                      /> */}
                </Grid>
                {edit && (
                  <Grid xs={12} md={12} px={1} pb={1}>
                    <div className="text-center createAcBtn mt-5">
                      <Button
                        onClick={() => {
                          setShowOldPassword(false);
                          setShowNewPassword(false);
                          setShowConfirmPassword(false);
                          resetForm();
                          setEdit(false);
                        }}
                        className="popup_Btn other_popup_btn mx-2"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="popup_Btn success_popup_btn mx-2"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Loader open={profileLoader} />
    </>
  );
};
