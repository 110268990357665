import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { Loader } from "../../common/loader";
import { useDispatch, useSelector } from "react-redux";
import { generateProfessionalDropdownOptions, getObjLength } from "../../helpers/utils";
import { useEffect } from "react";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";
import { useCallback } from "react";
import { PROFESSIONAL_TYPE } from "../../helpers/constants";
import CloseIcon from "@mui/icons-material/Close";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { propertyListAction } from "../../../redux/slices/admin/admin-dashboard/propertyListSlice";

const EditPropertyModal = ({
  open,
  handleClose,
  alertMsg,
  setAlertMsg,
  getPropertyListData,
  handleBindProfessional,
  selectedItem,
}) => {
  const dispatch = useDispatch();

  const { bindProfessionalDetails, bindProfessionalLoader } = useSelector(
    (state) => state?.propertyLists
  );
  const { professionalData, professionalListLoader } = useSelector(
    (state) => state?.professionals
  );

  const getProfessionalList = useCallback(() => {
    console.log("===selectedItem===", selectedItem);
    dispatch(
      professionalAction.fetchProfessionalListData({
        url: `professional?zip=${selectedItem?.zip}&default_professional_id=${selectedItem?.owner[0]?.default_professional_id}`,
        data: {},
        token: true,
      })
    );
  }, [dispatch, selectedItem]);
  
  // const getPropertyListData = () => {
  //   setIsRenewPlanModalOpen(false);
  //   cancelLennarSubscriptionModalClose();
  //   // console.log("===kkk propertyPayload?.offset=> ", propertyPayload?.offset);
  //   // let apiURL = "admin/property-list";
  //   // if (`${userId}`?.length > 0 && !isNaN(userId)) {
  //   //   apiURL = "admin/property-list/" + userId;
  //   // }
  //   dispatch(
  //     propertyListAction.getPropertyListData({
  //       url: apiURL,
  //     })
  //   );
  // };

  useEffect(() => {
    if (open) {
      getProfessionalList();
    }
  }, [dispatch, getProfessionalList, open]);

  return (
    <>
      <Dialog
        open={open}
        width={alertMsg && "sm"}
        fullWidth
        maxWidth={!alertMsg ? "lg": "sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            overflow: "visible",
          },
        }}
      >
        { !alertMsg &&<>
        <DialogTitle id="alert-dialog-title">
          Bind Professional
          <Button
            onClick={handleClose}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <>
          <DialogContent dividers sx={{ overflow: "visible" }}>
            <Box className="bg-white" p={1}>
              <Box className="d-flex justify-content-center bg-white">
                <Formik
                  enableReinitialize
                  validateOnChange
                  initialValues={{
                    lender_id:
                      selectedItem &&
                      selectedItem?.assign_professional?.length > 0
                        ? selectedItem?.assign_professional?.find(
                            (prof) => prof?.type === PROFESSIONAL_TYPE?.lender
                          )?.id
                        : "",
                    broker_id:
                      selectedItem?.assign_professional?.length > 0
                        ? selectedItem?.assign_professional?.find(
                            (prof) => prof?.type === PROFESSIONAL_TYPE?.broker
                          )?.id
                        : "",
                    other_id:
                      selectedItem?.assign_professional?.length > 0
                        ? selectedItem?.assign_professional?.find(
                            (prof) => prof?.type === PROFESSIONAL_TYPE?.other
                          )?.id
                        : "",
                  }}
                  validationSchema={Yup.object().shape({
                    lender_id: Yup.string(),
                    broker_id: Yup.string(),
                    other_id: Yup.string(),
                  })}
                  onSubmit={handleBindProfessional}
                >
                  {({
                    values,
                    setFieldValue,
                    touched,
                    errors,
                    resetForm,
                    initialValues,
                  }) => {
                    return (
                      <Form className="w-100">
                        {/* {!bindProfessionalDetails?.success && ( */}
                        <Grid container>
                          <Grid item xs={12} sm={4} md={4} lg={4} p={1}>
                            <FormControl
                              fullWidth
                              className="sign-textField"
                              error={touched.type && Boolean(errors.type)}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                loading={professionalListLoader}
                                loadingText="Loading..."
                                disabled={
                                  generateProfessionalDropdownOptions(
                                    professionalData?.data,
                                    PROFESSIONAL_TYPE?.lender
                                  )?.find(
                                    (item) => item?.value === values?.lender_id
                                  )?.is_editable ||
                                  (selectedItem?.user?.professional !== null &&
                                    selectedItem?.user?.professional?.user_type?.includes(
                                      PROFESSIONAL_TYPE?.lender
                                    ))
                                }
                                defaultValue={generateProfessionalDropdownOptions(
                                  professionalData?.data,
                                  PROFESSIONAL_TYPE?.lender
                                )?.find(
                                  (item) => item?.value === values?.lender_id
                                )}
                                options={generateProfessionalDropdownOptions(
                                  professionalData?.data,
                                  PROFESSIONAL_TYPE?.lender
                                )}
                                onChange={(_, selectedValue) => {
                                  if (selectedValue !== null) {
                                    setFieldValue(
                                      "lender_id",
                                      selectedValue?.value
                                    );
                                  } else {
                                    setFieldValue("lender_id", "");
                                  }
                                }}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={option?.value}
                                  >
                                    {option.label}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${PROFESSIONAL_TYPE?.lender}`}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} p={1}>
                            <FormControl
                              fullWidth
                              className="sign-textField "
                              error={touched.type && Boolean(errors.type)}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                loading={professionalListLoader}
                                loadingText="Loading..."
                                disabled={
                                  generateProfessionalDropdownOptions(
                                    professionalData?.data,
                                    PROFESSIONAL_TYPE?.broker
                                  )?.find(
                                    (item) => item?.value === values?.broker_id
                                  )?.is_editable ||
                                  (selectedItem?.user?.professional !== null &&
                                    selectedItem?.user?.professional?.user_type?.includes(
                                      PROFESSIONAL_TYPE?.broker
                                    ))
                                }
                                defaultValue={generateProfessionalDropdownOptions(
                                  professionalData?.data,
                                  PROFESSIONAL_TYPE?.broker
                                )?.find(
                                  (item) => item?.value === values?.broker_id
                                )}
                                options={generateProfessionalDropdownOptions(
                                  professionalData?.data,
                                  PROFESSIONAL_TYPE?.broker
                                )}
                                onChange={(_, selectedValue) => {
                                  if (selectedValue !== null) {
                                    setFieldValue(
                                      "broker_id",
                                      selectedValue?.value
                                    );
                                  } else {
                                    setFieldValue("broker_id", "");
                                  }
                                }}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={option?.value}
                                  >
                                    {option.label}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${PROFESSIONAL_TYPE?.broker}`}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} p={1}>
                            <FormControl
                              fullWidth
                              className="sign-textField "
                              error={touched.type && Boolean(errors.type)}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                loading={professionalListLoader}
                                loadingText="Loading..."
                                disabled={
                                  generateProfessionalDropdownOptions(
                                    professionalData?.data,
                                    PROFESSIONAL_TYPE?.other
                                  )?.find(
                                    (item) => item?.value === values?.other_id
                                  )?.is_editable ||
                                  (selectedItem?.user?.professional !== null &&
                                    selectedItem?.user?.professional?.user_type?.includes(
                                      PROFESSIONAL_TYPE?.other
                                    ))
                                }
                                defaultValue={generateProfessionalDropdownOptions(
                                  professionalData?.data,
                                  PROFESSIONAL_TYPE?.other
                                )?.find(
                                  (item) => item?.value === values?.other_id
                                )}
                                options={generateProfessionalDropdownOptions(
                                  professionalData?.data,
                                  PROFESSIONAL_TYPE?.other
                                )}
                                onChange={(_, selectedValue) => {
                                  if (selectedValue !== null) {
                                    setFieldValue(
                                      "other_id",
                                      selectedValue?.value
                                    );
                                  } else {
                                    setFieldValue("other_id", "");
                                  }
                                }}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={option?.value}
                                  >
                                    {option.label}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${PROFESSIONAL_TYPE?.other}`}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* )}
                       
                        <Typography
                          className={`${
                            bindProfessionalDetails?.success
                              ? "text-success"
                              : "text-danger"
                          } text-center w-100`}
                        >
                          {bindProfessionalDetails?.success
                            ? bindProfessionalDetails?.message
                            : bindProfessionalDetails?.data?.message}
                        </Typography> */}

                        <DialogActions>
                          <Stack direction="row" spacing={2}>
                            {bindProfessionalDetails?.success ? (
                              <Button
                                className="popup_Btn success_popup_btn"
                                onClick={() => {
                                  handleClose();
                                }}
                              >
                                Close
                              </Button>
                            ) : (
                              <>
                                <Button
                                  className="popup_Btn other_popup_btn mx-2"
                                  onClick={() => {
                                    handleClose();
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  className="popup_Btn success_popup_btn"
                                  disabled={
                                    JSON.stringify(initialValues) ===
                                    JSON.stringify(values)
                                  }
                                >
                                  Update
                                </Button>
                              </>
                            )}
                          </Stack>
                        </DialogActions>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </DialogContent>
        </>
      </>}

        <Loader open={bindProfessionalLoader || professionalListLoader} />

        {alertMsg && getObjLength(bindProfessionalDetails) && (
        <>
          {getObjLength(bindProfessionalDetails) &&
          bindProfessionalDetails?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                handleClose();
                getPropertyListData();
              }}
              message={bindProfessionalDetails?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                // dispatch(addLeadsAction.clearData({}));
              }}
              message={bindProfessionalDetails?.data?.message}
              footer={false}
            />
          )}
        </>
      )}
      </Dialog>
    </>
  );
};

export default EditPropertyModal;
