/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Paper, Typography } from "@mui/material";

import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import { PER_PAGE } from "../../helpers/constants";
import AddServiceProvider from "./AddServiceProvider";
import ServiceProvidersTable from "./ServiceProvidersTable";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { seviceProvidersAction } from "../../../redux/slices/admin/serviceProviders/seviceProvidersSlice";

const ServiceProviders = () => {
  const [spList, setSpListData] = useState([]);
  const [isAddServicerPageOpen, setIsAddServicerPageOpen] = useState(false);
  const [payload, setPayload] = useState({});
  const dispatch = useDispatch();
  const { seviceProvidersList, seviceProvidersLoader } = useSelector(
    (state) => state.seviceProviders
  );

  const queryString = window.location.pathname;
  const providerID = queryString.split("/")?.[2];
  console.log("Provider Id ==>", providerID);
  const columns = [
    { key: 1, id: "no", disablePadding: true, label: "No.", minWidth: 10 },
    {
      key: 2,
      id: "logo",
      disablePadding: true,
      label: "Logo",
      minWidth: 10,
    },
    {
      key: 3,
      id: "name",
      disablePadding: true,
      label: "Provider's Name",
      minWidth: 105,
    },
    {
      key: 4,
      id: "email",
      disablePadding: true,
      label: "Email Id",
      minWidth: 135,
    },
    {
      key: 5,
      id: "phone",
      disablePadding: true,
      label: "Phone Number",
      minWidth: 155,
    },
    {
      key: 6,
      id: "service_provider_type",
      disablePadding: true,
      label: "Provider's Type",
      minWidth: 40,
    },
    {
      key: 7,
      id: "company",
      disablePadding: true,
      label: "Company Name",
      minWidth: 90,
    },
    {
      key: 8,
      id: "is_active",
      disablePadding: true,
      label: "Status",
      minWidth: 20,
    },
    {
      key: 9,
      id: "created_at",
      disablePadding: true,
      label: "Created",
      minWidth: 115,
      sorting: true,
    },
    {
      key: 10,
      id: "updated_at",
      disablePadding: true,
      label: "Modified",
      minWidth: 115,
      sorting: true,
    },
    {
      key: 11,
      id: "action",
      disablePadding: true,
      label: "Actions",
      minWidth: 20,
    },
  ];

  const getSeviceProvidersData = () => {
    let url = "admin/service-provider/get-service-providers";
    if (providerID) {
      url = "admin/service-provider/get-service-providers/" + providerID;
    }
    dispatch(
      seviceProvidersAction.getSeviceProvidersData({
        url: url,
        data: {
          type: payload?.type,
          value: payload?.value,
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          order_by_column: payload?.order_by_column || "updated_at",
          sorting_order: payload?.sorting_order || "desc",
        },
        token: true,
      })
    );
  };

  const getProviderActivate = (data) => {
    if (data?.id) {
      dispatch(
        seviceProvidersAction.getSeviceProvidersActivate({
          url: "admin/service-provider/activate/" + data?.id,
          token: true,
        })
      );
    }
  };

  const getProviderTypes = (data) => {
    dispatch(
      serviceProviderSignupAction.getProfessionals({
        url: "service-provider/types",
        data: {},
      })
    );
  };

  // add servicer handler
  const handleAddServicer = () => {
    setIsAddServicerPageOpen(true);
  };

  useEffect(() => {
    getSeviceProvidersData();
    getProviderTypes();
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
  }, []);

  useEffect(() => {
    if (getObjLength(seviceProvidersList)) {
      if (seviceProvidersList?.success) {
        setSpListData(seviceProvidersList?.data);
      }
    }
  }, [seviceProvidersList]);

  console.log("seviceProvidersList==>", seviceProvidersList);

  return (
    <>
      {isAddServicerPageOpen ? (
        <AddServiceProvider
          goBack={() => {
            setIsAddServicerPageOpen(false);
            dispatch(serviceProviderSignupAction.resetSPState());
            getSeviceProvidersData();
          }}
        />
      ) : (
        <Box>
          <Paper
            elevation={0}
            className=" d-flex justify-content-between"
            sx={{ background: "none" }}
          >
            <Box className="" sx={{ mb: 1 }}>
              <Typography variant="h6" className="card-title fw-600">
                Service Providers
              </Typography>
            </Box>
            <Box className="">
              <Button
                variant="contained"
                className="success-btn d-none d-sm-block"
                style={{
                  padding: "5px 30px",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  handleAddServicer();
                }}
              >
                Add Service Provider
              </Button>
              <Button
                className="success-btn d-flex d-sm-none"
                variant="outlined"
                onClick={() => {
                  handleAddServicer();
                }}
                startIcon={<AddOutlinedIcon />}
                sx={{ color: "#FFF" }}
              >
                Add
              </Button>
            </Box>
          </Paper>
          <Box>
            <ServiceProvidersTable
              providersCount={seviceProvidersList?.count}
              columns={columns}
              rowsData={spList}
              getProviderActivate={getProviderActivate}
              providerID={providerID}
              latestData={seviceProvidersList}
            />
          </Box>
        </Box>
      )}

      <Loader open={seviceProvidersLoader} />
    </>
  );
};

export default ServiceProviders;
