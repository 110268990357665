import React from "react";

import { Col, Row } from "react-bootstrap";

import LazyIframe from "./LazyIframe";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../styles/realStories.scss";

const EquityProtectAssetsVideos = () => {
  return (
    <>
      {/* <Row className="my-2">
        <Col>
          <div className=" text-center">
            <div className="mb-5">
              <p className="notify_header">Educational</p>
              <h3 className="sub_header">Recent Assets on title fraud</h3>
            </div>
          </div>
        </Col>
      </Row> */}
      <Row
        style={{ margin: "0 auto" }}
        className="d-flex justify-content-center align-items-center p-4"
      >
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2">
          <div className="realStoryWrapper">
            <LazyIframe
              src="https://www.youtube.com/embed/uZnK4lq3SW4"
              id={"uZnK4lq3SW4"}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2 ">
          <div className="realStoryWrapper">
            <LazyIframe
              src="https://www.youtube.com/embed/q75Tsqzww4I"
              id="q75Tsqzww4I"
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2">
          <div className="realStoryWrapper">
            <LazyIframe
              src="https://www.youtube.com/embed/uuslWbcjIlY"
              id={"uuslWbcjIlY"}
            />
          </div>
        </Col>
        {/* </Row>
      <Row
        style={{ margin: "0 auto" }}
        className="d-flex justify-content-center align-items-center p-4"
      > */}
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2 ">
          <div className="realStoryWrapper">
            <LazyIframe
              src="https://www.youtube.com/embed/16zkwkQXMso"
              id="16zkwkQXMso"
            />
          </div>
        </Col>
        {/* </Row>
      <Row
        style={{ margin: "0 auto" }}
        className="d-flex justify-content-center align-items-center p-4"
      > */}
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2">
          <div className="realStoryWrapper">
            <LazyIframe
              src="https://www.youtube.com/embed/WKkZdtG6CCE"
              id={"WKkZdtG6CCE"}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2 ">
          <div className="realStoryWrapper">
            <LazyIframe
              src="https://www.youtube.com/embed/DqyzAftdzyw"
              id="DqyzAftdzyw"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default React.memo(EquityProtectAssetsVideos);
