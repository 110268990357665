import { useEffect } from "react";
import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import TableContainer from "@mui/material/TableContainer";
import LockResetIcon from "@mui/icons-material/LockReset";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import CallToActionOutlinedIcon from "@mui/icons-material/CallToActionOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MobileFriendlyOutlinedIcon from "@mui/icons-material/MobileFriendlyOutlined";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  Button,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import EditName from "./EditName";
import EditEmail from "./EditEmail";
import DialogBox from "../../common/DialogBox";
import EditPhoneNumber from "./EditPhoneNumber";
import { ROLES } from "../../helpers/constants";
import ManageCreditCard from "./ManageCreditCard";
import AlertDialog from "../../common/AlertDialog";
import VerifySecurityPIN from "./VerifySecurityPIN";
import SwitchToProfessional from "./SwitchToProfessional";
import ViewIconSVG from "../../common/customSVGs/ViewIconSVG";
import LockIconSVG from "../../common/customSVGs/LockIconSVG";
import AddPropertyPopup from "../../userProfile/add-property";
import SwitchToServiceProvider from "./SwitchToServiceProvider";
import AddCardForm from "../../userProfile/Dashboard/addCardForm";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import {
  decryptPayload,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
  readMoreSentance,
} from "../../helpers/utils";

import "./propertyUsers.scss";
import { Loader } from "../../common/loader";
import DrawerFromBottom from "../../common/DrawerFromBottom";

function Row({
  row,
  open,
  handleMenuClick,
  page,
  index,
  handleViewEditUser,
  handleCollapsibleRow,
  isCollapsible = false,
  offsetNum = null,
}) {
  // store data
  const { historyNotesData } = useSelector((state) => state?.propertyUsers);
  const county_details = row?.county_details;
  const provider = row?.provider;

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>

        <TableCell align="left" className="text-capitalize">
          {row.first_name} {row.last_name}
        </TableCell>
        <TableCell align="left" sx={{ wordBreak: "break-all" }}>
          {row?.email}
        </TableCell>
        <TableCell align="left">
          {/* {row.phone_number} */}
          {getFormatedPhoneNumber(row?.phone_number) || row?.phone_number}
        </TableCell>
        <TableCell align="left" className="text-capitalize">
          {row?.type === "lennar"
            ? row?.users_type?.[0] + ` (${row.type})` || "-"
            : row?.users_type?.[0] || "-"}
        </TableCell>
        <TableCell align="left">
          {row?.properties_count
            ? row?.properties_count === 1
              ? `${row?.properties_count} Property`
              : `${row?.properties_count} Properties`
            : "-"}
        </TableCell>
        <TableCell align="left" width={120}>
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left" width={120}>
          {row?.created_at ? getLocalFormatedDate(row?.updated_at) : "-"}
        </TableCell>
        {/* <TableCell align="left">
          {row?.notes_count
            ? row?.notes_count === 1
              ? `${row?.notes_count} note`
              : `${row?.notes_count} notes`
            : "-"}
        </TableCell> */}
        {/* <TableCell align="left">
          {row?.referral_user ? row?.referral_user?.email : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.default_professional_id?.default_professional_promocode
            ? row?.default_professional_id?.default_professional_promocode
            : row?.userPromocode?.promocode_name
            ? row?.userPromocode?.promocode_name
            : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.json_last_step ? row?.json_last_step?.step_key : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.created_by === 1 ? (
            "Super Admin"
          ) : row?.created_by && row?.created_by !== row?.user_id ? (
            <Tooltip
              title="Click here to see user's details."
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Link
                className="text-decoration-none "
                to={"/admins/" + row?.created_by}
              >
                {row?.created_by}
              </Link>
            </Tooltip>
          ) : row?.user_id === row?.created_by ? (
            <>
              {row.first_name} {row.last_name}
            </>
          ) : (
            "-"
          )}
        </TableCell> */}

        <TableCell sx={{ width: 180 }} align="left">
          <Stack direction="row" spacing={1}>
            {!decryptPayload(localStorage.getItem("roles"))?.includes(
              ROLES.provider
            ) &&
              !decryptPayload(localStorage.getItem("roles"))?.includes(
                ROLES.countyRecorder
              ) && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuClick(e, row);
                  }}
                  size="small"
                  className="settings-button"
                  aria-controls={open ? "settings-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <SettingsIconSVG />
                </IconButton>
              )}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight:
                  historyNotesData?.data?.notes?.[0]?.note?.length > 140
                    ? "200px"
                    : historyNotesData?.data?.properties?.length > 4
                    ? "150px"
                    : "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  User Status
                  {row?.status !== "active" && (
                    <Tooltip
                      title={"Registration Process Incomplete"}
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                      sx={{ cursor: "pointer" }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          marginLeft: "3px",
                          height: "0.9rem",
                          width: "0.9rem",
                        }}
                      />
                    </Tooltip>
                  )}
                </Typography>
                <Typography className="content-text m-0" component="div">
                  {row?.status === "active" ? (
                    <span className="text-success text-capitalize">
                      {row?.status}
                    </span>
                  ) : (
                    <span className="text-danger text-capitalize">
                      {row?.status}
                    </span>
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight:
                  historyNotesData?.data?.notes?.[0]?.note?.length > 140
                    ? "200px"
                    : historyNotesData?.data?.properties?.length > 4
                    ? "150px"
                    : "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  HISTORY
                </Typography>
                {historyNotesData?.success ? (
                  getObjLength(historyNotesData?.data?.activities) ? (
                    <>
                      <Typography
                        className="content-text mw-380"
                        component="div"
                      >
                        {readMoreSentance(
                          historyNotesData?.data?.activities[
                            Object.keys(historyNotesData?.data?.activities)?.[0]
                          ]?.[0]?.message
                        )}
                      </Typography>
                      <Typography
                        className="content-text mw-380"
                        component="div"
                      >
                        <>
                          {readMoreSentance(
                            historyNotesData?.data?.activities[
                              Object.keys(
                                historyNotesData?.data?.activities
                              )?.[0]
                            ]?.[1]?.message
                          )}
                        </>
                      </Typography>

                      {(historyNotesData?.data?.activities[
                        Object.keys(historyNotesData?.data?.activities)?.[0]
                      ]?.[1]?.message?.length >= 50 ||
                        historyNotesData?.data?.activities[
                          Object.keys(historyNotesData?.data?.activities)
                        ]?.length > 1 ||
                        historyNotesData?.data?.activities[
                          Object.keys(historyNotesData?.data?.activities)?.[0]
                        ]?.[0]?.length > 2) && (
                        <Typography
                          className="content-text cursor-pointer"
                          component="div"
                          onClick={() => handleViewEditUser(row)}
                        >
                          more...
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      className="content-text text-left"
                      component="div"
                      variant="h6"
                    >
                      No Data Found.
                    </Typography>
                  )
                ) : (
                  <Typography
                    classname="text-center content-text"
                    sx={{ color: "#dc3545 !important" }}
                  >
                    {historyNotesData?.data?.message}
                  </Typography>
                )}

                {/* <Box>
                  <Button
                    variant="contained"
                    className="view-edit-user-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      handleViewEditUser(row);
                    }}
                  >
                    View or edit user
                  </Button>
                </Box> */}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight:
                  historyNotesData?.data?.notes?.[0]?.note?.length > 140
                    ? "200px"
                    : historyNotesData?.data?.properties?.length > 4
                    ? "150px"
                    : "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  NOTES
                </Typography>
                {historyNotesData?.success ? (
                  historyNotesData?.data?.notes?.length > 0 ? (
                    <>
                      <Typography
                        className="content-text content-black-text mw-380"
                        component="div"
                      >
                        {historyNotesData?.data?.notes?.[0]?.note}
                      </Typography>

                      {historyNotesData?.data?.notes?.length > 1 && (
                        <Typography
                          className="content-text cursor-pointer"
                          component="div"
                          onClick={() => handleViewEditUser(row)}
                        >
                          more...
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      className="content-text text-left"
                      component="div"
                      variant="h6"
                    >
                      No Data Found.
                    </Typography>
                  )
                ) : (
                  <Typography
                    classname="text-center content-text"
                    sx={{ color: "#dc3545 !important" }}
                  >
                    {historyNotesData?.data?.message}
                  </Typography>
                )}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={
            getObjLength(county_details) || getObjLength(provider) ? 2 : 4
          }
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight:
                  historyNotesData?.data?.notes?.[0]?.note?.length > 140
                    ? "200px"
                    : historyNotesData?.data?.properties?.length > 4
                    ? "150px"
                    : "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                {historyNotesData?.success ? (
                  historyNotesData?.data?.properties?.length > 0 ? (
                    <>
                      <Typography className="content-text" component="div">
                        {historyNotesData?.data?.properties?.[0]?.address}
                        <br />
                        {historyNotesData?.data?.properties?.[1]?.address}
                        <br />
                        {historyNotesData?.data?.properties?.[2]?.address}
                        <br />
                        {/* {historyNotesData?.data?.properties?.[3]?.address}
                        <br />
                        {historyNotesData?.data?.properties?.[4]?.address}
                        <br /> */}
                      </Typography>

                      {historyNotesData?.data?.properties?.length > 3 && (
                        <Typography
                          className="content-text cursor-pointer"
                          component="div"
                          onClick={() => handleViewEditUser(row, true)}
                        >
                          {`+ ${
                            historyNotesData?.data?.properties_count - 3
                          } more`}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      className="content-text pt-3 text-left"
                      component="div"
                      variant="h6"
                    >
                      No Properties Found.
                    </Typography>
                  )
                ) : (
                  <Typography
                    classname="text-center content-text"
                    sx={{ color: "#dc3545 !important" }}
                  >
                    {historyNotesData?.data?.message}
                  </Typography>
                )}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        {getObjLength(county_details) && (
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight:
                    historyNotesData?.data?.notes?.[0]?.note?.length > 140
                      ? "200px"
                      : historyNotesData?.data?.properties?.length > 4
                      ? "150px"
                      : "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    County
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    <span className="text-capitalize">
                      {county_details?.county_name}
                    </span>
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        )}
        {getObjLength(provider) && (
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight:
                    historyNotesData?.data?.notes?.[0]?.note?.length > 140
                      ? "200px"
                      : historyNotesData?.data?.properties?.length > 4
                      ? "150px"
                      : "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Provider
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    <span className="text-capitalize">
                      {provider?.domain || provider?.custom_domain}
                    </span>
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Last Step
                </Typography>
                <Typography className="content-text m-0" component="div">
                  {row?.json_last_step ? row?.json_last_step?.step_key : "-"}
                </Typography>

                <Box>
                  <Button
                    variant="contained"
                    className="view-edit-user-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      handleViewEditUser(row);
                      if (getObjLength(offsetNum)) {
                        localStorage.setItem("offset", offsetNum);
                      }
                    }}
                  >
                    View or edit user
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={row?.users_type?.[0] === ROLES.serviceProvider ? 1 : 2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Created By
                </Typography>
                <Typography
                  className="content-text m-0 text-capitalize"
                  component="div"
                >
                  {row?.created_by === 1 ? (
                    "Super Admin"
                  ) : row?.created_by && row?.created_by !== row?.user_id ? (
                    decryptPayload(localStorage.getItem("roles"))?.includes(
                      ROLES.provider
                    ) ? (
                      row?.created_by
                    ) : (
                      <Tooltip
                        title="Click here to see user's details."
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <Link
                          className="text-decoration-none "
                          to={
                            getObjLength(row?.provider)
                              ? "/admin-providers/" + row?.provider?.id
                              : "/admins/" + row?.created_by
                            // : "/admin-professionals/" + row?.created_by
                          }
                        >
                          {row?.created_by}
                        </Link>
                      </Tooltip>
                    )
                  ) : row?.user_id === row?.created_by ? (
                    <>
                      {row.first_name} {row.last_name}
                    </>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        {row?.users_type?.[0] === ROLES.serviceProvider ? (
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Professional Type
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    {row?.role?.[0] || "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        ) : null}
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Referrel User
                </Typography>
                <Typography className="content-text m-0" component="div">
                  {row?.referral_user ? row?.referral_user?.email : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column" spacing={1}>
                <Box>
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Promo Code
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    {/* {row?.default_professional_id
                      ?.default_professional_promocode
                      ? row?.default_professional_id
                          ?.default_professional_promocode: */}
                    {row?.userPromocode?.promocode_name
                      ? row?.userPromocode?.promocode_name
                      : "-"}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {row?.users_type?.[0] === ROLES.professional ? (
        <TableRow
          className="collapsible-row"
          sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
            colSpan={1}
          ></TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Box>
                    <Typography
                      variant="h6"
                      className="content-heading"
                      component="div"
                    >
                      Company
                    </Typography>
                    <Typography className="content-text m-0" component="div">
                      {row?.company_name || "-"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Box>
                    <Typography
                      variant="h6"
                      className="content-heading"
                      component="div"
                    >
                      Zip code range
                    </Typography>
                    <Typography className="content-text m-0" component="div">
                      {row?.zip_code && row?.zip_code2
                        ? row?.zip_code + "-" + row?.zip_code2
                        : "-"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Box>
                    <Typography
                      variant="h6"
                      className="content-heading"
                      component="div"
                    >
                      Professional type
                    </Typography>
                    <Typography className="content-text m-0" component="div">
                      {row?.role?.[0] || "-"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Box>
                    <Typography
                      variant="h6"
                      className="content-heading"
                      component="div"
                    >
                      Referral Code
                    </Typography>
                    <Typography className="content-text m-0" component="div">
                      {row?.referral_code || "-"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}

const PropertyUsersListTable = ({
  columns,
  openPopup,
  resetStates,
  setPage,
  page,
  pageChangeHandler,
  handleViewEditUser,
  callListingAPI = () => {},
  handleSorting = () => {},
  directionFlow,
  setSelectedColumn,
  selectedColumn,
  callUserListingAPI = () => {},
  offsetNum,
}) => {
  const dispatch = useDispatch();

  const [isSwitchFormSubmit, setIsSwitchFormSubmit] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editAlert, setEditAlert] = useState(false);
  const [editName, setEditName] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [switchToProfessional, setSwitchToProfessional] = useState(false);
  const [switchToServiceProvider, setSwitchToServiceProvider] = useState(false);
  const [editEmailAlert, setEditEmailAlert] = useState(false);
  const [editMobileNumberAlert, setEditMobileNumberAlert] = useState(false);
  const [isAddPropertyModalOpen, setIsAddPropertyModalOpen] = useState(false);
  const [isAddPropertyErrorModal, setIsAddPropertyErrorModal] = useState(false);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [isEditPhoneNumber, setIsEditPhoneNumber] = useState(false);
  const [isEditEmailId, setIsEditEmailId] = useState(false);
  const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] =
    useState(null);
  const [actionPerformed, setActionPerformed] = useState("");
  const [resendWelcomeEmail, setResendWelcomeEmail] = useState(false);
  const [resetPassPopup, setResetPassPopup] = useState(false);
  // const [selectedColumn, setSelectedColumn] = useState(8); // updated_at key for sorting

  // store
  const {
    propertyUsersList,
    propertyUserDataCount,
    activateInactivateDetails,
    activateInactivateLoader,
    resendWelcomeEmailLoader,
    resendWelcomeEmailDetails,
    resetPasswordDetails,
  } = useSelector((state) => state.propertyUsers);

  const PER_PAGE = 10;
  const open = Boolean(anchorEl);

  const count = Math.ceil(propertyUserDataCount / PER_PAGE);
  const _DATA = propertyUsersList?.success ? propertyUsersList?.data : [];

  const handlePageChange = (e, p) => {
    pageChangeHandler(e, p);
    // _DATA.jump(p);
  };

  const handleMenuClick = (event, data) => {
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event) => {
    if (event === "name") {
      setEditName(true);
    }
    if (event === "security_pin") {
      setEditAlert(true);
    }
    if (event === "edit_email") {
      setEditEmailAlert(true);
    }
    if (event === "edit_phone") {
      setEditMobileNumberAlert(true);
    }
    if (event === "add_card") {
      setAddCard(true);
    }
    if (event === "delete_card") {
      setDeleteCard(true);
    }
    if (event === "switchToProfessional") {
      setSwitchToProfessional(true);
    }
    if (event === "switchToServiceProvider") {
      setSwitchToServiceProvider(true);
    }
    if (event === "resend_welcome_email") {
      setResendWelcomeEmail(true);
    }
    if (event === "reset_pass") {
      setResetPassPopup(true);
    }
  };

  const closeDialogBox = () => {
    setIsEditPhoneNumber(false);
    setIsEditEmailId(false);
    setEditAlert(false);
    setEditName(false);
    setEditEmailAlert(false);
    setEditMobileNumberAlert(false);
    setAddCard(false);
    setSwitchToProfessional(false);
    setSwitchToServiceProvider(false);
  };

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.user_id === row?.user_id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
    dispatch(propertyUsersAction.clearHistoryAndNotes());
    // to fetch history data
    dispatch(
      propertyUsersAction.getUserHistory({
        url: `users/${row?.user_id}`,
        token: true,
      })
    );
  };

  const handleViewPropertyBtn = () => {
    handleViewEditUser(selectedRow, true);
    if (getObjLength(offsetNum)) {
      localStorage.setItem("offset", offsetNum);
    }
  };

  const cardListAPI = () => {
    dispatch(
      propertyUsersAction.getCardListData({
        url: `admin/users/${selectedRow?.user_id}/card`,
        data: {},
        token: true,
      })
    );
  };

  const handleActivateInactivateCustomer = (selectedCustomer) => {
    console.log("selectedCustomer", selectedCustomer);
    if (selectedCustomer?.id) {
      let URL = "";
      if (actionPerformed === "activate") {
        URL = `admin/user/${selectedCustomer?.id}/active`;
      } else {
        URL = `admin/user/${selectedCustomer?.id}/inactive`;
      }

      dispatch(
        propertyUsersAction.activateInactivateCustomer({
          url: URL,
          data: {},
          token: true,
        })
      );
    }
  };

  const handleReSendWelcomeEmail = () => {
    console.log("selectedRow", selectedRow);

    dispatch(
      propertyUsersAction.resendWelcomeEmailData({
        url: `send-welcome-email`,
        data: { user_id: selectedRow?.user_id, from_admin: 1 },
        token: true,
      })
    );
    setResendWelcomeEmail(false);
  };

  const handleResetPass = () => {
    console.log("selectedRow", selectedRow);

    dispatch(
      propertyUsersAction.resetPasswordData({
        url: `admin/password/reset-email`,
        data: { user_id: selectedRow?.user_id },
        token: true,
      })
    );
    setResetPassPopup(false);
  };

  useEffect(() => {
    if (openPopup) {
      setEditAlert(false);
    }
  }, [openPopup]);

  console.log("selectedRow", selectedRow);

  const markAsActive =
    selectedRow?.json_last_step?.step_key === "SEND_WELCOME_EMAIL"
      ? selectedRow?.status === "inactive"
        ? true
        : false
      : false;

  return (
    <>
      {/* Property User Table if */}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHeaderForSorting
                columns={columns}
                handleSorting={handleSorting}
                directionFlow={directionFlow}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
              />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <>
                      <Row
                        key={(page - 1) * 10 + index + 1}
                        row={row}
                        open={open}
                        handleMenuClick={handleMenuClick}
                        page={page}
                        index={index}
                        handleViewEditUser={handleViewEditUser}
                        handleCollapsibleRow={handleCollapsibleRow}
                        isCollapsible={
                          selectedCollapsibleRow?.user_id === row?.user_id &&
                          isCollapsibleRow
                        }
                        offsetNum={offsetNum}
                      />
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {!propertyUsersList?.data?.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
        {propertyUserDataCount > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
      {/* </>
      ) : (
        <Typography className="text-danger text-center">
          {propertyUsersList?.data?.message || propertyUsersList?.message}
        </Typography>
      )} */}
      {/* table options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {selectedRow?.json_last_step &&
          selectedRow?.json_last_step?.step >= 2 &&
          selectedRow?.json_last_step?.step_key !== "GENERATE_QUESTIONS" && (
            <>
              {/* <Link
              className="text-blue text-decoration-none"
              to={"/admin-dashboard/" + selectedRow?.user_id}
            > */}
              <MenuItem
                key="view"
                onClick={() => handleViewPropertyBtn()}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <ViewIconSVG />
                  <Typography className="setting-menu-text">
                    View Properties
                  </Typography>
                </Box>
              </MenuItem>
              {/* </Link> */}
            </>
          )}
        {!markAsActive && (
          <>
            {selectedRow?.json_last_step &&
              selectedRow?.json_last_step?.step_key !== "SEND_OTP" &&
              selectedRow?.json_last_step?.step_key !== "VERIFY_PHONE_OTP" && (
                <Tooltip
                  title={
                    selectedRow?.json_last_step &&
                    selectedRow?.json_last_step?.step < 1
                      ? "Please Complete Signup Process First."
                      : ""
                  }
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <MenuItem
                    key="add_property"
                    onClick={() => {
                      localStorage.removeItem("searched_Add_popup");
                      if (
                        selectedRow?.json_last_step &&
                        selectedRow?.json_last_step?.step < 1 &&
                        selectedRow?.json_last_step?.step_key !== "SEND_OTP"
                      ) {
                        return;
                      }
                      dispatch(
                        propertiesAction.setSelectedUserID(selectedRow?.user_id)
                      );
                      if (selectedRow?.allow_to_add_property) {
                        setIsAddPropertyModalOpen(true);
                      } else {
                        setIsAddPropertyErrorModal(true);
                      }
                    }}
                    className="setting-menu-item"
                  >
                    <Box className="setting-menu-box">
                      <AddIcon />
                      <Typography className="setting-menu-text">
                        Add Property
                      </Typography>
                    </Box>
                  </MenuItem>
                </Tooltip>
              )}

            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("name")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <DriveFileRenameOutlineIcon />
                <Typography className="setting-menu-text">Edit Name</Typography>
              </Box>
            </MenuItem>

            {selectedRow?.json_last_step?.step_key === "SEND_WELCOME_EMAIL" &&
              selectedRow.role?.includes(ROLES?.customer) && (
                <MenuItem
                  key="view"
                  onClick={() => handleMenuItemClick("switchToProfessional")}
                  className="setting-menu-item"
                >
                  <Box className="setting-menu-box">
                    <FlipCameraAndroidOutlinedIcon />
                    <Typography className="setting-menu-text">
                      Switch to Professional
                    </Typography>
                  </Box>
                </MenuItem>
              )}

            {selectedRow?.json_last_step?.step_key === "SEND_WELCOME_EMAIL" &&
              selectedRow.role?.includes(ROLES?.customer) && (
                <MenuItem
                  key="view"
                  onClick={() => handleMenuItemClick("switchToServiceProvider")}
                  className="setting-menu-item"
                >
                  <Box className="setting-menu-box">
                    <FlipCameraAndroidOutlinedIcon />
                    <Typography className="setting-menu-text">
                      Switch to Service Provider
                    </Typography>
                  </Box>
                </MenuItem>
              )}

            {selectedRow?.json_last_step?.step > 4 && (
              <MenuItem
                key="security_pin"
                onClick={() => handleMenuItemClick("security_pin")}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <LockIconSVG color={"#15BE53"} />
                  <Typography className="setting-menu-text">
                    Verify Security PIN
                  </Typography>
                </Box>
              </MenuItem>
            )}

            {selectedRow?.json_last_step?.step_key === "SEND_OTP" ? null : (
              <MenuItem
                key="edit_email"
                onClick={() => handleMenuItemClick("edit_email")}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  {selectedRow?.json_last_step?.step_key === "SEND_OTP" ||
                  selectedRow?.json_last_step?.step_key ===
                    "VERIFY_PHONE_OTP" ? (
                    <MarkEmailReadOutlinedIcon />
                  ) : (
                    <EmailOutlinedIcon color={"#15BE53"} />
                  )}
                  <Typography className="setting-menu-text">
                    {selectedRow?.json_last_step?.step_key === "SEND_OTP" ||
                    selectedRow?.json_last_step?.step_key === "VERIFY_PHONE_OTP"
                      ? "Verify Email Id"
                      : "Edit and Verify Email Id"}
                  </Typography>
                </Box>
              </MenuItem>
            )}

            {selectedRow?.json_last_step?.step_key === "VERIFY_PHONE_OTP" ? (
              <MenuItem
                key="edit_email"
                onClick={() => {
                  setIsEditEmailId(true);
                  handleMenuItemClick("edit_email");
                }}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <EmailOutlinedIcon color={"#15BE53"} />
                  <Typography className="setting-menu-text">
                    Edit and Verify Email Id
                  </Typography>
                </Box>
              </MenuItem>
            ) : null}

            <MenuItem
              key="edit_phone"
              onClick={() => handleMenuItemClick("edit_phone")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                {selectedRow?.json_last_step?.step_key === "SEND_OTP" ? (
                  <MobileFriendlyOutlinedIcon />
                ) : (
                  <SmartphoneOutlinedIcon color={"#15BE53"} />
                )}
                <Typography className="setting-menu-text">
                  {selectedRow?.json_last_step?.step_key === "SEND_OTP"
                    ? "Verify Mobile Number"
                    : "Edit and Verify Mobile Number"}
                </Typography>
              </Box>
            </MenuItem>

            {selectedRow?.json_last_step?.step_key === "SEND_OTP" ? (
              <MenuItem
                key="edit_phone"
                onClick={() => {
                  setIsEditPhoneNumber(true);
                  handleMenuItemClick("edit_phone");
                }}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <SmartphoneOutlinedIcon color={"#15BE53"} />
                  <Typography className="setting-menu-text">
                    Edit and Verify Mobile Number
                  </Typography>
                </Box>
              </MenuItem>
            ) : null}

            {/* <MenuItem
          key="add_cards"
          onClick={() => handleMenuItemClick("add_card")}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <AddCardOutlinedIcon />
            <Typography className="setting-menu-text">Add Card</Typography>
          </Box>
        </MenuItem> */}

            {/* {selectedRow?.cards_count >= 1 && ( */}
            {selectedRow?.json_last_step?.step >= 4 && (
              <>
                <MenuItem
                  key="delete_cards"
                  onClick={() => handleMenuItemClick("delete_card")}
                  className="setting-menu-item"
                >
                  <Box className="setting-menu-box">
                    <CallToActionOutlinedIcon />
                    <Typography className="setting-menu-text">
                      Manage Cards
                    </Typography>
                  </Box>
                </MenuItem>

                <MenuItem
                  key="send_welcome_email"
                  onClick={() => handleMenuItemClick("resend_welcome_email")}
                  className="setting-menu-item"
                >
                  <Box className="setting-menu-box">
                    <EmailIcon />
                    <Typography className="setting-menu-text">
                      Resend Welcome Email
                    </Typography>
                  </Box>
                </MenuItem>
              </>
            )}
          </>
        )}

        {selectedRow?.json_last_step?.step > 0 && (
          <MenuItem
            key="send_welcome_email"
            onClick={() => handleMenuItemClick("reset_pass")}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <LockResetIcon />
              <Typography className="setting-menu-text">
                Send reset-password link
              </Typography>
            </Box>
          </MenuItem>
        )}

        {selectedRow?.json_last_step?.step_key === "SEND_WELCOME_EMAIL" ? (
          selectedRow?.status === "inactive" ? (
            <Tooltip
              title="Activate "
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <MenuItem
                key="edit_email"
                onClick={() => {
                  setActionPerformed("activate");
                  setIsActivateDeactivateModalOpen(true);
                }}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <CheckCircleOutlineOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Mark as Active
                  </Typography>
                </Box>
              </MenuItem>
            </Tooltip>
          ) : (
            <Tooltip
              title="Inactivate"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <MenuItem
                key="edit_email"
                onClick={() => {
                  setActionPerformed("inactivate");
                  setIsActivateDeactivateModalOpen(true);
                }}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <CloseOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Mark as Inactive
                  </Typography>
                </Box>
              </MenuItem>
            </Tooltip>
          )
        ) : null}
      </Menu>

      {/* Edit Name popup */}
      {editName && (
        <DialogBox
          width={"sm"}
          contentClassName={"bg-white"}
          openPopup={editName}
          setOpenPopup={setEditName}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditName
              uid={selectedRow?.user_id}
              rowData={selectedRow}
              closePopup={setEditName}
              callListingAPI={callListingAPI}
            />
          }
        />
      )}

      {/* Edit Mobile number popup */}
      {editMobileNumberAlert && (
        <DialogBox
          width={"sm"}
          contentClassName={"bg-white"}
          openPopup={editMobileNumberAlert}
          setOpenPopup={setEditMobileNumberAlert}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditPhoneNumber
              uid={selectedRow?.user_id}
              phoneNumber={selectedRow?.phone_number}
              json_last_step={selectedRow?.json_last_step}
              isEditPhoneNumber={isEditPhoneNumber}
              onClose={closeDialogBox}
              closePopup={setEditEmailAlert}
              callListingAPI={callListingAPI}
            />
          }
        />
      )}

      {/* Edit Email popup */}
      {editEmailAlert && (
        <DialogBox
          width={"sm"}
          contentClassName={"bg-white"}
          openPopup={editEmailAlert}
          setOpenPopup={setEditEmailAlert}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditEmail
              uid={selectedRow?.user_id}
              emailId={selectedRow?.email}
              json_last_step={selectedRow?.json_last_step}
              isEditEmailId={isEditEmailId}
              onClose={closeDialogBox}
              closePopup={setEditEmailAlert}
              callListingAPI={callListingAPI}
            />
          }
        />
      )}

      {/* Security PIN popup */}
      {editAlert && (
        <DialogBox
          width={"sm"}
          contentClassName={"bg-white"}
          openPopup={editAlert}
          setOpenPopup={setEditAlert}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <VerifySecurityPIN
              rowData={selectedRow}
              uid={selectedRow?.user_id}
              onClose={closeDialogBox}
              closePopup={setEditAlert}
            />
          }
        />
      )}

      {/* add property modal */}
      {isAddPropertyModalOpen && (
        <AddPropertyPopup
          classes={"d-none"}
          from={"add-property"}
          getPropertyList={() => {}}
          showBtn={false}
          initialyAddPropertyModal={isAddPropertyModalOpen}
          isByPassOptionAvailable={true}
          handleCloseModal={() => {
            callListingAPI();
            setIsAddPropertyModalOpen(false);
          }}
          callListingAPI={callListingAPI}
        />
      )}

      {/* Add Property Warning popup */}
      {isAddPropertyErrorModal && (
        <AlertDialog
          type={"Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            setIsAddPropertyErrorModal(false);
          }}
          mesage={
            "This account is associated with the provider's customer. You cannot add the property until the user adds payment details."
          }
          footer={false}
        />
      )}

      {/* Add Card Modal */}
      {addCard && (
        <DialogBox
          width="sm"
          contentClassName={"bg-white"}
          openPopup={addCard}
          setOpenPopup={setAddCard}
          onClose={() => setAddCard(false)}
          content={
            // <Elements stripe={stripePromise}>
            <AddCardForm
              fromAdmin={true}
              openPopup={addCard}
              loader={open}
              uid={selectedRow?.user_id}
              onClose={() => setAddCard(false)}
              // callListingAPI={callListingAPI}
              callListingAPI={cardListAPI}
            />
            // </Elements>
          }
        />
      )}

      {/* Delete Card Modal */}
      {deleteCard && (
        <DrawerFromBottom
          width={"720px"}
          open={deleteCard}
          onClose={() => {
            setDeleteCard(false);
            dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
        >
          <>
            <Typography
              variant="subtitle1"
              className="py-1 formHeader"
              sx={{ px: "10px", position: "relative" }}
            >
              <LockIcon sx={{ fontSize: 16 }} className="me-1" />
              Secure connection
              <Button
                onClick={() => {
                  setDeleteCard(false);
                  dispatch(propertyUsersAction.clearDeleteCardData({}));
                }}
                className="modal-close-btn close-text"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  alignSelf: "right",
                  background: "transparent",
                  textTransform: "none",
                  justifyContent: "end",
                }}
              >
                <CloseIcon sx={{ fontSize: 20, color: "white" }} />
              </Button>
            </Typography>
            <ManageCreditCard
              fromAdmin={true}
              addCard={() => handleMenuItemClick("add_card")}
              cardListAPI={cardListAPI}
              openPopup={deleteCard}
              loader={open}
              uid={selectedRow?.user_id}
              callListingAPI={callListingAPI}
              onClose={() => {
                setDeleteCard(false);
                dispatch(propertyUsersAction.clearDeleteCardData({}));
              }}
            />
          </>
        </DrawerFromBottom>
      )}

      {/* {deleteCard && (
        <DialogBox
          width="md"
          contentClassName={"bg-white"}
          openPopup={deleteCard}
          setOpenPopup={setDeleteCard}
          onClose={() => {
            setDeleteCard(false);
            dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
          content={
            <ManageCreditCard
              fromAdmin={true}
              addCard={() => handleMenuItemClick("add_card")}
              cardListAPI={cardListAPI}
              openPopup={deleteCard}
              loader={open}
              uid={selectedRow?.user_id}
              callListingAPI={callListingAPI}
              onClose={() => {
                setDeleteCard(false);
                dispatch(propertyUsersAction.clearDeleteCardData({}));
              }}
            />
          }
        />
      )} */}

      {/* Switch to professionals */}
      {switchToProfessional && (
        <DialogBox
          width={!isSwitchFormSubmit ? "md" : "sm"}
          title={!isSwitchFormSubmit ? "Switch to Professional" : null}
          contentClassName={"bg-white"}
          openPopup={switchToProfessional}
          setOpenPopup={setSwitchToProfessional}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <SwitchToProfessional
              uid={selectedRow?.user_id}
              handleClose={closeDialogBox}
              selectedItem={selectedRow}
              closePopup={setSwitchToProfessional}
              callListingAPI={callListingAPI}
              setIsSwitchFormSubmit={setIsSwitchFormSubmit}
              setSwitchToProfessional={setSwitchToProfessional}
            />
          }
        />
      )}
      {/* Switch to professionals */}
      {switchToServiceProvider && (
        <DialogBox
          width={!isSwitchFormSubmit ? "md" : "sm"}
          title={!isSwitchFormSubmit ? "Switch to Service Provider" : null}
          contentClassName={"bg-white"}
          openPopup={switchToServiceProvider}
          setOpenPopup={setSwitchToServiceProvider}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <SwitchToServiceProvider
              setSwitchToServiceProvider={setSwitchToServiceProvider}
              setIsSwitchFormSubmit={setIsSwitchFormSubmit}
              uid={selectedRow?.user_id}
              handleClose={closeDialogBox}
              selectedItem={selectedRow}
              closePopup={setSwitchToServiceProvider}
              callListingAPI={callListingAPI}
            />
          }
        />
      )}

      {/* activate/deactivate the customer */}
      {isActivateDeactivateModalOpen ? (
        <AlertDialog
          type={"Warning"}
          openPopup={isActivateDeactivateModalOpen}
          onClose={true}
          onCloseFun={() => {
            setActionPerformed("");
            setIsActivateDeactivateModalOpen(false);
          }}
          mesage={`Are you sure you want to ${
            actionPerformed === "activate"
              ? `activate the ${selectedRow?.users_type?.[0]}`
              : `inactivate the ${selectedRow?.users_type?.[0]}`
          }?`}
          buttons={
            <>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="popup_Btn other_popup_btn"
                  onClick={() => {
                    setActionPerformed("");
                    setIsActivateDeactivateModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    handleActivateInactivateCustomer(selectedRow);
                  }}
                >
                  Okay
                </Button>
              </Stack>
            </>
          }
          footer={false}
          loader={activateInactivateLoader}
        />
      ) : null}

      {/* activate deactivate customer success error modal */}
      {getObjLength(activateInactivateDetails) && (
        <AlertDialog
          type={activateInactivateDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (activateInactivateDetails?.success) {
              setActionPerformed("");
              setIsActivateDeactivateModalOpen(false);
              callUserListingAPI();
            }
            dispatch(propertyUsersAction.setActivateInactivateDetails({}));
          }}
          mesage={
            activateInactivateDetails?.success
              ? activateInactivateDetails?.message
              : activateInactivateDetails?.data?.message
          }
          footer={false}
        />
      )}

      {/* Resend Welcome email modal */}
      {/* activate/deactivate the customer */}
      {resendWelcomeEmail ? (
        <AlertDialog
          type={"Warning"}
          openPopup={resendWelcomeEmail}
          onClose={true}
          onCloseFun={() => {
            setResendWelcomeEmail(false);
          }}
          mesage={`Are you sure you want to Resend the Welcome Email?`}
          buttons={
            <>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="popup_Btn other_popup_btn"
                  onClick={() => {
                    setResendWelcomeEmail(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={handleReSendWelcomeEmail}
                >
                  Yes
                </Button>
              </Stack>
            </>
          }
          footer={false}
          loader={activateInactivateLoader}
        />
      ) : null}

      {getObjLength(resendWelcomeEmailDetails) && (
        <AlertDialog
          type={resendWelcomeEmailDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(propertyUsersAction.fetchResendWelcomeEmailData({}));
          }}
          mesage={
            resendWelcomeEmailDetails?.success
              ? resendWelcomeEmailDetails?.message
              : resendWelcomeEmailDetails?.data?.message
          }
          footer={false}
        />
      )}

      {/* Reset password */}
      {resetPassPopup ? (
        <AlertDialog
          type={"Warning"}
          openPopup={resetPassPopup}
          onClose={true}
          onCloseFun={() => {
            setResetPassPopup(false);
          }}
          mesage={`Are you sure you want to send a password reset link to this user?`}
          buttons={
            <>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="popup_Btn other_popup_btn"
                  onClick={() => {
                    setResetPassPopup(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={handleResetPass}
                >
                  Yes
                </Button>
              </Stack>
            </>
          }
          footer={false}
        />
      ) : null}

      {getObjLength(resetPasswordDetails) && (
        <AlertDialog
          type={resetPasswordDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(propertyUsersAction.fetchResetPasswordData({}));
          }}
          mesage={
            resetPasswordDetails?.success
              ? resetPasswordDetails?.message
              : resetPasswordDetails?.data?.message
          }
          footer={false}
        />
      )}

      <Loader open={resendWelcomeEmailLoader} />
    </>
  );
};

export default PropertyUsersListTable;
