import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useEffect } from "react";
import { Loader } from "../../common/loader";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../../common/CustomTextField";
import "../../common/common.scss";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { getObjLength } from "../../helpers/utils";

const AddNoteModal = ({ open, handleClose, onSubmit, selectedNote }) => {
  const dispatch = useDispatch();

  // store data
  const {
    selectedProperty,
    noteDetails,
    notesLoader,
  } = useSelector((state) => state?.propertyUsers);


  // clear add/edit/delete professional's existing data
  useEffect(() => {
    if (open) {
      dispatch(propertyUsersAction?.setAddNote({}));
    }
  }, [dispatch, open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!getObjLength(noteDetails) && <>
        <DialogTitle id="alert-dialog-title">
          {selectedNote?.note ? "Edit Note" : "Add Note"}
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            // subject: "",
            note: selectedNote?.note || "",
          }}
          validationSchema={Yup.object().shape({
            // subject: Yup.string("Please enter Subject.")
            //   .trim(
            //     "The Subject cannot include leading and trailing spaces"
            //   )
            //   .strict(true)
            //   .required("Subject is required"),
            note: Yup.string("Please enter Note.")
              .trim("The Note cannot include leading and trailing spaces")
              .strict(true)
              .required("Note is required"),
          })}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ values, setFieldValue, touched, errors, initialValues }) => {
            return (
              <Form>
                <DialogContent dividers sx={{ p: 4 }}>
                  <Grid
                    container
                    className="bg-white"
                    sx={{ borderRadius: "5px" }}
                  >
                    <>
                      {/* subject */}
                      {/* <Grid xs={12} md={12} px={1} pb={3}>
                            <CustomTextField
                              name="subject"
                              label="Subject"
                              validation={{
                                values,
                                setFieldValue,
                                touched,
                                errors,
                              }}
                            />
                          </Grid> */}
                      {/* notes */}
                      <Grid xs={12} md={12} p={3}>
                        <CustomTextField
                          name="note"
                          label="Note"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          otherProps={{
                            multiline: true,
                            rows: 4,
                          }}
                        />
                      </Grid>
                    </>
                  </Grid>
                </DialogContent>

                <DialogActions className="alert-modal-btn">
                  <Stack direction="row" spacing={2} my={1}>
                    <Button
                      className="popup_Btn other_popup_btn m-1"
                      // variant="contained"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="popup_Btn success_popup_btn m-1"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Stack>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
        </>}

        <Loader open={notesLoader} />

        {getObjLength(noteDetails) &&
        (noteDetails?.success ? (
          <PlainAlertMessage
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyUsersAction.setAddNote({}));
              handleClose();
              dispatch(
                propertyUsersAction.getUserHistory({
                  url: `users/${selectedProperty?.user_id}`,
                  token: true,
                })
              );
            }}
            message={noteDetails?.message}
            footer={false}
          />
        ) : (
          <PlainAlertMessage
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyUsersAction.setAddNote({}));
            }}
            message={noteDetails?.data?.message}
            footer={false}
          />
        ))}
      </Dialog>
    </>
  );
};

export default AddNoteModal;
