/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Paper, Typography } from "@mui/material";

import AddLeadsForm from "./AddLeadForm";
import { Loader } from "../../common/loader";
import LeadsListTable from "./LeadsListTable";
import { getObjLength } from "../../helpers/utils";

export const mobileFilterResetBtnStyle = {
  color: "rgba(255, 0, 107, 1)",
  fontSize: "14px",
  cursor: "pointer",

  "&:hover": {
    color: "rgb(200, 0, 83)",
  },
};

export const mobileFilterResultStyle = {
  height: "40px",
  color: "white",
  borderRadius: "24px",
  padding: "6px 20px",
  background: "rgba(3, 149, 255, 1)",
  "&:hover": {
    background: "rgba(3, 149, 255, 1) !important",
  },
};

const AddLeadsIndex = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [leadsData, setLeadsData] = useState([]);

  const { addleadsLoader, leadsList } = useSelector((state) => state.addLeads);

  const columns = [
    {
      key: 1,
      id: "no",
      disablePadding: true,
      label: "No.",
      minWidth: 50,
    },
    { key: 2, id: "name", disablePadding: true, label: "Name", minWidth: 120 },
    {
      key: 3,
      id: "email",
      disablePadding: true,
      label: "Email Id",
      minWidth: 120,
    },
    {
      key: 4,
      id: "phone",
      disablePadding: true,
      label: "Phone Number",
      minWidth: 160,
    },
    {
      key: 5,
      id: "promo_code",
      disablePadding: true,
      label: "Promo Code",
      minWidth: 120,
    },
    {
      key: 6,
      id: "status",
      disablePadding: true,
      label: "Status",
      minWidth: 100,
    },
    {
      key: 7,
      id: "created_at",
      disablePadding: true,
      label: "Created At",
      minWidth: 200,
      sorting: true,
    },
    {
      key: 8,
      id: "setting",
      disablePadding: true,
      label: "Actions",
      minWidth: 70,
    },
  ];

  const openAddLeadsForm = () => {
    setOpenPopup(true);
  };
  const closeDialogBox = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    if (getObjLength(leadsList)) {
      if (leadsList?.success) {
        setLeadsData(leadsList?.data);
      }
      setEditAlert(false);
    }
  }, [leadsList]);

  let initialData = {
    name1: "",
    email: "",
    phone: "",
    phone1: "",
    promo_code: "",
  };
  return (
    <>
      <>
        <Box>
          <Paper
            elevation={0}
            className=" d-flex justify-content-between"
            sx={{ background: "none" }}
          >
            <Box className="" sx={{ mb: 1 }}>
              <Typography variant="h6" className="card-title fw-600">
                Send Invite
              </Typography>
            </Box>
            <Box className="">
              <Button
                className="success-btn d-none d-sm-block"
                variant="contained"
                onClick={openAddLeadsForm}
              >
                Send Invite
              </Button>
              <Button
                className="success-btn d-flex d-sm-none"
                variant="outlined"
                onClick={openAddLeadsForm}
                startIcon={<SendIcon />}
                sx={{ color: "#FFF" }}
              >
                Invite
              </Button>
            </Box>
          </Paper>

          <LeadsListTable
            openPopup={editAlert}
            columns={columns}
            rowsData={leadsData}
            setOpenPopup={setOpenPopup}
          />
        </Box>
      </>

      {/* Add leads Forms Modal */}
      {openPopup && (
        <AddLeadsForm
          closeDialogBox={closeDialogBox}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          from={"inital"}
          initialData={initialData}
        />
      )}

      <Loader open={addleadsLoader} />
    </>
  );
};
export default AddLeadsIndex;
