import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";

const ResendInvitationModal = ({
  open,
  handleClose,
  resendInvitaionHandler,
  loader,
  module = "Invitation",
  noOfDaysPassed,
}) => {
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Resend {module}
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent className="bg-white break-word">
          {module === "Postcard" && noOfDaysPassed <= 15 ? (
            <Typography className="p-0 p-sm-4 bg-white text-start text-sm-center fs-5">
              You will be able to resend the postcard{" "}
              {noOfDaysPassed === 14 ? (
                <b>Tomorrow</b>
              ) : (
                <b>after {15 - noOfDaysPassed} days</b>
              )}
              .
            </Typography>
          ) : (
            `Are you sure you want to resend the ${module}?`
          )}
        </DialogContent>
        <DialogActions className="mb-3 me-3">
          <Button
            className="popup_Btn other_popup_btn"
            // variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          {module === "Postcard" && noOfDaysPassed > 15 && (
            <Button
              className="popup_Btn success_popup_btn"
              onClick={() => {
                resendInvitaionHandler();
              }}
              variant="contained"
            >
              Resend
            </Button>
          )}
          {module === "Invitation" && (
            <Button
              className="popup_Btn success_popup_btn"
              onClick={() => {
                resendInvitaionHandler();
              }}
              variant="contained"
            >
              Resend
            </Button>
          )}
        </DialogActions>
        <Loader open={loader} />
      </Dialog>
    </>
  );
};

export default ResendInvitationModal;
