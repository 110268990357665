import React, { useState } from 'react'
import ExportDetailsModal from './exportDetailsModal'
import { Box, Button, Paper, Typography } from '@mui/material';
import ExportPaymentDetailsTable from './exportPaymentDetailsTable';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const ExportCSVDetails = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleExportCSV = () => {
      setIsModalOpen(true);
    };
  return (
    <>
        {isModalOpen ? (
        <ExportDetailsModal
          goBack={() => {
            setIsModalOpen(false);
          }}
        />
      ) : (
      <Box>
        <Paper
          elevation={0}
          className=" d-flex justify-content-between"
          sx={{ background: "none" }}
        >
          <Box className="" sx={{ mb: 1 }}>
            <Typography variant="h6" className="card-title fw-600">
              Export Payments 
            </Typography>
          </Box>
          <Box className="">
            <Button
              variant="contained"
              className="success-btn d-none d-sm-block"
              style={{
                padding: "5px 30px",
                borderRadius: "20px",
              }}
              onClick={() => {
                handleExportCSV();
              }}
            >
              Export CSV
            </Button>
            <Button
              className="success-btn d-flex d-sm-none"
              variant="outlined"
              onClick={() => {
                handleExportCSV();

              }}
                startIcon={<AddOutlinedIcon />}
              sx={{ color: "#FFF" }}
            >
              Export
            </Button>
          </Box>
        </Paper>
        <Box>
          <ExportPaymentDetailsTable
          />
        </Box>
      </Box>
      )}
    </>
  )
}

export default ExportCSVDetails