import { useState } from "react";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

const OwnerVerification = ({
  isByPassOptionAvailable = false,
  isAdminCreateUser = false,
  selectedData = {},
  onChangeModalScreen = () => {},
  setFromPropertyList = () => {},
}) => {
  const [params, setParams] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [propertyName, setPropertyName] = useState("");

  const dispatch = useDispatch();

  const {
    createAccResponse,
    verifiedPropertiesList,
    propertyListLoader,
    deletedPropertyResponse,
  } = useSelector((state) => state.signUpUser);

  const {
    getPropertyData,
    selectedProperty: selectedPropertyData,
    propertyQuestion,
    propertyLoader,
    selectedUserID,
    isQuestionByPass,
    isSubscriptionByPass,
    editPropertiesDetails,
  } = useSelector((state) => state.properties);
  const { data: userData } = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );

  const addNewProperty = () => {
    dispatch(userSigupAction.addNewProperty({}));
    dispatch(propertiesAction.addNewProperty({}));
    dispatch(propertiesAction.setIsQuestionByPass(false));
    localStorage.removeItem("searched_Add_popup");
    onChangeModalScreen("security");
  };

  const propertyAction = (event, type, data) => {
    console.log("Selected row type==>", type, "==Data==>", data);

    if (type === "delete") {
      let ipParams = {
        // user_id: data?.owner_id,
        user_id: data?.owner_id || data?.user_id,
        property_id: data?.property_id,
      };
      setParams(ipParams);
      setPropertyName(data?.address);
      setOpenDeletePopup(true);
      console.log("user_id==>", ipParams);
    }
  };

  const APICall = () => {
    dispatch(
      userSigupAction.getPropertiesList({
        url: "admin/properties/list",
        token: true,
        data: {
          user_id: createAccResponse?.data?.user_id,
        },
      })
    );
  };

  const OwnerCardStyle = {
    background: "rgb(21 190 83 / 5%)",
    boxShadow: "0px 17.0825px 39.8592px -5.69417px rgba(0, 0, 0, 0.15);",
    borderRadius: "3px",
    py: 2,
    px: 4,
    // border: '1px solid #15be53',
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "55px",
    color: "#0D120B",
  };

  useEffect(() => {
    APICall();
  }, []);

  let verified = verifiedPropertiesList?.data?.find(
    (i, j) => i?.is_verified === 0
  );

  const onNextStep = () => {
    setFromPropertyList(true);
    let propertyIds = verifiedPropertiesList?.data?.map((i) => i?.property_id);
    let data = {};
    let properties = verifiedPropertiesList?.data?.map((i) => {
      return (data = {
        address: i?.address,
        city: i.city,
        property_id: i.property_id,
        state: i.state,
        zip: i.zip,
      });
    });
    console.log(propertyIds, "propertyIds==>", properties);
    dispatch(userSigupAction.setPropertiesId({ propertyIds, properties }));
    onChangeModalScreen("subscriptions");
  };

  return (
    <>
      <Box className="accountBoxWrapper bg-white">
        <Typography align="center" variant="h3">
          Owner Verified
        </Typography>
        <Typography align="center" variant="body1">
          Your Ownership of the below property has been verified by
          EquityProtect.
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent={`${
            verifiedPropertiesList?.data?.length > 1 ? "" : "center"
          }`}
        >
          {verifiedPropertiesList?.success ? (
            verifiedPropertiesList?.data?.map((data, key) => (
              <>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box sx={{ position: "relative", mb: 3 }}>
                    <Paper className="verifiedOwnerCard" sx={OwnerCardStyle}>
                      <Box>
                        <Typography variant="h3" sx={{ minHeight: "52px" }}>
                          Property: {data?.address}
                        </Typography>
                        <Typography variant="h3">City: {data?.city}</Typography>
                        <Typography variant="h3">
                          State: {data?.state}
                        </Typography>
                        <Typography variant="h3">
                          Country: {data?.country}
                        </Typography>
                        <Typography variant="h3">Zip: {data?.zip}</Typography>
                      </Box>
                      <Box
                        className={`d-flex ${
                          data?.is_verified === 0
                            ? `justify-content-between`
                            : `justify-content-end`
                        } pt-4`}
                      >
                        {data?.is_verified === 0 ? (
                          <Tooltip
                            title={"Verify this property"}
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                            disableFocusListener
                          >
                            <Button
                              className="px-4"
                              //   onClick={(e) => propertyAction(e, "verify", data)}
                              variant="contained"
                              color="success"
                            >
                              Verify
                            </Button>
                          </Tooltip>
                        ) : null}
                        {verifiedPropertiesList?.data?.length > 1 && (
                          <Tooltip
                            title={"Delete this property"}
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                            disableFocusListener
                          >
                            <IconButton
                              variant="contained"
                              onClick={(e) => propertyAction(e, "delete", data)}
                              color="error"
                            >
                              <DeleteIcon sx={{ fontSize: "30px" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Paper>
                    {data?.is_verified === 1 ? (
                      <>
                        <div className="verifiedOwnerbadge">
                          <VerifiedUserIcon
                            color="success"
                            sx={{ textAlign: "center" }}
                          />
                        </div>
                      </>
                    ) : null}
                  </Box>
                </Grid>
              </>
            ))
          ) : verifiedPropertiesList?.data?.message?.length > 0 ? (
            <p className="text-danger">
              {verifiedPropertiesList?.data?.message}
            </p>
          ) : (
            <Loader open={propertyListLoader} />
          )}
        </Grid>
        <Box align="center" mt={3}>
          <Button
            className="popup_Btn other_popup_btn"
            variant="contained"
            onClick={addNewProperty}
          >
            Add Another Property
          </Button>
          <Button
            onClick={onNextStep}
            // disabled={verifiedPropertiesList?.data?.length === 0}
            type="submit"
            className={`popup_Btn success_popup_btn m-2`}
            variant="contained"
          >
            Next
          </Button>
        </Box>
      </Box>

      {openDeletePopup && (
        <AlertDialog
          type="Attention"
          openPopup={openDeletePopup}
          onClose={true}
          onCloseFun={() => setOpenDeletePopup(false)}
          mesage={
            <>
              Are you sure you want to delete <b>{propertyName}</b> address?
            </>
          }
          buttons={
            <div className="text-center stepBtn mt-4 ">
              <Button
                onClick={() => {
                  setOpenDeletePopup(false);
                }}
                className="popup_Btn other_popup_btn"
                variant="contained"
              >
                No,Close
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    userSigupAction.deleteProperties({
                      url: "admin/properties/delete",
                      data: params,
                      token: true,
                    })
                  );
                  setOpenDeletePopup(false);
                }}
                className="popup_Btn success_popup_btn mx-3"
                variant="contained"
              >
                Yes,Delete
              </Button>
            </div>
          }
        />
      )}

      {getObjLength(deletedPropertyResponse) ? (
        deletedPropertyResponse?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={false}
            mesage={`Property removed successfully.`}
            buttons={
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={() => {
                    APICall();
                    setOpenDeletePopup(false);
                    dispatch(userSigupAction.clearDeletePropertiesResponse());
                  }}
                  className="next_button "
                  variant="contained"
                >
                  Okay, All Good.
                </Button>
              </div>
            }
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={false}
            mesage={deletedPropertyResponse?.data?.message}
            buttons={
              <div className="text-center stepBtn mt-4">
                <Button
                  className="popup_Btn error_popup_btn "
                  onClick={() => {
                    // setOpenDeletePopup(false);
                    dispatch(userSigupAction.clearDeletePropertiesResponse());
                  }}
                >
                  ok, Close
                </Button>
              </div>
            }
          />
        )
      ) : null}

      <Loader open={propertyListLoader} />
    </>
  );
};

export default OwnerVerification;
