import { put, call, takeEvery } from "redux-saga/effects";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { getAPIPayload } from "../../../../apis/getApi";
import { postAPIPayload } from "../../../../apis/postApi";
import { adminAction } from "../../../slices/admin/admins/adminSlice";

export function* fetchAdminListDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(adminAction.setAdminListData(response));
}

export function* addAdminDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(adminAction.setAdminDetails(response));
}

export function* deleteAdminDetailsSaga({ payload }) {
  let response;
  if (payload?.type === "post") {
    response = yield call(postAPIPayload, payload);
  } else {
    response = yield call(deleteAPIPayload, payload);
  }
  yield put(adminAction.setDeletedAdminDetails(response));
}

export function* addCountyAdminDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  yield put(adminAction.fetchCountyAdminDetails(response));
}

export function* watchAdminsSagaAsync() {
  yield takeEvery(adminAction.fetchAdminListData, fetchAdminListDataSaga);
  yield takeEvery(adminAction.addAdminDetails, addAdminDetailsSaga);
  yield takeEvery(adminAction.deleteAdminDetails, deleteAdminDetailsSaga);
  yield takeEvery(adminAction.addCountyAdminDetails, addCountyAdminDetailsSaga);
}
