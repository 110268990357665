import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { Loader } from "../../common/loader";
import { useDispatch, useSelector } from "react-redux";
import "../../common/common.scss";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { COUNTRY_CODE, PER_PAGE } from "../../helpers/constants";
import { fieldsValidations } from "../../Service-Provider/signup/SPSignupForm";
import { spProfessionalValidationSchema } from "../../Service-Provider/signup/SPProfessionalForm";
import { seviceProvidersAction } from "../../../redux/slices/admin/serviceProviders/seviceProvidersSlice";
import AlertDialog from "../../common/AlertDialog";
import { getObjLength } from "../../helpers/utils";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { useCallback } from "react";

const FieldStyle = {
  borderRadius: "3px",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditServiceProvide = ({
  open,
  selectedItem,
  handleClose,
  providerID,
  payload,
}) => {
  const { confirm_password, password, phone, ...SpSchema } = fieldsValidations;

  const [alertMsg, setAlertMsg] = useState(false);

  const dispatch = useDispatch();
  const { professionalListLoader } = useSelector(
    (state) => state.professionals
  );
  const { professionalsList, spSignupLoader } = useSelector(
    (state) => state.serviceProviderSignup
  );
  const { editServiceProvidersRes, actionLoader } = useSelector(
    (state) => state.seviceProviders
  );

  const stateData = useSelector((state) => state.signUpUser.stateList);

  const onSubmit = (values, errors) => {
    console.log("values=>", values);
    dispatch(
      seviceProvidersAction.getEditSeviceProvidersData({
        url: "admin/service-provider/edit/" + selectedItem?.id,
        data: values,
        token: true,
      })
    );
  };

  const onPopupClose = () => {
    setAlertMsg(false);
    dispatch(seviceProvidersAction.clearData());
    let finalURl = "admin/service-provider/get-service-providers";
    if (providerID) {
      finalURl = "admin/service-provider/get-service-providers/" + providerID;
    }
    dispatch(
      seviceProvidersAction.getSeviceProvidersData({
        url: finalURl,
        data: {
          type: payload?.type,
          value: payload?.value,
          service_provider_type: payload?.service_provider_type,
          is_active: payload?.is_active,
          json_last_step: payload?.json_last_step,
          limit: PER_PAGE,
          offset: payload?.offset || 0,
        },
        token: true,
      })
    );
    handleClose();
  };

  const getProviderTypes = useCallback(
    (data) => {
      dispatch(
        serviceProviderSignupAction.getProfessionals({
          url: "service-provider/types",
          data: {},
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (getObjLength(editServiceProvidersRes)) {
      setAlertMsg(true);
    }
  }, [editServiceProvidersRes]);

  useEffect(() => {
    if (open) {
      getProviderTypes();
    }
  }, [getProviderTypes, open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        className="zoom-modal-wrapper"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <p className="zoom-container">Edit Service Provider</p>
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn close-text-a"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
              textTransform:"none"
            }}
          >
            Close
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <Box className="d-flex justify-content-center bg-white zoom-container">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                first_name: selectedItem?.first_name || "",
                last_name: selectedItem?.last_name || "",
                address: selectedItem?.address?.address || "",
                city: selectedItem?.address?.city || "",
                state: selectedItem?.address?.state || "",
                zip: selectedItem?.address?.zip || "",
                email: selectedItem?.email || "",
                phone_number: selectedItem?.phone_number || "",
                phone1: selectedItem?.phone_number || "",
                company_name: selectedItem?.company_info?.company_name || "",
                ofc_address: selectedItem?.company_info?.ofc_address || "",
                ofc_suite: selectedItem?.company_info?.ofc_suite || "",
                ofc_city: selectedItem?.company_info?.ofc_city || "",
                ofc_state: selectedItem?.company_info?.ofc_state || "",
                ofc_zip: selectedItem?.company_info?.ofc_zip || "",
                ofc_phone: selectedItem?.company_info?.ofc_phone || "",
                ofc_phone1: selectedItem?.company_info?.ofc_phone || "",
                type: selectedItem?.service_provider_type?.id || "",
              }}
              validationSchema={Yup.object().shape({
                ...SpSchema,
                ...spProfessionalValidationSchema,
              })}
              // onSubmit={onSubmit}
              onSubmit={(values, validation) => {
                if (!isValidPhoneNumber(values?.phone1)) {
                  return;
                }
                if (!isValidPhoneNumber(values?.ofc_phone1)) {
                  return;
                }
                onSubmit(values);
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                resetForm,
                initialValues,
              }) => {
                console.log(
                  "values",
                  values,
                  "errors",
                  errors,
                  "professional",
                  professionalsList?.data?.find(
                    (professional) =>
                      professional?.id ===
                      selectedItem?.service_provider_type?.id
                  )?.id
                );

                return (
                  <Form className="beneficiary-form w-100">
                    <DialogContent className=" bg-white">
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid xs={12} md={12} px={1} pb={3}>
                          <Divider>
                            <span className="fw-600">Personal Details</span>{" "}
                          </Divider>
                        </Grid>
                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="First name"
                            name="first_name"
                            value={values?.first_name}
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"First Name"}
                            onChange={(event) => {
                              setFieldValue("first_name", event.target.value);
                            }}
                            helperText={touched.first_name && errors.first_name}
                            error={
                              touched.first_name && Boolean(errors.first_name)
                            }
                          />
                        </Grid>
                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="Last name"
                            name="last_name"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Last Name"}
                            value={values?.last_name}
                            onChange={(event) => {
                              setFieldValue("last_name", event.target.value);
                            }}
                            helperText={touched.last_name && errors.last_name}
                            error={
                              touched.last_name && Boolean(errors.last_name)
                            }
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="Address"
                            name="address"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Address"}
                            value={values?.address}
                            onChange={(event) => {
                              setFieldValue("address", event.target.value);
                            }}
                            helperText={touched.address && errors.address}
                            error={touched.address && Boolean(errors.address)}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="City"
                            name="city"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"City"}
                            value={values?.city}
                            onChange={(event) => {
                              setFieldValue("city", event.target.value);
                            }}
                            helperText={touched.city && errors.city}
                            error={touched.city && Boolean(errors.city)}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <FormControl
                            fullWidth
                            className="sign-textField"
                            error={touched.state && Boolean(errors.state)}
                          >
                            <InputLabel
                              shrink
                              // id="demo-simple-select-placeholder"
                              style={{ color: "#00000075" }}
                              label="State"
                            >
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              value={values?.state}
                              label="State"
                              name="state"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue("state", event.target.value);
                              }}
                              MenuProps={MenuProps}
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                <span style={{ color: "#00000075" }}>
                                  State
                                </span>
                              </MenuItem>
                              {stateData?.map((state, index) => (
                                <MenuItem key={index} value={state.name}>
                                  {state.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.state && Boolean(errors.state) && (
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.state && errors.state}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // length={6}
                            // id="outlined-basic"
                            label="Zip"
                            name="zip"
                            type="number"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Zip"}
                            value={values?.zip}
                            onChange={(event) => {
                              setFieldValue("zip", event.target.value);
                            }}
                            helperText={touched.zip && errors.zip}
                            error={touched.zip && Boolean(errors.zip)}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="Email Address"
                            name="email"
                            // disabled
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Email Address"}
                            value={values?.email}
                            onChange={(event) => {
                              setFieldValue("email", event.target.value);
                            }}
                            onBlur={(event) =>
                              setFieldValue("email", event.target.value)
                            }
                            helperText={touched.email && errors.email}
                            error={touched.email && Boolean(errors.email)}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <Box
                            className={`phone-number-input ${
                              (values?.phone1 &&
                                !isValidPhoneNumber(values?.phone1)) ||
                              (touched?.phone1 && errors?.phone1?.length > 0)
                                ? "phone-error"
                                : ""
                            }`}
                          >
                            <PhoneInput
                              international={false}
                              value={values?.phone1}
                              // disabled
                              countries={["US", "IN"]}
                              defaultCountry={
                                COUNTRY_CODE === "+91" ? "IN" : "US"
                              }
                              countryOptionsOrder={["US", "IN"]}
                              addInternationalOption={false}
                              placeholder="Phone Number"
                              onChange={(event) => {
                                setFieldValue("phone1", event);
                                setFieldValue("phone_number", event);
                              }}
                            />
                          </Box>
                          {((values?.phone1 &&
                            !isValidPhoneNumber(values?.phone1)) ||
                            (touched?.phone1 &&
                              errors?.phone1?.length > 0)) && (
                            <Typography
                              sx={{
                                color: "#d32f2f !important",
                                fontSize: "12px !important",
                                margin: "0px !important",
                              }}
                            >
                              Please enter a valid phone number
                            </Typography>
                          )}
                        </Grid>

                        <Grid xs={12} md={12} px={1} py={3}>
                          <Divider>
                            <span className="fw-600">Professional Details</span>{" "}
                          </Divider>
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="Company name"
                            name="company_name"
                            value={values?.company_name}
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Company Name"}
                            onChange={(event) => {
                              setFieldValue("company_name", event.target.value);
                            }}
                            helperText={
                              touched.company_name && errors.company_name
                            }
                            error={
                              touched.company_name &&
                              Boolean(errors.company_name)
                            }
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="Office Address"
                            name="ofc_address"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Office Address"}
                            value={values?.ofc_address}
                            onChange={(event) => {
                              setFieldValue("ofc_address", event.target.value);
                            }}
                            helperText={
                              touched.ofc_address && errors.ofc_address
                            }
                            error={
                              touched.ofc_address && Boolean(errors.ofc_address)
                            }
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="Office Suite"
                            name="ofc_suite"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Office Suite"}
                            value={values?.ofc_suite}
                            onChange={(event) => {
                              setFieldValue("ofc_suite", event.target.value);
                            }}
                            helperText={touched.ofc_suite && errors.ofc_suite}
                            error={
                              touched.ofc_suite && Boolean(errors.ofc_suite)
                            }
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // id="outlined-basic"
                            label="Office City"
                            name="ofc_city"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Office City"}
                            value={values?.ofc_city}
                            onChange={(event) => {
                              setFieldValue("ofc_city", event.target.value);
                            }}
                            helperText={touched.ofc_city && errors.ofc_city}
                            error={touched.ofc_city && Boolean(errors.ofc_city)}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <FormControl
                            fullWidth
                            className="sign-textField"
                            error={
                              touched.ofc_state && Boolean(errors.ofc_state)
                            }
                          >
                            <InputLabel
                              shrink
                              // id="demo-simple-select-placeholder"
                              style={{ color: "#00000075" }}
                              label="Office State"
                            >
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              value={values?.ofc_state}
                              label="Office State"
                              name="ofc_state"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue("ofc_state", event.target.value);
                              }}
                              MenuProps={MenuProps}
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                <span style={{ color: "#00000075" }}>
                                  State
                                </span>
                              </MenuItem>
                              {stateData?.map((ofc_state, index) => (
                                <MenuItem key={index} value={ofc_state.name}>
                                  {ofc_state.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.ofc_state && Boolean(errors.ofc_state) && (
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.ofc_state && errors.ofc_state}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <TextField
                            className="sign-textField "
                            // length={6}
                            // id="outlined-basic"
                            label="Office Zip"
                            name="ofc_zip"
                            type="number"
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Office Zip"}
                            value={values?.ofc_zip}
                            onChange={(event) => {
                              setFieldValue("ofc_zip", event.target.value);
                            }}
                            helperText={touched.ofc_zip && errors.ofc_zip}
                            error={touched.ofc_zip && Boolean(errors.ofc_zip)}
                          />
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <Box
                            className={`phone-number-input ${
                              (values?.ofc_phone1 &&
                                !isValidPhoneNumber(values?.ofc_phone1)) ||
                              (touched?.ofc_phone1 &&
                                errors?.ofc_phone1?.length > 0)
                                ? "phone-error"
                                : ""
                            }`}
                          >
                            <PhoneInput
                              international={false}
                              placeholder="Office Phone"
                              countries={["US", "IN"]}
                              defaultCountry={
                                COUNTRY_CODE === "+91" ? "IN" : "US"
                              }
                              countryOptionsOrder={["US", "IN"]}
                              addInternationalOption={false}
                              value={values?.ofc_phone1}
                              // disabled
                              onChange={(event) => {
                                setFieldValue("ofc_phone1", event);
                                setFieldValue("ofc_phone", event);
                              }}
                            />
                          </Box>
                          {((values?.ofc_phone1 &&
                            !isValidPhoneNumber(values?.ofc_phone1)) ||
                            (touched?.ofc_phone1 &&
                              errors?.ofc_phone1?.length > 0)) && (
                            <Typography
                              sx={{
                                color: "#d32f2f !important",
                                fontSize: "12px !important",
                                margin: "0px !important",
                              }}
                            >
                              Please enter a valid phone number
                            </Typography>
                          )}
                        </Grid>

                        <Grid xs={12} md={6} px={1} pb={2}>
                          <FormControl
                            fullWidth
                            className="sign-textField"
                            error={touched.type && Boolean(errors.type)}
                          >
                            <InputLabel
                              shrink
                              // id="demo-simple-select-placeholder"
                              style={{ color: "#00000075" }}
                              label="Professional type"
                            >
                              Professional type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              value={values?.type}
                              label="Professional type"
                              name="type"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue("type", event.target.value);
                              }}
                              MenuProps={MenuProps}
                              displayEmpty
                            >
                              {professionalsList?.data?.map((val, index) => (
                                <MenuItem key={index} value={val?.id}>
                                  {val?.type}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.type && Boolean(errors.type) && (
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.type && errors.type}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid xs={12} md={12} px={1} pb={2}>
                          <div className="text-center createAcBtn mt-5">
                            <Button
                              type="submit"
                              className="popup_Btn other_popup_btn mx-1"
                              variant="contained"
                              onClick={() => handleClose()}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="popup_Btn success_popup_btn mx-1"
                              variant="contained"
                              disabled={
                                JSON.stringify(initialValues) ===
                                JSON.stringify(values)
                              }
                            >
                              Update
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </DialogContent>
        <Loader
          open={professionalListLoader || actionLoader || spSignupLoader}
        />
      </Dialog>
      {alertMsg && getObjLength(editServiceProvidersRes) && (
        <>
          {editServiceProvidersRes?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={onPopupClose}
              mesage={editServiceProvidersRes?.message}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={onPopupClose}
              mesage={editServiceProvidersRes?.data?.message}
              footer={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditServiceProvide;
