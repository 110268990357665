import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme, useMediaQuery } from '@mui/material';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import { Loader } from "../common/loader";
import AlertDialog from "../common/AlertDialog";
import Authentication from "./Authentication/index";
import { userSigInAction } from "../../redux/slices/user/userSignInSlice";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import SecureConnectionTemplate from "../common/secureConnectionTemplate";
import {
  clearLocalStoarge,
  decryptPayload,
  encryptPayload,
  getObjLength,
} from "../helpers/utils";
import {
  CUSTOM_DOMAIN_URL,
  jsonLastSteps,
  OTHER_PROVIDERS_URL,
  ROLE_CODES,
  ROLES,
  SUBDOMAIN,
} from "../helpers/constants";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";
const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  password: Yup.string("Please enter your Password.")
    .required("Password is required")
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    ),
});

const LoginScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginForm = useRef();
  const captchaRef = useRef();

  const [fromErr, setFormErr] = useState("");
  const [open, setOpen] = useState(false);
  const [authScreen, setAuthScreen] = useState(false);
  const [isBiometric, setIsBiometric] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));

  const params = window.location.search;
  let valuOf = params?.split("=")[0];
  let code = params?.split("=")[1];
  console.log(valuOf, "==valuOf==>", code);
  if (code === "ios" || code === "android") {
    localStorage.setItem("isMobile", encryptPayload(code));
  }

  const { userSignInDetails: loginDetails } = useSelector(
    (state) => state.signInUser
  );

  const { checkReCAPTCHADetails, checkReCAPTCHALoader } = useSelector(
    (state) => state?.signUpUser
  );

  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // clear success-error captcha data
    dispatch(userSigupAction.setreCAPTCHA({}));

    console.log("url==>>", params);

    // logout native method call for mobile app
    if (JSON.parse(localStorage.getItem("ivokeNativeMethod"))) {
      console.log("ivokeNativeMethod called=>");
      if (
        window?.webkit &&
        window?.webkit?.messageHandlers &&
        window?.webkit?.messageHandlers?.invokeNativeLogout
      ) {
        console.log("Logout Native App got called...");
        window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
        localStorage.removeItem("ivokeNativeMethod");
      }
    }

    sessionStorage.removeItem("userSignupDetails");
    localStorage.removeItem("userSignupDetails");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("user_Id");
    localStorage.removeItem("errorCount");
    localStorage.removeItem("skip");
    localStorage.removeItem("completed");
    localStorage.removeItem("promoCode");
    localStorage.removeItem("referralCode");
    localStorage.removeItem("ivokeNativeMethod");
    dispatch(userSigupAction.clearData());
    dispatch(userSigupAction.clearResumeProcessData());
    dispatch(userSigInAction?.clearStoreData());

    if (code !== "ios" && params.length > 0) {
      const addressID = params.split("?");
      localStorage.setItem("addressId", addressID[1]);
    } else {
      localStorage.removeItem("addressId");
    }

    // when user logs in from iOS APP
    let iosCode = "";
    let iosActiveLogin = "";
    if (code === "ios") {
      localStorage.setItem("isMobile", encryptPayload("ios"));
      let cookieValue = document.cookie;
      iosCode = cookieValue?.replace(
        // eslint-disable-next-line no-useless-escape
        /(?:(?:^|.*;\s*)iosValue\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      // For redirect the user on user-profile page after user kills the app
      iosActiveLogin = cookieValue?.replace(
        // eslint-disable-next-line no-useless-escape
        /(?:(?:^|.*;\s*)iosAppStoarge\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      if (iosActiveLogin) {
        // iosCode = source?.[2];
        const iosData = JSON.parse(window.atob(iosActiveLogin));
        console.log("iosActiveLogin==>", iosData);
        localStorage.setItem("authToken", iosData?.authToken);
        localStorage.setItem("authorised", iosData?.authorised);
        localStorage.setItem(
          "loggedInEmail",
          encryptPayload(iosData?.loggedInEmail)
        );
        localStorage.setItem("path", iosData?.path);
        localStorage.setItem("paymentPopup", iosData?.paymentPopup);
        localStorage.setItem("preference", iosData?.preference);
        localStorage.setItem("roles", encryptPayload(iosData?.role));
        localStorage.setItem("uID", iosData?.uID);
        // localStorage.setItem("stanupPopupCount", 1);
        localStorage.setItem("isIos", true);
        localStorage.setItem("paymentPopup", 0);
        localStorage.setItem("isMobile", encryptPayload("ios"));
      }

      if (iosCode) {
        // iosCode = source?.[2];
        const data = JSON.parse(window.atob(iosCode));

        dispatch(
          userSigInAction?.setUserCredentials({
            email: data.email,
            password: data.password,
          })
        );
        localStorage.setItem("preference", true);
        console.log("response==>", JSON.parse(data.response));
        dispatch(userSigInAction?.fetchUserData(JSON.parse(data.response)));
        console.log(iosCode, "cookie valye==>", data);
        localStorage.setItem("isMobile", encryptPayload("ios"));
      }
    }

    // if (!getObjLength(loginDetails)) {
    //   localStorage.removeItem("authToken");
    //   localStorage.removeItem("authorised");
    // }
    let roles = decryptPayload(localStorage.getItem("roles"));
    if (
      localStorage.getItem("authToken") &&
      roles &&
      code !== SUBDOMAIN?.provider
    ) {
      if (
        roles?.includes(ROLES.admin) ||
        roles?.includes(ROLES.subAdmin) ||
        roles?.includes(ROLES.provider) ||
        roles?.includes(ROLES.countyRecorder)
      ) {
        navigate("/admin-dashboard", { replace: false });
      }
      if (roles?.includes(ROLES.professional)) {
        navigate("/professional-dashboard", { replace: false });
      }
      if (roles?.includes(ROLES.customer)) {
        navigate("/user-profile", { replace: false });
      }
    }

    // login native method call for mobile app
    if (code !== "ios") {
      if (
        window?.webkit &&
        window?.webkit?.messageHandlers &&
        window?.webkit?.messageHandlers?.invokeNativeLogin
      ) {
        console.log("Login Native App got called...");
        window.webkit.messageHandlers.invokeNativeLogin.postMessage({});
      }
    }
    if (code === SUBDOMAIN?.provider) {
      clearLocalStoarge("check_county");
    }
    if (!localStorage.getItem("authToken")) {
      localStorage?.removeItem("roles");
    }
  }, []);

  useEffect(() => {
    if (getObjLength(loginDetails)) {
      if (loginDetails?.success) {
        handleClose();
        setRecaptchaToken(null);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        localStorage.removeItem("CAPTCHA_Validate");
      } else {
        validate();
        setRecaptchaToken(null);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        loginForm?.current?.setSubmitting(false);
      }
    }
  }, [loginDetails]);

  const validate = () => {
    handleClose();
    setFormErr(loginDetails?.data?.message);
  };

  const loggedIn = () => {
    console.log("login called...");
  };
  const onSubmit = (values) => {
    console.log("Form data", values);
    localStorage.removeItem("roles");
    dispatch(userSigInAction.userSignInData({ url: "login", data: values }));
    // localStorage.clear();
    // localStorage.setItem("preference", true);
    handleToggle();
  };
  const handleClose = () => {
    setOpen(false);
    const previousPath = localStorage.getItem("previousPath");
    console.log("previousPath", previousPath);
    if (getObjLength(loginDetails) && loginDetails?.success) {
      let uID = loginDetails?.data?.user?.user_id;
      localStorage.setItem("uID", uID);
      localStorage.removeItem("CAPTCHA_Validate");
      setFormErr("");
      console.info(loginDetails, " ============Login Details============");
      console.log(
        "loginDetails?.data?.role?.includes(ROLES.subAdmin)",
        loginDetails?.data?.role
      );
      if (
        loginDetails?.data?.role?.includes(ROLES.admin) ||
        loginDetails?.data?.role?.includes(ROLES.subAdmin) ||
        loginDetails?.data?.role?.includes(ROLES.provider) ||
        loginDetails?.data?.role?.includes(ROLES.countyRecorder)
      ) {
        if (
          previousPath &&
          parseInt(uID) === parseInt(localStorage.getItem("previousUserID"))
        ) {
          navigate(previousPath, { replace: false });
          localStorage.removeItem("previousPath");
        } else {
          navigate("/admin-dashboard", { replace: false });
        }
      }
      if (loginDetails?.data?.status === "pending") {
        dispatch(userSigInAction.clearStoreData());
        if (
          loginDetails?.data?.from_provider &&
          parseInt(loginDetails?.data?.json_last_step?.step) < 3 &&
          loginDetails?.data?.json_last_step !== jsonLastSteps?.PAYMENT
        ) {
          let providerData = {
            user_id: loginDetails.data?.id,
            step: loginDetails.data?.json_last_step?.step,
            last_step: loginDetails?.data?.json_last_step?.step_key,
            phone: loginDetails.data?.phone,
            email: loginDetails.data?.email,
            searched_address: loginDetails.data?.searched_address,
          };
          let redirectionURL = null;
          if (loginDetails?.data?.domain) {
            redirectionURL = OTHER_PROVIDERS_URL + loginDetails?.data?.domain;
          }
          if (loginDetails?.data?.provider?.custom_domain) {
            redirectionURL =
              CUSTOM_DOMAIN_URL +
              loginDetails?.data?.provider?.custom_domain +
              ".com";
          }

          if (redirectionURL) {
            window.location.href =
              redirectionURL +
              "/create-account?" +
              encryptPayload(JSON.stringify(providerData));
          } else {
            setFormErr("Something went wrong. Please try again later.");
          }
        } else {
          let county_details = loginDetails?.data?.county_details;
          if (getObjLength(county_details)) {
            localStorage.setItem(
              "county-code",
              encryptPayload(county_details?.county_domain_name)
            );
            //Set County-Recorder Page to HomePage(scrp_hp)
            localStorage.setItem("scrp_hp", 1);
            navigate(
              "/" + county_details?.county_domain_name + "/create-account",
              {
                replace: false,
              }
            );
          } else {
            localStorage.removeItem("scrp_hp");
            localStorage.removeItem("county-code");
            dispatch(propertyRiskManagementAction.fetchCountyData({}));
            navigate("/create-account", { replace: false });
          }
        }
      }
      setIsBiometric(loginDetails?.data?.biometric_registered);
      if (
        loginDetails?.data?.role?.includes(ROLES.customer) ||
        loginDetails?.data?.user?.user_type?.includes(ROLES.secondaryUser) ||
        loginDetails?.data?.role?.includes(ROLES.professional)
      ) {
        setAuthScreen(true);
        localStorage.setItem("subuserLogin", 1);
      }
      // Check if the user role exists in ROLES
      if (
        Object.values(ROLES).includes(
          decryptPayload(localStorage.getItem("roles"))
        )
      ) {
        // If yes, get the corresponding role code from ROLE_CODES
        const userRoleCode = Object.keys(ROLES)?.find(
          (role) =>
            ROLES[role] === decryptPayload(localStorage.getItem("roles"))
        );
        // Store the user role code in localStorage
        localStorage.setItem(
          "userRoleCode",
          encryptPayload(ROLE_CODES?.[userRoleCode].toString())
        );
      }
      // navigate("/user-profile", { replace: true });
    } else {
      setAuthScreen(false);
    }
  };
  const handleToggle = () => {
    setOpen(!open);
    console.log("Logi details==>.", loginDetails);
  };

  const handleBackBtn = () => {
    setAuthScreen(false);
    dispatch(userSigInAction?.clearStoreData());
    if (code === "ios") {
      if (
        window?.webkit &&
        window?.webkit?.messageHandlers &&
        window?.webkit?.messageHandlers?.invokeNativeLogin
      ) {
        console.log("Login Native App got called from Back button...");
        window.webkit.messageHandlers.invokeNativeLogin.postMessage({});
      }
    }
  };

  // useEffect(() => {
  //   if (
  //     getObjLength(checkReCAPTCHADetails) &&
  //     checkReCAPTCHADetails?.data?.success &&
  //     formValues
  //   ) {
  //     if (localStorage.getItem("CAPTCHA_Validate")) {
  //       onSubmit(formValues);
  //     }
  //   }
  // }, [checkReCAPTCHADetails]);

  function login() {
    return (
      <>
        {!authScreen ? (
          <Box className="accountBoxWrapper Form_Wrapper">
            <Typography align="center" variant="h3">
              Log in to your account
            </Typography>
            <Typography
              align="center"
              variant="body1"
              sx={{ marginBottom: "1rem !important" }}
            >
              To access your account, please enter your provided credentials
              below
            </Typography>
            <Box>
              <Formik
                enableReinitialize
                validateOnChange
                initialValues={initialValues}
                validationSchema={validationSchema}
                innerRef={loginForm}
                onSubmit={(values) => {
                  // if (!recaptchaToken) {
                  //   return;
                  // }
                  // localStorage.setItem("CAPTCHA_Validate", 1);
                  // dispatch(
                  //   userSigupAction.checkreCAPTCHA({
                  //     url: `validate-captcha?token=${recaptchaToken}`,
                  //     data: {},
                  //   })
                  // );
                  // setFormValues(values);

                  const payload = JSON.parse(JSON.stringify(values));
                  payload.password = values?.password?.trim();
                  onSubmit(payload);
                }}
              >
                {({ values, setFieldValue, touched, errors, isSubmitting }) => {
                  return (
                    <Form>
                      <Grid
                        container
                        spacing={2}
                        textAlign={"center"}
                        alignItems={"center"}
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            className="textField"
                            label="Email address"
                            name="email"
                            variant="outlined"
                            // sx={{ width: "320px" }}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#0D120B",
                              },
                              shrink: true,
                            }}
                            placeholder={"Email address"}
                            value={values?.email}
                            onChange={(event) => {
                              setFieldValue("email", event.target.value);
                            }}
                            helperText={touched.email && errors.email}
                            error={touched.email && Boolean(errors.email)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl className="textField">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                              className="textfield"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              // sx={{ width: "320px" }}
                              name="password"
                              inputProps={{
                                autoComplete: "new-password",
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword(setShowPassword)
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              value={values?.password}
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  "password",
                                  event.target.value?.trim()
                                );
                              }}
                              helperText={touched.password && errors.password}
                              error={
                                touched.password && Boolean(errors.password)
                              }
                            />
                            {touched.password && Boolean(errors.password) && (
                              <FormHelperText
                                className="d-block d-sm-none"
                                sx={{
                                  color: "rgb(211, 47, 47) !important",
                                  fontSize: "12px !important",
                                  maxWidth: "100% !important",
                                }}
                              >
                                {touched.password && errors.password}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {touched.password && Boolean(errors.password) && (
                            <FormHelperText
                              className="d-none d-sm-block"
                              sx={{
                                color: "rgb(211, 47, 47) !important",
                                fontSize: "12px !important",
                                px: 0.5,
                              }}
                            >
                              {touched.password && errors.password}
                            </FormHelperText>
                          )}
                        </Grid>
                        {/* <Grid
                          item
                          xs={12}
                          md={12}
                          className="d-flex justify-content-center my-2"
                        >
                          <FormControl className="textField captcha-field">
                            <ReCAPTCHA
                              sitekey={RECAPTCHA_SITE_KEY}
                              onChange={handleCaptchaChange}
                              className="g-recaptcha"
                              ref={captchaRef}
                            />
                          </FormControl>
                        </Grid>*/}
                        {fromErr?.length > 0 && (
                          <p className="text-danger text-center w-100 mb-3">
                            {fromErr}
                          </p>
                        )}
                      </Grid>
                      <div className="text-center stepBtn mt-4">
                        <Button
                          className="login_button"
                          type="submit"
                          variant="contained"
                          onClick={loggedIn}
                        >
                          Log In Now
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div className="text-center resetPwd mt-3">
                <Link
                  to="/reset-password"
                  onClick={() => {
                    setFormValues(null);
                    localStorage.removeItem("CAPTCHA_Validate");
                    dispatch(userSigInAction.clearStoreData());
                  }}
                >
                  Reset your password
                </Link>
              </div>
            </Box>
          </Box>
        ) : (
          <Authentication
            isBiometric={isBiometric}
            handleBackBtn={handleBackBtn}
          />
        )}
      </>
    );
  }
  return (
    <>
      <Grid sx={isPadding && {marginBottom:"110px"}} container>
        <Grid
          sx={{ margin: "0 auto" }}
          xs={12}
          sm={12}
          // mdOffset={2}
          md={10}
          lgOffset={2}
          lg={8}
          xlOffset={2}
          xl={8}
        >
          <SecureConnectionTemplate
            content={login()}
            // content={!authScreen ? login() : Authentication()}
            // content={Authentication()}
            footer={true}
          />
        </Grid>
      </Grid>
      {getObjLength(checkReCAPTCHADetails) &&
        !checkReCAPTCHADetails?.data?.success && (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userSigupAction.setreCAPTCHA({}));
            }}
            mesage={checkReCAPTCHADetails?.data?.message}
            footer={false}
          />
        )}
      <Loader open={open || checkReCAPTCHALoader} />
    </>
  );
};

export default LoginScreen;
