import React from "react";
import { Box, Grid, Button, Typography, Dialog } from "@mui/material";
import { Link } from "react-router-dom";
import Successbadge from "../../../assets/properties/success-badge.svg";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const DownloadCSV = ({ onClose }) => {
  const { exportCsvLoader, exportCsvData } = useSelector(
    (state) => state.exportCSVDetails
  );

  return (
    <Box sx={{ p: 2 }} className="step4 documentSteps successStep">
      <Button
        onClick={onClose}
        className="modal-close-btn"
        style={{
          position: "absolute",
          right: 5,
          top: 5,
          background: "transparent",
        }}
      >
        <CloseIcon sx={{ color: "#9CB8A6" }} />
      </Button>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h5" className="title mb-3">
            Success
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            variant="body1"
            className="text-center description mb-3 mb-md-5"
          >
            This is a success message. Your requested payment details document
            is ready! You can download it below.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <img src={Successbadge} alt="success badge" className="py-4" />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12}>
          <Link href="#" className="downloadDocumentLink">
            {exportCsvData?.data?.csv_link}
          </Link>
        </Grid> */}
        <Grid item xs={12} sm={8} md={6}>
          <a
            className="custom-link-wrapper text-break"
            href={exportCsvData?.data?.csv_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              onClick={() => onClose()}
              variant="contained"
              className="popup_Btn success_popup_btn mx-2"
            >
              Download your file
            </Button>
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadCSV;
