import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box, Divider, Grid, Typography } from "@mui/material";

import { getLocalFormatedDate } from "../../helpers/utils";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";

import CheckIcon from "@mui/icons-material/Check";
import PrintIcon from "@mui/icons-material/Print";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ForwardIcon from "@mui/icons-material/Forward";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#27aa80",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#27aa80",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#27aa80",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    background: "#27aa80",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <CheckIcon />,
    2: <PrintIcon />,
    3: <ForwardIcon />,
    4: <LocalShippingIcon />,
    5: <LocationOnIcon />,
    6: <MarkEmailReadIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  { key: "received", label: "Recieved" },
  { key: "producing", label: "Producing" },
  { key: "handed_over", label: "Handed Over" },
  { key: "in_transit", label: "In Transit" },
  { key: "local_delivery", label: "Local Delivery" },
  { key: "delivered", label: "Delivered" },
];

function CustomizedSteppers({ postcardData }) {
  console.log("postcardData", postcardData);
  let activeStep = null;
  if (postcardData?.tracking?.delivered) {
    activeStep = 5;
  } else if (postcardData?.tracking?.local_delivery) {
    activeStep = 4;
  } else if (postcardData?.tracking?.in_transit) {
    activeStep = 3;
  } else if (postcardData?.tracking?.handed_over) {
    activeStep = 2;
  } else if (postcardData?.tracking?.producing) {
    activeStep = 1;
  } else if (postcardData?.tracking?.received) {
    activeStep = 0;
  }
  console.log("activeStep", activeStep);
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((data, key) => (
          <Step key={key}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {data?.label}
            </StepLabel>
            <Typography className="text-center" sx={{ fontSize: "12px" }}>
              {postcardData?.tracking?.[data?.key]}
            </Typography>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
const PostCardTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.propertyLists?.viewPropertyDetails
  );
  let postcardData = propertyData?.user_mailing_address?.postcards;

  return (
    <>
      {postcardData?.length > 0 ? (
        postcardData.map((data, key) => (
          <>
            <CustomizedSteppers postcardData={data} />
            <Box className="p-5">
              <Grid container spacing={2} mb={3}>
                <Grid item xs={12} sm={10} md={8} lg={6} className="mx-4">
                  <Row>
                    <Col md={4} lg={4} className="label-text">
                      <Typography>Mailing Address: </Typography>
                    </Col>
                    <Col className="value-text">
                      <Typography>
                        {propertyData?.user_mailing_address?.id
                          ? [
                              propertyData?.user_mailing_address?.address,
                              propertyData?.user_mailing_address?.city,
                              propertyData?.user_mailing_address?.state,
                            ]?.join(", ")
                          : "-"}
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} lg={4} className="label-text">
                      <Typography>Zip: </Typography>
                    </Col>
                    <Col className="value-text">
                      <Typography>
                        {propertyData?.user_mailing_address?.id
                          ? propertyData?.user_mailing_address?.zip
                          : "-"}
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} lg={4} className="label-text">
                      <Typography>Status: </Typography>
                    </Col>
                    <Col className="value-text text-capitalize">
                      <Typography>
                        {data?.status || "-"}{" "}
                        {data?.delivery_date &&
                          `(
                  ${data?.delivery_date || null})`}
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} lg={4} className="label-text">
                      <Typography>Sent Date: </Typography>
                    </Col>
                    <Col className="value-text">
                      <Typography>
                        {data?.created_at
                          ? getLocalFormatedDate(data?.created_at)
                          : "-"}
                      </Typography>
                    </Col>
                  </Row>
                </Grid>
              </Grid>
              {postcardData?.length > 1 && postcardData?.length - 1 !== key && (
                <Divider />
              )}
            </Box>
          </>
        ))
      ) : (
        <Typography className="p-5 text-center" variant="h6">
          No Data Found.
        </Typography>
      )}
    </>
  );
};

export default PostCardTab;
