import React from "react";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Zoom from "@mui/material/Zoom";
import { useSelector } from "react-redux";

const referalLinkStryle = {
  width: "87%",
};

const ReferralLink = ({ Paperstyles }) => {
  const { referralDetails } = useSelector((state) => state?.userProfile);
  console.log("referralDetails==>", referralDetails);
  return (
    <Paper elevation={0} className="trusted-contacts" sx={Paperstyles}>
      <Box
        className="d-flex align-items-center justify-content-between"
        sx={{ mb: 1 }}
      >
        <Typography variant="h6" className="card-title">
          Referral Link
        </Typography>
      </Box>
      <Typography variant="body1" className="w-75 caption mb-3 mb-lg-4">
        You can share this link with others and you can receive some discounts
        to your account.
      </Typography>
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          spacing={3}
          // margin={2}
        >
          <Box className="contacts d-flex flex-column">
            <Typography variant="body1" className="px-2" sx={referalLinkStryle}>
              {referralDetails?.success &&
              referralDetails?.data?.url?.length > 0
                ? referralDetails?.data?.url
                : "No referral link found for now."}
            </Typography>
          </Box>
          {referralDetails?.success &&
          referralDetails?.data?.url?.length > 0 ? (
            <Stack
              direction="row"
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              <Tooltip
                title="Copy Referral Link"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigator.clipboard.writeText(referralDetails?.data?.url)
                  }
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </>
    </Paper>
  );
};

export default ReferralLink;
