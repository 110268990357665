import { createSlice } from "@reduxjs/toolkit";

import {
  decryptPayload,
  reactPixelEventOnlyForCustomer,
} from "../../../components/helpers/utils";

const intialPropertiesState = {
  propertiesData: [],
  propertiesCount: 6,
  getPropertyData: {},
  verifiedPropertiesList: {},
  propertyVerified: {},
  propertyQuestion: {},
  selectedProperty: {},
  editPropertiesDetails: {},
  propertiesLoader: false,
  documentTypes: {},
  requestDoc: {},
  generateDoc: {},
  reqLoader: false,
  propertyLoader: false,
  propertyPayment: {},
  cancelSubscriptionDetails: {},
  cancelSubscriptionMetaData: {},
  cancelSubscriptionLoader: false,
  viewDetailsPropertyData: {},
  subscriptionHistoryLoader: false,
  subscriptionHistory: [],
  selectedUserID: null,
  isQuestionByPass: false,
  isSubscriptionByPass: false,
  verificationLoader: false,
  isBypassOptionAvailable: false,
  addSubscriptionLoader: false,
  addSubscriptionDetails: {},
  selectedPropertiesData: {},
  securityPropertiesQueData: {},
  verifySecurityInfoData: {},
  stopSubscriptionData: {},
  genearteQuesPayload: {},
  userViewDetailsPropertyData: {},
};

const properties = createSlice({
  name: "propertiesDetails",
  initialState: intialPropertiesState,
  reducers: {
    resetState: () => intialPropertiesState,
    //  for modal
    clearPropertiesList(state, action) {
      console.log("properties list==>", action.payload);
      state.getPropertyData = {};
      state.verifiedPropertiesList = {};
      state.propertyVerified = {};
      state.propertyQuestion = {};
      // state.documentTypes = {};
      state.generateDoc = {};
      state.reqLoader = false;
      state.propertyPayment = {};
      state.paymentLoader = false;
      state.viewDetailsPropertyData = {};
      if (action.payload !== "error") {
        state.selectedProperty = {};
        state.selectedUserID = null;
      }
      state.isQuestionByPass = false;
      state.isSubscriptionByPass = false;
      state.verificationLoader = false;
      state.isBypassOptionAvailable = false;
      state.propertyLoader = false;
    },
    //for logout
    resetProperties(state, action) {
      state.propertiesData = [];
      state.propertiesCount = 0;
      state.getPropertyData = {};
      state.verifiedPropertiesList = {};
      state.propertyVerified = {};
      state.propertyQuestion = {};
      state.selectedProperty = {};
      state.documentTypes = {};
      state.generateDoc = {};
      state.reqLoader = false;
      state.viewDetailsPropertyData = {};
      state.selectedUserID = null;
      state.propertyLoader = false;
    },
    fetchPropertyList(state, action) {
      state.propertiesLoader = true;
      console.log("property payload==>>", action.payload);
    },
    setPropertyList(state, action) {
      const objectDictionary = {};

      state.propertiesData?.forEach((obj) => {
        objectDictionary[obj["id"]] = obj;
      });

      action.payload?.data?.forEach((obj) => {
        objectDictionary[obj["id"]] = obj;
      });

      const resultArray = Object.values(objectDictionary);
      state.propertiesData = resultArray;
      state.propertiesCount = action.payload?.count || 0;
      state.propertiesLoader = false;
    },
    fetchUserProperty(state, action) {
      state.propertyQuestion = {};
      state.propertyLoader = true;
      console.log("fet user property payload==>>", action.payload);
    },
    setUserPropertyData(state, action) {
      console.log("In Property slice...", action.payload);
      state.getPropertyData = action.payload;
      state.verifiedPropertiesList = {};
      state.propertyVerified = {};
      state.propertyLoader = false;
    },
    addNewProperty(state, action) {
      console.log("New property slice..", action.payload);
      state.getPropertyData = {};
      state.selectedProperty = {};
      state.propertyQuestion = {};
      // state.errorFlagCount = 0;
    },
    generateQuestions(state, action) {
      state.propertyLoader = true;
      console.log("In generateQuestions slice...", action.payload);
    },
    generateQuestionsInputParams(state, action) {
      console.log("In generateQuestions params slice...", action.payload);
      state.genearteQuesPayload = action.payload;
    },
    setGeneratedQuestionsData(state, action) {
      console.log("In statecode slice...", action.payload);
      state.propertyQuestion = action.payload;
      state.propertyLoader = false;
      state.verifiedPropertiesList = {};
    },
    editPropertiesDetails(state, action) {
      state.propertyLoader = true;
      console.log("In edit property slice...", action.payload);
    },
    setEditPropertiesData(state, action) {
      console.log("In edit property slice...", action.payload);
      state.editPropertiesDetails = action.payload;
      state.propertyLoader = false;
    },
    securityPropertiesQueDetails(state, action) {
      state.propertyLoader = true;
      console.log("In security generateQuestions slice...", action.payload);
    },
    setSecurityPropertiesQueData(state, action) {
      console.log("In security Properties Que  slice...", action.payload);
      state.securityPropertiesQueData = action.payload;
      state.propertyLoader = false;
    },
    verifySecurityInfoDetails(state, action) {
      state.propertyLoader = true;
      console.log("In security generateQuestions slice...", action.payload);
    },
    fetchVerifySecurityInfoData(state, action) {
      console.log("In security Properties Que  slice...", action.payload);
      state.verifySecurityInfoData = action.payload;
      state.propertyLoader = false;
    },
    setStopSubscriptionData(state, action) {
      console.log("In StopSubscription  slice...", action.payload);
      state.propertyLoader = true;
    },
    fetchStopSubscriptionDetails(state, action) {
      state.propertyLoader = false;
      state.stopSubscriptionData = action.payload;
      console.log("In Stop Subscription slice...", action.payload);
    },
    clearGeneratedQuestionsData(state, action) {
      console.log("In statecode slice...", action.payload);
      state.propertyQuestion = {};
      state.getPropertyData = {};
      state.propertyVerified = {};
      state.selectedProperty = {};
      state.editPropertiesDetails = {};
      state.propertyLoader = false;
      state.verifiedPropertiesList = {};
      state.isQuestionByPass = false;
    },
    selectedUserPropertyData(state, action) {
      console.log("In selected Property slice...", action.payload);
      state.selectedProperty = action.payload;
    },
    verifyAnswers(state, action) {
      console.log("In verify answer slice...", action.payload);
    },
    setVerifedAnswersData(state, action) {
      console.log("In verify answer slice...", action.payload);
      state.propertyVerified = action.payload;
    },
    clearVerifedAnswersData(state, action) {
      console.log("In verify answer slice...", action.payload);
      state.propertyVerified = {};
    },
    setVerificationLoader(state, action) {
      state.verificationLoader = action.payload;
    },
    getDocumentTypes(state, action) {},
    fetchDocumentTypeList(state, action) {
      console.log("Document type list slice ...", action.payload);
      state.documentTypes = action.payload;
    },
    reqDocument(state, action) {
      state.reqLoader = true;
    },
    fethRequestedDoc(state, action) {
      console.log("reqDocument slice==>", action.payload);
      state.requestDoc = action.payload;
      state.reqLoader = false;
    },
    generateDocument(state, action) {
      state.reqLoader = true;
    },
    fethGeneratedDoc(state, action) {
      console.log("getGeneratedDoc slice==>", action.payload);
      state.generateDoc = action.payload;
      state.reqLoader = false;
    },
    paymentForProperty(state, action) {
      console.log("paymentForProperty slice==>", action.payload);
      state.paymentLoader = true;
    },
    fethPayment(state, action) {
      console.log("getGeneratedDoc slice==>", action.payload);
      state.propertyPayment = action.payload;
      state.paymentLoader = false;
    },
    cancelSubscription(state, action) {
      state.cancelSubscriptionLoader = true;
      state.cancelSubscriptionMetaData = action.payload;
    },
    setCancelSubscription(state, action) {
      state.cancelSubscriptionDetails = action.payload;

      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        propertyAddress: state.cancelSubscriptionMetaData?.propertyAddress,
        type: state.cancelSubscriptionMetaData?.data?.type,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Cancel Subscription", data);
      }
      state.cancelSubscriptionLoader = false;
    },
    clearCancelSubscriptionStates(state, action) {
      state.cancelSubscriptionDetails = {};
      state.cancelSubscriptionLoader = false;
    },
    getViewPropertyDetails(state, action) {
      state.viewPropertyDetailLoader = true;
      state.userViewDetailsPropertyData = {};
    },
    setViewPropertyDetails(state, action) {
      state.userViewDetailsPropertyData = action?.payload;
      state.viewPropertyDetailLoader = false;
    },
    setViewDetailsPropertyData(state, action) {
      state.viewDetailsPropertyData = action.payload;
    },
    clearOrderDoc(state, action) {
      state.requestDoc = {};
      state.generateDoc = {};
      state.reqLoader = false;
    },
    fetchSubscriptionHistory(state, action) {
      state.subscriptionHistoryLoader = true;
    },
    setSubscriptionHistory(state, action) {
      state.subscriptionHistory = action.payload;
      state.subscriptionHistoryLoader = false;
    },
    setSelectedUserID(state, action) {
      state.selectedUserID = action.payload;
    },
    setIsQuestionByPass(state, action) {
      state.isQuestionByPass = action.payload;
    },
    setIsSubscriptionByPass(state, action) {
      state.isSubscriptionByPass = action.payload;
    },
    setIsBypassOptionAvailable(state, action) {
      state.isBypassOptionAvailable = action.payload;
    },
    addPropertySubscription(state, action) {
      state.addSubscriptionLoader = true;
    },
    setAddPropertySubscription(state, action) {
      state.addSubscriptionDetails = action.payload;
      console.log("addPropertySubscription==:>", action.payload);
      if (action.payload?.success && !action.payload?.data?.actionRequired) {
        if (action.payload?.success) {
          reactPixelEventOnlyForCustomer(
            "Add Subscription Successfully",
            JSON.parse(localStorage.getItem("PlanDetails"))
          );
        } else {
          reactPixelEventOnlyForCustomer(
            "Add Subscription Failed",
            JSON.parse(localStorage.getItem("PlanDetails"))
          );
        }
        localStorage.removeItem("PlanDetails");
      }
      state.addSubscriptionLoader = false;
    },
    selectedPropertiesData(state, action) {
      state.selectedPropertiesData = action.payload;
    },
  },
});
export const propertiesAction = properties.actions;
export default properties.reducer;
