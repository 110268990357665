import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import PhoneInput from "react-phone-number-input";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DeleteModal from "../../common/DeleteModal";
import AlertDialog from "../../common/AlertDialog";
import { COUNTRY_CODE, PER_PAGE } from "../../helpers/constants";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";
import {
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

import "../AdminDashboard/propertyTable.scss";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const PROFESSIONAL_REFERRAL_LINK =
  process.env.REACT_APP_PROFESSIONAL_REFERRAL_LINK;

const columns = [
  {
    key: 1,
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  { key: 2, id: "name", disablePadding: true, label: "Name", minWidth: 135 },
  {
    key: 3,
    id: "company_name",
    disablePadding: true,
    label: "Company",
    minWidth: 120,
  },
  { key: 4, id: "email", disablePadding: true, label: "Email", minWidth: 200 },
  {
    key: 5,
    id: "phone_number",
    disablePadding: true,
    label: "Phone Number",
    minWidth: 170,
  },
  {
    key: 6,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 120,
  },
  {
    key: 7,
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    minWidth: 120,
    sorting: true,
  },
  {
    key: 8,
    id: "referral_link",
    disablePadding: true,
    label: "Referral Link",
    minWidth: 80,
  },
  {
    key: 9,
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 70,
  },
];

function Row({
  row,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
  handleMenuItemClick = () => {},
  setSelectedRow = () => {},
}) {
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell
          className="text-capitalize"
          align="left"
        >{`${row?.first_name} ${row?.last_name}`}</TableCell>
        <TableCell align="left">{row?.company_name}</TableCell>
        <TableCell align="left" sx={{ wordBreak: "break-all" }}>
          {row?.email}
        </TableCell>
        <TableCell align="left">
          {row?.phone_number ? getFormatedPhoneNumber(row?.phone_number) : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.status === "active" ? (
            <span className="text-success text-capitalize">{row?.status}</span>
          ) : (
            <span className="text-danger text-capitalize">{row?.status}</span>
          )}
        </TableCell>
        <TableCell align="left" width={130}>
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left">
          <Tooltip
            title="Copy Referral Link"
            TransitionComponent={Zoom}
            placement="top"
            arrow
          >
            <IconButton
              sx={{
                color: "#0395FF",
                fontSize: "1.4rem",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  PROFESSIONAL_REFERRAL_LINK + row?.promo_codes?.[0].promo_code
                );
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="left" width={120}>
          <Stack direction="row" spacing={0}>
            <Tooltip
              title="actions"
              sx={{
                color: "#15BE53",
                // fontSize: "1.4rem",
                // cursor: "pointer",
              }}
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRow(row);
                  handleMenuItemClick(e);
                }}
              >
                <SettingsIconSVG color={"#15BE53"} />
              </IconButton>
            </Tooltip>

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      {/* collapsible row */}
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Zip Range
                </Typography>
                <Typography className="content-text">
                  {`${row?.zip_code}-${row?.zip_code2}`}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Professional Type
                </Typography>
                <Typography className="content-text">
                  {row?.professional_type?.type}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Referral Code
                </Typography>
                <Typography className="content-text">
                  {row?.promo_codes?.[0]?.promo_code}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Tooltip
                title="Click here to see professional's details."
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    More details..
                  </Typography>
                  <Link
                    className="text-decoration-none "
                    to={"/admin-dashboard/" + row?.id}
                  >
                    <Typography className="content-text">
                      Go to Dashboard
                    </Typography>
                  </Link>
                </Stack>
              </Tooltip>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        ></TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ProfessionalListTable({
  page,
  setPage,
  handleEditButton,
  setIsAddProfessionalModalOpen,
  isActivateDeactivateProfessionalModalOpen,
  setIsActivateDeactivateProfessionalModalOpen
}) {
  const dispatch = useDispatch();
  const params = useParams();

  // states
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [isDeleteProfessionalModalOpen, setIsDeleteProfessionalModalOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedProfessionalType, setSelectedProfessionalType] = useState("");
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [isCallFilterAPI, setIsCallFilterAPI] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(6); // updated_at key for sorting
  const [sortingColumn, setSortingColumn] = useState("created_at"); // created_at for default sorting
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [
  //   isActivateDeactivateProfessionalModalOpen,
  //   setIsActivateDeactivateProfessionalModalOpen,
  // ] = useState(null);
  const [actionPerformed, setActionPerformed] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  // store data
  const {
    professionalListLoader,
    professionalTypeList,
    professionalData,
    professionalDataCount,
    deleteProfessionalDetails,
    addProfessionalDetails,
    activateDeactivateProfessionalDetails,
    activateDeactivateProfessionalLoader,
  } = useSelector((state) => state?.professionals);

  const count = Math.ceil(professionalDataCount / PER_PAGE);
  const _DATA = professionalData?.data ? professionalData?.data : [];

  const handlePageChange = (e, p) => {
    setPage(p);
    dispatch(
      professionalAction.fetchProfessionalListData({
        url: getProfessionalUrl(p),
        data: {},
        token: true,
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // handle open delete modal
  const handleDeleteButton = (selectedItem) => {
    setIsDeleteProfessionalModalOpen(true);
    setSelectedItem(selectedItem);
  };

  //   handleClosing delete modal
  const handleCloseDeleteModal = () => {
    setIsDeleteProfessionalModalOpen(false);
    setSelectedItem(null);
    dispatch(professionalAction.clearData({}));
  };

  // dispatch action to delete professional
  const handleDeleteProfessional = () => {
    if (selectedItem && selectedItem.id) {
      dispatch(
        professionalAction.deleteProfessionalDetails({
          url: `professional/${selectedItem?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // handle filter property in professionals
  const handleFilterProperty = () => {
    if (
      (selectedKey && selectedValue) ||
      selectedProfessionalType ||
      selectedDateRange ||
      selectedStatus
    ) {
      dispatch(
        professionalAction.fetchProfessionalListData({
          url: getProfessionalUrl(1),
          data: {},
          token: true,
        })
      );
      setPage(1);
      setIsCallFilterAPI(true);
    } else {
      return;
    }
  };

  // handle reset function in professionals
  const resetFilterOption = () => {
    if (isCallFilterAPI) {
      setSelectedKey("all");
      setSelectedValue("");
      setSelectedProfessionalType("");
      setSelectedStatus("");
      setSelectedDateRange(null);
      setPage(1);

      setSelectedColumn(6);
      setDirectionFlow(true);
      setIsCallFilterAPI(false);
      // setProfessionalPayload({});
      dispatch(
        professionalAction.fetchProfessionalListData({
          url: `professional?limit=${PER_PAGE}&offset=${0}`,
          data: {},
          token: true,
        })
      );
    } else {
      setSelectedKey("all");
      setSelectedValue("");
      setSelectedProfessionalType("");
      setSelectedStatus("");
      setSelectedDateRange(null);
    }
  };

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  // for sorting
  const handleSorting = (column) => {
    setSortingColumn(column);
    setDirectionFlow(!directionFlow);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActivateDeactivateProfessional = (selectedProfessional) => {
    console.log("selectedProfessional", selectedProfessional);
    if (selectedProfessional?.id) {
      let URL = "";
      if (actionPerformed === "activate") {
        URL = `professional/${selectedProfessional?.id}/active`;
      } else {
        URL = `professional/${selectedProfessional?.id}/inactive`;
      }

      dispatch(
        professionalAction.activateDeactivateProfessional({
          url: URL,
          data: {},
          token: true,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      professionalAction.fetchProfessionalListData({
        url: `${getProfessionalUrl(page)}`,
        data: {},
        token: true,
      })
    );
  }, [sortingColumn, directionFlow]);

  const getProfessionalUrl = (p) => {
    let URL = "";
    if (!isNaN(params?.professionalId)) {
      URL = `professional/${params?.professionalId}`;
    } else {
      URL = `professional`;
    }
    const key = selectedKey
      ? `&type=${selectedKey === "company_name" ? "company" : selectedKey}`
      : "";
    const value = selectedValue
      ? `&value=${encodeURIComponent(selectedValue)}`
      : "";
    const professional_type = selectedProfessionalType
      ? `&professional_type=${selectedProfessionalType}`
      : "";
    const status = selectedStatus ? `&status=${selectedStatus}` : "";
    const selectedDateRangePayload = selectedDateRange
      ? `&from_date=${
          getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) || ""
        } &to_date=${
          getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) || ""
        }`
      : "";

    const direction = directionFlow
      ? `&sorting_order=desc`
      : `&sorting_order=asc`;
    const column = sortingColumn ? `&order_by_column=${sortingColumn}` : "";

    const finalUrl = `${URL}?limit=${PER_PAGE}&offset=${
      (p - 1) * PER_PAGE
    }${key}${value}${professional_type}${status}${selectedDateRangePayload}${direction}${column}`;

    return finalUrl;
  };

  const getProfessionalDetails = useCallback(() => {
    let URL = "";
    if (!isNaN(params?.professionalId)) {
      URL = `professional/${
        params?.professionalId
      }?limit=${PER_PAGE}&offset=${0}`;
    } else {
      URL = `professional?limit=${PER_PAGE}&offset=${0}`;
    }
    // dispatch();
    dispatch(
      professionalAction.fetchProfessionalListData({
        url: URL,
        data: {},
        token: true,
      })
    );
  }, [dispatch, params?.professionalId]);

  useEffect(() => {
    getProfessionalDetails();
  }, [getProfessionalDetails]);

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      {/* Filtering Component Section */}
      <Tooltip title="Filter options" placement="right-start">
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
      </Tooltip>

      {/* Filtering Options */}
      <Box
        sx={{
          // overflow: "hidden",
          // overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(event) => {
                        setSelectedKey(event.target.value);
                        setSelectedValue("");
                        setSelectedProfessionalType("");
                        setSelectedDateRange(null);
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.id !== "no." &&
                          cell.id !== "action" &&
                          cell.id !== "type" &&
                          cell.id !== "status" &&
                          cell.id !== "referral_link" &&
                          cell.id !== "zip" && (
                            <MenuItem
                              key={cell.id}
                              value={
                                cell.id === "company_name" ? "company" : cell.id
                              }
                            >
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                      <MenuItem value={"promo_codes"}>Referral Code</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedKey !== "phone_number" &&
                  selectedKey !== "created_at" &&
                  selectedKey !== "status" &&
                  selectedKey !== "professional_type" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search w-100"
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {selectedKey === "phone_number" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl
                      className="filter-search w-100"
                      variant="outlined"
                    >
                      <Box className={`phone-number-input bg-white filter`}>
                        <PhoneInput
                          className="bg-white"
                          international={false}
                          placeholder="Phone Number"
                          value={selectedValue}
                          countries={["US", "IN"]}
                          defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                          countryOptionsOrder={["US", "IN"]}
                          addInternationalOption={false}
                          onChange={(event) => {
                            setSelectedValue(event);
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                )}

                {(selectedKey === "status" || selectedKey === "all") && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={selectedStatus}
                        onChange={(event) =>
                          setSelectedStatus(event.target.value)
                        }
                      >
                        {[
                          { label: "Active", value: "active" },
                          { label: "Inactive", value: "inactive" },
                        ]?.map((status) => (
                          <MenuItem value={status?.value}>
                            {status?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {selectedKey !== "status" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Professional Type"
                        value={selectedProfessionalType}
                        onChange={(event) =>
                          setSelectedProfessionalType(event.target.value)
                        }
                      >
                        {professionalTypeList?.data?.map((type) => (
                          <MenuItem value={type?.id}>{type?.type}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedKey === "all" || selectedKey === "created_at") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterProperty}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={() => {
                      resetFilterOption();
                    }}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card className="d-none d-md-block filter-options-carddiv">
              <Grid container className="filter-options-box">
                <Stack direction="row" spacing={2} alignItems="center">
                  <div className="search-text mx-2">Search By</div>
                  <FormControl className="filter-select-one">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(event) => {
                        setSelectedKey(event.target.value);
                        setSelectedValue("");
                        setSelectedProfessionalType("");
                        setSelectedDateRange(null);
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.id !== "no." &&
                          cell.id !== "action" &&
                          cell.id !== "type" &&
                          cell.id !== "referral_link" &&
                          cell.id !== "zip" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                      <MenuItem value={"promo_codes"}>Referral Code</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                    </TextField>
                  </FormControl>
                </Stack>
                {selectedKey !== "phone_number" &&
                  selectedKey !== "created_at" &&
                  selectedKey !== "status" &&
                  selectedKey !== "professional_type" && (
                    <Grid item>
                      <FormControl className="filter-search" variant="outlined">
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {selectedKey === "phone_number" && selectedKey !== "status" && (
                  <Grid item>
                    <Box className={`phone-number-input bg-white filter`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={selectedValue}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setSelectedValue(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {(selectedKey === "status" || selectedKey === "all") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={selectedStatus}
                        onChange={(event) =>
                          setSelectedStatus(event.target.value)
                        }
                      >
                        {[
                          { label: "Active", value: "active" },
                          { label: "Inactive", value: "inactive" },
                        ]?.map((status) => (
                          <MenuItem value={status?.value}>
                            {status?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {selectedKey !== "status" && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Professional Type"
                        value={selectedProfessionalType}
                        onChange={(event) =>
                          setSelectedProfessionalType(event.target.value)
                        }
                      >
                        {professionalTypeList?.data?.map((type) => (
                          <MenuItem value={type?.id}>{type?.type}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedKey === "all" || selectedKey === "created_at") && (
                  <Grid item>
                    <FormControl className="filter-search">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterProperty}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetFilterOption}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>
      {/* Professional Listing Table */}
      {professionalData?.success ? (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <TableHeaderForSorting
                  handleSorting={handleSorting}
                  directionFlow={directionFlow}
                  setSelectedColumn={setSelectedColumn}
                  selectedColumn={selectedColumn}
                  rowCount={professionalData?.data?.length}
                  columns={columns}
                />
                <TableBody>
                  {_DATA?.map((row, index) => {
                    return (
                      <>
                        <Row
                          key={(page - 1) * 10 + index + 1}
                          row={row}
                          handleEditButton={handleEditButton}
                          handleDeleteButton={handleDeleteButton}
                          page={page}
                          index={index}
                          handleCollapsibleRow={handleCollapsibleRow}
                          isCollapsible={
                            selectedCollapsibleRow?.id === row?.id &&
                            isCollapsibleRow
                          }
                          handleMenuItemClick={handleMenuItemClick}
                          setSelectedRow={setSelectedRow}
                        />
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              {!professionalData?.data?.length > 0 &&
                !professionalListLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
            </TableContainer>
          </Paper>
          {professionalDataCount > PER_PAGE && (
            <Box id="pagination-container">
              <CustomPagination
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Typography className="text-danger text-center">
          {professionalData?.data?.message}
        </Typography>
      )}

      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Tooltip title="Edit" TransitionComponent={Zoom} placement="top" arrow>
          <MenuItem
            className="setting-menu-item"
            onClick={(e) => {
              dispatch(professionalAction.clearAddProfessionalDetailsData());
              handleEditButton(selectedRow);
            }}
          >
            <Box className="setting-menu-box">
              <ModeOutlinedIcon />
              <Typography className="setting-menu-text">Edit</Typography>
            </Box>
          </MenuItem>
        </Tooltip>

        <Tooltip
          title="Delete"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <MenuItem
            className="setting-menu-item"
            onClick={(e) => {
              dispatch(professionalAction.clearAddProfessionalDetailsData());
              handleDeleteButton(selectedRow);
            }}
          >
            <Box className="setting-menu-box">
              <DeleteOutlineIcon />
              <Typography className="setting-menu-text">Delete</Typography>
            </Box>
          </MenuItem>
        </Tooltip>

        {selectedRow?.status === "inactive" ? (
          <Tooltip
            title="Activate"
            TransitionComponent={Zoom}
            placement="top"
            arrow
          >
            <MenuItem
              className="setting-menu-item"
              onClick={(e) => {
                setActionPerformed("activate");
                setIsActivateDeactivateProfessionalModalOpen(true);
              }}
            >
              <Box className="setting-menu-box">
                <CheckCircleOutlineOutlinedIcon />
                <Typography className="setting-menu-text">
                  Mark as Active
                </Typography>
              </Box>
            </MenuItem>
          </Tooltip>
        ) : (
          <Tooltip
            title="Inactivate"
            TransitionComponent={Zoom}
            placement="top"
            arrow
          >
            <MenuItem
              className="setting-menu-item"
              onClick={(e) => {
                setActionPerformed("deactivate");
                setIsActivateDeactivateProfessionalModalOpen(true);
              }}
            >
              <Box className="setting-menu-box">
                <CloseOutlinedIcon />
                <Typography className="setting-menu-text">
                  Mark as Inactive
                </Typography>
              </Box>
            </MenuItem>
          </Tooltip>
        )}
      </Menu>

      <DeleteModal
        open={isDeleteProfessionalModalOpen}
        title="Delete Professional"
        item="Professional"
        deleteLoader={professionalListLoader}
        handleClose={handleCloseDeleteModal}
        handleDeleteBtn={handleDeleteProfessional}
        fetchedData={deleteProfessionalDetails}
        isDeleteSuccess={deleteProfessionalDetails?.success}
        successMessage={deleteProfessionalDetails?.message}
        errorMessage={deleteProfessionalDetails?.data?.message}
        callListingAPI={() => {
          if (
            professionalData?.data?.length === 1 &&
            professionalDataCount > PER_PAGE &&
            isDeleteProfessionalModalOpen
          ) {
            setPage(page - 1);
            dispatch(
              professionalAction.fetchProfessionalListData({
                url: getProfessionalUrl(page - 1),
                data: {},
                token: true,
              })
            );
          } else {
            dispatch(
              professionalAction.fetchProfessionalListData({
                url: getProfessionalUrl(page),
                data: {},
                token: true,
              })
            );
          }
        }}
        footer={false}
      />

      {isActivateDeactivateProfessionalModalOpen ? (
        <PlainAlertMessage
          type={"Warning"}
          openPopup={isActivateDeactivateProfessionalModalOpen}
          onClose={true}
          onCloseFun={() => {
            setActionPerformed("");
            setIsActivateDeactivateProfessionalModalOpen(false);
          }}
          message={`Are you sure you want to ${
            actionPerformed === "activate"
              ? "activate the professional"
              : "inactivate the professional"
          }?`}
          buttons={
            <>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="popup_Btn other_popup_btn"
                  onClick={() => {
                    setActionPerformed("");
                    setIsActivateDeactivateProfessionalModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    handleActivateDeactivateProfessional(selectedRow);
                  }}
                >
                  Okay
                </Button>
              </Stack>
            </>
          }
          footer={false}
          loader={activateDeactivateProfessionalLoader}
        />
      ) : null}

      {/* activate deactivate professional success error modal */}
      {getObjLength(activateDeactivateProfessionalDetails) && (
        <PlainAlertMessage
          type={
            activateDeactivateProfessionalDetails?.success ? "Success" : "Error"
          }
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (activateDeactivateProfessionalDetails?.success) {
              setActionPerformed("");
              setIsActivateDeactivateProfessionalModalOpen(false);
              dispatch(
                professionalAction.fetchProfessionalListData({
                  url: getProfessionalUrl(page),
                  data: {},
                  token: true,
                })
              );
            }
            dispatch(
              professionalAction.setActivateDeactivateProfessionalDetails({})
            );
          }}
          message={
            activateDeactivateProfessionalDetails?.success
              ? activateDeactivateProfessionalDetails?.message
              : activateDeactivateProfessionalDetails?.data?.message
          }
          footer={false}
        />
      )}

      {getObjLength(addProfessionalDetails) && (
        <PlainAlertMessage
          type={addProfessionalDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (addProfessionalDetails?.success) {
              dispatch(professionalAction.clearAddProfessionalDetailsData());
              setIsAddProfessionalModalOpen(false);
              dispatch(
                professionalAction.fetchProfessionalListData({
                  url: getProfessionalUrl(page),
                  data: {},
                  token: true,
                })
              );
            } else {
              dispatch(professionalAction.clearAddProfessionalDetailsData());
            }
          }}
          message={
            addProfessionalDetails?.success
              ? addProfessionalDetails?.message
              : addProfessionalDetails?.data?.message
          }
          footer={false}
        />
      )}

      <Loader open={professionalListLoader} />
    </>
  );
}
