import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Paper, Typography } from "@mui/material";

import CountyRecordersListTable from "./CountyRecordersListTable";
import AddCountyRecorderComponent from "./AddCountyRecorderComponent";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import { Loader } from "../../common/loader";

const CountyAdmin = () => {
  const [isAddProvider, setIsAddProvider] = useState(false);
  const dispatch = useDispatch();

  const { countyRecordersLoader } = useSelector(
    (state) => state.countyRecorders
  );

  // get countyAdmins details
  const getCountyAdminsLists = () => {
    dispatch(
      countyRecordersAction?.countyRecordersListData({
        url: `admin/county/list`,
        data: {
          limit: 10,
          offset: 0,
          order_by_column: "updated_at",
          sorting_order: "desc",
        },
        token: true,
      })
    );
  };

  return (
    <>
      {isAddProvider ? (
        <AddCountyRecorderComponent
          goBack={() => {
            setIsAddProvider(false);
          }}
          //   listingAPI={getProvidersLists}
        />
      ) : (
        <>
          <Paper
            elevation={0}
            className=" d-flex justify-content-between"
            sx={{ background: "none" }}
          >
            <Box className="" sx={{ mb: 1 }}>
              <Typography variant="h6" className="card-title fw-600">
                County List
              </Typography>
            </Box>
            <Box className="">
              <Button
                variant="contained"
                className="success-btn d-none d-sm-block"
                style={{
                  padding: "5px 30px",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  setIsAddProvider(true);
                }}
              >
                Add County
              </Button>
              <Button
                className="success-btn d-flex d-sm-none"
                variant="outlined"
                onClick={() => {
                  setIsAddProvider(true);
                }}
                startIcon={<AddOutlinedIcon />}
                sx={{ color: "#FFF" }}
              >
                Add
              </Button>
            </Box>
          </Paper>

          <Box>
            <CountyRecordersListTable listingAPI={getCountyAdminsLists} />
          </Box>
        </>
      )}
      <Loader open={countyRecordersLoader} />
    </>
  );
};

export default CountyAdmin;
