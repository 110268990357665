import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import AdminFreeMonitoringListTable from "./AdminFreeMonitoringListTable";
import { Button } from "react-bootstrap";
import { decryptPayload } from "../../helpers/utils";
import { ROLES } from "../../helpers/constants";

const AdminFreeMonitoring = () => {
  const [propertiesIDList, setPropertiesIDList] = useState([]);
  const [deletePropertyModal, setDeletePropertyModal] = useState(false);
  const [selectAllFlag, setSelectAllFlag] = useState(false);

  const handleDeleteProperty = (action) => {
    setDeletePropertyModal(true);
  };
  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6" className="title">
          Property Monitoring
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {!county_admin && propertiesIDList?.length > 0 && (
            <Button
              variant="contained"
              className="btn d-none d-sm-block popup_Btn"
              style={{ backgroundColor: "#f43455", borderRadius: "25px" }}
              onClick={() => {
                console.log("On delete click");
                handleDeleteProperty();
              }}
            >
              Delete Properties
            </Button>
          )}
        </Stack>
      </Stack>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <AdminFreeMonitoringListTable
          setPropertiesIDList={setPropertiesIDList}
          propertiesIDList={propertiesIDList}
          setDeletePropertyModal={setDeletePropertyModal}
          deletePropertyModal={deletePropertyModal}
          handleDeleteProperty={handleDeleteProperty}
          selectAllFlag={selectAllFlag}
          setSelectAllFlag={setSelectAllFlag}
        />
      </Box>
    </>
  );
};

export default AdminFreeMonitoring;
