import React, { useRef } from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import logo from "../../assets/logo/equity.WebP";
import Testimonials from "../common/Testimonials";
import EvaluateRisk from "../common/EvaluateRisk";
import { decryptPayload } from "../helpers/utils";
import forbes from "../../assets/leadpage/forbes.WebP";
import house from "../../assets/properties/house.webp";
import tv_icon from "../../assets/leadpage/tv-icon.svg";
import check_img from "../../assets/icons/checkmark.png";
import HowItWorksSection from "../common/HowItWorksSection";
import usa_today from "../../assets/leadpage/usa_today.WebP";
import phishing from "../../assets/properties/phishing.webp";
import msnbc_logo from "../../assets/leadpage/msnbc-logo.png";
import maskgroup from "../../assets/properties/mask-group.webp";
import PrivateGuardSection from "../common/PrivateGuardSection";
import PropertyRiskManagementForm from "./PropertyRiskManagementForm";
import isolation_mode from "../../assets/properties/isolation_mode.webp";

import "./LeadGenDesktop.scss";
import "../../styles/landingpage.scss";
import "../../styles/howItWorksSection.scss";

const LeadGenDesktop = () => {
  const riskFormRef = useRef(null);

  // Function to handle scrolling to the form
  const scrollToForm = () => {
    riskFormRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  return (
    <div className="lead-gen-content bg-white pb-150 main_class">
      <section className="first_section light_bg">
        <Link
          to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
          className="EP_logo"
        >
          <LazyLoadImage src={logo} width="250px" height="100%" alt="logo" />
        </Link>
        <div
          className="d-flex align-items-center prm_bg_img  first-section"
          ref={riskFormRef}
        >
          <Container fluid className="text-white mx-150">
            <Row className="align-items-center" spacing={3}>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Stack direction={"column"} spacing={2}>
                  <Box className="top_section_spacing">
                    <p
                      className="notify_header mb-2"
                      style={{ color: "#0395FF" }}
                    >
                      Check your property
                    </p>
                    <h1 className="fw-700 mb-3 main-text">
                      We are the <em>only</em> service that PREVENTS equity
                      theft and fraud BEFORE it happens!
                    </h1>
                    <p className="paragraph_text fw-400 mb-3 mt-2">
                      With over 1,100 data points meticulously analyzed, Equity
                      Protect's Risk Assessment Report delivers unparalleled
                      accuracy in evaluating the risk of equity theft and fraud.
                    </p>
                  </Box>
                </Stack>
                <div className="my-3 py-1 d-flex align-items-center as_seen_on ">
                  <span className="pe-2 pt-1">As Seen on</span>
                  <img src={tv_icon} alt="Tv_icon" />
                </div>
                <div className="pb-3">
                  <Stack
                    className="homepage_logos d-flex  logo_columned"
                    spacing={2}
                  >
                    <Stack
                      spacing={{ xs: 2, sm: 3 }}
                      direction="row"
                      className="alignments"
                    >
                      <LazyLoadImage src={nbc} alt="nbc" />
                      <LazyLoadImage src={forbes} alt="forbes" />
                      <LazyLoadImage src={fox} alt="fox" />
                    </Stack>
                    <Stack
                      spacing={{ xs: 1, sm: 3 }}
                      direction="row"
                      className="alignments"
                    >
                      <LazyLoadImage src={cbs} alt="cbs" />
                      <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" />
                      <LazyLoadImage src={usa_today} alt="usa_today" />
                      <LazyLoadImage src={cnn} alt="cnn" />
                    </Stack>
                  </Stack>
                  <Box
                    className="homepage_logos d-flex logo_row flex-wrap"
                    gap={"16px"}
                  >
                    <LazyLoadImage src={nbc} alt="nbc" />
                    <LazyLoadImage src={forbes} alt="forbes" />
                    <LazyLoadImage src={fox} alt="fox" />
                    <LazyLoadImage src={cbs} alt="cbs" />
                    <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" />
                    <LazyLoadImage src={usa_today} alt="usa_today" />
                    <LazyLoadImage src={cnn} alt="cnn" />
                  </Box>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box
                  className="property_form property-risk-page-content lead_form"
                  id="lead-page-form"
                >
                  <PropertyRiskManagementForm
                    getFreeReport={true}
                    leadGen={true}
                    widthClass={"lead-prm-form"}
                    source={"EquityProtect"}
                  />
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="pt-3 d-flex justify-content-center align-items-center light_bg second_section lg_section">
        <Stack
          direction={{ sm: "column", md: "row" }}
          justifyContent={{ sm: "center", md: "space-around" }}
          alignItems={{ sm: "center", xs: "center" }}
          spacing={2}
          className="sec1"
        >
          <>
            <Stack direction={"row"}>
              <img src={check_img} className="check_img ml-0" alt="check" />
              <span className="sec1_text">Your data is completely secure</span>
            </Stack>
          </>
          <>
            <Stack direction={"row"} className="mt-0">
              <img src={check_img} className="check_img img_2" alt="check" />
              <span className="sec1_text">We won’t save anything locally</span>
            </Stack>
          </>
        </Stack>
      </section>

      <section className="pt-5 light_bg">
        <EvaluateRisk />
      </section>

      <Divider className="divider" />

      <section className="py-5 text-center light_bg">
        <Container fluid className="my-3">
          <div className="free_report-spacing">
            <h5 className="safeGuard">
              Let us help you safeguard your equity and prevent costly fraud.
            </h5>
            <Button
              className={`button success_popup_btn my-4`}
              variant="contained"
              onClick={scrollToForm}
            >
              Get your free report
            </Button>
          </div>
        </Container>
      </section>

      <section className="alert_section  text-center mobile-secions-pt-0 light_bg">
        <Container fluid className="mx-150 it_works">
          <h3 className="how_its_works_title">How this works</h3>
          <Typography className="my-4 how_its_works_texts">
            Let's make sure everything on your property is how it should be
          </Typography>
          <Row className="steps_wrapper text-start mt-4">
            <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
              <Box className="info_blocks">
                <div className="number_alignment">
                  <span className="steps mb-4">1</span>
                </div>
                <h5>Fill in the form above</h5>
                <Typography className="mt-4 block1">
                  This tells us where to look.
                </Typography>
              </Box>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
              <Box className="info_blocks">
                <div className="number_alignment">
                  <span className="steps mb-4">2</span>
                </div>
                <h5>Property Search</h5>
                <Typography className="mt-4 block2">
                  Our system is going to locate your property and pull the most
                  recent title data.
                </Typography>
              </Box>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} xl={4} className="py-2">
              <Box className="info_blocks">
                <div className="number_alignment">
                  <span className="steps mb-4 ">3</span>
                </div>
                <h5>We'll send you your free report.</h5>
                <Typography className="mt-4 block3">
                  This report will display the current vesting information on
                  our title report.
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white testimonial-section">
        <Container fluid className="p-0 mt-5 card_top box_shadow">
          <>
            <Row className=" text-start risk_points">
              <Col xs={12} sm={12} md={6} className=" ">
                <Box className="card_content">
                  <>
                    <Typography className="title mb-4 d-flex text-start">
                      Learn How EquityProtect™ Can Help Protect You
                    </Typography>
                    <ul
                      className="features_list mb-4"
                      style={{ listStyleType: "none", textAlign: "left" }}
                    >
                      <li className="position-relative mb-3">
                        Physical Protection prevents the crime from occurring
                      </li>
                      <li className="position-relative mb-3">
                        24/7 title monitoring
                      </li>
                      <li className="position-relative mb-3">
                        Utilization of Multifactor authentication before
                        allowing permission to move forward with a new refinance
                        or sale
                      </li>
                    </ul>
                    <Link
                      className=" text-white text-decoration-none"
                      to="/create-account"
                    >
                      <Button className="button success_popup_btn">
                        Find Out More
                      </Button>
                    </Link>
                  </>
                </Box>
              </Col>
              <Col xs={12} sm={12} md={6} className="">
                <Box className="m-0 markgroup_img">
                  <img src={maskgroup} className="h-100 w-100" alt="home_Img" />
                </Box>
              </Col>
            </Row>
          </>
        </Container>
        <Container
          fluid
          className="mx-150 pt-150 pb-5 middle-container testimonials"
        >
          <Testimonials />
        </Container>
      </section>

      <section className="mt-5 light_bg">
        <Container fluid className="property-protected-container pt-5">
          <div>
            <h3 className="pp_title ">
              Why it’s important to have your property protected
            </h3>
            <Typography className="pp_caption pt-2">
              Your Smart Policy includes more protection for your peace of mind.
            </Typography>
          </div>

          <Row className="mt-4 mb-3 text-start pp_points">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              className="content-spacing "
            >
              <img
                className="mb-3"
                src={house}
                alt="Title Fraud and Property Theft"
              />
              <p className="protection_title">Title Fraud and Property Theft</p>
              <p className="protection_desc pe-0 pe-lg-5">
                Criminals forge documents to steal property titles, selling
                homes or taking loans, leaving owners with legal and financial
                battles.
              </p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              className="content-spacing"
            >
              <img className="mb-3" src={phishing} alt="Data Breaches" />
              <p className="protection_title" style={{ paddingTop: "5px" }}>
                Data Breaches
              </p>
              <p className="protection_desc pe-0 pe-lg-5">
                Cybercriminals target lenders, stealing homeowners' sensitive
                data for identity theft, leading to unauthorized transactions
                and equity loss.
              </p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              className="content-spacing"
            >
              <img
                className="mb-3"
                src={isolation_mode}
                alt="Home Equity Scams"
              />
              <p className="protection_title">Home Equity Scams</p>
              <p className="protection_desc pe-0 pe-lg-5">
                Deceptive practices strip homeowners of equity through inflated
                fees and unfair terms, exploiting vulnerabilities for financial
                gain.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="how_its_Works_section light_bg">
        <Container fluid className="px-3 px-md-5">
          <HowItWorksSection
            title={
              "I want peace of mind and my risk report is medium to high, what are the next steps?"
            }
          />
        </Container>
      </section>

      <PrivateGuardSection />
    </div>
  );
};

export default LeadGenDesktop;
