import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Button, FormHelperText, Stack } from "@mui/material";

import { Loader } from "../common/loader";
import OwnerVerified from "./ownerVerified";
import AlertDialog from "../common/AlertDialog";
import ContactSupport from "../common/ContactSupport";
import { getObjLength, numberToWords } from "../helpers/utils";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import CustomizedCheckbox from "../common/customDesignedComponents/customCheckbox";

const initialValues = {
  houseInfo_1: "",
  houseInfo_2: "",
  houseInfo_3: "",
};

const validationSchema1 = Yup.object().shape({
  houseInfo_1: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_2: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_3: Yup.string().required(
    "Please select an option to proceed further."
  ),
});
const validationSchema2 = Yup.object().shape({
  houseInfo_1: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_2: Yup.string().required(
    "Please select an option to proceed further."
  ),
});

const SecurityInfoScreen = ({
  handleBack,
  onBtnClick,
  backtoAddProperty,
  errorPopup,
  reduceErrorCount,
  propertyListScreen,
  setPropertyListScreen,
}) => {
  const [open, setOpen] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checkTerms, setCheckTerms] = useState(true);
  const [fromErr, setFormErr] = useState("");
  const [ownerScreen, setOwnerScreen] = useState(false);
  const [verifiedCount, setVerifiedCount] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [viewPropertyListScreen, setViewPropertyListScreen] = useState(false);
  const [skipVerificationPopup, setSkipVerificationPopup] = useState(false);

  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  let isOwnerVerified = JSON.parse(localStorage.getItem("isOwnerVerified"));
  const getPropertyData = useSelector(
    (state) => state.signUpUser.selectedProperty
  );

  const propertyQuestion = useSelector(
    (state) => state.signUpUser.propertyQuestion
  );
  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );
  const propertyVerified = useSelector(
    (state) => state.signUpUser.propertyVerified
  );
  const propertiesList = useSelector(
    (state) => state.signUpUser.verifiedPropertiesList
  );
  const signupLoader = useSelector((state) => state.signUpUser.signupLoader);
  const deletedPropertyResponse = useSelector(
    (state) => state.signUpUser.deletedPropertyResponse
  );
  const showSecurityInfoScreen = useSelector(
    (state) => state.signUpUser.showSecurityInfoScreen
  );
  const skipVerifyAnswers = useSelector(
    (state) => state.signUpUser.skipVerifyAnswers
  );
  const { selectedProperty, userPropertyData } = useSelector(
    (state) => state.signUpUser
  );
  const { genearteQuesPayload } = useSelector((state) => state?.properties);
  const dispatch = useDispatch();
  const APICall = () => {
    let data = {
      user_id: getObjLength(crerateAccData)
        ? crerateAccData.data?.user_id
        : userIdFromSession,
      // crerateAccData?.data?.user_id,
    };
    dispatch(
      userSigupAction.getPropertiesList({
        url: "customer/property-list",
        data: data,
      })
    );
  };
  const propertyListAPicall = () => {
    if (
      // !getObjLength(crerateAccData) &&
      isOwnerVerified === 1
    ) {
      APICall();
      // setOwnerScreen(true);
      // backtoAddProperty(2, "hide");
    }
    // setOpenDeletePopup(false);
  };

  useEffect(() => {
    console.log("showSecurityInfoScreen=>", showSecurityInfoScreen);
    if (!showSecurityInfoScreen) {
      propertyListAPicall();
    }
  }, []);
  useEffect(() => {
    console.log("propertiesList  ==>", propertiesList);
    if (Object.keys(propertiesList).length > 0) {
      ownerVerified();
    }
  }, [propertiesList]);
  useEffect(() => {
    console.log("propertyQuestion 33 ==>", propertyQuestion);
    // if (
    //   propertyQuestion?.data?.questions?.length > 0 &&
    //   propertyQuestion?.success &&
    //   !propertyQuestion?.data?.is_verified
    // ) {
    //   console.log("Ff 1");
    //   setOwnerScreen(false);
    //   if (viewPropertyListScreen) {
    //     backtoAddProperty(2, "propertyList");
    //   }
    // }
  });
  useEffect(() => {
    console.log("propertyQuestion  ==>", propertyQuestion);
    if (propertyQuestion?.success && propertyQuestion?.data?.is_verified) {
      propertyListAPicall();
      ownerVerified();
    }

    if (
      propertyQuestion?.data?.questions?.length > 0 &&
      propertyQuestion?.success &&
      !propertyQuestion?.data?.is_verified
    ) {
      console.log("Ff 1");
      setOwnerScreen(false);
      if (viewPropertyListScreen) {
        backtoAddProperty(2, "propertyList");
      }
    }
  }, [propertyQuestion]);

  useEffect(() => {
    if (Object.keys(propertyVerified).length > 0 && propertyVerified?.success) {
      let data = {
        property_id: getPropertyData?.property_id,
        user_id: getObjLength(crerateAccData)
          ? crerateAccData.data?.user_id
          : userIdFromSession,
        // crerateAccData?.data?.user_id,
      };
      dispatch(
        userSigupAction.getPropertiesList({
          url: "customer/property-list",
          data: data,
        })
      );
      console.log("propertyVerified 1 ==>", propertyVerified);
      handleClose();
      // if (propertyVerified?.success) {
      //   // onBtnClick();
      // }
    }

    if (!propertyVerified?.success) {
      console.log("propertyVerified 1==>", propertyVerified);
      setOpenPopup(true);
      handleClose();
    }
  }, [propertyVerified]);

  const handleClose = () => {
    console.log("propertyVerified 1 ==>", propertyVerified);
    setOpen(false);
    if (propertyVerified?.success && propertiesList?.success) {
      console.log(propertiesList, "propertyVerified==> 22", propertyVerified);
      setOwnerScreen(true);
      dispatch(userSigupAction.clearPropertyQuestion({}));
      setVerifiedCount(1);
      setChecked1(false);
      localStorage.removeItem("button");
    }

    if (
      Object.keys(propertyVerified).length > 0 &&
      !propertyVerified?.success
    ) {
      // setOpenPopup(true);
      // addNewProperty();
    }
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const ownerVerified = () => {
    if (propertyQuestion?.data?.is_verified) {
      console.log("propertyQuestion==> 33", propertyQuestion);
      backtoAddProperty(2, "hide");
      setOwnerScreen(true);
      setVerifiedCount(1);
      setChecked1(false);
      localStorage.removeItem("button");
    }

    if (propertiesList?.success) {
      if (propertiesList?.success && propertiesList?.data?.length > 1) {
        console.log("propertiesList==>", propertiesList);
        // localStorage.setItem("button", "back");
      }
      backtoAddProperty(2, "hide");
      setOwnerScreen(true);
      dispatch(userSigupAction.clearPropertyQuestion({}));
      setVerifiedCount(1);
      setChecked1(false);
      localStorage.setItem("isOwnerVerified", 1);
    }
  };

  const onSubmit = (values, errors) => {
    // setVerifiedCount(verifiedCount + 1);
    setCheckTerms(checked1);
    console.log(values, "qq <===inner Form data==>", errors);
    console.log(
      getPropertyData,
      "qq questions==>>",
      Object.values(propertyQuestion?.data?.questions)[0]?.question_code
    );
    const key1 = Object.values(propertyQuestion?.data?.questions)[0]
      ?.question_code;
    const key2 = Object.values(propertyQuestion?.data?.questions)[1]
      ?.question_code;
    const key3 = Object.values(propertyQuestion?.data?.questions)[2]
      ?.question_code;

    let answer = {};
    answer[key1] = values.houseInfo_1;
    answer[key2] = values.houseInfo_2;
    answer[key3] = values.houseInfo_3;
    var selectedData = {};
    if (Object.keys(getPropertyData).length > 0) {
      let propertyData =
        getPropertyData?.json_response || getPropertyData?.json_res;
      selectedData = {
        property_id: getPropertyData?.property_id,
        state_code:
          getPropertyData.state_code || propertyData?.mm_fips_state_code,
        user_id: getObjLength(crerateAccData)
          ? crerateAccData.data?.user_id
          : userIdFromSession,
        // crerateAccData?.data?.user_id,
        answers: answer,
        step: 2,
        step_key: "VERIFY_ANSWERS",
        // ...genearteQuesPayload, // due to revert the changes
        street_number: genearteQuesPayload?.street_number,
        site_route: genearteQuesPayload?.site_route,
        city: genearteQuesPayload?.city,
        state: genearteQuesPayload?.state,
        zip: genearteQuesPayload?.zip,
        country: genearteQuesPayload?.country,
        latitude: genearteQuesPayload?.latitude,
        longitude: genearteQuesPayload?.longitude,
        address: genearteQuesPayload?.address,
      };

      console.log("answers object==>", selectedData);
    }
    if (checked1) {
      handleToggle();
      dispatch(
        userSigupAction.verifyAnswers({
          url: "properties/verify-answers",
          data: selectedData,
        })
      );
    }

    // if (Object.keys(fromErr).length === 0) {
    // onBtnClick();
    // }
  };

  const onClickSubmit = (err) => {
    console.log("inner error==>", err);
    setFormErr(err);
  };

  const addNewProperty = (screen) => {
    console.log(propertiesList, "New property ads==>", screen);
    if (propertiesList?.data?.length >= 1) {
      localStorage.setItem("button", "skip");
    }
    dispatch(userSigupAction.addNewProperty({}));
    setVerifiedCount(verifiedCount + 1);
    if (screen === "listing_screen") {
      console.log("ownership screen...");
      localStorage.setItem("isOwnerVerified", 1);
      isOwnerVerified = 1;
      // backtoAddProperty(2);
      // setOwnerScreen(true);
      getOwnerVerificationScreen();
    } else {
      console.log("Ff 2");
      setOwnerScreen(false);
      backtoAddProperty(1);
    }
  };

  const questions =
    propertyQuestion?.data !== undefined &&
    Object.values(
      // Object.keys(propertyQuestion?.data)?.length > 0
      propertyQuestion?.success && !propertyQuestion?.data?.is_verified
        ? propertyQuestion?.data?.questions
        : {}
    );
  console.log(ownerScreen, "questions==>>", questions);
  console.log("propertyVerified==>.", propertyVerified);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const onChecked = (e) => {
    console.log("cjceked val==?", e.target.checked);
    setChecked1(e.target.checked);
    if (!e.target.checked) {
      setCheckTerms(e.target.checked);
    } else {
      setCheckTerms(true);
    }
  };

  const getOwnerVerificationScreen = () => {
    dispatch(userSigupAction.clearPropertyVerified("propertyListScreen"));
    propertyListAPicall();
    setOwnerScreen(true);
    dispatch(userSigupAction.clearPropertyQuestion({}));
    setVerifiedCount(1);
    setChecked1(false);
    localStorage.removeItem("button");
  };

  const skipQuestionAnswers = () => {
    // ---------- due to revert changes --------------
    let property_id = selectedProperty?.property_id;
    console.log("property_id==>", selectedProperty);
    dispatch(
      userSigupAction.skipVerifyAnswers({
        // url: `properties/${property_id}/skip-verify-answers`,
        url: `properties/skip-verify-answers?property_id=${property_id}`,
        data: {
          user_id: getObjLength(crerateAccData)
            ? crerateAccData.data?.user_id
            : userIdFromSession,
          step: 2,
          step_key: "SKIP_VERIFY_ANSWERS",
          // property_id: property_id,
          // state_code: selectedProperty?.json_response?.mm_fips_state_code,
          ...userPropertyData?.data,
          state_code:
            selectedProperty?.state_code ||
            selectedProperty?.json_response?.mm_fips_state_code,

          // ---------- these values replaced by userPropertyData ----------
          // street_number: genearteQuesPayload?.street_number,
          // site_route: genearteQuesPayload?.site_route,
          // city: selectedProperty?.city,
          // state: selectedProperty?.state,
          // zip: selectedProperty?.zip,
          // country: selectedProperty?.country,
          // latitude: selectedProperty?.latitude,
          // longitude: selectedProperty?.longitude,
          // address: selectedProperty?.address,
        },
      })
    );
    // APICall();
    // getOwnerVerificationScreen();
    setSkipVerificationPopup(false);
  };

  useEffect(() => {
    console.log(
      getObjLength(deletedPropertyResponse),
      "deletedPropertyResponse  ==>",
      deletedPropertyResponse
    );
    if (
      getObjLength(deletedPropertyResponse)
      // && deletedPropertyResponse?.success
    ) {
      // propertyListAPicall();
      setOpenDeletePopup(true);
    }
  }, [deletedPropertyResponse]);

  useEffect(() => {
    console.log("propertyListScreen  ==>", propertyListScreen);
    if (propertyListScreen) {
      getOwnerVerificationScreen();
    }
  }, [propertyListScreen]);

  // --------- due to revert changes -------------
  useEffect(() => {
    console.log("skipVerifyAnswers  ==>", skipVerifyAnswers);
    if (getObjLength(skipVerifyAnswers) && skipVerifyAnswers?.success) {
      // onBtnClick();
      getOwnerVerificationScreen();
    }
  }, [skipVerifyAnswers]);

  return (
    <>
      {!ownerScreen ? (
        // || !propertyQuestion?.data?.is_verified
        <>
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={
              questions?.length > 2 ? validationSchema1 : validationSchema2
            }
            onSubmit={onSubmit}
            // validateOnBlur
            // validateOnMount
          >
            {({ values, setFieldValue, touched, setTouched, errors }) => {
              return (
                <Form>
                  <Box className="accountBoxWrapper infoForm_Wrapper">
                    <Typography align="center" variant="h3">
                      Let’s make sure you are the owner
                    </Typography>
                    <Typography align="center" variant="body1">
                      Please answer the following questions about your property
                      so we are able to identify you as the owner.
                    </Typography>
                    {/* <hr className="formSeparator" /> */}
                    <Typography
                      variant="h4"
                      className="infoTitle"
                      px={2}
                      mt={1}
                    >
                      House information
                    </Typography>

                    <Box p={2}>
                      <Grid
                        container
                        spacing={1}
                        textAlign={"start"}
                        alignItems={"center"}
                      >
                        {propertyQuestion?.success &&
                          Object.keys(propertyQuestion).length > 0 && (
                            // questions.map((data, key) => (
                            <>
                              {questions[0]?.question && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="mb-0 mb-lg-3"
                                  >
                                    <Typography
                                      variant="body1"
                                      className="infoLabel"
                                    >
                                      {questions[0]?.question}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="mb-0 mb-lg-3"
                                  >
                                    <FormControl
                                      fullWidth
                                      className="sign-textField"
                                      error={
                                        touched.houseInfo_1 &&
                                        Boolean(errors.houseInfo_1)
                                      }
                                    >
                                      <InputLabel id="demo-simple-select-placeholder">
                                        Select
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-placeholder"
                                        id="demo-simple-select"
                                        value={values?.houseInfo_1}
                                        label="select"
                                        name="houseInfo_1"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "houseInfo_1",
                                            event.target.value
                                          );
                                        }}
                                        MenuProps={MenuProps}
                                      >
                                        {questions[0]?.options?.map(
                                          (data, i) => (
                                            <MenuItem
                                              value={data !== "" ? data : " "}
                                              sx={{ whiteSpace: "normal" }}
                                            >
                                              {data !== "" ? data : "-"}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      {touched.houseInfo_1 &&
                                        Boolean(errors.houseInfo_1) && (
                                          <FormHelperText
                                            sx={{ color: "#d32f2f" }}
                                          >
                                            {touched.houseInfo_1 &&
                                              errors.houseInfo_1}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </>
                              )}

                              {questions[1]?.question && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="mb-0 mb-lg-3"
                                  >
                                    <Typography
                                      variant="body1"
                                      className="infoLabel"
                                    >
                                      {questions[1]?.question}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="mb-0 mb-lg-3"
                                  >
                                    <FormControl
                                      fullWidth
                                      className="sign-textField"
                                      error={
                                        touched.houseInfo_2 &&
                                        Boolean(errors.houseInfo_2)
                                      }
                                    >
                                      <InputLabel id="demo-simple-select-placeholder">
                                        Select
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-placeholder"
                                        id="demo-simple-select"
                                        value={values?.houseInfo_2}
                                        label="select"
                                        name="houseInfo_2"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "houseInfo_2",
                                            event.target.value
                                          );
                                        }}
                                        MenuProps={MenuProps}
                                      >
                                        {questions[1]?.options?.map(
                                          (data, i) => (
                                            <MenuItem
                                              value={data !== "" ? data : " "}
                                              sx={{ whiteSpace: "normal" }}
                                            >
                                              {data !== "" ? data : "-"}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      {touched.houseInfo_2 &&
                                        Boolean(errors.houseInfo_2) && (
                                          <FormHelperText
                                            sx={{ color: "#d32f2f" }}
                                          >
                                            {touched.houseInfo_2 &&
                                              errors.houseInfo_2}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </>
                              )}
                              {questions[2]?.question && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="mb-0 mb-lg-3"
                                  >
                                    <Typography
                                      variant="body1"
                                      className="infoLabel"
                                    >
                                      {questions[2]?.question}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="mb-0 mb-lg-3"
                                  >
                                    <FormControl
                                      fullWidth
                                      className="sign-textField"
                                      error={
                                        touched.houseInfo_3 &&
                                        Boolean(errors.houseInfo_3)
                                      }
                                    >
                                      <InputLabel id="demo-simple-select-placeholder">
                                        Select
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-placeholder"
                                        id="demo-simple-select"
                                        value={values?.houseInfo_3}
                                        label="select"
                                        name="houseInfo_3"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "houseInfo_3",
                                            event.target.value
                                          );
                                        }}
                                        MenuProps={MenuProps}
                                      >
                                        {questions[2]?.options?.map(
                                          (data, i) => (
                                            <MenuItem
                                              value={data !== "" ? data : " "}
                                              sx={{ whiteSpace: "normal" }}
                                            >
                                              {data !== "" ? data : "-"}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      {touched.houseInfo_3 &&
                                        Boolean(errors.houseInfo_3) && (
                                          <FormHelperText
                                            sx={{ color: "#d32f2f" }}
                                          >
                                            {touched.houseInfo_3 &&
                                              errors.houseInfo_3}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </>
                              )}
                            </>
                            // ) : (
                            // ))
                          )}
                      </Grid>
                      {Object.keys(propertyVerified).length > 0 &&
                        !propertyVerified?.success &&
                        (verifiedCount <= 2 ? (
                          <AlertDialog
                            type="Error"
                            openPopup={openPopup}
                            onClose={false}
                            mesage={
                              <>
                                <b>{propertyVerified?.data?.message}</b>
                                <p className="pt-1">
                                  You've{" "}
                                  {numberToWords(3 - parseInt(verifiedCount))}{" "}
                                  more{" "}
                                  {3 - parseInt(verifiedCount) === 1
                                    ? "chance"
                                    : "chances"}{" "}
                                  to choose correct answers or else you can Find
                                  Property again.
                                </p>
                              </>
                            }
                            buttons={
                              <>
                                <Button
                                  className="popup_Btn other_popup_btn m-2"
                                  onClick={() => {
                                    setVerifiedCount(
                                      parseInt(verifiedCount) + 1
                                    );
                                    dispatch(
                                      userSigupAction.clearPropertyVerified(
                                        "SecurityInfoScreen"
                                      )
                                    );
                                  }}
                                >
                                  Try Again ({3 - parseInt(verifiedCount)})
                                </Button>
                                <Button
                                  className="popup_Btn error_popup_btn"
                                  onClick={() =>
                                    addNewProperty("listing_screen")
                                  }
                                >
                                  Find Property again.
                                </Button>
                              </>
                            }
                          />
                        ) : verifiedCount > 2 ? (
                          <AlertDialog
                            type={"Error"}
                            openPopup={openPopup}
                            onClose={false}
                            onCloseFun={() => {
                              reduceErrorCount();
                            }}
                            mesage={
                              <>
                                <p className={`text-danger`}>
                                  {propertyVerified?.data?.message}
                                </p>
                                <p>
                                  {/* {errorPopup && ( */}
                                  <p>
                                    <ContactSupport />
                                  </p>
                                  {/* )} */}
                                </p>
                              </>
                            }
                            buttons={
                              <div className="mb-5 text-center">
                                <Button
                                  onClick={skipQuestionAnswers}
                                  className="mx-2 popup_Btn other_popup_btn"
                                  variant="contained"
                                >
                                  Skip Verification
                                </Button>
                                <Button
                                  className="popup_Btn error_popup_btn"
                                  onClick={() =>
                                    addNewProperty("listing_screen")
                                  }
                                >
                                  Find Property again
                                </Button>
                              </div>
                            }
                          />
                        ) : null)}
                    </Box>

                    <Box
                      className={!checkTerms && "border border-danger"}
                      textAlign={"start"}
                      py={1}
                      px={2}
                    >
                      <Stack
                        direction={"row"}
                        spacing={1}
                        justifyContent={"flex-start"}
                        alignItems={"start"}
                      >
                        <span className="pt-1">
                          <CustomizedCheckbox onChecked={onChecked} />
                        </span>
                        <span>
                          I agree that an Employee of EquityProtect may call me
                          at any time if they find an issue or an attempt at
                          fraud on my property. I understand that I will be
                          asked challenge questions on that call to verify my
                          identity. If I receive a call and I am not asked the
                          challenge questions, I will hang up and call Customer
                          Support directly.
                        </span>
                      </Stack>
                    </Box>
                    {!checkTerms && (
                      <p className="text-danger mx-0">
                        Please select given terms to proceed further.
                      </p>
                    )}
                    <Box textAlign={"start"} py={1} px={2}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        justifyContent={"flex-start"}
                        alignItems={"start"}
                      >
                        <span className="pt-1">
                          <CustomizedCheckbox checked />
                        </span>
                        <span>
                          By checking this box I acknowledge that I am the owner
                          of the property above and give EquityProtect
                          permission to verify my ownership.
                        </span>
                      </Stack>
                    </Box>
                    <hr className="formSeparator" />
                    <div className="mb-5 text-center">
                      <Button
                        onClick={() => {
                          if (checked1) {
                            setSkipVerificationPopup(true);
                          } else {
                            setCheckTerms(checked1);
                          }
                        }}
                        className="mx-2 next_button other_popup_btn"
                        variant="contained"
                      >
                        Skip
                      </Button>
                      <Button
                        onClick={() => onClickSubmit(errors)}
                        type="submit"
                        className="mx-2 next_button"
                        variant="contained"
                      >
                        Next
                      </Button>
                    </div>
                  </Box>

                  <Loader open={open} />
                </Form>
              );
            }}
          </Formik>
          <Loader open={signupLoader} />
        </>
      ) : (
        <OwnerVerified
          propertiesList={propertiesList}
          addNewProperty={addNewProperty}
          onBtnClick={onBtnClick}
          backtoAddProperty={backtoAddProperty}
          setOwnerScreen={setOwnerScreen}
          setViewPropertyListScreen={setViewPropertyListScreen}
          setPropertyListScreen={setPropertyListScreen}
          viewPropertyListScreen={viewPropertyListScreen}
        />
      )}

      {getObjLength(deletedPropertyResponse) ? (
        deletedPropertyResponse?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={false}
            mesage={`Property removed successfully.`}
            buttons={
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={() => {
                    propertyListAPicall();
                    setOpenDeletePopup(false);
                    dispatch(userSigupAction.clearDeletePropertiesResponse());
                  }}
                  className="next_button "
                  variant="contained"
                >
                  Okay, All Good.
                </Button>
              </div>
            }
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={false}
            mesage={deletedPropertyResponse?.data?.message}
            buttons={
              <div className="text-center stepBtn mt-4">
                <Button
                  className="popup_Btn error_popup_btn "
                  onClick={() => {
                    // setOpenDeletePopup(false);
                    dispatch(userSigupAction.clearDeletePropertiesResponse());
                  }}
                >
                  ok, Close
                </Button>
              </div>
            }
          />
        )
      ) : null}

      {getObjLength(skipVerifyAnswers) ? (
        skipVerifyAnswers?.success ? null : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            mesage={skipVerifyAnswers?.data?.message}
            onCloseFun={() =>
              dispatch(userSigupAction.clearSkipVerifyAnswers())
            }
            // buttons={
            //   <div className="text-center stepBtn mt-4">
            //     <Button
            //       className="popup_Btn error_popup_btn "
            //       onClick={() => {
            //         // setOpenDeletePopup(false);
            //         dispatch(userSigupAction.clearSkipVerifyAnswers());
            //       }}
            //     >
            //       ok, Close
            //     </Button>
            //   </div>
            // }
          />
        )
      ) : null}

      {skipVerificationPopup && (
        <AlertDialog
          type="Info"
          openPopup={skipVerificationPopup}
          onClose={true}
          onCloseFun={() => {
            setSkipVerificationPopup(false);
          }}
          mesage={"Do you want to skip the Property Verification process?"}
          footer={false}
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={() => setSkipVerificationPopup(false)}
              >
                No
              </Button>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={skipQuestionAnswers}
              >
                Yes
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default SecurityInfoScreen;
