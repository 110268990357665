/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Cropper } from "react-cropper";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { Loader } from "../../common/loader";
import FileUpload from "../../common/FileUpload";
import AlertDialog from "../../common/AlertDialog";
import { RECAPTCHA_SITE_KEY } from "../../helpers/constants";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import { createFileUrl, getObjLength } from "../../helpers/utils";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import pdfPlaceholder from "../../../assets/service-provider/pdf_placeholder.webp";
import wordDocPlaceholder from "../../../assets/service-provider/document_placeholder.webp";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";

import "../../user-signup/signup.scss";

export const fieldsValidations = {
  first_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string("Please enter your Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
  address: Yup.string("Please enter your Address.")
    .trim("The Address cannot include leading and trailing spaces")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),

  city: Yup.string("Please enter your City.")
    .trim("The city cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),

  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // .min(6, "Zip needs 6 digits")
  // .max(6, "Zip needs 6 digits"),
  phone: Yup.string("PLease enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Mobile number is required"),
  password: Yup.string("Please enter Password.")
    .required("Password is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
    )
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    ),
  confirm_password: Yup.string("Please enter Confirm Password.")
    .required("Confirm Password is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf([Yup.ref("password")], "Both password need to be the same")
        .test(
          "no-spaces",
          "Password cannot contain any spaces",
          (value) => !/\s/.test(value)
        ),
    }),
};
const validationSchema = Yup.object().shape({
  ...fieldsValidations,
  upload_logo: Yup.string(),
  document: Yup.string(),
});

export const closeDeleteIconStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  cursor: "pointer",
};

const SignInForm = (props) => {
  const dispatch = useDispatch();
  const signUpFormRef = useRef();
  const captchaRef = useRef();
  const cropperRef = useRef();

  const [fromErr, setFormErr] = useState("err");
  const [openPopup, setOpenPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadedServiceAgreement, setUploadedServiceAgreement] =
    useState(null);
  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));
  
  const stateData = useSelector((state) => state.signUpUser.stateList);
  const { spSignupLoader, createAccResponse } = useSelector(
    (state) => state.serviceProviderSignup
  );
  const { checkReCAPTCHADetails, checkReCAPTCHALoader } = useSelector(
    (state) => state?.signUpUser
  );

  const onSubmit = (values, errors) => {
    // console.log("values=>", values);

    // let data = { ...values, step_key: "SEND_OTP", step: 0 };
    dispatch(
      serviceProviderSignupAction.spSignupData({
        url: "service-provider/send-otp",
        data: values,
        isFile: true,
      })
    );
  };
  const onClickSubmit = (err) => {
    setFormErr(err);
    // props.onBtnClick();
  };
  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCaptchaChange = (captchaValue) => {
    setRecaptchaToken(captchaValue);
    dispatch(serviceProviderSignupAction.fetchSuccess({}));
    setFormErr("");
  };

  // when user select the image
  const onChange = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  // get cropped image
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      signUpFormRef?.current?.setFieldValue(
        "upload_logo",
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      console.log(
        "croppedImage: ",
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
    }
  };

  // clear service agreement data
  const clearServiceAgreementData = () => {
    signUpFormRef?.current?.setFieldValue("document", "");
    setUploadedServiceAgreement("");
  };

  useEffect(() => {
    // clear success-error captcha data
    dispatch(userSigupAction.setreCAPTCHA({}));

    console.log("states api");
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
  }, []);
  useEffect(() => {
    if (getObjLength(createAccResponse)) {
      if (createAccResponse?.success) {
        setOpenPopup(false);
        setRecaptchaToken(null);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        localStorage.removeItem("CAPTCHA_Validate");
        props.onBtnClick();
      } else {
        setOpenPopup(true);
        setRecaptchaToken(null);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        signUpFormRef?.current?.setSubmitting(false);
      }
    }
  }, [createAccResponse]);
  const FieldStyle = {
    borderRadius: "3px",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (
      getObjLength(checkReCAPTCHADetails) &&
      checkReCAPTCHADetails?.data?.success &&
      formValues
    ) {
      if (localStorage.getItem("CAPTCHA_Validate")) {
        localStorage.setItem("spSignupDetails", JSON.stringify(formValues));
        const formData = new FormData();

        Object.keys(formValues)?.map((key, index) =>
          formData.append(key, formValues[key])
        );
        onSubmit(formData);
      }
    }
  }, [checkReCAPTCHADetails]);
  return (
    <>
      <Box className={`${isPadding && "apex-padding"}`}>
        <Formik
          enableReinitialize
          validateOnChange
          innerRef={signUpFormRef}
          initialValues={{
            first_name: "",
            last_name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            email: "",
            phone: "",
            phone_number: "",
            password: "",
            confirm_password: "",
            upload_logo: "",
            document: "",
          }}
          validationSchema={validationSchema}
          // onSubmit={onSubmit}
          onSubmit={(values, validation) => {
            if (!isValidPhoneNumber(values?.phone)) {
              return;
            }
            if (!recaptchaToken) {
              return;
            }
            localStorage.setItem("CAPTCHA_Validate", 1);
            dispatch(
              userSigupAction.checkreCAPTCHA({
                url: `validate-captcha?token=${recaptchaToken}`,
                data: {},
              })
            );

            setFormValues(values);
            // onSubmit(values);
          }}
        >
          {({ values, setFieldValue, touched, errors, isSubmitting }) => {
            console.log("values", values);
            console.log(
              "uploadedServiceAgreement",
              uploadedServiceAgreement?.type
            );
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="First name"
                      name="first_name"
                      value={values?.first_name}
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"First Name"}
                      onChange={(event) => {
                        setFieldValue("first_name", event.target.value);
                      }}
                      helperText={touched.first_name && errors.first_name}
                      error={touched.first_name && Boolean(errors.first_name)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Last name"
                      name="last_name"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Last Name"}
                      value={values?.last_name}
                      onChange={(event) => {
                        setFieldValue("last_name", event.target.value);
                      }}
                      helperText={touched.last_name && errors.last_name}
                      error={touched.last_name && Boolean(errors.last_name)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Address"
                      name="address"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Address"}
                      value={values?.address}
                      onChange={(event) => {
                        setFieldValue("address", event.target.value);
                      }}
                      helperText={touched.address && errors.address}
                      error={touched.address && Boolean(errors.address)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="City"
                      name="city"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"City"}
                      value={values?.city}
                      onChange={(event) => {
                        setFieldValue("city", event.target.value);
                      }}
                      helperText={touched.city && errors.city}
                      error={touched.city && Boolean(errors.city)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      className="sign-textField"
                      error={touched.state && Boolean(errors.state)}
                    >
                      <InputLabel
                        shrink
                        // id="demo-simple-select-placeholder"
                        style={{ color: "#00000075" }}
                        label="State"
                      >
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-placeholder"
                        id="demo-simple-select"
                        value={values?.state}
                        label="State"
                        name="state"
                        variant="outlined"
                        onChange={(event) => {
                          setFieldValue("state", event.target.value);
                        }}
                        MenuProps={MenuProps}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <span style={{ color: "#00000075" }}>State</span>
                        </MenuItem>
                        {stateData?.map((state) => (
                          <MenuItem value={state.name}>{state.name}</MenuItem>
                        ))}
                      </Select>
                      {touched.state && Boolean(errors.state) && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.state && errors.state}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="sign-textField "
                      // length={6}
                      // id="outlined-basic"
                      label="Zip"
                      name="zip"
                      type="number"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Zip"}
                      value={values?.zip}
                      onChange={(event) => {
                        setFieldValue("zip", event.target.value);
                      }}
                      helperText={touched.zip && errors.zip}
                      error={touched.zip && Boolean(errors.zip)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Email Address"
                      name="email"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Email Address"}
                      value={values?.email}
                      onChange={(event) => {
                        setFieldValue("email", event.target.value);
                      }}
                      onBlur={(event) =>
                        setFieldValue("email", event.target.value)
                      }
                      helperText={touched.email && errors.email}
                      error={touched.email && Boolean(errors.email)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomPhoneNumberInput
                      name="phone"
                      values={values}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      otherProps={{
                        onChange: (event) => {
                          setFieldValue("phone", event);
                          setFieldValue("phone_number", event);
                          if (!event) {
                            setFieldValue("phone", "");
                            setFieldValue("phone_number", "");
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OutlinedInput
                      className="textfield"
                      type={showPassword ? "text" : "password"}
                      placeholder="Create Your Password"
                      name="password"
                      sx={{ backgroundColor: "#f6f9fc", outline: "none" }}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword(setShowPassword)
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={values?.password}
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("password", event.target.value?.trim());
                      }}
                      helperText={touched.password && errors.password}
                      error={touched.password && Boolean(errors.password)}
                    />
                    {touched.password && Boolean(errors.password) && (
                      <FormHelperText
                        sx={{
                          color: "#d32f2f !important",
                          m: "0 !important",
                          maxWidth: "100% !important",
                        }}
                      >
                        {touched.password && errors.password}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OutlinedInput
                      className="textfield"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Your Password"
                      name="confirm_password"
                      sx={{ backgroundColor: "#f6f9fc" }}
                      inputProps={{
                        autoComplete: "new-confirm_password",
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm_password visibility"
                            onClick={() =>
                              handleClickShowPassword(setShowConfirmPassword)
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={values?.confirm_password}
                      fullWidth
                      onChange={(event) => {
                        setFieldValue(
                          "confirm_password",
                          event.target.value?.trim()
                        );
                      }}
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      error={
                        touched.confirm_password &&
                        Boolean(errors.confirm_password)
                      }
                    />
                    {touched.confirm_password &&
                      Boolean(errors.confirm_password) && (
                        <FormHelperText
                          sx={{
                            color: "#d32f2f !important",
                            m: "0 !important",
                            maxWidth: "100% !important",
                          }}
                        >
                          {touched.confirm_password && errors.confirm_password}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* When cropper will crop the image then we will have data in the croppedImage state so code will transfer to else condition */}
                    {!croppedImage ? (
                      selectedFile ? (
                        <>
                          {/* When service provider selects the image then this cropper will be displayed */}
                          <Cropper
                            ref={cropperRef}
                            style={{ height: "280px", width: "100%" }}
                            zoomTo={0}
                            initialAspectRatio={1}
                            src={selectedFile}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={false}
                          />
                          <Box className="d-flex justify-content-start" mt={2}>
                            <Button
                              onClick={getCropData}
                              variant="contained"
                              className="success-btn"
                              // color="primary"
                            >
                              Crop Logo
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <>
                          {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                          <span className="servicer-signup-form">
                            <FileUpload
                              name="upload_doc"
                              accept={{
                                "image/png": [".png", ".jpeg", ".jpg"],
                              }}
                              multiple={false}
                              onFileUpload={async (file) => {
                                onChange(file);
                              }}
                              dragDropText={`Drag and drop Company Logo or `}
                              greenText="Upload a Company Logo"
                              subText="Only .png, .jpeg, .jpg files are allowed"
                            />
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                        <Box
                          className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                          sx={{
                            backgroundColor: "#F6F9FC",
                            border: croppedImage ? "1px solid #E2E8EB" : "",
                          }}
                        >
                          <img
                            src={croppedImage}
                            style={{
                              minHeight: "150px",
                              maxHeight: "230px",
                            }}
                            alt="cropped"
                            id="croppedImage"
                            className="rounded"
                          />

                          <IconButton
                            sx={{
                              ...closeDeleteIconStyle,
                              color: "#FC2125",
                              fontSize: "1.4rem",
                            }}
                            onClick={() => {
                              setCroppedImage(null);
                              setSelectedFile(null);
                              signUpFormRef?.current?.setFieldValue(
                                "upload_logo",
                                ""
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className="">
                    <span
                      className={`${
                        uploadedServiceAgreement
                          ? "d-flex justify-content-center align-items-center flex-column rounded py-4"
                          : ""
                      }`}
                      style={{
                        backgroundColor: "#F6F9FC",
                        height: "100%",
                        border: uploadedServiceAgreement
                          ? "1px solid #E2E8EB"
                          : "",
                        gap: uploadedServiceAgreement ? "10px" : "",
                      }}
                    >
                      {/* This condition will be true if servicer selects any file which type is either .pdf or .doc/.docx */}
                      {uploadedServiceAgreement ? (
                        <>
                          {/* This is displayed for the file type pdf */}
                          {uploadedServiceAgreement?.type ===
                          "application/pdf" ? (
                            <>
                              <span
                                className="position-relative p-2 bg-white rounded"
                                sx={{ backgroundColor: "#F6F9FC" }}
                                onClick={() => {
                                  window.open(
                                    createFileUrl(uploadedServiceAgreement),
                                    "_blank"
                                  );
                                }}
                              >
                                <img
                                  src={pdfPlaceholder}
                                  alt="Document Preview"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                                <span
                                  style={closeDeleteIconStyle}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    clearServiceAgreementData();
                                  }}
                                >
                                  <CloseOutlinedIcon />
                                </span>
                              </span>
                              <Typography className="mw-100 m-0">
                                <CustomLinkWrapper
                                  link={
                                    uploadedServiceAgreement
                                      ? createFileUrl(uploadedServiceAgreement)
                                      : "#"
                                  }
                                  title={uploadedServiceAgreement?.path}
                                  key={uploadedServiceAgreement?.path}
                                  classes="preview-url"
                                />
                              </Typography>
                            </>
                          ) : uploadedServiceAgreement?.type ===
                              "application/msword" ||
                            uploadedServiceAgreement?.type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                            <>
                              {/* This will display for the file type document */}
                              <span
                                className="position-relative p-2 bg-white rounded"
                                sx={{ backgroundColor: "#F6F9FC" }}
                                onClick={() => {
                                  window.open(
                                    createFileUrl(uploadedServiceAgreement),
                                    "_blank"
                                  );
                                }}
                              >
                                <img
                                  src={wordDocPlaceholder}
                                  alt="Document Preview"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                                <span
                                  style={closeDeleteIconStyle}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    clearServiceAgreementData();
                                  }}
                                >
                                  <CloseOutlinedIcon />
                                </span>
                              </span>
                              <Typography className="mw-100 m-0">
                                <CustomLinkWrapper
                                  link={
                                    uploadedServiceAgreement
                                      ? createFileUrl(uploadedServiceAgreement)
                                      : "#"
                                  }
                                  title={uploadedServiceAgreement?.path}
                                  key={uploadedServiceAgreement?.path}
                                  classes="preview-url"
                                />
                              </Typography>
                            </>
                          ) : (
                            <>
                              {/* It will show the selected file if servicer selects the file which is neither in the pdf nor in the doc category */}
                              <Box
                                className="preview-file bg-white"
                                padding="3px 15px"
                                mb={0.9}
                              >
                                <CustomLinkWrapper
                                  link={
                                    uploadedServiceAgreement
                                      ? createFileUrl(uploadedServiceAgreement)
                                      : "#"
                                  }
                                  title={uploadedServiceAgreement?.path}
                                  key={uploadedServiceAgreement?.path}
                                  classes="preview-url"
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    clearServiceAgreementData();
                                  }}
                                >
                                  <CloseOutlinedIcon />
                                </span>
                              </Box>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* when there is no file selected then it will display the file upload field */}
                          <span className="servicer-signup-form">
                            <FileUpload
                              name="document"
                              accept={{
                                "application/pdf": [".pdf"],
                                "application/msword": [".doc"],
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                  [".docx"],
                              }}
                              multiple={false}
                              onFileUpload={async (file) => {
                                // onChange(file);
                                if (file?.length > 0) {
                                  setUploadedServiceAgreement(file[0]);
                                  setFieldValue("document", file[0]);
                                }
                              }}
                              dragDropText={`Drag and drop Service Agreement or `}
                              greenText="Upload a Service Agreement"
                              subText="Only .pdf, .doc, .docx files are allowed"
                            />
                          </span>
                        </>
                      )}
                    </span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center my-2"
                  >
                    <FormControl className="textField captcha-field">
                      <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                        className="g-recaptcha"
                        ref={captchaRef}
                      />
                    </FormControl>
                  </Grid>
                  {isSubmitting && !recaptchaToken && (
                    <p className="text-danger text-center w-100 mb-3">
                      {isSubmitting && !recaptchaToken
                        ? "Please verify ReCAPTCHA"
                        : fromErr}
                    </p>
                  )}

                  <Grid item xs={12} md={12}>
                    <div className="text-center createAcBtn mt-2">
                      <Button
                        onClick={() => onClickSubmit(errors)}
                        type="submit"
                        className="next_button"
                        variant="contained"
                      >
                        Create your account
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
      {getObjLength(createAccResponse) && openPopup ? (
        // !createAccResponse?.data?.success
        // && createAccResponse?.data !== null
        <AlertDialog
          type={
            createAccResponse?.data?.errors?.user_exist ? "Warning" : "Error"
          }
          openPopup={openPopup}
          onClose={true}
          mesage={
            // {<p className="text-center text-danger m-0">
            !createAccResponse?.data?.success &&
            createAccResponse?.data?.message
          }
          onCloseFun={() => setOpenPopup(false)}
          buttons={
            createAccResponse?.data?.errors?.user_exist && (
              <Button
                className="popup_Btn success_popup_btn"
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                ok, Close
              </Button>
            )
          }
          // </p>}
        />
      ) : null}

      {getObjLength(checkReCAPTCHADetails) &&
        !checkReCAPTCHADetails?.data?.success && (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userSigupAction.setreCAPTCHA({}));
            }}
            mesage={checkReCAPTCHADetails?.data?.message}
            footer={false}
          />
        )}
      <Loader open={spSignupLoader || checkReCAPTCHALoader} />
    </>
  );
};

export default SignInForm;
