import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Button, FormHelperText, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";

import { Loader } from "../../common/loader";
import { ROLES } from "../../helpers/constants";
import AlertDialog from "../../common/AlertDialog";
import {
  decryptPayload,
  getObjLength,
  numberToWords,
} from "../../helpers/utils";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";

const initialValues = {
  houseInfo_1: "",
  houseInfo_2: "",
  houseInfo_3: "",
};

const validationSchema1 = Yup.object().shape({
  houseInfo_1: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_2: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_3: Yup.string().required(
    "Please select an option to proceed further."
  ),
});

const validationSchema2 = Yup.object().shape({
  houseInfo_1: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_2: Yup.string().required(
    "Please select an option to proceed further."
  ),
});

const SecurityInfoScreen = ({
  values,
  setFieldValue,
  touched,
  errors,
  onBtnClick,
  setAnswers,
  onChangeModalScreen,
  onCloseModalHandler,
  isSubscriptionScreenRequired,
  isByPassOptionAvailable,
  isAdminCreateUser = false,
  isQuestionByPass = false,
  backToSearchProperty,
  editProperty = false,
  isShow = true,
  //old property data
  oldPropertyData = {},
  setSkipVerification,
  handleCloseAddPropertyModal,
}) => {
  const [checked1, setChecked1] = useState(false);
  const [checkTerms, setCheckTerms] = useState(true);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [verifiedCount, setVerifiedCount] = useState(1);
  const [skipVerificationPopup, setSkipVerificationPopup] = useState(false);

  const role = decryptPayload(localStorage.getItem("roles"));

  const getPropertyData = useSelector(
    (state) => state.properties.selectedProperty
  );

  const propertyQuestion = useSelector(
    (state) => state?.properties?.propertyQuestion
  );

  const { data: userData } = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );
  const {
    propertyVerified,
    selectedUserID,
    verificationLoader,
    genearteQuesPayload,
  } = useSelector((state) => state?.properties);
  const accCreated = useSelector((state) => state.signUpUser.createAccResponse);

  const dispatch = useDispatch();

  console.log("oldPropertyData ==>", oldPropertyData);

  const onSubmit = (values, errors) => {
    setCheckTerms(checked1);

    const key1 = Object.values(propertyQuestion?.data?.questions)[0]
      ?.question_code;
    const key2 = Object.values(propertyQuestion?.data?.questions)[1]
      ?.question_code;
    const key3 = Object.values(propertyQuestion?.data?.questions)[2]
      ?.question_code;

    let answer = {};
    answer[key1] = values.houseInfo_1;
    answer[key2] = values.houseInfo_2;
    answer[key3] = values.houseInfo_3;
    var selectedData = {};
    if (Object.keys(getPropertyData).length > 0) {
      console.log("getPropertyData==> 1", getPropertyData);
      let propertyData = getPropertyData?.json_response;
      selectedData = {
        property_id: getPropertyData?.property_id,
        state_code: propertyData.mm_fips_state_code,
        user_id:
          isAdminCreateUser && getObjLength(accCreated)
            ? accCreated?.data?.user_id
            : isByPassOptionAvailable
            ? selectedUserID
            : userData?.user_id,
        answers: answer,
      };

      console.log("answers object==>", selectedData);
    }

    if (checked1) {
      if (isByPassOptionAvailable) {
        dispatch(propertiesAction.setVerificationLoader(true));
        console.log("edit ptoperty", editProperty);
        if (editProperty) {
          dispatch(
            propertiesAction.verifyAnswers({
              url: "admin/property/verify-answers",
              data: {
                ...selectedData,
                prop_id: oldPropertyData?.id,
                old_address: oldPropertyData?.address,
                old_property_id: oldPropertyData?.property_id,
                step: 2,
                step_key: "VERIFY_ANSWERS",
                ...genearteQuesPayload,
              },
              token: true,
            })
          );
        } else {
          dispatch(
            propertiesAction.verifyAnswers({
              url: "admin/properties/verify-answers",
              data: {
                ...selectedData,
                step: 2,
                step_key: "VERIFY_ANSWERS",
                ...genearteQuesPayload,
              },
              token: true,
            })
          );
        }
      } else {
        setAnswers(selectedData);
        onChangeModalScreen(
          isAdminCreateUser ? "propertyList" : "subscriptions"
        );
      }
      // handleToggle();
      // dispatch(
      //   propertiesAction.verifyAnswers({
      //     url: "properties/verify-answers",
      //     data: selectedData,
      //     token: true,
      //   })
      // );
    }

    // if (Object.keys(fromErr).length === 0) {
    // onBtnClick();
    // }
  };

  const onClickSubmit = (err) => {
    console.log("inner error==>", err);
    // setFormErr(err);
  };

  const questions =
    propertyQuestion?.data !== undefined &&
    Object.values(
      //  Object.keys(propertyQuestion?.data)?.length > 0
      propertyQuestion?.success && !propertyQuestion?.data?.is_verified
        ? propertyQuestion?.data?.questions
        : {}
    );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const onChecked = (e) => {
    console.log("cjceked val==?", e.target.checked);
    setChecked1(e.target.checked);
    if (!e.target.checked) {
      setCheckTerms(e.target.checked);
    } else {
      setCheckTerms(true);
    }
  };

  // handle modal open
  const handleResponseModalOpen = () => {
    setIsResponseModalOpen(true);
  };

  // handle modal close
  const handleResponseModalClose = () => {
    if (editProperty) {
      if (oldPropertyData?.subscription?.is_active) {
        onCloseModalHandler("success");
      } else {
        onChangeModalScreen(
          isAdminCreateUser ? "propertyList" : "subscriptions"
        );
      }
    } else {
      onChangeModalScreen(isAdminCreateUser ? "propertyList" : "subscriptions");
    }
    setIsResponseModalOpen(false);
  };

  // Skip property verification
  const skipQuestionAnswers = () => {
    setSkipVerification(true);
    setAnswers({
      property_id: getPropertyData?.property_id,
      state_code: getPropertyData?.json_response?.mm_fips_state_code,
      user_id:
        isAdminCreateUser && getObjLength(accCreated)
          ? accCreated?.data?.user_id
          : isByPassOptionAvailable
          ? selectedUserID
          : userData?.user_id,
    });
    setSkipVerificationPopup(false);
    onChangeModalScreen(isAdminCreateUser ? "propertyList" : "subscriptions");
  };

  // to prevent closing modal until verified answers
  // useEffect(() => {
  //   if (propertyVerified?.success === true) {
  //     onCloseModalHandler();
  //   }
  //   handleClose();
  // }, [onCloseModalHandler, propertyVerified]);

  // useEffect to show success modal if the question verified successfully
  useEffect(() => {
    if (isByPassOptionAvailable && getObjLength(propertyVerified)) {
      handleResponseModalOpen();
      dispatch(propertiesAction.setVerificationLoader(false));
    }
  }, [dispatch, isByPassOptionAvailable, propertyVerified]);

  return (
    <>
    {isShow &&
    <Typography
         align="center"
         variant="subtitle1"
         className="py-1 formHeader zoom-container"
         sx={{position: "relative"}}
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
            <Button
            onClick={handleCloseAddPropertyModal}
            className="modal-close-btn close-text hide-inner-close"
            style={{
              position: "absolute",
              top: 0,
              right: -16,
              background: "transparent",
              textTransform:"none",
            
            }}
          >
            <CloseIcon className="hide-inner-close" sx={{fontSize:20 , color: "white" }} />
        </Button>
      </Typography>
      }
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={initialValues}
        validationSchema={
          questions?.length > 2 ? validationSchema1 : validationSchema2
        }
        onSubmit={onSubmit}
        // validateOnBlur
        // validateOnMount
      >
        {({ values, setFieldValue, touched, setTouched, errors }) => {
          return (
            <Form>
              <Box className="accountBoxWrapper infoForm_Wrapper p-0 p-sm-3">
                <Typography align="center" variant="h3">
                  Let’s make sure{" "}
                  {role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                    ? "the User is the real owner"
                    : "you are the owner"}{" "}
                </Typography>
                <Typography align="center" variant="body1">
                  Please answer the following questions about{" "}
                  {role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                    ? "User's"
                    : "your"}{" "}
                  property so we are able to identify{" "}
                  {role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                    ? "user"
                    : "you"}{" "}
                  as the owner.
                </Typography>
                <hr className="formSeparator d-none d-sm-block" />
                <Typography
                  variant="h4"
                  className="infoTitle pt-3 p-sm-0 px-0 px-sm-2"
                  mb={2}
                >
                  House information
                </Typography>

                <Box pb={3}>
                  <Grid
                    container
                    spacing={2}
                    textAlign={"start"}
                    alignItems={"center"}
                  >
                    {propertyQuestion?.success &&
                      Object.keys(propertyQuestion).length > 0 && (
                        // questions.map((data, key) => (
                        <>
                          {questions[0]?.question && (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className="mb-0 mb-lg-3"
                              >
                                <Typography
                                  variant="body1"
                                  className="infoLabel"
                                >
                                  {questions[0]?.question}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className="mb-0 mb-lg-3"
                              >
                                <FormControl
                                  fullWidth
                                  className="sign-textField"
                                  error={
                                    touched.houseInfo_1 &&
                                    Boolean(errors.houseInfo_1)
                                  }
                                >
                                  <InputLabel id="demo-simple-select-placeholder">
                                    Select
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-placeholder"
                                    id="demo-simple-select"
                                    value={values?.houseInfo_1}
                                    label="select"
                                    name="houseInfo_1"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "houseInfo_1",
                                        event.target.value
                                      );
                                    }}
                                    MenuProps={MenuProps}
                                  >
                                    {questions[0]?.options?.map((data, i) => (
                                      <MenuItem
                                        value={data !== "" ? data : " "}
                                        sx={{ whiteSpace: "normal" }}
                                      >
                                        {data !== "" ? data : "-"}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.houseInfo_1 &&
                                    Boolean(errors.houseInfo_1) && (
                                      <FormHelperText sx={{ color: "#d32f2f" }}>
                                        {touched.houseInfo_1 &&
                                          errors.houseInfo_1}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </>
                          )}
                          {questions[1]?.question && (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className="mb-0 mb-lg-3"
                              >
                                <Typography
                                  variant="body1"
                                  className="infoLabel"
                                >
                                  {questions[1]?.question}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className="mb-0 mb-lg-3"
                              >
                                <FormControl
                                  fullWidth
                                  className="sign-textField"
                                  error={
                                    touched.houseInfo_2 &&
                                    Boolean(errors.houseInfo_2)
                                  }
                                >
                                  <InputLabel id="demo-simple-select-placeholder">
                                    Select
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-placeholder"
                                    id="demo-simple-select"
                                    value={values?.houseInfo_2}
                                    label="select"
                                    name="houseInfo_2"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "houseInfo_2",
                                        event.target.value
                                      );
                                    }}
                                    MenuProps={MenuProps}
                                  >
                                    {questions[1]?.options?.map((data, i) => (
                                      <MenuItem
                                        value={data !== "" ? data : " "}
                                        sx={{ whiteSpace: "normal" }}
                                      >
                                        {data !== "" ? data : "-"}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.houseInfo_2 &&
                                    Boolean(errors.houseInfo_2) && (
                                      <FormHelperText sx={{ color: "#d32f2f" }}>
                                        {touched.houseInfo_2 &&
                                          errors.houseInfo_2}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </>
                          )}
                          {questions[2]?.question && (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className="mb-0 mb-lg-3"
                              >
                                <Typography
                                  variant="body1"
                                  className="infoLabel"
                                >
                                  {questions[2]?.question}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className="mb-0 mb-lg-3"
                              >
                                <FormControl
                                  fullWidth
                                  className="sign-textField"
                                  error={
                                    touched.houseInfo_3 &&
                                    Boolean(errors.houseInfo_3)
                                  }
                                >
                                  <InputLabel id="demo-simple-select-placeholder">
                                    Select
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-placeholder"
                                    id="demo-simple-select"
                                    value={values?.houseInfo_3}
                                    label="select"
                                    name="houseInfo_3"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "houseInfo_3",
                                        event.target.value
                                      );
                                    }}
                                    MenuProps={MenuProps}
                                  >
                                    {questions[2]?.options?.map((data, i) => (
                                      <MenuItem
                                        value={data !== "" ? data : " "}
                                        sx={{ whiteSpace: "normal" }}
                                      >
                                        {data !== "" ? data : "-"}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.houseInfo_3 &&
                                    Boolean(errors.houseInfo_3) && (
                                      <FormHelperText sx={{ color: "#d32f2f" }}>
                                        {touched.houseInfo_3 &&
                                          errors.houseInfo_3}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </>
                          )}
                        </>
                        // ) : (
                        // ))
                      )}
                  </Grid>
                  {!propertyVerified?.success &&
                    propertyVerified?.data?.message && (
                      <p className="text-danger text-center mt-4">
                        {propertyVerified?.data?.message}
                      </p>
                    )}
                </Box>

                <Box
                  className={!checkTerms && "border border-danger"}
                  textAlign={"start"}
                  py={1}
                  px={2}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"flex-start"}
                    alignItems={"start"}
                  >
                    <span className="pt-1">
                      <CustomizedCheckbox onChecked={onChecked} />
                    </span>
                    <span>
                      I agree that an Employee of EquityProtect may call me at
                      any time if they find an issue or an attempt at fraud on
                      my property. I understand that I will be asked challenge
                      questions on that call to verify my identity. If I receive
                      a call and I am not asked the challenge questions, I will
                      hang up and call Customer Support directly.
                    </span>
                  </Stack>
                </Box>

                {!checkTerms && (
                  <p className="text-danger mx-0">
                    Please select given terms to proceed further.
                  </p>
                )}
                <Box textAlign={"start"} py={1} px={2}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"flex-start"}
                    alignItems={"start"}
                  >
                    <span className="pt-1">
                      <CustomizedCheckbox checked />
                    </span>
                    <span>
                      By checking this box I acknowledge that I am the owner of
                      the property above and give EquityProtect permission to
                      verify my ownership.
                    </span>
                  </Stack>
                </Box>

                <hr className="formSeparator" />
                <div className=" stepBtn d-flex justify-content-center px-2 px-sm-5 pb-3">
                  {role?.includes(ROLES.customer) ||
                  role?.includes(ROLES.professional) ||
                  role?.includes(ROLES.serviceProvider) ||
                  role?.includes(ROLES.secondaryUser) ? (
                    <Button
                      onClick={() => {
                        if (checked1) {
                          setSkipVerificationPopup(true);
                        } else {
                          setCheckTerms(checked1);
                        }
                      }}
                      className="mx-2 next_button other_popup_btn"
                      variant="contained"
                    >
                      Skip
                    </Button>
                  ) : (
                    <Button
                      className="mx-2 next_button other_popup_btn"
                      onClick={() => {
                        console.log("New property ads==>");
                        dispatch(propertiesAction.addNewProperty({}));
                        backToSearchProperty("security");
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    onClick={() => onClickSubmit(errors)}
                    type="submit"
                    className="mx-2 next_button success_popup_btn"
                    // sx={{ padding: "12px 31px !important" }}
                    // variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </Box>
              {(role?.includes(ROLES.customer) ||
                role?.includes(ROLES.professional) ||
                role?.includes(ROLES.serviceProvider)) && (
                <Button
                  className="mx-2 next_button other_popup_btn"
                  onClick={() => {
                    console.log("New property ads==>");
                    dispatch(propertiesAction.addNewProperty({}));
                    backToSearchProperty("security");
                  }}
                >
                  Back
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>

      <Loader open={verificationLoader} />

      {isResponseModalOpen && getObjLength(propertyVerified) && (
        <>
          {getObjLength(propertyVerified) && propertyVerified?.success ? (
            <AlertDialog
              type="Success"
              openPopup={isResponseModalOpen}
              onClose={true}
              onCloseFun={() => {
                handleResponseModalClose();
              }}
              mesage={propertyVerified?.message}
              footer={false}
            />
          ) : (
            // <AlertDialog
            //   type="Error"
            //   openPopup={isResponseModalOpen}
            //   onClose={true}
            //   onCloseFun={() => {
            //     handleResponseModalClose();
            //   }}
            //   mesage={propertyVerified?.data?.message}
            //   footer={false}
            // />
            <AlertDialog
              type="Error"
              openPopup={isResponseModalOpen}
              onClose={false}
              mesage={
                <>
                  <b>{propertyVerified?.data?.message}</b>
                  {parseInt(verifiedCount) < 3 && (
                    <p className="pt-1">
                      You've {numberToWords(3 - parseInt(verifiedCount))} more{" "}
                      {3 - parseInt(verifiedCount) === 1 ? "chance" : "chances"}{" "}
                      to choose correct answers or else you can Find Property
                      again.
                    </p>
                  )}
                </>
              }
              buttons={
                <>
                  {parseInt(verifiedCount) < 3 && (
                    <Button
                      className="popup_Btn other_popup_btn m-2"
                      onClick={() => {
                        setVerifiedCount(parseInt(verifiedCount) + 1);
                        setIsResponseModalOpen(false);
                        dispatch(propertiesAction.clearVerifedAnswersData({}));
                      }}
                    >
                      Try Again ({3 - parseInt(verifiedCount)})
                    </Button>
                  )}
                  <Button
                    className="popup_Btn error_popup_btn"
                    onClick={() => {
                      console.log("New property ads==>");
                      dispatch(propertiesAction.addNewProperty({}));
                      backToSearchProperty("security");
                    }}
                  >
                    Find Property again.
                  </Button>
                </>
              }
            />
          )}
        </>
      )}

      {skipVerificationPopup && (
        <AlertDialog
          type="Info"
          openPopup={skipVerificationPopup}
          onClose={true}
          onCloseFun={() => {
            setSkipVerificationPopup(false);
          }}
          mesage={"Do you want to skip the Property Verification process?"}
          footer={false}
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={() => setSkipVerificationPopup(false)}
              >
                No
              </Button>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={skipQuestionAnswers}
              >
                Yes
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default SecurityInfoScreen;
