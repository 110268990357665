import { useState } from "react";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import CardPayment from "./CardPayment";
import CreditCardForm from "./CreditCardForm";
import SubscriptionPlans from "./SubscriptionPlans";
import OwnerVerification from "./OwnerVerification";
import SecurityInfoScreen from "./SecurityInfoScreen";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";

const SecurityInfoAndCardPayment = ({
  onCloseModalHandler,
  screen,
  onChangeModalScreen,
  isByPassOptionAvailable,
  isAdminCreateUser = false,
  backToSearchProperty,
  selectedData,
  editProperty,
  addPromoCode = false,
  from = "",
  skipVerification = false,
  isShow=true,
  setSkipVerification,
  isUserCreateProperty = false,
  handleCloseAddPropertyModal,
}) => {
  const dispatch = useDispatch();
  const { selectedUserID, selectedProperty } = useSelector(
    (state) => state.properties
  );
  const { mailingAddress, statesCodeList, subscriptionPlans } = useSelector(
    (state) => state.signUpUser
  );

  // states
  const [answers, setAnswers] = useState({});
  const [planId, setPlanId] = useState("");
  const [yearlyPlan, setYearlyPlan] = useState();
  const [fromPropertyList, setFromPropertyList] = useState(false);
  const [planDetails, setPlanDetails] = useState(yearlyPlan);
  const [finalAmount, setFinalAmount] = useState(yearlyPlan);

  useEffect(() => {
    // let data = {
    //   user_id: getObjLength(createAccResponse)
    //     ? createAccResponse.data?.user_id
    //     : userIdFromSession,
    //   step: 2,
    //   step_key: "GET_MAILING_ADDRESS",
    // };
    // dispatch(
    //   userSigupAction.getMailingAddress({
    //     url: "get-mailing-address",
    //     data: data,
    //   })
    // );
    // dispatch(
    //   userSigupAction.getStateCode({ url: "get-states-code", data: {} })
    // );
    let plan = subscriptionPlans?.data?.find((i) => i.name === "Yearly");
    setYearlyPlan(plan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (planId) {
      const planData = subscriptionPlans?.data?.filter(
        (i) => i.plan_id === parseInt(planId)
      );
      console.log("e.target.value==>", planData?.[0]);
      setPlanDetails(planData?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  const cardListAPI = () => {
    dispatch(
      // propertyUsersAction.getCardListData({
      userProfileAction.userCardDetails({
        url: `admin/users/${selectedUserID}/card`,
        data: {},
        token: true,
      })
    );
  };

  useEffect(() => {
    if (from === "add-property") {
      cardListAPI();
    }
  }, [from]);

  return (
    <Box
    // className={`mb-5 profile-wrapper ${
    //   screen === "cardsInfo" ? "pb-0" : ""
    // } bg-white`}
    >
      {screen === "questions" && (
        <SecurityInfoScreen
          isShow={isShow}
          onChangeModalScreen={(scr) =>
            // isByPassOptionAvailable || isAdminCreateUser
            // editProperty
            //   ? onCloseModalHandler("success"):
            onChangeModalScreen(scr)
          }
          handleCloseAddPropertyModal={handleCloseAddPropertyModal}
          onCloseModalHandler={onCloseModalHandler}
          backToSearchProperty={backToSearchProperty}
          setAnswers={setAnswers}
          isByPassOptionAvailable={isByPassOptionAvailable}
          isAdminCreateUser={isAdminCreateUser}
          editProperty={editProperty}
          oldPropertyData={selectedData}
          setSkipVerification={setSkipVerification}
        />
      )}

      {screen === "propertyList" && (
        <OwnerVerification
          onChangeModalScreen={onChangeModalScreen}
          setFromPropertyList={setFromPropertyList}
        />
      )}

      {screen === "subscriptions" && (
        <SubscriptionPlans
          isShow={isShow} 
          handleCloseAddPropertyModal={handleCloseAddPropertyModal}
          from={from}
          planId={setPlanId}
          onChangeModalScreen={onChangeModalScreen}
          isByPassOptionAvailable={isByPassOptionAvailable}
          isAdminCreateUser={isAdminCreateUser}
          setFinalAmount={setFinalAmount}
          setSkipVerification={setSkipVerification}
          isUserCreateProperty={isUserCreateProperty}
          fromPropertyList={fromPropertyList}
        />
      )}
      {screen === "cardsInfo" &&
        (isAdminCreateUser ? (
          <Box className="bg-white p-3">
            {/* <Elements stripe={stripePromise}> */}
            <CreditCardForm
              isShow={isShow}
              mailingAddress={mailingAddress?.data?.address}
              mailingData={mailingAddress?.data}
              stateCodesData={statesCodeList}
              planDetails={planDetails}
              onChangeModalScreen={onChangeModalScreen}
              finalAmount={finalAmount}
              onCloseModalHandler={onCloseModalHandler}
              fromPropertyList={fromPropertyList}
              setFromPropertyList={setFromPropertyList}
            />
            {/* </Elements> */}
          </Box>
        ) : (
          <CardPayment
            isShow={isShow}
            handleCloseAddPropertyModal={handleCloseAddPropertyModal}
            planId={planId}
            answers={answers}
            onChangeModalScreen={onChangeModalScreen}
            onCloseModalHandler={onCloseModalHandler}
            isByPassOptionAvailable={isByPassOptionAvailable}
            isAdminCreateUser={isAdminCreateUser}
            selectedData={
              from === "add-property" ? selectedProperty : selectedData
            }
            addPromoCode={addPromoCode}
            // for add property
            from={from}
            uid={from === "add-property" && selectedUserID}
            callListingAPI={cardListAPI}
            skipVerification={skipVerification}
            isUserCreateProperty={isUserCreateProperty}
          />
        ))}
    </Box>
  );
};

export default SecurityInfoAndCardPayment;
