import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import useTimer from "../../hooks/useTimer";
import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import SecureConnectionTemplate from "../../common/secureConnectionTemplate";
import { serviceProviderSignInAction } from "../../../redux/slices/serviceProvider/SPSignINSlice";
import { resendOTPSeconds } from "../../helpers/constants";

const initialValues = {
  confirmation_code: "",
  password: "",
  password_confirmation: "",
};

const validationSchema = Yup.object().shape({
  confirmation_code: Yup.string("Please enter Verification code.")
    .max(6, "Verification code must be at most 6 digits")
    .required("Verification code is required"),
  password: Yup.string("Please enter your new Password.")
    .required("Password is required.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
    )
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    ),
  password_confirmation: Yup.string("Please enter Confirm Password.")
    .required("Confirm new password is required.")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf([Yup.ref("password")], "Both password need to be the same.")
        .test(
          "no-spaces",
          "Password cannot contain any spaces",
          (value) => !/\s/.test(value)
        ),
    }),
});

const ServicerCreateNewPass = () => {
  const [fromErr, setFormErr] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [otpSend, setOtpSend] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //initializes the timer states for hook
  const {
    timer,
    isTimerActive,
    startTimer,
    stopTimer,
    formatTimeForResendOTP,
  } = useTimer(120);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    forgetPassData,
    emailForResetPass,
    signInLoader,
    resetPassData,
    selectedOption,
  } = useSelector((state) => state.serviceProviderSignin);

  useEffect(() => {
    window.onbeforeunload = function () {
      console.log("refreshed...");
      return true;
    };

    return () => {
      console.log("refreshed stop...");
      // dispatch(userSigupAction.clearData());
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const onSubmit = (values, errors) => {
    // return console.log("values asdasd", values)
    console.log("Form data", values);
    console.log("payload in js", { values }, { emailForResetPass });
    const data = { ...values, ...emailForResetPass };
    console.log(data, "Form data", values);
    dispatch(
      serviceProviderSignInAction.userResetPassData({
        url: "service-provider/password/reset/" + selectedOption,
        data: { ...data },
      })
    );
  };

  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const sendOTP = (resetForm) => {
    setOtpSend("");
    setFormErr("");
    resetForm();
    dispatch(
      serviceProviderSignInAction.userForgetPassData({
        url: "service-provider/forgot-password/" + selectedOption,
        data: emailForResetPass,
        selectedOption: selectedOption,
      })
    );
    startTimer(resendOTPSeconds);
  };
  useEffect(() => {
    if (
      Object.keys(forgetPassData)?.length === 0 ||
      emailForResetPass?.length === 0
    ) {
      navigate("/reset-password", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (getObjLength(forgetPassData)) {
      if (forgetPassData?.success) {
        setOtpSend(forgetPassData?.message);
        setFormErr("");
      } else {
        setOtpSend("");
        setFormErr(forgetPassData?.data?.message);
      }
    }
  }, [forgetPassData]);

  useEffect(() => {
    if (getObjLength(resetPassData)) {
      if (resetPassData?.success) {
        setOtpSend(resetPassData?.message);
        setOpenPopup(true);
        setFormErr("");
        stopTimer();
      } else {
        setOpenPopup(false);
        setOtpSend("");
        setFormErr(resetPassData?.data?.message);
      }
    }
  }, [resetPassData]);

  function createPass() {
    return (
      <>
        <Box className="accountBoxWrapper Form_Wrapper ">
          <Typography align="center" variant="h3">
            Create new password
          </Typography>
          <Typography align="center" variant="body1">
            Please enter your new password below
          </Typography>
          <Box>
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, touched, errors, resetForm }) => {
                return (
                  <Form>
                    <Grid
                      container
                      spacing={2}
                      textAlign={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={12} md={12}>
                        <TextField
                          className="textField"
                          type={"number"}
                          variant="outlined"
                          label={`Verify Token from ${
                            selectedOption === "email" ? "Email" : "Phone"
                          }`}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          name="confirmation_code"
                          value={values?.confirmation_code}
                          onChange={(event) => {
                            setFieldValue(
                              "confirmation_code",
                              event.target.value
                            );
                            setOtpSend("");
                            setFormErr("");
                          }}
                          helperText={
                            touched.confirmation_code &&
                            errors.confirmation_code
                          }
                          error={
                            touched.confirmation_code &&
                            Boolean(errors.confirmation_code)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl className="textField">
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <OutlinedInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm Your Password"
                            name="password"
                            sx={{ backgroundColor: "#f6f9fc" }}
                            inputProps={{
                              autoComplete: "new-password",
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    handleClickShowPassword(setShowPassword)
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            value={values?.password}
                            fullWidth
                            onChange={(event) => {
                              setFieldValue(
                                "password",
                                event.target.value?.trim()
                              );
                            }}
                            helperText={touched.password && errors.password}
                            error={touched.password && Boolean(errors.password)}
                          />
                        </FormControl>
                        {touched.password && Boolean(errors.password) && (
                          <FormHelperText
                            sx={{
                              color: "rgb(211, 47, 47) !important",
                              mb: "0 !important",
                              fontSize: "12px !important",
                              // maxWidth: "50% !important",
                            }}
                          >
                            {touched.password && errors.password}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl className="textField">
                          <InputLabel htmlFor="confirm password">
                            Confirm Password
                          </InputLabel>
                          <OutlinedInput
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Your Password"
                            name="password_confirmation"
                            sx={{ backgroundColor: "#f6f9fc" }}
                            inputProps={{
                              autoComplete: "new-password_confirmation",
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password_confirmation visibility"
                                  onClick={() =>
                                    handleClickShowPassword(
                                      setShowConfirmPassword
                                    )
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            value={values?.password_confirmation}
                            fullWidth
                            onChange={(event) => {
                              setFieldValue(
                                "password_confirmation",
                                event.target.value?.trim()
                              );
                            }}
                            helperText={
                              touched.password_confirmation &&
                              errors.password_confirmation
                            }
                            error={
                              touched.password_confirmation &&
                              Boolean(errors.password_confirmation)
                            }
                          />
                        </FormControl>
                        {touched.password_confirmation &&
                          Boolean(errors.password_confirmation) && (
                            <FormHelperText
                              sx={{
                                color: "rgb(211, 47, 47) !important",
                                mb: "0 !important",
                                fontSize: "12px !important",
                                // maxWidth: "100% !important",
                              }}
                            >
                              {touched.password_confirmation &&
                                errors.password_confirmation}
                            </FormHelperText>
                          )}
                      </Grid>
                    </Grid>

                    {fromErr?.length > 0 && (
                      <p className="text-danger text-center mt-1">{fromErr}</p>
                    )}
                    {otpSend?.length > 0 && (
                      <p className="text-success text-center mt-1">
                        <b>
                          {otpSend}
                          {selectedOption === "email"
                            ? otpSend?.length > 0 &&
                              " If you haven't received the email in your inbox, please check your email spam folder."
                            : null}
                        </b>
                      </p>
                    )}

                    <div className="text-center stepBtn mt-5">
                      <Button
                        type="submit"
                        className="login_button"
                        variant="contained"
                        // onClick={checkFields}
                      >
                        Reset password
                      </Button>
                      {/* <Button onClick={resetForm}>REset</Button> */}
                    </div>
                    <div className="text-center tokenCodeText">
                      {isTimerActive ? (
                        <b>
                          Resend OTP in {formatTimeForResendOTP(timer)} seconds
                        </b>
                      ) : (
                        <>
                          Did not receive the code?
                          <Button
                            sx={{ textTransform: "none" }}
                            onClick={() => sendOTP(resetForm)}
                          >
                            <Link
                              className="text-blue text-decoration-none"
                              // to="/reset-password"
                            >
                              Get a new one
                            </Link>
                          </Button>
                        </>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </>
    );
  }
  return (
    <>
      <Grid container>
        <Grid
          xs={12}
          sm={12}
          mdOffset={2}
          md={8}
          lgOffset={2}
          lg={8}
          xlOffset={2}
          xl={8}
        >
          <SecureConnectionTemplate content={createPass()} footer={true} />
        </Grid>
      </Grid>
      <Loader open={signInLoader} />
      {Object.keys(resetPassData).length > 0 &&
      resetPassData.success &&
      openPopup ? (
        <AlertDialog
          type="Success"
          openPopup={openPopup}
          mesage={otpSend}
          onClose={false}
          buttons={
            <Link
              className="text-decoration-none text-white"
              style={{ letterSpacing: "0.5px" }}
              to="/login"
            >
              <Button
                className="popup_Btn success_popup_btn mt-3"
                variant="contained"
                onClick={() => {
                  setOpenPopup(false);
                  dispatch(
                    serviceProviderSignInAction.clearResetPasswordData()
                  );
                }}
              >
                &nbsp; Please Login again.
              </Button>
            </Link>
          }
        />
      ) : null}
    </>
  );
};

export default ServicerCreateNewPass;
