import React from "react";
import Success from "../../assets/icons/success.png";
import Error from "../../assets/icons/error.png";
import Info from "../../assets/icons/info.png";
import Warning from "../../assets/icons/warning.png";
import { Box, Button, Stack, Typography } from "@mui/material";
import help_center from "../../assets/icons/help_center.svg";
import CallUsNow from "./CallUsNow";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import "./common.scss";

const PlainAlertMessage = ({
  openPopup,
  type,
  message,
  onClose,
  buttons,
  onCloseFun,
  footer = true,
}) => {
  const [open, setOpen] = React.useState(openPopup);
  const handleClose = () => {
    setOpen(false);
    onCloseFun();
  };
  return (
    <>
      <Box className="text-center">
        {onClose && (
          <Button
            className="modal-close-btn"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 0,
              top: 8,
              padding: 0,
              margin: 0,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        )}

        <Box className="my-3">
          {type === "Success" && (
            <img className="popup_icon" src={Success} alt={type} />
          )}
          {type === "Error" && (
            <img className="popup_icon" src={Error} alt={type} />
          )}
          {type === "Info" && (
            <img className="popup_icon" src={Info} alt={type} />
          )}
          {type === "Warning" && (
            <img className="popup_icon" src={Warning} alt={type} />
          )}
          <p className="text-center fw-600 m-0 my-2">{type}</p>
          <Typography className="text-center fw-400 m-0 ">{message}</Typography>
          <Box className="my-4 alert-modal-btn">
            {onClose &&
              !buttons &&
              (type === "Error" ? (
                <Button
                  className="popup_Btn error_popup_btn "
                  onClick={handleClose}
                >
                  ok, Close
                </Button>
              ) : (
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={handleClose}
                >
                  ok, All Good
                </Button>
              ))}
            {buttons && buttons}
          </Box>
        </Box>
      </Box>
      {footer && (
        <>
          <hr />
          <Stack direction={"row"} justifyContent="center" padding={2}>
            <img src={help_center} alt="help_center" className="me-1" />
            <span className="me-1">Having trouble?</span>
            <CallUsNow
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              renderText={
                <Link className="text-blue text-decoration-none" to="">
                  Contact support
                </Link>
              }
            />{" "}
          </Stack>
        </>
      )}
    </>
  );
};

export default PlainAlertMessage;
