import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

import useTimer from "../../hooks/useTimer";
import { Loader } from "../../common/loader";
import { ROLES } from "../../helpers/constants";
import PersonalTokenScreen from "../../common/OTPScreen";
import { userSigInAction } from "../../../redux/slices/user/userSignInSlice";
import {
  censorEmail,
  decryptPayload,
  subUsersLoginCount,
} from "../../helpers/utils";

const TwoFactorAuth = ({ backToAuth, isBiometric, code, authWay }) => {
  const [open, setOpen] = useState(false);
  const [otpErr, SetOTPErr] = useState(undefined);
  const [otpSucess, SetOTPSucess] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //initializes the timer states for hook
  const { stopTimer } = useTimer(120);

  const loginDetails = useSelector(
    (state) => state.signInUser.userSignInDetails
  );
  const userCredentials = useSelector(
    (state) => state.signInUser.userCredentials
  );
  const optVerification = useSelector(
    (state) => state.signInUser.optVerification
  );
  const resendOTP = useSelector((state) => state.signInUser.resendOTP);
  const sendOTPResponse = useSelector(
    (state) => state.signInUser.sendOTPResponse
  );

  useEffect(() => {
    console.log("send opt-->", optVerification);
    if (Object.keys(optVerification).length > 0) {
      console.log("validate 1");
      handleClose();
    }
  }, [optVerification]);

  useEffect(() => {
    console.log("send opt-->", resendOTP);
    if (Object.keys(resendOTP).length > 0) {
      console.log("validate 2");
      handleClose();
    }
  }, [resendOTP]);

  const handleClose = () => {
    const previousPath = localStorage.getItem("previousPath");
    console.log("optVerification", optVerification);
    if (optVerification?.success) {
      let uID =
        optVerification?.data?.user?.address?.user_id ||
        optVerification?.data?.user?.id;
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.secondaryUser
        )
      ) {
        subUsersLoginCount(uID);
      }
      localStorage.setItem("uID", uID);
      dispatch(userSigInAction.clearStoreData({}));
      localStorage.setItem("stanupPopupCount", 1);
      if (code === "ios") {
        localStorage.setItem("isIos", true);
      } else {
        localStorage.removeItem("isIos");
      }
      if (
        previousPath &&
        parseInt(uID) === parseInt(localStorage.getItem("previousUserID"))
      ) {
        localStorage.removeItem("previousPath");
        navigate(previousPath, { replace: false });
      } else if (optVerification?.data?.role?.includes(ROLES.professional)) {
        localStorage.setItem("paymentPopup", 0);
        navigate("/professional-dashboard", { replace: false });
      } else {
        localStorage.setItem("paymentPopup", 0);
        if (localStorage.getItem("plan_renewal")) {
          navigate("/subscriptions", { replace: false });
        } else {
          navigate("/user-profile", { replace: false });
        }
        stopTimer();
      }
      SetOTPErr("");
    }

    if (!optVerification?.success) {
      SetOTPErr(optVerification?.data?.message);
    }
    if (Object.keys(resendOTP).length > 0) {
      if (resendOTP?.success) {
        SetOTPErr("");
        SetOTPSucess(resendOTP?.message);
      }
      if (!resendOTP?.success) {
        SetOTPErr(resendOTP?.data?.message);
      }
    }

    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const onsubmit = (otp) => {
    let inputParam = {
      verification_code: otp,
      ...userCredentials,
      otp_identifier:
        sendOTPResponse?.data?.otp_identifier ||
        resendOTP?.data?.otp_identifier,
    };
    console.log(otp, "on submit==>.", inputParam);
    handleToggle();
    let url = "2fa-verify-otp";
    if (authWay === "email") {
      url = "2fa-verify-otp-email";
    }
    dispatch(
      userSigInAction.userVerifyOTP({
        url: url,
        data: inputParam,
      })
    );
  };

  const resendOTPSubmit = () => {
    console.log("resendOTPSubmit==>>");
    let inputParam = {
      email: userCredentials?.email,
    };
    let url = "2fa-resend-otp";
    if (authWay === "email") {
      url = "2fa-otp-email";
    }
    dispatch(userSigInAction.sendOTPResponse({}));
    dispatch(
      userSigInAction.resendOTP({
        url: url,
        data: inputParam,
      })
    );
    handleToggle();
  };

  const removeResendMsg = () => {
    SetOTPSucess("");
  };

  const backToAuthScreen = () => {
    setOpen(false);
    SetOTPErr(undefined);
    SetOTPSucess("");
    dispatch(userSigInAction.sendOTPResponse({}));
    backToAuth();
  };

  console.log("login details==>>", loginDetails);
  const phoneNumber =
    loginDetails?.data?.phone_number || loginDetails?.data?.user?.phone_number;
  const phn_num1 = phoneNumber?.substring(phoneNumber?.length - 4);
  const msg =
    authWay === "mobile"
      ? `mobile phone * *** ***${phn_num1 ? phn_num1 : "4321"}`
      : `Email ID ${censorEmail(userCredentials?.email)}`;
  return (
    <>
      <Box>
        <PersonalTokenScreen
          subTitle={`Please enter the 6 digit security code we have sent to your 
        ${msg}`}
          phoneNumber={phoneNumber}
          onsubmit={onsubmit}
          resendOTPSubmit={resendOTPSubmit}
          otpSucess={otpSucess}
          errOTP={otpErr}
          removeResendMsg={removeResendMsg}
        />
        {isBiometric && (
          <Button type="submit" onClick={backToAuthScreen}>
            Back
          </Button>
        )}
      </Box>
      <Loader open={open} />
    </>
  );
};

export default TwoFactorAuth;
