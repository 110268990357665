import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Stack } from "@mui/material";

import CallUsNow from "../common/CallUsNow";
import {
  BBB_REVIEW_LINK,
  DEFAULT_URL,
  EP_ADDRESS,
  Gorka30,
  Mike30,
  Prager30,
} from "../helpers/constants";
import soc2 from "../../assets/footer/soc2_logo.WebP";
import { ROLES, SUBDOMAIN } from "../helpers/constants";
import csiLogo from "../../assets/footer/csi_logo.webp";
import BBB2 from "../../assets/leadpage/family_img_bedge.WebP";
import footerLogo from "../../assets/footer/equity_grayscale.WebP";
import { decryptPayload, navigateToCustomerDomain } from "../helpers/utils";
import "../../styles/footer.scss";

const Footer = ({ isTryPage = false }) => {
  const navigate = useNavigate();

  const path = window.location.pathname;
  const promocode_source = path?.includes("gorka")
    ? Gorka30
    : path?.includes("gallagher")
    ? Mike30
    : false;
  let promocode = promocode_source
    ? promocode_source
    : path?.includes("prager")
    ? Prager30
    : null;

  // handle My Account Button
  const handleMyAccountBtn = () => {
    if (isTryPage) {
      window.location.href = `${DEFAULT_URL}login`;
    } else {
      let user = localStorage.getItem("authorised");
      // let userRole = userSignInDetails?.data?.role;
      let userRole = decryptPayload(localStorage.getItem("roles"));
      if (
        user === "true" &&
        (userRole?.includes(ROLES.admin) ||
          userRole?.includes(ROLES.subAdmin) ||
          userRole?.includes(ROLES.provider) ||
          userRole?.includes(ROLES.countyRecorder))
      ) {
        navigate("/admin-profile");
      } else if (
        user === "true" &&
        (userRole?.includes(ROLES.customer) ||
          userRole?.includes(ROLES.secondaryUser))
      ) {
        navigate("/user-profile");
      } else if (user === "true" && userRole?.includes(ROLES.serviceProvider)) {
        navigate("/servicer-profile");
      } else {
        navigate("/login");
      }
    }
  };

  const handleRedirect = (route = "/") => {
    window.location.href = `${DEFAULT_URL}${route}`;
  };
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  return (
    <section className="footer_body">
      <Container fluid className="mx-150">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="w-100 d-flex justify-content-center align-items-center">
                <LazyLoadImage
                  src={footerLogo}
                  className="footer_logo mx-3"
                  alt="footerLogo"
                  style={{ width: "200px", height: "100%" }}
                />
                {is_scrp_hp && (
                  <LazyLoadImage
                    src={csiLogo}
                    className="footer_logo mx-3"
                    alt="footerLogo"
                    style={{ width: "66px", height: "72px" }}
                  />
                )}
              </div>
              <Nav className="footer_nav">
                <Link
                  className="text-uppercase"
                  style={{ padding: "0 27.5px" }}
                  to={
                    !isTryPage
                      ? is_scrp_hp
                        ? `/county-recorder/${countyCode}`
                        : "/"
                      : "#"
                  }
                  onClick={() => (isTryPage ? handleRedirect("home") : null)}
                >
                  Home
                </Link>
                {window.location.hostname
                  .split(".")[0]
                  ?.includes(SUBDOMAIN.servicer) &&
                window.location.hostname.split(".")[0] !== "staging" ? (
                  <>
                    <Link
                      className={() =>
                        `text-white text-uppercase mx-0 mx-md-3 mx-6 
                    }`
                      }
                      onClick={() => navigateToCustomerDomain("")}
                    >
                      For Customer
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      className="text-uppercase"
                      style={{ padding: "0 27.5px" }}
                      to={!isTryPage ? "/how-it-works" : "#"}
                      onClick={() =>
                        isTryPage ? handleRedirect("how-it-works") : null
                      }
                    >
                      How it Works
                    </Link>
                    <Link
                      className="text-uppercase"
                      style={{ padding: "0 27.5px" }}
                      to={`${is_scrp_hp ? "/" + countyCode : ""}/pricing${
                        promocode ? "?" + promocode : ""
                      }`}
                      // to={!isTryPage ? "/pricing" : "#"}
                      onClick={() =>
                        isTryPage ? handleRedirect("pricing") : null
                      }
                    >
                      Pricing
                    </Link>
                  </>
                )}
                {!is_scrp_hp && (
                  <Link
                    className="text-uppercase"
                    style={{ padding: "0 27.5px" }}
                    to={!isTryPage ? "/for-service-provider" : "#"}
                    onClick={() =>
                      isTryPage ? handleRedirect("for-service-provider") : null
                    }
                  >
                    For Service providers
                  </Link>
                )}
                <Link
                  className="text-uppercase"
                  style={{ padding: "0 27.5px" }}
                  to={!isTryPage ? "/sitemap" : "#"}
                  onClick={() => (isTryPage ? handleRedirect("sitemap") : null)}
                >
                  Sitemap
                </Link>
              </Nav>
              <div className="d-flex justify-content-center pb-4">
                <CallUsNow
                  type="text"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                />
                <button
                  className="m-2 footer_btn footer_green"
                  onClick={handleMyAccountBtn}
                >
                  My Account
                </button>
              </div>
              <div className="coyright_bar">
                <p className="mb-2">
                  © {new Date().getFullYear()} EquityProtect, Inc. All rights
                  reserved
                </p>
                <p className="mb-0">EquityProtect Inc. - {EP_ADDRESS}</p>
              </div>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                gap={2}
                className="align-items-center mt-3"
              >
                <a href={BBB_REVIEW_LINK} target="_blank" rel="noreferrer">
                  <LazyLoadImage
                    src={BBB2}
                    className="footer_logo"
                    alt="bbbLogo"
                    style={{
                      width: "auto",
                      height: "60px",
                    }}
                  />
                </a>
                <LazyLoadImage
                  src={soc2}
                  className="footer_logo"
                  alt="soc2Logo"
                  style={{
                    width: "auto",
                    height: "90px",
                  }}
                />
              </Stack>
              <hr className="separator" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default React.memo(Footer);
