import React from "react";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import PropertyTable from "./PropertyTable";
import { useSelector } from "react-redux";

const propertyBtnStyles = {
  padding: "5px 30px",
  borderRadius: "20px",
};

const DashBoard = ({ tabComponent = false, offsetNum }) => {
  const { selectedProperty } = useSelector((state) => state?.propertyUsers);
  const queryString = window.location.pathname;
  const userId = queryString.split("/")?.[2];
  console.log(parseInt(userId), "url val== >>", queryString);
  return (
    <>
      {!tabComponent && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Typography variant="h6" className="title">
            {userId ? "Users" : "Latest"} Properties
          </Typography>
        </Box>
      )}
      <Box>
        {/* <PropertyTable userId={userId} /> */}
        <PropertyTable
          userId={userId || selectedProperty?.user_id}
          tabComponent={tabComponent}
          offsetNum={offsetNum}
        />
      </Box>
    </>
  );
};

export default DashBoard;
